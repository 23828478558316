import { connect } from 'react-redux'
import {dismissToast} from "../../actions/productsBundle";
import ProductsWarranty from "../../components/products/ProductsWarranty";
import {loadProductWarranty} from "../../actions/productsWarranty";

const mapStateToProps = state => ({
    toastIsActive: state.productsWarranty.toastIsActive,
    isShowWarranties: state.productsWarranty.isShowWarranties,
    isShowEdit: state.productsWarranty.isShowEdit,
    isShowBulkEdit: state.productsWarranty.isShowBulkEdit,
    ready: state.productsWarranty.ready,
});

const mapDispatchToProps = (dispatch) => ({
    onDismissToast: () => dispatch(dismissToast()),
    load: () => dispatch(loadProductWarranty()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductsWarranty);
