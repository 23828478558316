import React  from 'react';
import '../App.css';
import {Card, ProgressBar, Spinner, Stack, Tabs, Frame, Toast} from '@shopify/polaris';
import '@shopify/polaris/dist/styles.css';
import { connect } from 'react-redux';
import VisibleSupport from "./VisibleSupport";
import {dismissOutOfDateToast, fetchDataIfNeeded, updateZoorix} from "../actions/data";
import VisibleFAQ from "./VisibleFAQ";
import VisibleStatus from "./VisibleStatus";
import {
    changeTab, dismissGenericToast,
    getTabIndexByName,
    handleTabChange,
    sliderClicked,
} from "../actions/nav";
import {createMappingAction} from "../actions/products";
import VisibleManualInstallation from "./VisibleManualInstallation";
import VisiblePlans from "./plans/VisiblePlans";
import VisibleProductsMain from "./products/VisibleProductsMain";
import VisibleSettingsMain from "./settings/VisibleSettingsMain";
import VisibleCssEditor from "./VisibleCssEditor";
import VisibleWarrantyModeration from "./VisibleWarrantyModeration";
import VisibleTextsMain from "./texts/VisibleTextsMain";
import VisibleAnalyticsMain from "./analytics/VisibleAnalyticsMain";
import VisibleIntegrations from "./VisibleIntegrations";
import VisibleAppV2Wizard from "./v2/wizard/VisibleAppV2Wizard";

const App = ({ match: { params }, fetchDataIfNeeded, isFetching, selected, handleTabChange, createMappingActionInvoked,
                 createMappingAction, jumpToProducts, jumpToStatus, tabs, onSliderClicked, installationProgress,
                 outOfDateToastActive, dismissOutOfDateToast, updateZoorix, showAdminWizard, genericToastActive,
                 genericToastContent, dismissGenericToast }) => {
    fetchDataIfNeeded(params.storeName, params.token, params.app_type);
    if (params.action === 'create_mapping' && !createMappingActionInvoked) {
        jumpToProducts();
        createMappingAction(params.storeName, params.token, params.product_id);
        onSliderClicked();
    } else if (params.app_type === 'bundles') {

    } else if (typeof selected === 'undefined') {
        jumpToStatus();
        onSliderClicked();
    }

    function getTab(selected) {
        if (selected === getTabIndexByName('status', tabs)) {
            return (
                <div>
                    <VisibleStatus params={params} />
                </div>
            );
        } else if (selected === getTabIndexByName('settings', tabs)) {
            return (
                <Card.Section>
                    <VisibleSettingsMain />
                </Card.Section>
            )
        } else if (selected === getTabIndexByName('products', tabs)) {
            return (
                <Card.Section>
                    <VisibleProductsMain />
                </Card.Section>
            )
        } else if (selected === getTabIndexByName('texts', tabs)) {
            return (
                <Card.Section>
                    <VisibleTextsMain />
                </Card.Section>
            )
        } else if (selected === getTabIndexByName('analytics', tabs)) {
            return (
                <VisibleAnalyticsMain />
            )
        } else if (selected === getTabIndexByName('support', tabs)) {
            return (
                <Card.Section>
                    <VisibleSupport />
                </Card.Section>
            )
        } else if (selected === getTabIndexByName('faq', tabs)) {
            return (
                <Card.Section>
                    <VisibleFAQ />
                </Card.Section>
            )
        } else if (selected === getTabIndexByName('manual-installation', tabs)) {
            return (
                <VisibleManualInstallation />
            )
        } else if (selected === getTabIndexByName('plans', tabs)) {
            return (
                <VisiblePlans />
            )
        } else if (selected === getTabIndexByName('css-editor', tabs)) {
            return (
                <VisibleCssEditor />
            )
        } else if (selected === getTabIndexByName('warranty-moderation', tabs)) {
            return (
                <VisibleWarrantyModeration />
            )
        } else if (selected === getTabIndexByName('integrations', tabs)) {
            return (
                <VisibleIntegrations />
            )
        }
    }
    return (
        <Frame>
            {isFetching ? (
                <Card>
                    <Stack distribution="center">
                        <Stack.Item>
                            <div className="app-loading">
                                <Spinner />
                            </div>
                        </Stack.Item>
                    </Stack>
                </Card>
            ) : showAdminWizard ? <div className={'app-v2-wizard-wrapper'}>
                <VisibleAppV2Wizard />
            </div> : (
                    <div className="zrx-tabs-wrapper">
                        {installationProgress < 100 && <div className="app-progress-bar"><ProgressBar progress={installationProgress} size={"small"} /></div>}
                        <Card>
                            {tabs ? (
                                <Tabs tabs={tabs} selected={selected} onSelect={handleTabChange}>
                                    {getTab(selected)}
                                </Tabs>) : null}
                        </Card>
                    </div>
                )}
            {outOfDateToastActive && <Toast content="Zoorix is out of date" onDismiss={dismissOutOfDateToast}
                                            action={{
                                                content: 'Update',
                                                onAction: updateZoorix,
                                            }}
                                            duration={60_000} />}
            {genericToastActive && <Toast content={genericToastContent} onDismiss={dismissGenericToast} />}
        </Frame>

    );
};

const mapStateToProps = state => {
    return {
        isFetching: state.data.isFetching,
        showAdminWizard: state.data.showAdminWizard,
        selected: state.nav.selected,
        genericToastActive: state.nav.genericToastActive,
        genericToastContent: state.nav.genericToastContent,
        tabs: state.nav.tabs,
        createMappingActionInvoked: state.products.createMappingActionInvoked,
        installationProgress: state.status.installationProgress,
        outOfDateToastActive: state.data.outOfDateToastActive,
    };
};

const mapDispatchToProps = dispatch => ({
    fetchDataIfNeeded: (shopName, token, appType) => dispatch(fetchDataIfNeeded(shopName, token, appType)),
    handleTabChange: tab => dispatch(handleTabChange(tab)),
    jumpToProducts: () => dispatch(changeTab('products')),
    jumpToStatus: () => dispatch(changeTab('status')),
    createMappingAction: (shopName, token, product_id) => dispatch(createMappingAction(shopName, token, product_id)),
    onSliderClicked: () => dispatch(sliderClicked()),
    dismissOutOfDateToast: () => dispatch(dismissOutOfDateToast()),
    dismissGenericToast: () => dispatch(dismissGenericToast()),
    updateZoorix: () => dispatch(updateZoorix()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
