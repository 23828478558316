import { connect } from 'react-redux'
import {platinumPlusChangeRange} from "../../actions/plans";
import OurPricing from "../../components/plans/OurPricing";

const mapStateToProps = state => ({
    platinumPlusRange: state.plans.platinumPlusRange,
});

const mapDispatchToProps = (dispatch) => ({
    platinumPlusChangeRange: value => dispatch(platinumPlusChangeRange(value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OurPricing);
