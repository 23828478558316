import { connect } from 'react-redux'
import {platPlusBetaChangeRange} from "../../actions/plans";
import OurPricingPlatPlusBeta from "../../components/plans/OurPricingPlatPlusBeta";

const mapStateToProps = state => ({
    platPlusBetaRange: state.plans.platPlusBetaRange,
});

const mapDispatchToProps = (dispatch) => ({
    platPlusBetaChangeRange: value => dispatch(platPlusBetaChangeRange(value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OurPricingPlatPlusBeta);
