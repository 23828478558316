import React from 'react';
import {
    Collapsible,
    DataTable
} from "@shopify/polaris";
import '../../css/Analytics.css'
import Moment from "react-moment";

const AnalyticsOrders = ({
    ordersGenByZoorix, productsGenByZoorix, openOrder, orderOpen }) => (
    <div className="roi-summary-orders">
        <div className="roi-data-table">
            <DataTable
                columnContentTypes={[
                    'text',
                    'text',
                    'text',
                ]}
                headings={['Order name', 'Processed at', 'Total']}
                rows={ordersGenByZoorix.map(o => [
                    <div key={o.id}>
                        <div onClick={openOrder(o.id)}
                             className={'roi-clickable-offer'}
                        >{o.name}</div>
                        <Collapsible open={orderOpen[o.id]}
                                     transition={{duration: '500ms', timingFunction: 'ease-in-out'}}>
                            <DataTable
                                columnContentTypes={['text', 'text', 'text', 'text']}
                                headings={['Product Name', 'QTY', 'Price', 'Generated by Zoorix']}
                                rows={o.shopify_line_items.map(i => [
                                    productsGenByZoorix[i.shopify_product_id] && productsGenByZoorix[i.shopify_product_id].title,
                                    i.quantity,
                                    <span>{i.price} {o.currency}</span>,
                                    <span>{i.gen_by_zoorix ? 'Yes' : 'No'}</span>,
                                ])}
                            />
                        </Collapsible>
                    </div>,
                    <Moment format="YYYY/MM/DD HH:mm:ss">{o.processed_at}</Moment>,
                    <span>{o.total_price} {o.currency}</span>,
                ])}
            />
        </div>
    </div>

    );

export default AnalyticsOrders;
