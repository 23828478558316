import { connect } from 'react-redux'
import TextsMixAndMatch from "../../components/texts/TextsMixAndMatch";
import {textsChangeText} from "../../actions/texts";

const mapStateToProps = state => ({
    translations: state.texts.translations,
    locale: state.texts.locale,
    saving: state.texts.saving,
});

const mapDispatchToProps = (dispatch) => ({
    onTextChange: (widget, attr) => text => dispatch(textsChangeText(widget, attr, text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TextsMixAndMatch);
