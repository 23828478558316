import React from 'react';
import '../../css/TextsMain.css'
import { TextField} from "@shopify/polaris";

const TextsMinicart = ({ translations, locale, saving, restoreDefaults, onFreeDiscountTitleChange,
                           onEmptyCartTextChange, onCartTotalTextChange, onCheckoutTextChange,
                           onUnlockDiscountTextChange, onFreeDiscountUnlockedTextChange, onCartTitleChange,
                           onFreeShippingTitleChange, onUnlockShippingTextChange, onFreeShippingUnlockedTextChange,
                           onMiniCartViewCartTextChange, onMiniCartAddCartNoteTextChange,
                           onMiniCartCartNotePlaceholderTextChange, onMiniCartOfferCtaTextChange, onTextChange }) =>
    <div>
            <TextField label="Free Discount Title:" onChange={onFreeDiscountTitleChange}
                       value={translations[locale].mini_cart.free_discount_title}
                       disabled={saving} autoComplete={false} />
            <TextField label="Empty Cart Text:"
                       value={translations[locale].mini_cart.empty_cart_text}
                       onChange={onEmptyCartTextChange}
                       disabled={saving} autoComplete={false} />
            <TextField label="Cart total:"
                       value={translations[locale].mini_cart.cart_total_text}
                       onChange={onCartTotalTextChange}
                       disabled={saving} autoComplete={false} />
            <TextField label="Checkout:"
                       value={translations[locale].mini_cart.checkout_text}
                       onChange={onCheckoutTextChange}
                       disabled={saving} autoComplete={false} />
            <TextField label="Free Discount Unlock Discount:"
                       value={translations[locale].mini_cart.unlock_discount_text}
                       onChange={onUnlockDiscountTextChange}
                       disabled={saving} autoComplete={false} />
            <TextField label="Free Discount Unlocked:"
                       value={translations[locale].mini_cart.free_discount_unlocked_text}
                       onChange={onFreeDiscountUnlockedTextChange}
                       disabled={saving} autoComplete={false} />
            <TextField label="Title:"
                       value={translations[locale].mini_cart.cart_title}
                       onChange={onCartTitleChange}
                       disabled={saving} autoComplete={false} />
            <TextField label="Free Shipping Title:"
                       value={translations[locale].mini_cart.free_shipping_title}
                       onChange={onFreeShippingTitleChange}
                       disabled={saving} autoComplete={false} />
            <TextField label="Free Shipping Unlock Shipping:"
                       value={translations[locale].mini_cart.unlock_shipping_text}
                       onChange={onUnlockShippingTextChange}
                       disabled={saving} autoComplete={false} />
            <TextField label="Free Shipping Unlocked:"
                       value={translations[locale].mini_cart.free_shipping_unlocked_text}
                       onChange={onFreeShippingUnlockedTextChange}
                       disabled={saving} autoComplete={false} />
            <TextField label="View Cart Text:"
                       value={translations[locale].mini_cart.mini_cart_view_cart_text}
                       onChange={onMiniCartViewCartTextChange}
                       disabled={saving} autoComplete={false} />
            <TextField label="Cart Note:"
                       value={translations[locale].mini_cart.add_cart_note_text}
                       onChange={onMiniCartAddCartNoteTextChange}
                       disabled={saving} autoComplete={false} />
            <TextField label="Cart Note Placeholder:"
                       value={translations[locale].mini_cart.cart_note_placeholder_text}
                       onChange={onMiniCartCartNotePlaceholderTextChange}
                       disabled={saving} autoComplete={false} />
            <TextField label="Offer Call To Action:"
                       value={translations[locale].mini_cart.offer_cta_text}
                       onChange={onMiniCartOfferCtaTextChange}
                       disabled={saving} autoComplete={false} />
            <TextField label="Free Text:" value={translations[locale].mini_cart.free_text}
                       onChange={onTextChange('mini_cart', 'free_text')}
                       disabled={saving} autoComplete={false} />
            <TextField label="I Agree Text:" value={translations[locale].mini_cart.i_agree_text}
                       onChange={onTextChange('mini_cart', 'i_agree_text')}
                       disabled={saving} autoComplete={false} />
            <TextField label="Warning Text:" value={translations[locale].mini_cart.warning_text}
                       onChange={onTextChange('mini_cart', 'warning_text')}
                       disabled={saving} autoComplete={false} />
            <TextField label="Continue Shopping Text:" value={translations[locale].mini_cart.continue_shopping_text}
                       onChange={onTextChange('mini_cart', 'continue_shopping_text')}
                       disabled={saving} autoComplete={false} />
            <TextField label="Checkout Redirect Text:" value={translations[locale].mini_cart.checkout_redirect_text}
                       onChange={onTextChange('mini_cart', 'checkout_redirect_text')}
                       disabled={saving} autoComplete={false} />


            {/*<span className="texts-restore">*/}
            {/*        <Button onClick={restoreDefaults}>Restore defaults</Button>*/}
            {/*</span>*/}
</div>;

export default TextsMinicart;
