import { connect } from 'react-redux'
import PartnersLogin from "../../components/partners/PartnersLogin";
import {
    partnersEmailChanged,
    partnersLogin,
    partnersNavToForgotPassword,
    partnersNavToLogin,
    partnersNavToSignUp,
    partnersPasswordChanged,
    partnersSendForgotPasswordCode, partnersSetNewPassword,
    partnersSignUp,
    partnersVerificationCodeChange,
    partnersVerifyCode
} from "../../actions/partners";

const mapStateToProps = state => ({
    email: state.partners.email,
    password: state.partners.password,
    loggingIn: state.partners.loggingIn,
    navState: state.partners.navState,
    signingUp: state.partners.signingUp,
    verificationCode: state.partners.verificationCode,
    verifyingCode: state.partners.verifyingCode,
    sendingForgotPasswordCode: state.partners.sendingForgotPasswordCode,
});

const mapDispatchToProps = (dispatch) => ({
    onEmailChanged: text => dispatch(partnersEmailChanged(text)),
    onPasswordChanged: text => dispatch(partnersPasswordChanged(text)),
    onVerificationCodeChange: text => dispatch(partnersVerificationCodeChange(text)),
    onLogin: () => dispatch(partnersLogin()),
    onNavToSignUp: () => dispatch(partnersNavToSignUp()),
    onSignUp: () => dispatch(partnersSignUp()),
    onVerifyCode: () => dispatch(partnersVerifyCode()),
    onSetNewPassword: () => dispatch(partnersSetNewPassword()),
    onBack: () => dispatch(partnersNavToLogin()),
    onForgotPassword: () => dispatch(partnersNavToForgotPassword()),
    onSendForgotPasswordCode: () => dispatch(partnersSendForgotPasswordCode()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PartnersLogin);
