import React from 'react';
import '../../css/TextsMain.css'
import { TextField} from "@shopify/polaris";

const TextsWarranty = ({ translations, locale, saving, restoreDefaults, onTitleChange, onCallToActionChange,
                           onTooltipTemplateChange}) =>
    <div>
        <TextField label="Widget Title:" value={translations[locale].warranty.title}
                   onChange={onTitleChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Widget Call To Action:" value={translations[locale].warranty.call_to_action}
                   onChange={onCallToActionChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Tooltip HTML Template:" value={translations[locale].warranty.tooltip_template}
                   onChange={onTooltipTemplateChange}
                   disabled={saving} autoComplete={false} />

        {/*<span className="texts-restore">*/}
        {/*        <Button onClick={restoreDefaults}>Restore defaults</Button>*/}
        {/*</span>*/}
</div>;

export default TextsWarranty;
