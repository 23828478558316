import { connect } from 'react-redux'
import ProductsCartDrawer from "../../components/products/ProductsCartDrawer";
import {
    cartDrawerAddOffer, cartDrawerChangeProductSearchText, cartDrawerChooseOfferProduct,
    cartDrawerCloseChooseProduct,
    cartDrawerDeleteOffer, cartDrawerFocusProductSearchText, cartDrawerMoveItem,
    loadCartDrawer
} from "../../actions/productsCartDrawer";

const mapStateToProps = state => ({
    ready: state.productsCartDrawer.ready,
    offers: state.productsCartDrawer.offers,
    deletingOffer: state.productsCartDrawer.deletingOffer,
    chooseProductOpen: state.productsCartDrawer.chooseProductOpen,
    searchBoxText: state.productsCartDrawer.searchBoxText,
    showSearchSpinner: state.productsCartDrawer.showSearchSpinner,
    searchedProducts: state.productsCartDrawer.searchedProducts,
    selectingOfferProduct: state.productsCartDrawer.selectingOfferProduct,
});

const mapDispatchToProps = dispatch => ({
    load: () => dispatch(loadCartDrawer()),
    deleteOffer: id => () => dispatch(cartDrawerDeleteOffer(id)),
    addOffer: () => dispatch(cartDrawerAddOffer()),
    onCloseChooseProduct: () => dispatch(cartDrawerCloseChooseProduct()),
    onChangeSearchText: text => dispatch(cartDrawerChangeProductSearchText(text)),
    onFocusSearchText: () => dispatch(cartDrawerFocusProductSearchText()),
    chooseOfferProduct: id => () => dispatch(cartDrawerChooseOfferProduct(id)),
    moveItem: (direction, index) => () => dispatch(cartDrawerMoveItem(direction, index)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductsCartDrawer);
