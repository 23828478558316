import { connect } from 'react-redux'
import ImportExportBundles from "../../components/products/ImportExportBundles";
import {
    back,
    bundlesGetSignedUrlCallback,
    bundlesUploadSuccess, overrideExistingBundlesOnImport,
    overrideExistingBundlesOnImportChange, toggleOverrideExistingBundlesModal
} from "../../actions/productsBundle";

const mapStateToProps = state => ({
    productsBundle: state.productsBundle,
    data: state.data,
});

const mapDispatchToProps = (dispatch) => ({
    back: () => dispatch(back()),
    overrideExistingBundlesOnImport: () => dispatch(overrideExistingBundlesOnImport()),
    uploadSuccess: (response, json) => dispatch(bundlesUploadSuccess(response, json)),
    toggleOverrideExistingBundlesModal: bool => () => dispatch(toggleOverrideExistingBundlesModal(bool)),
    overrideExistingBundlesOnImportChange: bool => dispatch(overrideExistingBundlesOnImportChange(bool)),
    getSignedUrlCallback: data => () => bundlesGetSignedUrlCallback(data),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ImportExportBundles);
