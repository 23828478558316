import { connect } from 'react-redux'
import Analytics from "../../components/analytics/Analytics";

const mapStateToProps = state => {
    return {
        totalOrders: state.analytics.totalOrders,
        zoorixOrders: state.analytics.zoorixOrders,
        currency: state.analytics.currency,
        multiCurrency: state.analytics.multiCurrency,
        totalSales: state.analytics.totalSales,
        totalZoorixSales: state.analytics.totalZoorixSales,
        zoorixRevenue: state.analytics.zoorixRevenue,
        zoorixRevenueUsd: state.analytics.zoorixRevenueUsd,
        payments: state.analytics.payments,
        revenue: state.analytics.revenue,
        orders: state.analytics.orders,
        analytics: state.analytics,
    };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Analytics);
