import React from 'react';
import {Button, TextField, TextStyle} from "@shopify/polaris";
import '../../css/Feedback.css'

const Feedback = ({
                      match: {params}, submitFiveStars, isRatingSubmitting, ratingText, onRatingTextChanged,
                      isRatingSubmitError, submitRating, initFeedback, isShowThankYou, gifSrc, type
                  }) => {

    const isFiveStarsRating = params.rating === '5';
    initFeedback(params.token, params.rating);

    const typeIsStandard = type === 'standard';
    const typeIsSupport = type === 'support';
    
    return (
        <div>
            {isShowThankYou ? (
                <div className="feedback-sent-wrapper">
                    <img width="60" height="60" alt="zoorix" src="https://public.zoorix.com/images/zoorix-logo.png" />
                    <div className="feedback-sent">Feedback Sent.</div>
                    <div>Thank You <span aria-label='thanks' role="img">🙏</span></div>

                </div>
            ) : (
                    <div>
                        {isFiveStarsRating ? (
                            <div className="happy-gif-wrapper">
                                <div className="happy-gif">
                                    <img className="happy-gif-img" alt='WooHoo!'
                                        src={gifSrc}
                                    />
                                </div>
                                <div className="whohoo"><i className="fa fa-heart" /><span
                                    className="whohoo-text">WooHoo</span><i className="fa fa-heart" /></div>
                                <div className="happy-text">
                                    {typeIsStandard && <div>
                                        We’re so happy to know that you’re enjoying Zoorix. It’s
                                        wonderful to know that our hard work is being appreciated :)
                                        Please take a moment and share your experience with other merchants on Shopify. Thank
                                        you for your support!
                                    </div>}
                                    {typeIsSupport && <div>
                                       Thank you so much for your feedback on our support!<span aria-label='party' role="img"> 🥳</span>
                                    </div>}
                                </div>
                                <div className="rate-button">
                                    <Button primary onClick={submitFiveStars}
                                            loading={isRatingSubmitting}>Rate Zoorix on Shopify</Button>
                                </div>
                            </div>
                        ) : (
                                <div>
                                    <div className="feedback-wrapper">
                                        <div className="feedback">
                                            <div className="help-us">
                                                {typeIsStandard && <span>Help us improve Zoorix</span>}
                                                {typeIsSupport && <span>Help us improve Zoorix</span>}
                                            </div>
                                            <p className="asking-feedback">
                                                {typeIsStandard && <span>
                                                If your experience with Zoorix has been anything short of
                                                awesome, please help us improve our product by leaving your feedback below
                                                </span>}
                                                {typeIsSupport && <span>
                                                If your experience with Zoorix has been anything short of
                                                awesome, please help us to improve by leaving your feedback below
                                                </span>}
                                            </p>
                                            <div>
                                                <TextField className="text-area" multiline={5} value={ratingText}
                                                    onChange={onRatingTextChanged} />
                                            </div>
                                            {isRatingSubmitError ?
                                                (<TextStyle variation="negative">Something went wrong, please try to submit
                                            again</TextStyle>)
                                                : null}
                                            <div className="submit-button"><Button primary loading={isRatingSubmitting}
                                                onClick={submitRating}>Submit</Button></div>
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                )}
        </div>
    );
};

export default Feedback;
