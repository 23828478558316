import React from 'react';
import * as _ from 'lodash';
import {
    Card, DataTable
} from "@shopify/polaris";
import '../../css/Analytics.css'

function mapTotals(products, currency) {
    return [
        '',
        _.sum(Object.values(products).map(p => p.quantity)),
        `${_.sum(Object.values(products).map(p => Number(p.total))).toFixed(2)} ${currency}`,
    ]
}

const AnalyticsProducts = ({ handleSortProducts, sortedProducts, products, currency }) => (
    <div>
        {sortedProducts && sortedProducts.length > 0 && (
                <div>
                    <div className="roi-summary-products">
                        <Card>
                            <DataTable
                                columnContentTypes={[
                                    'text',
                                    'numeric',
                                    'numeric',
                                ]}
                                headings={['Product', 'Total quantity', 'Total sales']}
                                rows={sortedProducts}
                                totals={mapTotals(products, currency)}
                                showTotalsInFooter initialSortColumnIndex={0}
                                defaultSortDirection="ascending"
                                sortable={[true, true, true]}
                                onSort={handleSortProducts}
                            />
                        </Card>
                    </div>
                </div>)}
    </div>

    );

export default AnalyticsProducts;
