import React from 'react';
import {Button, Icon, TextField, TextStyle} from '@shopify/polaris';
import '../../css/Pages.css'
import {CaretDownMinor, CaretUpMinor, DeleteMajor} from "@shopify/polaris-icons";
import ReactSearchBox from "react-search-box";
import {calcBundleName} from "../../reducers/productsBundle";

const EditPage = ({page, back, onPageTitleChange, moveBundleUp, moveBundleDown, pageBundles,
                      onBundleSelected, save, pageSaving, saveError, removeBundle}) =>
        <div>
            <span className="bundle-page-back">
            <Button onClick={back}>Back</Button>
            </span>
            <Button onClick={save} loading={pageSaving} primary
                    disabled={page.title === '' || page.bundles.length === 0}>Save</Button>
            {saveError && <TextStyle variation="negative">Something went wrong</TextStyle>}
            <TextField label="Title:" value={page.title} onChange={onPageTitleChange} disabled={pageSaving}
                       autoComplete={false} />
            <div className="search-bundle-title">Search bundle:</div>
            <span className="bundle-search-box">
                <ReactSearchBox value={''} data={pageBundles} onSelect={onBundleSelected}
                            placeholder="Bundle title" />
            </span>
            {page.bundles.map((bundle, index) => <div className="bundle-in-page-item" key={index}>
                <span className={ page.bundles.length > 1 ? 'bundle-page-arrows' : ''}>
                    {index > 0 && <span onClick={moveBundleUp(index)}>
                        <Icon source={CaretUpMinor} /></span>}
                    {index < page.bundles.length - 1 && <span  onClick={moveBundleDown(index)}>
                        <Icon source={CaretDownMinor} /></span>}
                </span>
                {bundle.name} ({calcBundleName(bundle)})
                <span className="remove-page-bundle" onClick={removeBundle(index)}>
                    <Icon source={DeleteMajor} />
                </span>
            </div>)}
        </div>

export default EditPage;
