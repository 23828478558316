import { connect } from 'react-redux'
import ManualInstallation from "../components/ManualInstallation";

const mapStateToProps = state => ({
    installation: state.status.installation,
    jsSelectors: state.settingsApp.jsSelectors,
});

const mapDispatchToProps = (dispatch) => ({

});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ManualInstallation);
