import { connect } from 'react-redux'
import ProductsVolume from "../../components/products/ProductsVolume";
import {loadProductVolume, volumeDismissGeneralToast} from "../../actions/productsVolume";

const mapStateToProps = state => ({
    ready: state.productsVolume.ready,
    loadError: state.productsVolume.loadError,
    isShowDiscounts: state.productsVolume.isShowDiscounts,
    isShowEdit: state.productsVolume.isShowEdit,
    productsVolume: state.productsVolume,
});

const mapDispatchToProps = dispatch => ({
    load: () => dispatch(loadProductVolume()),
    onDismissGeneralToast: () => dispatch(volumeDismissGeneralToast()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductsVolume);
