import React from 'react';
import '../../../css/v2/AppV2Wizard.scss';
import {TextField, Spinner} from "@shopify/polaris";
import 'antd/dist/antd.css';
import {Modal} from 'antd';

export const trigger = (currentTrigger, triggerType, selectTrigger, block) =>
    <div className="select-trigger-item-wrapper">
        <div
            className={currentTrigger === triggerType ? 'select-trigger-item selected' : 'select-trigger-item not-selected'}
            onClick={selectTrigger(triggerType)}>
            <img className="trigger-text" alt="" src={"https://public.zoorix.com/images/" + triggerType + ".svg"}/>
            <div className="trigger-text">{block}</div>
        </div>
        <span className={'checkbox'}>
            <div className={'container'}>
                <div className={'round'}>
                    <input readOnly type="checkbox" checked={currentTrigger === triggerType} id={triggerType}/>
                    <label htmlFor={triggerType}></label>
                </div>
            </div>
        </span>
    </div>

export const select = (appV2Wizard, typeField, type, onClick, collection, emptyText, oneText, manyText) => <div>
    {appV2Wizard[typeField] === type && <div>
        <div onClick={onClick} className={appV2Wizard.invalidSelectTrigger || appV2Wizard.invalidSelectOffer ? 'invalid open-modal-btn': 'open-modal-btn'}>
            {appV2Wizard[collection].length > 1 ? <div>
                {appV2Wizard[collection].length} {manyText}
            </div> : appV2Wizard[collection].length === 1 ? <div>
                1 {oneText}
            </div> : <div>
                {emptyText}
            </div>}
        </div>
    </div>}
</div>

const AppV2WizardSelectTrigger = ({
        appV2Wizard, next, back, selectTrigger, toggleSelectProductsModal,
        approveSelectedProducts, onProductSearchTextChange,
        toggleSelectProduct, removeSelectedProduct, toggleSelectCollectionsModal,
        approveSelectedCollections, onCollectionSearchTextChange,
        toggleSelectCollection, removeSelectedCollection, toggleSelectTagsModal,
        approveSelectedTags, onTagSearchTextChange, removeSelectedTag, toggleSelectTag
    }) =>
        <div className={'app-v2-wizard-select-trigger'}>
            <div className="wizard-select-trigger-title">Select A Trigger</div>
            <div className="wizard-select-trigger-subtitle">A trigger means the action which triggers your offer, e.g. a
                specific product or collection
            </div>

            {appV2Wizard.offerType === 'cross_sell' && <div>
                <div className="select-trigger-wrapper">
                    {trigger(appV2Wizard.triggerType, 'specific_products', selectTrigger, <div
                        className="product-trigger">
                        Show offer on specific products
                    </div>)}
                    {trigger(appV2Wizard.triggerType, 'collections', selectTrigger, <div
                        className="collection-trigger">
                        Show offer on specific collections
                    </div>)}
                    {trigger(appV2Wizard.triggerType, 'tags', selectTrigger, <div
                        className="tag-trigger">
                        Show offer on specific tags
                    </div>)}
                </div>
                <div className={'open-modal-btn-wrapper'}>
                    {select(appV2Wizard, 'triggerType', 'specific_products', toggleSelectProductsModal,
                        'confirmedSelectedProducts', 'Select products', 'Product', 'Products')}
                    {select(appV2Wizard, 'triggerType', 'collections', toggleSelectCollectionsModal,
                        'confirmedSelectedCollections', 'Select collections', 'Collection', 'Collections')}
                    {select(appV2Wizard, 'triggerType', 'tags', toggleSelectTagsModal,
                        'confirmedSelectedTags', 'Select tags', 'Tag', 'Tags')}
                </div>
            </div>}

            {appV2Wizard.offerType === 'mix_and_match' && <div>
                <div className="select-trigger-wrapper">
                    {trigger(appV2Wizard.triggerType, 'mnm_items', selectTrigger, <div
                        className="">
                        Show offer on the Mix & Match items
                    </div>)}
                    {trigger(appV2Wizard.triggerType, 'collections', selectTrigger, <div
                        className="collection-trigger">
                        Show offer on specific collections
                    </div>)}
                </div>
                <div className={'open-modal-btn-wrapper'}>
                    {select(appV2Wizard, 'triggerType', 'collections', toggleSelectCollectionsModal,
                        'confirmedSelectedCollections', 'Select collections', 'Collection', 'Collections')}
                </div>
            </div>}

            {appV2Wizard.offerType === 'bundle' && <div>
                <div className="select-trigger-wrapper">
                    {trigger(appV2Wizard.triggerType, 'bundle_items', selectTrigger, <div className="">
                        Show offer on bundle items
                    </div>)}
                    {trigger(appV2Wizard.triggerType, 'collections', selectTrigger, <div className="">
                        Show offer on specific collections
                    </div>)}
                    {trigger(appV2Wizard.triggerType, 'tags', selectTrigger, <div className="">
                        Show offer on specific tags
                    </div>)}
                </div>
                <div className={'open-modal-btn-wrapper'}>
                    {select(appV2Wizard, 'triggerType', 'collections', toggleSelectCollectionsModal,
                        'confirmedSelectedCollections', 'Select collections', 'Collection', 'Collections')}
                    {select(appV2Wizard, 'triggerType', 'tags', toggleSelectTagsModal,
                        'confirmedSelectedTags', 'Select tags', 'Tag', 'Tags')}
                </div>
            </div>}

            {appV2Wizard.offerType === 'volume_discount' && <div>
                <div className="select-trigger-wrapper">
                    {trigger(appV2Wizard.triggerType, 'specific_products', selectTrigger, <div className="">
                        Show offer on a specific product
                    </div>)}
                    {trigger(appV2Wizard.triggerType, 'collections', selectTrigger, <div className="">
                        Show offer on a specific collection
                    </div>)}
                    {trigger(appV2Wizard.triggerType, 'tags', selectTrigger, <div className="">
                        Show offer on a specific tag
                    </div>)}
                </div>
                <div className={'open-modal-btn-wrapper'}>
                    {select(appV2Wizard, 'triggerType', 'specific_products', toggleSelectProductsModal,
                        'confirmedSelectedProducts', 'Select product', 'Product')}
                    {select(appV2Wizard, 'triggerType', 'collections', toggleSelectCollectionsModal,
                        'confirmedSelectedCollections', 'Select collection', 'Collection')}
                    {select(appV2Wizard, 'triggerType', 'tags', toggleSelectTagsModal,
                        'confirmedSelectedTags', 'Select tag', 'Tag')}
                </div>
            </div>}

            <Modal
                visible={appV2Wizard.selectProductsModalOpen}
                className="trigger-products-modal"
                title={appV2Wizard.offerType === 'volume_discount' ? 'Select trigger product' : 'Select trigger products'}
                onCancel={toggleSelectProductsModal}
                onOk={approveSelectedProducts}
                width="50%"
                cancelText="Close"
                okText="Select"
            >

                <TextField label=""
                           value={appV2Wizard.searchText} placeholder={'Search products'}
                           onChange={onProductSearchTextChange} autoComplete={false}/>
                <div
                    className="selected-items-wrapper">{appV2Wizard.selectedProducts && appV2Wizard.selectedProducts.map(p =>
                    <div className="selected-item" key={p.id}>
                        <span className="selected-item-title">{p.title}</span>
                        <span className="selected-item-x" onClick={removeSelectedProduct(p.id)}>X</span>
                    </div>)}</div>
                {appV2Wizard.searchedProductsSpinner && <span className="spinner-wrapper"><Spinner /></span>}
                <div className="search-items-wrapper">
                    {appV2Wizard.searchedProducts && appV2Wizard.searchedProducts.map(p =>
                        <div key={p.node.id} onClick={toggleSelectProduct(p.node.id)}
                             className={p.selected ? 'search-item app-v2-wizard-selected-product' : 'search-item app-v2-wizard-not-selected-product'}>
                            {p.node.images.edges.length > 0 && <img width={'30px'} height={'30px'} alt=""
                                                                    src={p.node.images.edges[0].node.originalSrc}/>}
                            <span>{p.node.title}</span>
                            <span className={'checkbox'}>
                                <div className={'container'}>
                                    <div className={'round'}>
                                        <input type="checkbox" readOnly checked={p.selected} id={p.node.id}/>
                                        <label htmlFor={p.node.id}></label>
                                    </div>
                                </div>
                             </span>
                            <div className="bottom-border"></div>
                        </div>)}
                </div>
            </Modal>
            <Modal
                visible={appV2Wizard.selectCollectionsModalOpen}
                className="collection-modal"
                title={appV2Wizard.offerType === 'volume_discount' ? 'Select trigger collection' : 'Select trigger collections'}
                onCancel={toggleSelectCollectionsModal}
                onOk={approveSelectedCollections}
                width="50%"
                cancelText="Close"
                okText="Select"
            >

                <div>
                    <TextField label=""
                               value={appV2Wizard.searchText} placeholder={'Search collections'}
                               onChange={onCollectionSearchTextChange} autoComplete={false}/></div>
                <div
                    className="selected-items-wrapper">{appV2Wizard.selectedCollections && appV2Wizard.selectedCollections.map(c =>
                    <div className="selected-item" key={c.id}>
                        <span className="selected-item-title">{c.title}</span>
                        <span className="selected-item-x" onClick={removeSelectedCollection(c.id)}>X</span>
                    </div>)}</div>
                {appV2Wizard.searchedCollectionsSpinner && <span className="spinner-wrapper"><Spinner /></span>}
                <div className="search-items-wrapper">
                {appV2Wizard.searchedCollections && appV2Wizard.searchedCollections.map(p =>
                    <div key={p.node.id} onClick={toggleSelectCollection(p.node.id)}
                         className={p.selected ? 'search-item app-v2-wizard-selected-collection' : 'search-item app-v2-wizard-not-selected-collection'}>
                         <span className={'checkbox'}>
                             <div className={'container'}>
                                  <div className={'round'}>
                                      <input type="checkbox" readOnly checked={p.selected} id={p.node.id}/>
                                      <label htmlFor={p.node.id}></label>
                                  </div>
                             </div>
                         </span>
                        <span>{p.node.title}</span>
                        <span> ({p.node.productsCount} products)</span>
                    </div>)}
                    </div>

            </Modal>
            <Modal visible={appV2Wizard.selectTagsModalOpen}
                   onCancel={toggleSelectTagsModal}
                   title={appV2Wizard.offerType === 'volume_discount' ? 'Select trigger tag' : 'Select trigger tags'}
                   onOk={approveSelectedTags}
                   width="50%"
                   cancelText="Close"
                   okText="Select"
                   className="tags-modal"

            >
                <TextField label=""
                           value={appV2Wizard.searchText} placeholder={'Search tags'}
                           onChange={onTagSearchTextChange} autoComplete={false}/>
                <div className="selected-items-wrapper">{appV2Wizard.selectedTags && appV2Wizard.selectedTags.map(tag =>
                    <div className="selected-item" key={tag}>
                        <span className="selected-item-title">{tag}</span>
                        <span className="selected-item-x" onClick={removeSelectedTag(tag)}>X</span>
                    </div>)}</div>
                <div className="search-items-wrapper">
                {appV2Wizard.searchedTags && appV2Wizard.searchedTags.map(edge =>
                edge.node && <div key={edge.node} onClick={toggleSelectTag(edge.node)}
                                  className={edge.selected ? 'search-item app-v2-wizard-selected-tag' : 'search-item app-v2-wizard-not-selected-tag'}>
                    <span>{edge.node}</span>
                    <span className={'checkbox'}>
                             <div className={'container'}>
                                  <div className={'round'}>
                                      <input type="checkbox" readOnly checked={edge.selected} id={edge.node}/>
                                      <label htmlFor={edge.node}></label>
                                  </div>
                             </div>
                         </span>
                </div>)}
                </div>
            </Modal>

            <div className={'drawings-wrapper'}>
                <img alt="cat-ball" src="https://public.zoorix.com/images/cat-ball2.png" className={'cat-ball'}></img>
            </div>

            <div className={'nav-buttons-wrapper'}>
                <div onClick={back} className={'app-v2-wizard-back-btn'}>Back</div>
                <div onClick={next} className={'app-v2-wizard-next-btn'}>Next</div>
            </div>
        </div>
    ;

export default AppV2WizardSelectTrigger;
