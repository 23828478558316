import React from 'react';
import '../../css/ProductsWarranty.css'
import {
    Button,
    FormLayout,
    Select, Spinner,
    TextField,
    TextStyle
} from "@shopify/polaris";
import ImageUpload from "../ImageUpload";
import {host} from "../../actions/data";

const priceOptions = [
    { label: 'Fixed', value: 'fixed' },
    { label: 'Percentage', value: 'percentage' },
];

const collectionOptions = [
    { label: 'Tag', value: 'tag' },
    { label: 'Smart Collection', value: 'smart-collection' },
    { label: 'Custom Collection', value: 'custom-collection' },
];

const WarrantyBulkEdit = ({
    onBack, onSave, saveError, isSaving,
    isWarrantyDefaultImage, settings, tokenId, upload, isUploading, uploadError,
    cancelUpload, uploadSuccess, uploadFailure, shopName, token, restoreToWarrantyDefaultImage,
    restoreToWarrantyOriginalImage, imageBackup, restoreToWarrantyStoreImage, bulkWarranty, tags, onTagChange,
    tagCount, onWarrantyPercentageChange, onWarrantyNameTemplateChange, errorBadNameTemplate, currencySymbol,
    onPriceTypeChange, onBulkWarrantyPriceChange, onFilterTypeChange, collectionCount,
    onSmartCollectionChange, smartCollections, customCollections, onCustomCollectionChange,
}) => (
        <FormLayout>
            <div className="navigation">
                <span className="navigation-button">
                    <Button onClick={onBack} disabled={isSaving}>Back</Button>
                </span>
                <span className="navigation-button">
                    <Button onClick={onSave} primary loading={isSaving}
                            disabled={(bulkWarranty.price_type === 'percentage' && !bulkWarranty.percentage) ||
                            (bulkWarranty.price_type === 'fixed' && !bulkWarranty.price)}
                    >Save</Button>
                </span>
            </div>
            {saveError && <TextStyle variation="negative">There was an error sending your request. Please try again in a
            few minutes</TextStyle>}
            <div>
                <Select label="Collection type:" options={collectionOptions} onChange={onFilterTypeChange}
                        disabled={isSaving} value={bulkWarranty.filter_type} />
                {bulkWarranty.filter_type === 'tag' && <div>
                    <Select label="Assign warranty to tag:" options={tags}
                            onChange={onTagChange} value={bulkWarranty.tag}
                            disabled={isSaving} />
                    <div className="warranty-bulk-tag-count">You have
                        <span className="warranty-bulk-tag-number"> {tagCount}</span> products with this tag.
                    </div>
                </div>}
                {bulkWarranty.filter_type === 'smart-collection' && <div>
                    <Select label="Assign warranty to collection:" options={smartCollections}
                            onChange={onSmartCollectionChange}
                            value={bulkWarranty.smartCollection}
                            disabled={isSaving} />
                    <div className="warranty-bulk-tag-count">You have
                        {Number.isInteger(collectionCount[bulkWarranty.filter_type][bulkWarranty.smartCollection]) ?
                            <span className="warranty-bulk-tag-number"> {collectionCount[bulkWarranty.filter_type][bulkWarranty.smartCollection]} </span>
                            : <Spinner size="small" />}
                        products in this collection.
                    </div>
                </div>}
                {bulkWarranty.filter_type === 'custom-collection' && <div>
                    <Select label="Assign warranty to collection:" options={customCollections}
                            onChange={onCustomCollectionChange}
                            value={bulkWarranty.customCollection}
                            disabled={isSaving} />
                    <div className="warranty-bulk-tag-count">You have
                        {Number.isInteger(collectionCount[bulkWarranty.filter_type][bulkWarranty.customCollection]) ?
                            <span className="warranty-bulk-tag-number"> {collectionCount[bulkWarranty.filter_type][bulkWarranty.customCollection]} </span>
                            : <Spinner size="small" />}
                        products in this collection.
                    </div>
                </div>}

            </div>

            <div>
                {errorBadNameTemplate && <TextStyle variation="negative">{"Template must contain '{{ product_name }}'"}
                    </TextStyle>}
                <TextField label="Warranty name template:"
                           value={bulkWarranty.warranty_name_template}
                           onChange={onWarrantyNameTemplateChange} disabled={isSaving} autoComplete={false} />
            </div>

            <div>
                <Select label="Price type:" options={priceOptions} onChange={onPriceTypeChange} value={bulkWarranty.price_type}
                        disabled={isSaving} />
                {bulkWarranty.price_type === 'percentage' &&
                <TextField label={`Warranty price in %:`} value={bulkWarranty.percentage}
                           onChange={onWarrantyPercentageChange} type="number"
                           disabled={isSaving} autoComplete={false} />}
                {bulkWarranty.price_type === 'fixed' &&
                <TextField label={`Warranty price in ${currencySymbol}:`} value={bulkWarranty.price}
                           onChange={onBulkWarrantyPriceChange} type="number"
                           disabled={isSaving} autoComplete={false} />}
            </div>

            <div>
                {bulkWarranty.image_src ?
                <div>
                    <img src={bulkWarranty.image_src} alt="Warranty" height={'200px'} width={'200px'}/>
                    <div>
                        <Button onClick={restoreToWarrantyStoreImage}>Restore to store default image</Button>
                    </div>
                </div>
                :
                <div>
                    {isWarrantyDefaultImage ?
                        <div>
                            { settings.is_zoorix_default_warranty_image ?
                                <div>
                                    <img src="https://public.zoorix.com/images/warranty.png" alt="zoorix default"
                                         height={'200px'} width={'200px'}/>
                                </div>
                                :
                                <div>

                                    <img src={`https://public.zoorix.com/shops/${tokenId}/images/default_warranty`}
                                         alt="store default" height={'200px'} width={'200px'}/>

                                </div>
                            }
                        </div>
                        :
                        <div>
                            <img src={`https://public.zoorix.com/shops/${tokenId}/images/warranties/${bulkWarranty.id}/warranty_image?${Date.now()}`}
                                 alt="custom" height={'200px'} width={'200px'}/>
                             <div className="restore-image-button"><span><Button onClick={restoreToWarrantyDefaultImage}>Restore default image</Button></span></div>
                        </div>
                    }
                    {imageBackup && <Button onClick={restoreToWarrantyOriginalImage}>Restore to original image</Button>}
                </div>
                }
                {isUploading ?
                    <div>
                        {uploadError &&
                        <TextStyle variation="negative">There was an error uploading your file. Please try
                            again in a few minutes</TextStyle>}
                        <ImageUpload onCancel={cancelUpload}
                                     presignedUrl={`https://${host}/image/get_url_for_warranty?shop_name=${shopName}&token=${token}&warranty_id=${bulkWarranty.id}`}
                                     uploadSuccess={uploadSuccess}
                                     uploadFailure={uploadFailure}

                        />
                    </div>
                    :
                    <div className="upload-warranty-image">
                        <div className="upload-warranty-image-div">
                            <Button onClick={upload}>Replace image</Button>
                        </div>
                    </div>
                }
            </div>
        </FormLayout >
    );

export default WarrantyBulkEdit;
