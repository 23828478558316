import React from 'react';
import {Frame, Toast, Card, Spinner, TextStyle, Button} from "@shopify/polaris";
import '../../css/MixAndMatch.scss'
import VisibleMixAndMatches from "../../containers/products/VisibleMixAndMatches";
import VisibleEditMixAndMatch from "../../containers/products/VisibleEditMixAndMatch";

const ProductsMixAndMatch = ({ mixAndMatch, load, onDismissToast, status, navToSupport }) => {
    load();
    return <div className="mix-and-match-products-wrapper">
        <Frame className="frame-wrapper">
            {!status.appEmbeds && <div className="mm-not-supported-wrapper"><div className="mm-not-supported-title">To upgrade your account (free of charge) and enable this feature please contact support</div>
                <div className="mm-not-supported-btn-wrapper"><Button primary onClick={navToSupport}>Contact Support</Button></div></div>}
            {mixAndMatch.loadOffersError && <TextStyle variation="negative">There was an error loading offers.</TextStyle>}
            {status.appEmbeds && <div className={`warranty-card-wrapper ${mixAndMatch.offersReady && 'warranty-loaded'}`}>
                {mixAndMatch.offersReady ?
                    <Card>
                        <Card.Section sectioned>
                            {mixAndMatch.offersState === 'offer_list' && <VisibleMixAndMatches/>}
                            {mixAndMatch.offersState === 'edit_offer' && <VisibleEditMixAndMatch/>}
                            {mixAndMatch.toastIsActive && <Toast content={mixAndMatch.toastContent} onDismiss={onDismissToast} />}
                        </Card.Section>
                    </Card>
                    :
                    <span className="spinner-wrapper"><Spinner/></span>}
            </div>}
        </Frame>
    </div>
};

export default ProductsMixAndMatch;
