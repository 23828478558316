import React from 'react';
import '../../css/Analytics.css';
import {Collapsible, DataTable, Spinner} from '@shopify/polaris';
import {round2} from "./AnalyticsBundles";

const AnalyticsVolumeDiscounts = ({isFetchingVolumeDiscounts, volumeDiscounts, openVolume, volumeOpen, currency}) => (
        <div>
            {isFetchingVolumeDiscounts ?
                <div><Spinner /></div> :
                <div className="roi-data-table">
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={['Volume discount', 'Conversion rate', 'Total Sales']}
                        rows={volumeDiscounts.map(o => [
                            <div>
                                <div onClick={o.products.length > 0 ? openVolume(o.id) : ()=>{}}
                                     className={o.products.length > 0 ? 'roi-clickable-offer' : ''}
                                >{o.name}</div>
                                <Collapsible open={volumeOpen[o.id]}
                                             transition={{duration: '500ms', timingFunction: 'ease-in-out'}}>
                                    <DataTable
                                        columnContentTypes={['text', 'text', 'text']}
                                        headings={['Product Name', 'QTY', 'Total Sales']}
                                        rows={o.products.map(p => [p.title, p.count, `${round2(p.total_sales)} ${currency}`])}
                                    />
                                </Collapsible>
                            </div>,
                            o.percentage,
                            `${round2(o.products.map(p => p.total_sales).reduce((a, b) => a + b, 0))} ${currency}`,
                        ])}
                    />
                </div>}
        </div>

    );

export default AnalyticsVolumeDiscounts;
