import React from 'react';
import '../../../css/v2/AppV2Wizard.scss';
import {Spinner, TextField} from "@shopify/polaris";
import {InputNumber, Select, Modal} from 'antd';
import {Icon} from "@shopify/polaris";
import {DeleteMajor} from "@shopify/polaris-icons";

const {Option} = Select;

export const selectAfter = (appV2Wizard, onBundlesDiscountTypeChange) =>
    <Select defaultValue="percentage" className="select-after" onChange={onBundlesDiscountTypeChange}>
        <Option value="percentage">% Off</Option>
        <Option value="fixed">{appV2Wizard.currencySymbol + " Off"}</Option>
    </Select>;

const AppV2WizardConfigBundle = ({
    appV2Wizard, onBundleAmountChange, onBundlesDiscountTypeChange, chooseProduct,
    closeSelectProductsModal, onProductSearchTextChange, selectProduct, addProduct,
    removeProduct
}) =>
    <div className={'app-v2-wizard-config-bundle'}>

         <span className="field-wrapper">
                <InputNumber addonAfter={selectAfter(appV2Wizard, onBundlesDiscountTypeChange)} addonBefore="Discount"
                             value={appV2Wizard.bundle.amount.toString()} size="large" min={0}
                             max={appV2Wizard.bundleType === 'percentage' ? 100 : undefined}
                             defaultValue={1} onChange={onBundleAmountChange}/>
            </span>

        <div className="selected-bundle-items-wrapper">

            {appV2Wizard.bundle.products.map((p, idx) => <div key={idx}>
                {p && p.empty ?
                    <div className={appV2Wizard.invalidSelectOffer ? 'invalid select-products' : 'select-products' } onClick={chooseProduct(idx)}>Add Product</div> :
                    <div className="selected-bundle-items-container">
                    <span className="selected-bundle-items">
                        {p.imageSrc &&
                        <img className="selected-bundle-item-image" onClick={chooseProduct(idx)} src={p.imageSrc}
                             alt="product" width="30px" height="30px"/>}
                        <span className="selected-bundle-item-title" onClick={chooseProduct(idx)}>{p.title}</span>
                        <span className="delete-bundle-item" onClick={removeProduct(idx)}> <Icon source={DeleteMajor}/></span>
                    </span>

                    </div>}
            </div>)}
            {!appV2Wizard.bundle.products[appV2Wizard.bundle.products.length - 1].empty &&
            appV2Wizard.bundle.products.length < 8 && <div className="select-products" onClick={addProduct}>
                Add Product
            </div>}

        </div>

        <Modal
            visible={appV2Wizard.selectProductsModalOpen}
            className="offer-bundle-items-modal"
            title={'Select bundle items'}
            onCancel={closeSelectProductsModal}
            onOk={closeSelectProductsModal}
            width="50%"
            cancelText="Close"
            okText="Select">


            <TextField label=""
                       value={appV2Wizard.searchText} placeholder={'Search products'}
                       onChange={onProductSearchTextChange} autoComplete={false}/>
            {appV2Wizard.bundle.products[appV2Wizard.bundle.choosingProductIndex] && !appV2Wizard.bundle.products[appV2Wizard.bundle.choosingProductIndex].empty &&
            <div className="bundle-selected-item">
                <img className="bundle-selected-item-image"
                     src={appV2Wizard.bundle.products[appV2Wizard.bundle.choosingProductIndex].imageSrc}
                     alt="product" width="30px" height="30px"/>
                <span
                    className="bundle-selected-item-title">{appV2Wizard.bundle.products[appV2Wizard.bundle.choosingProductIndex].title}</span>
                <span className={'checkbox'}>
                                <div className={'container'}>
                                    <div className={'round'}>
                                        <input type="checkbox" readOnly checked/>
                                        <label></label>
                                    </div>
                                </div>
                            </span>
            </div>}
            {appV2Wizard.searchedProductsSpinner && <span className="spinner-wrapper"><Spinner /></span>}
            <div className="search-items-wrapper">
                {!appV2Wizard.searchedProductsSpinner && appV2Wizard.searchedProducts && appV2Wizard.searchedProducts.map(p =>
                    <div className="search-item" key={p.node.id} onClick={selectProduct(p.node.id)}>
                        {p.node.images.edges.length > 0 && <img width={'30px'} height={'30px'} alt=""
                                                                src={p.node.images.edges[0].node.originalSrc}/>}
                        <span>{p.node.title}</span>
                    </div>)}</div>


        </Modal>
    </div>;

export default AppV2WizardConfigBundle;
