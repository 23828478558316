import { connect } from 'react-redux'
import {
    minicartAddCartNoteTextChange, minicartCartNotePlaceholderTextChange,
    minicartCartTitleChange,
    minicartCartTotalTextChange,
    minicartCheckoutTextChange,
    minicartEmptyCartTextChange,
    minicartFreeDiscountTitleChange,
    minicartFreeDiscountUnlockedTextChange,
    minicartFreeShippingTitleChange, minicartFreeShippingUnlockedTextChange, minicartOfferCtaTextChange,
    minicartUnlockDiscountTextChange, minicartUnlockShippingTextChange, minicartViewCartTextChange,
    restoreMinicartTextsDefaults, textsChangeText,
} from "../../actions/texts";
import TextsMinicart from "../../components/texts/TextsMinicart";

const mapStateToProps = state => ({
    translations: state.texts.translations,
    locale: state.texts.locale,
    saving: state.texts.saving,
});

const mapDispatchToProps = (dispatch) => ({
    restoreDefaults: () => dispatch(restoreMinicartTextsDefaults()),
    onFreeDiscountTitleChange: text => dispatch(minicartFreeDiscountTitleChange(text)),
    onEmptyCartTextChange: text => dispatch(minicartEmptyCartTextChange(text)),
    onCartTotalTextChange: text => dispatch(minicartCartTotalTextChange(text)),
    onCheckoutTextChange: text => dispatch(minicartCheckoutTextChange(text)),
    onUnlockDiscountTextChange: text => dispatch(minicartUnlockDiscountTextChange(text)),
    onFreeDiscountUnlockedTextChange: text => dispatch(minicartFreeDiscountUnlockedTextChange(text)),
    onCartTitleChange: text => dispatch(minicartCartTitleChange(text)),
    onFreeShippingTitleChange: text => dispatch(minicartFreeShippingTitleChange(text)),
    onUnlockShippingTextChange: text => dispatch(minicartUnlockShippingTextChange(text)),
    onFreeShippingUnlockedTextChange: text => dispatch(minicartFreeShippingUnlockedTextChange(text)),
    onMiniCartViewCartTextChange: text => dispatch(minicartViewCartTextChange(text)),
    onMiniCartAddCartNoteTextChange: text => dispatch(minicartAddCartNoteTextChange(text)),
    onMiniCartCartNotePlaceholderTextChange: text => dispatch(minicartCartNotePlaceholderTextChange(text)),
    onMiniCartOfferCtaTextChange: text => dispatch(minicartOfferCtaTextChange(text)),
    onTextChange: (widget, attr) => text => dispatch(textsChangeText(widget, attr, text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TextsMinicart);
