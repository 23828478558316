import { connect } from 'react-redux'
import Redeem from '../components/Redeem'
import {
    closeRedeemModal,
    initRedeemData, redeemAction,
    redeemImageUploadFailure,
    redeemImageUploadSuccess,
    redeemItem, redeemRequestBodyChange
} from "../actions/redeem";

const mapStateToProps = state => ({
    failInit: state.redeem.failInit,
    lineItems: state.redeem.lineItems,
    itemId: state.redeem.itemId,
    redeemModalOpen: state.redeem.redeemModalOpen,
    showUploadedImage: state.redeem.showUploadedImage,
    imageUploadError: state.redeem.imageUploadError,
    requestBody: state.redeem.requestBody,
    redeemSpinner: state.redeem.redeemSpinner,
    redeemError: state.redeem.redeemError,
    currency: state.redeem.currency,
    noImageError: state.redeem.noImageError,
});

const mapDispatchToProps = (dispatch) => ({
    initRedeem: token => dispatch(initRedeemData(token)),
    redeemItem: id => () => dispatch(redeemItem(id)),
    closeRedeemModal: () => dispatch(closeRedeemModal()),
    redeemImageUploadSuccess: () => dispatch(redeemImageUploadSuccess()),
    redeemImageUploadFailure: () => dispatch(redeemImageUploadFailure()),
    redeemAction: token => () => dispatch(redeemAction(token)),
    onRequestBodyChange: text => dispatch(redeemRequestBodyChange(text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Redeem);
