import React from 'react';
import { Frame, Toast, Card } from "@shopify/polaris";
import '../../css/ProductsBundle.css'
import VisibleBundles from "../../containers/products/VisibleBundles";
import VisibleBundleEdit from "../../containers/products/VisibleBundleEdit";
import VisibleGroupedBundleEdit from "../../containers/products/VisibleGroupedBundleEdit";
import VisiblePages from "../../containers/products/VisiblePages";
import VisibleEditPage from "../../containers/products/VisibleEditPage";
import VisibleImportExportBundles from "../../containers/products/VisibleImportExportBundles";

const ProductsBundle = ({ toastIsActive, onDismissToast, isShowBundles, isShowEdit, isShowGroupedEdit,
                            isShowPages, isShowEditPage, productsBundle }) => (
    <Frame className="frame-wrapper">
        <Card>
            <Card.Section sectioned>
                {isShowBundles && <VisibleBundles />}
                {isShowEdit && <VisibleBundleEdit />}
                {isShowGroupedEdit && <VisibleGroupedBundleEdit />}
                {isShowPages && <VisiblePages />}
                {isShowEditPage && <VisibleEditPage />}
                {productsBundle.isShowImportExport && <VisibleImportExportBundles />}
                {toastIsActive && <Toast content="Settings saved" onDismiss={onDismissToast} />}
            </Card.Section>
        </Card>
    </Frame>
);

export default ProductsBundle;
