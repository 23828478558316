import React from 'react';
import '../../css/TextsMain.css'
import { TextField} from "@shopify/polaris";

const TextsBundle = ({ translations, locale, saving, restoreDefaults, onAddSelectedToCartTextChange,
                         onTotalPriceTextChange, onAddToCartTextChange,
                         onJustAddedTextChange, onBundleDiscountGrantedTextChange, onBundleContinueShoppingTextChange,
                         onBundleQuickViewFullDetailsTextChange, onBundlePopupViewCartTextChange,
                         onBundleNotifPopupQtyTextChange, onBundleSaveTextChange, onBundleOutOfStockTextChange }) =>
    <div>
        <TextField label="Add Selected To Cart Button Text:" value={translations[locale].bundle.add_selected_to_cart_text}
                   onChange={onAddSelectedToCartTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Total Price Text:" value={translations[locale].bundle.total_price_text}
                   onChange={onTotalPriceTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Add To Cart Button Text (Quick View):" value={translations[locale].bundle.add_to_cart_text}
                   onChange={onAddToCartTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Add Selected To Cart Button Just Added Text:" value={translations[locale].bundle.just_added_text}
                   onChange={onJustAddedTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Discount granted text:" value={translations[locale].bundle.bundle_discount_granted_text}
                   onChange={onBundleDiscountGrantedTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Notification Popup Continue Shopping Text:"
                   value={translations[locale].bundle.bundle_continue_shopping_text}
                   onChange={onBundleContinueShoppingTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Quick View Full Details Text:" value={translations[locale].bundle.bundle_quick_view_full_details_text}
                   onChange={onBundleQuickViewFullDetailsTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Notification Popup View Cart Text:" value={translations[locale].bundle.bundle_popup_view_cart_text}
                   onChange={onBundlePopupViewCartTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Notification Popup Quantity Text:" value={translations[locale].bundle.bundle_notif_popup_qty_text}
                   onChange={onBundleNotifPopupQtyTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Save Text:" value={translations[locale].bundle.bundle_save_text}
                   onChange={onBundleSaveTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Out Of Stock Text:" value={translations[locale].bundle.bundle_out_of_stock_text}
                   onChange={onBundleOutOfStockTextChange}
                   disabled={saving} autoComplete={false} />
        {/*<span className="texts-restore">*/}
        {/*        <Button onClick={restoreDefaults}>Restore defaults</Button>*/}
        {/*</span>*/}
</div>;

export default TextsBundle;
