import React from 'react';
import '../../css/Plans.css';
import { Icon } from "@shopify/polaris";
import { TickMinor } from '@shopify/polaris-icons';

const PlatinumTable = ({isPlatinumPlus}) => <div>
    <table className="marketing-table pricing-table platinum">
        <thead>
        <tr>
            <th className="pricing-table__table-head" scope="col"></th>
            <th className="pricing-table__table-head" scope="col" id="p-0">
                <p className="plan-description">Up to 50 monthly store orders</p></th>
            <th className="pricing-table__table-head">
                <p className="plan-description">'Up to 100 monthly store orders</p>
            </th>
            <th className="pricing-table__table-head">
                <p className="plan-description">Up to 200 monthly store orders</p>
            </th>
            <th className="pricing-table__table-head">
                <p className="plan-description">200+ monthly store orders</p>
            </th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td>Monthly price</td>
            <td><span className="price__number free">Free</span></td>
            <td>
                <div className="monthly-price">
                    <span className="monthly-price__currency text-minor" aria-hidden="true">USD</span>
                    <span
                        className="pricing-table__feature-value pricing-table__feature-price monthly-price__price">
                                    <sup className="currency-sign">$</sup><span className="price"><span
                        aria-hidden="true"><span className="price__number">7.99</span></span></span>
                                </span>
                    <span className="monthly-price__billing-period text-minor"
                          aria-hidden="true">/mo</span>
                </div>
            </td>
            <td>
                <div className="monthly-price">
                    <span className="monthly-price__currency text-minor" aria-hidden="true">USD</span>
                    <span
                        className="pricing-table__feature-value pricing-table__feature-price monthly-price__price">
                                    <sup className="currency-sign">$</sup><span className="price"><span
                        aria-hidden="true"><span className="price__number">14.99</span></span></span>
                                </span>
                    <span className="monthly-price__billing-period text-minor"
                          aria-hidden="true">/mo</span>
                </div>
            </td>
            <td>
                <div className="monthly-price">
                    <span className="monthly-price__currency text-minor" aria-hidden="true">USD</span>
                    <span
                        className="pricing-table__feature-value pricing-table__feature-price monthly-price__price">
                                    <sup className="currency-sign">$</sup><span className="price"><span
                        aria-hidden="true"><span className="price__number">19.99</span></span></span>
                                </span>
                    <span className="monthly-price__billing-period text-minor"
                          aria-hidden="true">/mo</span>

                    {isPlatinumPlus && <span className="monthly-price-additional">+$0.01 per order</span>}
                </div>

            </td>
        </tr>
        <tr>
            <th scope="col" colSpan="5">
                <span className="heading--5 pricing-table__section-heading">Features</span>
            </th>
        </tr>
        <tr>
            <td className="unlimited-usage">Unlimited usage of all features</td>
            <td><Icon color="purple" source={TickMinor}/></td>
            <td><Icon color="purple" source={TickMinor}/></td>
            <td><Icon color="purple" source={TickMinor}/></td>
            <td><Icon color="purple" source={TickMinor}/></td>
        </tr>
        </tbody>
    </table>
</div>

export default PlatinumTable;
