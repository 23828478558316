import { connect } from 'react-redux'
import AnalyticsBundles from "../../components/analytics/AnalyticsBundles";
import {analyticsToggleBundle} from "../../actions/analytics";

const mapStateToProps = state => ({
    isFetchingBundles: state.analytics.isFetchingBundles,
    bundles: state.analytics.bundles,
    bundleOpen: state.analytics.bundleOpen,
    currency: state.analytics.currency,
});

const mapDispatchToProps = (dispatch) => ({
    openBundle: id => () => dispatch(analyticsToggleBundle(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AnalyticsBundles);
