import { connect } from 'react-redux'
import AppV2WizardSelectOfferType from "../../../components/v2/wizard/AppV2WizardSelectOfferType";
import {
    appV2WizardBack,
    appV2WizardSelectOfferType,
    selectBundleType,
    selectOfferType,
    toggleContactUsModal,
    toggleCrossSellVideoModal,
    toggleBundleVideoModal,
    toggleVolumeDiscountVideoModal,
    toggleMixAndMatchVideoModal
} from "../../../actions/v2/appV2Wizard";
import {emailChange, nameChange} from "../../../actions/data";
import {submit, textChange} from "../../../actions/support";

const mapStateToProps = state => ({
    appV2Wizard: state.appV2Wizard,
    data: state.data,
    support: state.support,
    status: state.status,
});

const mapDispatchToProps = (dispatch) => ({
    selectOfferType: offerType => () => dispatch(selectOfferType(offerType)),
    selectBundleType: bundleType => () => dispatch(selectBundleType(bundleType)),
    next: () => dispatch(appV2WizardSelectOfferType()),
    back: () => dispatch(appV2WizardBack()),
    toggleContactUsModal: () => dispatch(toggleContactUsModal()),
    onNameChange: text => dispatch(nameChange(text)),
    onEmailChanged: text => dispatch(emailChange(text)),
    onTextChanged: text => dispatch(textChange(text)),
    sendSupportRequest: () => dispatch(submit()),
    toggleCrossSellVideoModal: () => dispatch(toggleCrossSellVideoModal()),
    toggleBundleVideoModal: () => dispatch(toggleBundleVideoModal()),
    toggleVolumeDiscountVideoModal: () => dispatch(toggleVolumeDiscountVideoModal()),
    toggleMixAndMatchVideoModal: () => dispatch(toggleMixAndMatchVideoModal()),

});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppV2WizardSelectOfferType);
