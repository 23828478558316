import { connect } from 'react-redux'
import {
    volumeAddToCartTextChange,
    restoreVolumeTextsDefaults,
    volumePopularTextChange,
    volumeTotalTextChange,
    volumeSaveTextChange,
    volumeEachItemTextChange,
    volumeJustAddedTextChange,
    volumeQtyTextChange,
    volumeSavePerItemChange,
    volumeOutOfStockTextChange, volumeTiersTableQtyTextChange, volumeTiersTableDiscountTextChange,
} from "../../actions/texts";
import TextsVolume from "../../components/texts/TextsVolume";

const mapStateToProps = state => ({
    translations: state.texts.translations,
    locale: state.texts.locale,
    saving: state.texts.saving,
});

const mapDispatchToProps = (dispatch) => ({
    restoreDefaults: () => dispatch(restoreVolumeTextsDefaults()),
    onAddToCartTextChange: text => dispatch(volumeAddToCartTextChange(text)),
    onPopularTextChange: text => dispatch(volumePopularTextChange(text)),
    onTotalTextChange: text => dispatch(volumeTotalTextChange(text)),
    onSaveTextChange: text => dispatch(volumeSaveTextChange(text)),
    onEachItemTextChange: text => dispatch(volumeEachItemTextChange(text)),
    onJustAddedTextChange: text => dispatch(volumeJustAddedTextChange(text)),
    onQtyTextChange: text => dispatch(volumeQtyTextChange(text)),
    onSavePerItemChange: text => dispatch(volumeSavePerItemChange(text)),
    onOutOfStockTextChange: text => dispatch(volumeOutOfStockTextChange(text)),
    onTiersTableQtyTextChange: text => dispatch(volumeTiersTableQtyTextChange(text)),
    onTiersTableDiscountTextChange: text => dispatch(volumeTiersTableDiscountTextChange(text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TextsVolume);
