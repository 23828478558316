import { connect } from 'react-redux'
import {
    bulkPriceTypeChange,
    bulkWarrantyCustomCollectionChangeAction,
    bulkWarrantyFilterTypeChangeAction,
    bulkWarrantyPriceChange, bulkWarrantySmartCollectionChangeAction,
    cancelUploadWarrantyImage,
    restoreToWarrantyDefaultImage,
    restoreToWarrantyOriginalImage,
    restoreToWarrantyStoreImage,
    saveBulk,
    uploadWarrantyFailure,
    uploadWarrantyImage,
    uploadWarrantySuccess,
    warrantyBack,
    warrantyNameTemplateChange,
    warrantyPercentageChange,
    warrantyTagChange
} from "../../actions/productsWarranty";
import WarrantyBulkEdit from "../../components/products/WarrantyBulkEdit";

const mapStateToProps = state => ({
    isSaving: state.productsWarranty.isSaving,
    saveError: state.productsWarranty.saveError,
    isWarrantyDefaultImage: state.productsWarranty.isWarrantyDefaultImage,
    tokenId: state.productsWarranty.tokenId,
    isUploading: state.productsWarranty.isUploading,
    uploadError: state.productsWarranty.uploadError,
    imageBackup: state.productsWarranty.imageBackup,
    bulkWarranty: state.productsWarranty.bulkWarranty,
    tags: state.productsWarranty.tags,
    tagCount: state.productsWarranty.tagCount,
    errorBadNameTemplate: state.productsWarranty.errorBadNameTemplate,
    currencySymbol: state.productsWarranty.currencySymbol,
    collectionCount: state.productsWarranty.collectionCount,
    smartCollections: state.productsWarranty.smartCollections,
    customCollections: state.productsWarranty.customCollections,

    settings: state.settingsWarranty.settings,

    shopName: state.data.shopName,
    token: state.data.token,
});

const mapDispatchToProps = (dispatch) => ({
    onBack: () => dispatch(warrantyBack()),
    onSave: () => dispatch(saveBulk()),
    onTagChange: text => dispatch(warrantyTagChange(text)),
    onPriceTypeChange: text => dispatch(bulkPriceTypeChange(text)),
    onWarrantyNameTemplateChange: text => dispatch(warrantyNameTemplateChange(text)),
    onFilterTypeChange: text => dispatch(bulkWarrantyFilterTypeChangeAction(text)),
    onSmartCollectionChange: text => dispatch(bulkWarrantySmartCollectionChangeAction(text)),
    onCustomCollectionChange: text => dispatch(bulkWarrantyCustomCollectionChangeAction(text)),
    upload: () => dispatch(uploadWarrantyImage()),
    uploadSuccess: () => dispatch(uploadWarrantySuccess()),
    cancelUpload: () => dispatch(cancelUploadWarrantyImage()),
    uploadFailure: () => dispatch(uploadWarrantyFailure()),
    restoreToWarrantyDefaultImage: () => dispatch(restoreToWarrantyDefaultImage()),
    restoreToWarrantyOriginalImage: () => dispatch(restoreToWarrantyOriginalImage()),
    restoreToWarrantyStoreImage: () => dispatch(restoreToWarrantyStoreImage()),
    onWarrantyPercentageChange: num => dispatch(warrantyPercentageChange(num)),
    onBulkWarrantyPriceChange: num => dispatch(bulkWarrantyPriceChange(num)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WarrantyBulkEdit);
