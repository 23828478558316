import { connect } from 'react-redux'
import FreeShippingBarDashboard from "../../components/free-shipping-bar/FreeShippingBarDashboard";

const mapStateToProps = state => ({
    freeShippingBar: state.freeShippingBar,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FreeShippingBarDashboard);
