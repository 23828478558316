import { connect } from 'react-redux'
import {
    openOrder,
} from "../../actions/analytics";
import AnalyticsOrders from "../../components/analytics/AnalyticsOrders";

const mapStateToProps = state => ({
    ordersGenByZoorix: state.analytics.ordersGenByZoorix,
    productsGenByZoorix: state.analytics.productsGenByZoorix,
    orderOpen: state.analytics.orderOpen,
});

const mapDispatchToProps = (dispatch) => ({
    openOrder: id => () => dispatch(openOrder(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AnalyticsOrders);
