import React from 'react';
import '../../css/v2/AppV2.scss';
import {Spinner, Frame} from "@shopify/polaris";
import VisibleAppV2Wizard from "../../containers/v2/wizard/VisibleAppV2Wizard";

const AppV2 = ({match: { params }, initApp, appV2 }) => {
    initApp(params);
    return <Frame>
        { appV2.showWizard ? <div>
            <VisibleAppV2Wizard />
        </div> : appV2.showDashboard ? <div>
            Dashboard
        </div> :
        <div>
            <Spinner size={'large'} />
        </div>}
    </Frame>
};

export default AppV2;
