import React from 'react';
import '../../css/Analytics.css';
import {Collapsible, DataTable, Spinner} from '@shopify/polaris';

export const round2 = num => Math.round((num + Number.EPSILON) * 100) / 100;

const AnalyticsBundles = ({isFetchingBundles, bundles, openBundle, bundleOpen, currency}) => (
        <div>
            {isFetchingBundles ?
                <div><Spinner /></div> :
                <div className="roi-data-table">
                    <DataTable
                        columnContentTypes={[
                            'text',
                            'text',
                            'text',
                        ]}
                        headings={['Bundle name', 'Conversion rate', 'Total Sales']}
                        rows={bundles.map(o => [
                            <div>
                                <div onClick={o.products.length > 0 ? openBundle(o.id) : ()=>{}}
                                     className={o.products.length > 0 ? 'roi-clickable-offer' : ''}
                                >{o.name}</div>
                                <Collapsible open={bundleOpen[o.id]}
                                             transition={{duration: '500ms', timingFunction: 'ease-in-out'}}>
                                    <DataTable
                                        columnContentTypes={['text', 'text', 'text']}
                                        headings={['Product Name', 'QTY', 'Total Sales']}
                                        rows={o.products.map(p => [p.title, p.count, `${round2(p.total_sales)} ${currency}`])}
                                    />
                                </Collapsible>
                            </div>,
                            o.percentage,
                            `${round2(o.products.map(p => p.total_sales).reduce((a, b) => a + b, 0))} ${currency}`,
                        ])}
                    />
                </div>}
        </div>

    );

export default AnalyticsBundles;
