import React from 'react';
import {
    Button,
    Icon,
    Modal,
    Pagination,
    Select,
    Spinner,
    TextContainer,
    TextStyle,
    Toast,
    Tooltip
} from "@shopify/polaris";
import NoImage from "./NoImage";
import '../../css/ProductsBundle.css'
import {DeleteMajor, DuplicateMinor, ViewMajor} from "@shopify/polaris-icons";
import {addByFilterOptions} from "./ProductsSlider";

const bundleNameOptions = [
    { label: 'Titles', value: 'title' },
    { label: 'Names', value: 'name' },
];

const Bundles = ({ onCreateBundle, bundles, currencySymbol, editBundle, removeBundle, removeBundleModalOpen,
    closeRemoveBundleModal, removeBundleAction, removeBundleSpinner, bundleName, removeBundleError, showShortCode,
    showShortCodeModalOpen, shortCodeBundleId, closeShortCodeModal, switchEnable, switchEnableLoading, switchEnableError,
    onGenAutoBundles, onDelAutoBundles, removeAutoBundleModalOpen, closeRemoveAutoBundleModal, removeAutoBundlesAction,
    removeAutoBundlesSpinner, removeAutoBundlesError, noAutoBundlesModalOpen, closeNoAutoBundlesModal, duplicateBundle,
    duplicateBundleStarted, duplicateBundleFailure, onDismissDuplicateBundleToast, onCreateGroupedBundle,
    pages, onManagePages, loadPages, editingBundle, creatingBundle, creatingAutoBundle, creatingGroupedBundle,
    loadAutoBundles, autoBundles, onPreviousAutoBundlePage, onNextAutoBundlePage, showBundlesSemanticNames,
    onShowBundlesSemanticNamesChange, isShowBundlesSemanticNamesSaving, importedBundlesCount, productsBundle, previewBundle,
    importExport, loadImportedBundles, onPreviousImportedBundlePage, onNextImportedBundlePage}) => {
    loadAutoBundles();
    loadImportedBundles();
    loadPages();
    return <div className='bundles'>
        {switchEnableError && <TextStyle variation="negative">There was an error sending your request. Please
            try again in a few minutes</TextStyle>}
        <div className="create-bundle-buttons">
            <Tooltip content="Create a single bundle with fixed, percentage or quantitative discount" dismissOnMouseOut>
            <Button onClick={onCreateBundle} primary loading={creatingBundle}>Create bundle</Button>
            </Tooltip>
            <span className="create-group-bundle-button">
            <Tooltip content="Create bundles around a collection, e.g. collection A with a1, a2, a3, bundled with items X & Y, resulting in 3 bundles: [a1, X, Y], [a2, X, Y], [a3, X, Y]" dismissOnMouseOut>
                <Button onClick={onCreateGroupedBundle} primary loading={creatingGroupedBundle}>Create pivot bundles</Button>
            </Tooltip>
            </span>
            {bundles && bundles.filter(b => b.bundle_type === 'auto').length === 0 &&
            <span className="create-auto-bundle-button">
                <Tooltip content="Create multiple bundles randomly or using store orders by items frequently bought together" dismissOnMouseOut>
                    <Button onClick={onGenAutoBundles} primary loading={creatingAutoBundle}>Generate bundles in bulk</Button>
                </Tooltip>
            </span>}
            {bundles && bundles.filter(b => b.bundle_type === 'auto').length > 0 &&
            <span className="create-auto-bundle-button"><Button
                onClick={onDelAutoBundles}>Delete automatic bundles</Button></span>}
            <span className="create-auto-bundle-button">
                                <Tooltip content="Manage bundle promotion pages" dismissOnMouseOut>
                    <Button primary onClick={onManagePages}
                            loading={!pages || productsBundle.loadingAutoBundlesForSearch}>
                        {pages ? (pages.length === 0 ? 'Create page' : 'Manage pages') : 'Create page'}
                    </Button></Tooltip>
            </span>
            <span class="import-bundle-csv">
                <Button primary onClick={importExport}>Import</Button>
            </span>
            {bundles && bundles.length > 0 && <span className="show-bundle-by">
            <Select label="Show bundles by:" options={bundleNameOptions} onChange={onShowBundlesSemanticNamesChange}
                    value={showBundlesSemanticNames ? 'name' : 'title'} disabled={isShowBundlesSemanticNamesSaving} />
            </span>}
        </div>
        {[null, 'grouped', 'auto', 'imported'].map(bundle_type => <div className={bundle_type + '-bundle-type'} key={bundle_type}>
                {bundle_type === 'auto' && bundles && bundles.filter(b => b.bundle_type === 'auto').length > 0 &&
                <div>
                    <div className="auto-bundle-titles">Automatically generated bundles ({autoBundles.count})</div>
                </div>}
                {bundle_type === 'imported' && bundles && bundles.filter(b => b.bundle_type === 'imported').length > 0 &&
                <div>
                    <div className="imported-bundle-titles">Imported bundles ({productsBundle.importedBundles.count})</div>
                </div>}
                {bundle_type === 'grouped' && bundles && bundles.filter(b => b.bundle_type === 'grouped').length > 0 &&
                <div className="auto-bundle-titles">Pivot bundles</div>}
                {bundles && bundles.filter(b => b.bundle_type === bundle_type).map(bundle => (
                    <div key={bundle.id} className={'bundle-item-wrapper'}>
                        <div className="bundle-name">{showBundlesSemanticNames? bundle.semantic_name : bundle.name}</div>
                        {bundle.is_fixed && <div className="bundle-discount">{bundle.amount}{currencySymbol} off</div>}
                        {bundle.is_percentage && <div className="bundle-discount">{bundle.amount}% off</div>}
                        {bundle.is_quantitative && <div className="bundle-discount">
                            {bundle.amount && `${parseFloat(bundle.amount)}${bundle.discount_types && bundle.discount_types[0] === 'fixed' ? currencySymbol : '%'}`}
                            {bundle.amount2 && `, ${parseFloat(bundle.amount2)}${bundle.discount_types && bundle.discount_types[1] === 'fixed' ? currencySymbol : '%'}`}
                            {bundle.amount3 && `, ${parseFloat(bundle.amount3)}${bundle.discount_types && bundle.discount_types[2] === 'fixed' ? currencySymbol : '%'}`}
                            {bundle.amount4 && `, ${parseFloat(bundle.amount4)}${bundle.discount_types && bundle.discount_types[3] === 'fixed' ? currencySymbol : '%'}`}
                            {bundle.amount5 && `, ${parseFloat(bundle.amount5)}${bundle.discount_types && bundle.discount_types[4] === 'fixed' ? currencySymbol : '%'}`}
                            {bundle.amount6 && `, ${parseFloat(bundle.amount6)}${bundle.discount_types && bundle.discount_types[5] === 'fixed' ? currencySymbol : '%'}`}
                            {bundle.amount7 && `, ${parseFloat(bundle.amount7)}${bundle.discount_types && bundle.discount_types[6] === 'fixed' ? currencySymbol : '%'}`}
                            {bundle.amount8 && `, ${parseFloat(bundle.amount8)}${bundle.discount_types && bundle.discount_types[7] === 'fixed' ? currencySymbol : '%'}`}
                            &nbsp;off</div>}
                        <br/>
                        {bundle.bundle_type === 'grouped' && <div className="bundle-product-titles">
                            {addByFilterOptions.filter(o => o.value === bundle.filter1_type)[0].label}:&nbsp;
                            {bundle.filter1_value}</div>}
                        {bundle.bundle_type !== 'grouped' && <div className="bundle-product-titles">
                            {bundle.products && bundle.products.map(p => p.title).join(', ')}</div>}
                        {bundle.bundle_type === 'grouped' && <div className="bundle-product-images"/>}
                        {bundle.bundle_type !== 'grouped' && <div className="bundle-product-images">
                            {bundle.products && bundle.products.map((product) => (
                                <div key={product.id}>{product.image_src ? (
                                    <div className="bundle-product-image"
                                         style={{'backgroundImage': 'url(' + product.image_src + ')'}}/>
                                ) : (
                                    <NoImage itemId={product.shopify_product_id} klassName={'bundle-product-image'}/>
                                )}
                                </div>
                            ))}</div>}
                        <div className="bundle-list-buttons">
                        <span className="bundle-show-short-code-button">
                            <Button plain onClick={showShortCode(bundle.id)}>Short code</Button>
                        </span>
                            <span className="bundle-disable-button">
                        <Button primary={!bundle.is_enabled} onClick={switchEnable(bundle.id)}
                                loading={switchEnableLoading[bundle.id]}>
                            {bundle.is_enabled ? 'Disable' : 'Enable'}</Button>
                        </span>
                            <span className="bundle-edit-button">
                            <Button onClick={editBundle(bundle.id)} loading={editingBundle[bundle.id]}>Edit</Button>
                        </span>
                            <span className="delete-dup-buttons">
                        {duplicateBundleStarted[bundle.id] ?
                            <span className="dup-spinner"><Spinner size="small"/></span> :
                            <span data-tooltip="Duplicate" onClick={duplicateBundle(bundle.id)}
                                  className="duplicate-item">
                                <Icon source={DuplicateMinor}/>
                            </span>}
                            <span data-tooltip="Delete" onClick={removeBundle(bundle.id)} className="remove-item">
                                <Icon source={DeleteMajor}/>
                            </span>
                            <span data-tooltip="Preview" className="view-bundle" >
                                {productsBundle.previewBundleLoading[bundle.id] ?
                                    <Spinner size="small" /> :
                                    <span onClick={previewBundle(bundle.id)}><Icon source={ViewMajor}/></span>
                                }
                            </span>
                        </span>
                        </div>
                    </div>
                ))}
                {bundle_type === 'auto' && autoBundles && autoBundles.maxPage > 0 &&
                <div className="bundle-pagination"><Pagination
                    label={autoBundles.loading ? <Spinner size="small"/> : `Page ${autoBundles.page + 1}`}
                    hasPrevious={!autoBundles.loading && autoBundles.page > 0}
                    onPrevious={onPreviousAutoBundlePage}
                    hasNext={!autoBundles.loading && autoBundles.page < autoBundles.maxPage}
                    onNext={onNextAutoBundlePage}
                /></div>}
                {bundle_type === 'imported' && productsBundle.importedBundles && productsBundle.importedBundles.maxPage > 0 &&
                <div className="bundle-pagination"><Pagination
                    label={productsBundle.importedBundles.loading ? <Spinner size="small"/> : `Page ${productsBundle.importedBundles.page + 1}`}
                    hasPrevious={!productsBundle.importedBundles.loading && productsBundle.importedBundles.page > 0}
                    onPrevious={onPreviousImportedBundlePage}
                    hasNext={!productsBundle.importedBundles.loading && productsBundle.importedBundles.page < productsBundle.importedBundles.maxPage}
                    onNext={onNextImportedBundlePage}
                /></div>}
            </div>
        )}
        <Modal open={showShortCodeModalOpen} onClose={closeShortCodeModal}
               title={'Short code'}
        >
            <Modal.Section>
                    <span className="bundle-snippet-code"><TextStyle>{`<span zoorix-widget="bundle-list">`} <br/>
                        {productsBundle.shortCodeSingleId ? <div>
                            &nbsp;&nbsp;{`<div data-bundle-id="${shortCodeBundleId}"></div>`} <br/>
                            </div> : <div>
                            {productsBundle.shortCodeBundleIds && productsBundle.shortCodeBundleIds.map(id => <div key={id}>
                                &nbsp;&nbsp;{`<div data-bundle-id="${id}"></div>`} <br/>
                            </div>)}
                            </div>}
                        {`</span>`}</TextStyle>
                    </span>
            </Modal.Section>
        </Modal>
        <Modal open={noAutoBundlesModalOpen} onClose={closeNoAutoBundlesModal}
               title={'Insufficient data in your store orders'}
        >
            <Modal.Section>
                We couldn't build automatic bundles due to insufficient data in your store orders.
            </Modal.Section>
        </Modal>
        <Modal open={removeBundleModalOpen} onClose={closeRemoveBundleModal}
               title="Delete bundle"
               primaryAction={{
                   content: 'Delete',
                   onAction: removeBundleAction,
               }}
               secondaryActions={[
                   {
                       content: 'Cancel',
                       onAction: closeRemoveBundleModal,
                   },
               ]}>
            <Modal.Section>
                {removeBundleSpinner ? (
                    <span className="spinner-wrapper">
                            <Spinner/>
                        </span>
                ) : <TextContainer>
                    <p>
                        Are you sure you want to delete bundle <b>{bundleName}</b>?
                    </p>
                    {removeBundleError && <TextStyle variation="negative">Something went wrong</TextStyle>}
                </TextContainer>}

            </Modal.Section>
        </Modal>
        <Modal open={removeAutoBundleModalOpen} onClose={closeRemoveAutoBundleModal}
               title="Delete automatic bundles"
               primaryAction={{
                   content: 'Delete',
                   onAction: removeAutoBundlesAction,
               }}
               secondaryActions={[
                   {
                       content: 'Cancel',
                       onAction: closeRemoveAutoBundleModal,
                   },
               ]}>
            <Modal.Section>
                {removeAutoBundlesSpinner ?
                    <span className="spinner-wrapper">
                            <Spinner/>
                        </span>
                    :
                    <TextContainer>
                        <p>
                            Are you sure you want to delete all automatic bundles? This action cannot be undone!
                        </p>
                        {removeAutoBundlesError && <TextStyle variation="negative">Something went wrong</TextStyle>}
                    </TextContainer>}

            </Modal.Section>
        </Modal>
        {duplicateBundleFailure &&
        <Toast content="Bundle duplication failed" onDismiss={onDismissDuplicateBundleToast}/>}
    </div>
};

export default Bundles;
