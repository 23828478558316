import React from 'react';
import { Heading, Link, TextContainer } from "@shopify/polaris";
import "../css/PrivacyPolicy.css";

const PrivacyPolicy = () => (
    <TextContainer>
        <Heading>Zoorix Privacy Policy</Heading>
        <p>Zoorix App (the App) provides service (the Service) to merchants who use Shopify to power their stores.
            This Privacy Policy describes how personal information is collected, used, and shared when you install
            or use the App in connection with your Shopify-supported store.</p>
        <Heading>Personal Information the App Collects</Heading>
        <p>When you install the App, we are automatically able to access certain types of information from your
            Shopify account: read products, variants and collections and modify script tags in your store's theme template files.
            Additionally, we collect the following types of personal information from you once you have installed the App:
            Information about you and others who may access the App on behalf of your store, such as your name,
            address, email address, phone number, and billing information. <br />
            We collect personal information directly from the relevant individual, through your Shopify account,
            or using the following technologies: “Cookies” are data files that are placed on your device or computer
            and often include an anonymous unique identifier. For more information about cookies, and how to disable
            cookies, visit http://www.allaboutcookies.org. “Log files” track actions occurring on the Site,
            and collect data including your IP address, browser type, Internet service provider, referring/exit pages,
            and date/time stamps. “Web beacons,” “tags,” and “pixels” are electronic files used to record information
            about how you browse the Site. <br />
            When you install and use our App, we do not collect, process or retain any personal data of the customers or
            visitors of your Shopify store.
        </p>
        <Heading>How Do We Use Your Personal Information?</Heading>
        <p>We use the personal information we collect from you and your customers in order to provide the Service
            and to operate the App. Additionally, we use this personal information to: Communicate with you; Optimize
            or improve the App; and Provide you with information or advertising relating to our products or services.</p>
        <Heading>Sharing Your Personal Information</Heading>
        <p>We may share your Personal Information to comply with applicable laws and regulations, to respond to a
            subpoena, a search warrant or other lawful request for information we receive, or to otherwise protect our rights.</p>
        <Heading>Behavioural Advertising</Heading>
        <p>As described above, we use your Personal Information to provide you with targeted advertisements or
            marketing communications we believe may be of interest to you. For more information about how targeted
            advertising works, you can visit the Network Advertising Initiative’s (“NAI”) educational page
            at <Link url="http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work">http://www.networkadvertising.org/understanding-online-advertising/how-does-it-work</Link>.
            You can opt out of targeted advertising by: <br />
            FACEBOOK - <Link url="https://www.facebook.com/settings/?tab=ads">https://www.facebook.com/settings/?tab=ads</Link><br />
            GOOGLE - <Link url="https://www.google.com/settings/ads/anonymous">https://www.google.com/settings/ads/anonymous</Link><br />
            BING - <Link url="https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads">https://advertise.bingads.microsoft.com/en-us/resources/policies/personalized-ads</Link><br />
            Additionally, you can opt out of some of these services by visiting the Digital Advertising Alliance’s
            opt-out portal at: <Link url="http://optout.aboutads.info/">http://optout.aboutads.info/</Link>.
        </p>
        <Heading>Your Rights</Heading>
        <p>If you are a European resident, you have the right to access personal information we hold about you and
            to ask that your personal information be corrected, updated, or deleted. If you would like to exercise this
            right, please contact us through the contact information below. <br />
            Additionally, if you are a European resident we note that we are processing your information in order to
            fulfill contracts we might have with you, or otherwise to pursue our legitimate business interests listed
            above. Additionally, please note that your information will be transferred outside of Europe, including to
            Canada and the United States.
        </p>
        <Heading>Data Retention</Heading>
        <p>When you install the app, we will maintain your Information for our records unless and until you ask us to
            delete this information.</p>
        <Heading>Changes</Heading>
        <p>We may update this privacy policy from time to time in order to reflect, for example, changes to our
            practices or for other operational, legal or regulatory reasons.</p>
        <Heading>Contact Us</Heading>
        <p>For more information about our privacy practices, if you have questions, or if you would like to make a
            complaint, please contact us by e-mail at <Link url="mailto:support@zoorix.com">support@zoorix.com</Link></p>
    </TextContainer>
);

export default PrivacyPolicy;
