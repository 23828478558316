import { connect } from 'react-redux'
import TextsBundle from "../../components/texts/TextsBundle";
import {
    bundleAddSelectedToCartTextChange,
    bundleAddToCartTextChange,
    bundleContinueShoppingTextChange,
    bundleDiscountGrantedTextChange,
    bundleJustAddedTextChange,
    bundleNotifPopupQtyTextChange, bundleOutOfStockTextChange,
    bundlePopupViewCartTextChange,
    bundleQuickViewFullDetailsTextChange, bundleSaveTextChange,
    bundleTotalPriceTextChange,
    restoreBundleTextsDefaults
} from "../../actions/texts";

const mapStateToProps = state => ({
    translations: state.texts.translations,
    locale: state.texts.locale,
    saving: state.texts.saving,
});

const mapDispatchToProps = (dispatch) => ({
    restoreDefaults: () => dispatch(restoreBundleTextsDefaults()),
    onAddSelectedToCartTextChange: text => dispatch(bundleAddSelectedToCartTextChange(text)),
    onTotalPriceTextChange: text => dispatch(bundleTotalPriceTextChange(text)),
    onAddToCartTextChange: text => dispatch(bundleAddToCartTextChange(text)),
    onJustAddedTextChange: text => dispatch(bundleJustAddedTextChange(text)),
    onBundleDiscountGrantedTextChange: text => dispatch(bundleDiscountGrantedTextChange(text)),
    onBundleContinueShoppingTextChange: text => dispatch(bundleContinueShoppingTextChange(text)),
    onBundleQuickViewFullDetailsTextChange: text => dispatch(bundleQuickViewFullDetailsTextChange(text)),
    onBundlePopupViewCartTextChange: text => dispatch(bundlePopupViewCartTextChange(text)),
    onBundleNotifPopupQtyTextChange: text => dispatch(bundleNotifPopupQtyTextChange(text)),
    onBundleSaveTextChange: text => dispatch(bundleSaveTextChange(text)),
    onBundleOutOfStockTextChange: text => dispatch(bundleOutOfStockTextChange(text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TextsBundle);
