import { connect } from 'react-redux'
import Unsubscribed from "../components/Unsubscribed";

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Unsubscribed);
