import { connect } from 'react-redux'
import {addAlphaToColor} from "../settings/VisibleSettingsMain";
import FreeShippingBarSettings from "../../components/free-shipping-bar/FreeShippingBarSettings";
import {
    freeShippingBarAddCountry,
    freeShippingBarCancelAddingNewCountry,
    freeShippingBarChangeGoal,
    freeShippingBarChangeSettingBool,
    freeShippingBarChangeSettingColor,
    freeShippingBarChangeSettingText,
    freeShippingBarRemoveGoal,
    freeShippingBarRemoveTargetCountry,
    freeShippingBarSaveSettings,
    freeShippingBarSelectNewCountry,
    freeShippingBarSelectNewTargetCountry
} from "../../actions/free-shipping-bar/freeShippingBar";

const mapStateToProps = state => ({
    freeShippingBar: state.freeShippingBar,
});

const mapDispatchToProps = (dispatch) => ({
    save: () => dispatch(freeShippingBarSaveSettings()),
    addCountry: () => dispatch(freeShippingBarAddCountry()),
    cancelAddingNewCountry: () => dispatch(freeShippingBarCancelAddingNewCountry()),
    changeDefaultGoal: text => dispatch(freeShippingBarChangeGoal('default', text)),
    changeGoal: code => text => dispatch(freeShippingBarChangeGoal(code, text)),
    selectNewCountry: text => dispatch(freeShippingBarSelectNewCountry(text)),
    selectNewTargetCountry: text => dispatch(freeShippingBarSelectNewTargetCountry(text)),
    removeGoal: text => () => dispatch(freeShippingBarRemoveGoal(text)),
    removeTargetCountry: text => () => dispatch(freeShippingBarRemoveTargetCountry(text)),
    changeText: attribute => text => dispatch(freeShippingBarChangeSettingText(attribute, text)),
    changeBool: attribute => text => dispatch(freeShippingBarChangeSettingBool(attribute, text)),
    changeColor: attribute => color => dispatch(freeShippingBarChangeSettingColor(attribute, addAlphaToColor(color))),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FreeShippingBarSettings);
