import React from 'react';
import '../../css/TextsMain.css'
import { TextField} from "@shopify/polaris";

const TextsSlider = ({ translations, locale, saving, onTitleChange, onSubTitleChange, restoreDefaults,
                         onAddToCartButtonTitleChange, onAddToCartButtonAddedTextChange, onQuickViewTextChange,
                         onFullProductDetailsChange, onSliderContinueShoppingTextChange,
                         onSliderPopupJustAddedTextChange, onSliderPopupViewCartTextChange, onTitleCartSliderChange,
                         onTitleBottomSliderChange, onTitleMainSliderChange, onSliderNotifPopupQtyTextChange,
                         onTextChange}) =>
    <div>
        <TextField label="Title:" value={translations[locale].slider.title} onChange={onTitleChange} disabled={saving}
                   autoComplete={false} />
        <TextField label="Subtitle:" value={translations[locale].slider.sub_title} onChange={onSubTitleChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Cart custom title:" value={translations[locale].slider.title_cart_slider}
                   onChange={onTitleCartSliderChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Bottom custom title:" value={translations[locale].slider.title_bottom_slider}
                   onChange={onTitleBottomSliderChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Main custom title:" value={translations[locale].slider.title_main_slider}
                   onChange={onTitleMainSliderChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Add To Cart Button Text:" value={translations[locale].slider.add_to_cart_button_title}
                   onChange={onAddToCartButtonTitleChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Add To Cart Button Added Text:" value={translations[locale].slider.add_to_cart_button_added_text}
                   onChange={onAddToCartButtonAddedTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Quick View Text:" value={translations[locale].slider.quick_view_text}
                   onChange={onQuickViewTextChange} disabled={saving} autoComplete={false} />
        <TextField label="Full Product Details Text:" value={translations[locale].slider.full_product_details}
                   onChange={onFullProductDetailsChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Notification Popup Continue Shopping Text:"
                   value={translations[locale].slider.slider_continue_shopping_text}
                   onChange={onSliderContinueShoppingTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Notification Popup Just Added Text:" value={translations[locale].slider.slider_popup_just_added_text}
                   onChange={onSliderPopupJustAddedTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Notification Popup View Cart Text:" value={translations[locale].slider.slider_popup_view_cart_text}
                   onChange={onSliderPopupViewCartTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Notification Popup Quantity Text:" value={translations[locale].slider.slider_notif_popup_qty_text}
                   onChange={onSliderNotifPopupQtyTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Remove Text:" value={translations[locale].slider.remove_text}
                   onChange={onTextChange('slider', 'remove_text')}
                   disabled={saving} autoComplete={false} />
        {/*<span className="texts-restore">*/}
        {/*        <Button onClick={restoreDefaults}>Restore defaults</Button>*/}
        {/*</span>*/}
</div>;

export default TextsSlider;
