import React from 'react';
import '../../css/TextsMain.css'
import { TextField} from "@shopify/polaris";

const textField = (label, translations, locale, attr, textChange, saving) =>
    <TextField label={label} value={translations[locale].mix_and_match[attr]}
               onChange={textChange('mix_and_match', attr)}
               disabled={saving} autoComplete={false} />

const TextsMixAndMatch = ({translations, locale, saving, onTextChange}) =>
    <div>
        {textField('Save Amount:', translations, locale, 'save_amount', onTextChange, saving)}
        {textField('Add bundle:', translations, locale, 'add_selected_to_cart', onTextChange, saving)}
        {textField('Bundle just added:', translations, locale, 'just_added', onTextChange, saving)}
        {textField('Add Item From Collection:', translations, locale, 'add_item_from_collection', onTextChange, saving)}
        {textField('Item added:', translations, locale, 'item_just_added_text', onTextChange, saving)}
        {textField('Add item:', translations, locale, 'add_item_text', onTextChange, saving)}
        {textField('Bundle tier success:', translations, locale, 'tier_success_text', onTextChange, saving)}
        {textField('Search product placeholder:', translations, locale, 'search_product_text', onTextChange, saving)}
        {textField('Qty/Total added template:', translations, locale, 'qty_added_template', onTextChange, saving)}
        {textField('Total bundle label:', translations, locale, 'total_offer_price_label', onTextChange, saving)}
        {textField('Build bundle action:', translations, locale, 'go_to_bundle_builder_text', onTextChange, saving)}
        {textField('Out of stock item text:', translations, locale, 'out_of_stock_text', onTextChange, saving)}
        {textField('Load more text:', translations, locale, 'load_more_text', onTextChange, saving)}
    </div>;

export default TextsMixAndMatch;
