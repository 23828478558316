import { connect } from 'react-redux'
import Install from "../components/Install";
import {installShopDomainChange, installZoorix} from "../actions/install";

const mapStateToProps = state => ({
    shopDomain: state.install.shopDomain,
    installing: state.install.installing,
});

const mapDispatchToProps = (dispatch) => ({
    onShopDomainChange: text => dispatch(installShopDomainChange(text)),
    install: () => dispatch(installZoorix()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Install);
