import { connect } from 'react-redux'
import EditPage from "../../components/products/EditPage";
import {
    back,
    bundleSelected,
    moveBundleDown,
    moveBundleUp,
    pageTitleChange, removeBundleFromPage,
    savePage
} from "../../actions/productsBundle";

const mapStateToProps = state => ({
    page: state.productsBundle.page,
    pageSaving: state.productsBundle.pageSaving,
    pageBundles: state.productsBundle.pageBundles,
    saveError: state.productsBundle.saveError,
});

const mapDispatchToProps = (dispatch) => ({
    back: () => dispatch(back()),
    save: () => dispatch(savePage()),
    onPageTitleChange: text => dispatch(pageTitleChange(text)),
    moveBundleUp: index => () => dispatch(moveBundleUp(index)),
    moveBundleDown: index => () => dispatch(moveBundleDown(index)),
    removeBundle: index => () => dispatch(removeBundleFromPage(index)),
    onBundleSelected: item => dispatch(bundleSelected(item)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditPage);
