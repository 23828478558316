import { connect } from 'react-redux'
import {
    appV2WizardBack,
    selectPageType,
    wizardValidateSelectPage
} from "../../../actions/v2/appV2Wizard";
import AppV2WizardSelectPage from "../../../components/v2/wizard/AppV2WizardSelectPage";

const mapStateToProps = state => ({
    appV2Wizard: state.appV2Wizard,
});

const mapDispatchToProps = (dispatch) => ({
    selectPage: pageType => () => dispatch(selectPageType(pageType)),
    next: () => dispatch(wizardValidateSelectPage()),
    back: () => dispatch(appV2WizardBack()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppV2WizardSelectPage);
