import { connect } from 'react-redux'
import ProductsMixAndMatch from "../../components/products/ProductsMixAndMatch";
import {loadProductMixAndMatch, mixAndMatchDismissToast} from "../../actions/mixAndMatch";
import {changeTab} from "../../actions/nav";

const mapStateToProps = state => ({
    mixAndMatch: state.mixAndMatch,
    status: state.status,
});

const mapDispatchToProps = dispatch => ({
    load: () => dispatch(loadProductMixAndMatch()),
    onDismissToast: () => dispatch(mixAndMatchDismissToast()),
    navToSupport: () => dispatch(changeTab('support')),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductsMixAndMatch);
