import React from 'react';
import '../../css/Plans.css';
import {Icon, Spinner, Card, TextContainer, Button, Tooltip, TextField} from "@shopify/polaris";
import {InfoMinor} from "@shopify/polaris-icons";
import { TickMinor } from '@shopify/polaris-icons';
import PlatinumTable from "./PlatinumTable";
import VisibleOurPricingDiamond from "../../containers/plans/VisibleOurPricingDiamond";
import VisibleOurPricingPlatPlusBeta from "../../containers/plans/VisibleOurPricingPlatPlusBeta";
import VisibleOurPricingDiamondFlat from "../../containers/plans/VisibleOurPricingDiamondFlat";
import VisibleEmeraldTable from "../../containers/plans/VisibleEmeraldTable";
import VisibleOurPricingQuartz from "../../containers/plans/VisibleOurPricingQuartz";


const Plans = ({ installation, numOfOrders, packageName, trialDays, customPackagePrice, referralOfferingDiscount,
                   zoorixRevenueUsd, load, plans, setWhiteLabel, createCharge, promoCodeChange, submitPromoCode }) => (
    <div>
        {load() && <div></div>}
        <div className="zrx-tab-wrapper">
            <div className="zrx-tab-title">Plans</div>
            <div className="zrx-tab-subtitle">See pricing table and number of orders in the last 30 days</div>
        </div>
        {installation && installation.loading_plans ?
            <div className="app-loading"><Spinner /><span className="load-plan">Loading your plan...</span></div> :
            <div>
                <div className="plans-stats-cards">
                    {packageName === 'sapphire' ? <Card>
                        <Card.Section title="Zoorix revenue in the last month">
                            <TextContainer>
                                {isNaN(zoorixRevenueUsd) ? <Spinner size="small"/> :
                                <div className="number-of-orders">${Number(zoorixRevenueUsd).toLocaleString('en')}</div>}
                            </TextContainer>
                        </Card.Section>
                    </Card> :

                <Card>
                    <Card.Section title="Number of orders last month">
                        <TextContainer>
                            <div className="number-of-orders">{numOfOrders}</div>
                        </TextContainer>
                    </Card.Section>
                </Card>
                    }

                {trialDays >= 0 && <Card>
                    <Card.Section title="Trial days left">
                        <TextContainer>
                            <div className="number-of-orders">{trialDays}</div>
                        </TextContainer>
                    </Card.Section>
                </Card>}

                    {packageName === 'custom' && customPackagePrice && <Card>
                        <Card.Section title="Price per month">
                            <TextContainer>
                                <div className="number-of-orders">${customPackagePrice}</div>
                            </TextContainer>
                        </Card.Section>
                    </Card>}

                    {referralOfferingDiscount > 0 && <Card>
                        <Card.Section title="Referral Discount">
                            <TextContainer>
                                <div className="number-of-orders">{referralOfferingDiscount}%</div>
                            </TextContainer>
                        </Card.Section>
                    </Card>}

                    {!plans.refCodeLock && (['diamond', 'platinum_plus', 'diamond_flat', 'emerald', 'quartz', 'titanium', 'sapphire'].includes(packageName)) && <Card>
                        <Card.Section title={'Promo code'}>
                            <div className="promo-code-container">
                            <div className="promo-code-wrapper">
                                <TextField label={''} onChange={promoCodeChange} autoComplete={false}
                                       value={plans.promoCode}/>
                            </div>
                            <div className="promo-code-button-wrapper">
                                <Button loading={plans.submittingPromoCode} onClick={submitPromoCode}>Submit</Button>
                            </div>
                            </div>
                        </Card.Section>
                    </Card>}

                    {(plans.isFreeTier || plans.poweredByZoorixWhiteLabel) && <Card>
                        <Card.Section title={<div><span>Remove Powered by</span><span className="pwdby-tooltip">
                                                    <Tooltip light content="When moving to a paying tier the Powered By will be removed automatically and you will not be charged">
                                                        <Icon source={InfoMinor} />
                                                    </Tooltip>
                                                </span></div>}>

                            {plans.currentCharge ? <div>
                                {plans.poweredByZoorixWhiteLabel ?  <div className="zrx-remove-pwdby-wrapper">
                                    <Button onClick={setWhiteLabel(false)} loading={plans.whiteLabelLoading}>Opt out</Button>
                                    <div className="zrx-pwdby-cost">You are currently paying $7.99/mo</div>

                                </div> : <div className="zrx-remove-pwdby-wrapper">
                                    <Button onClick={setWhiteLabel(true)} primary={true} loading={plans.whiteLabelLoading}>Opt in</Button>
                                    <div className="zrx-pwdby-cost">Costs $7.99/mo</div>
                                </div>}
                            </div> : <div className="zrx-remove-pwdby-wrapper">
                                <span>
                                    <Button onClick={createCharge} primary={true}>Approve Charges</Button>
                                </span>
                            </div>}
                            {<div className="zrx-pwdby-white-days">
                                Monthly white label days: {plans.poweredByZoorixMonthlyCounter || 0}
                            </div>}
                        </Card.Section>
                    </Card>}

                </div>

                {packageName === 'emerald' && <VisibleEmeraldTable />}
                {(packageName === 'gold' || packageName === 'titanium') &&
                    <div>
                <table className="marketing-table pricing-table">
                    <thead>
                        <tr>
                            <th className="pricing-table__table-head" scope="col">
                            </th>
                            <th className={'pricing-table__table-head'} scope="col" id="p-0">
                                <p className="plan-description">For stores with fewer than 50 monthly store orders</p>
                            </th>
                            <th className={'pricing-table__table-head'} scope="col" id="p-1">
                                <p className="plan-description">For stores with more than 50 monthly store orders</p>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>Monthly Price</td>
                        <td><span className="price__number free">Free</span></td>
                        <td headers="p-0 f-monthly_price" className={'js-is-hidden'}>
                            <div className="monthly-price">
                                <span className="monthly-price__currency text-minor" aria-hidden="true">USD</span>
                                <span className="pricing-table__feature-value pricing-table__feature-price monthly-price__price">
                                    <sup className="currency-sign">$</sup><span className="price"><span aria-hidden="true"><span className="price__number">{packageName === 'gold' ? 7.99 : 19.99}</span></span></span>
                                </span>
                                <span className="monthly-price__billing-period text-minor" aria-hidden="true">/mo</span>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <th scope="col" colSpan="3">
                            <span className="heading--5 pricing-table__section-heading">Features</span>
                        </th>
                    </tr>
                    <tr>
                        <td>Unlimited usage of all features</td>
                        <td><span className="tick-icon"><Icon color="purple" source={TickMinor} /></span></td>
                        <td><span className="tick-icon"><Icon color="purple" source={TickMinor} /></span></td>
                    </tr>
                    </tbody>
                </table>
                    </div>}
                {packageName === 'platinum' && <PlatinumTable isPlatinumPlus={false} /> }
                {packageName === 'platinum_plus' && <div>
                    <PlatinumTable isPlatinumPlus={true} />
                </div>}
                {packageName === 'diamond' && <div>
                        <table className="diamond-table">
                        <thead>
                        <tr>
                            <th>Orders Per Month</th>
                            <th>Feature</th>
                            <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td aria-label="Orders">
                                0 - 50
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">FREE</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                51 - 100
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$7.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                101 - 200
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$14.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                201 - 500
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$19.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                501 - 1000
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$29.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                1001 - 2000
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$39.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                2001 - 5000
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$49.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                5001 - 10000
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$79.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                10001 - 20000
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$99.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                20001+
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$99.99 + $0.02 per additional order</td>
                        </tr>

                        </tbody>
                    </table>
                    <div className="diamond-pricing-slider">
                        <VisibleOurPricingDiamond/>
                    </div>
                </div>}
                {packageName === 'diamond_flat' && <div>
                        <table className="diamond-table">
                        <thead>
                        <tr>
                            <th>Orders Per Month</th>
                            <th>Feature</th>
                            <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td aria-label="Orders">
                                0 - 50
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">FREE</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                51 - 100
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$7.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                101 - 200
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$14.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                201 - 500
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$19.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                501 - 1000
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$29.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                1001 - 2000
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$39.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                2001 - 5000
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$49.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                5001 - 10000
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$79.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                10001+
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$99.99</td>
                        </tr>


                        </tbody>
                    </table>
                    <div className="diamond-pricing-slider">
                        <VisibleOurPricingDiamondFlat/>
                    </div>
                </div>}
                {packageName === 'quartz' && <div>
                        <table className="diamond-table">
                        <thead>
                        <tr>
                            <th>Orders Per Month</th>
                            <th>Feature</th>
                            <th>Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td aria-label="Orders">
                                0 - 50
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">FREE</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                51 - 100
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$7.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                101 - 200
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$14.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                201 - 500
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$29.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                501 - 1000
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$49.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                1001 - 2000
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$89.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                2001 - 5000
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$139.99</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                5001 - UNLIMITED
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Price">$199.99</td>
                        </tr>
                        </tbody>
                    </table>
                    <div className="diamond-pricing-slider">
                        <VisibleOurPricingQuartz/>
                    </div>
                </div>}
                {packageName === 'sapphire' && <div>
                        <table className="diamond-table">
                        <thead>
                        <tr>
                            <th>If Zoorix Makes you an additional:</th>
                            <th>Your Zoorix Subscription Is:</th>
                            <th>Your Estimated ROI:</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td aria-label="Sales">
                                $0 - $100
                            </td>
                            <td aria-label="Subscription">FREE</td>
                            <td aria-label="ROI">UNLIMITED</td>
                        </tr>
                        <tr>
                            <td aria-label="Sales">
                                $100 - $200
                            </td>
                            <td aria-label="Subscription">$7.99</td>
                            <td aria-label="ROI">12x - 25x</td>
                        </tr>
                        <tr>
                            <td aria-label="Sales">
                                $200 - $400
                            </td>
                            <td aria-label="Subscription">$14.99</td>
                            <td aria-label="ROI">13x - 26x</td>
                        </tr>
                        <tr>
                            <td aria-label="Sales">
                                $400 - $800
                            </td>
                            <td aria-label="Subscription">$29.99</td>
                            <td aria-label="ROI">13x - 27x</td>
                        </tr>
                        <tr>
                            <td aria-label="Sales">
                                $800 - $1.8k
                            </td>
                            <td aria-label="Subscription">$59.99</td>
                            <td aria-label="ROI">13x - 30x</td>
                        </tr>
                        <tr>
                            <td aria-label="Sales">
                                $1.8k - $3k
                            </td>
                            <td aria-label="Subscription">$89.99</td>
                            <td aria-label="ROI">20x - 33x</td>
                        </tr>
                        <tr>
                            <td aria-label="Sales">
                                $3k - $5k
                            </td>
                            <td aria-label="Subscription">$139.99</td>
                            <td aria-label="ROI">21x - 35x</td>
                        </tr>
                        <tr>
                            <td aria-label="Sales">
                                5k - 10k
                            </td>
                            <td aria-label="Subscription">$179.99</td>
                            <td aria-label="ROI">27x - 55x</td>
                        </tr>
                        <tr>
                            <td aria-label="Sales">
                                10k - 20k
                            </td>
                            <td aria-label="Subscription">$249.99</td>
                            <td aria-label="ROI">40x - 80x</td>
                        </tr>
                        <tr>
                            <td aria-label="Sales">
                                20k - 50k
                            </td>
                            <td aria-label="Subscription">$299.99</td>
                            <td aria-label="ROI">66x - 166x</td>
                        </tr>
                        <tr>
                            <td aria-label="Sales">
                                50k - 100k
                            </td>
                            <td aria-label="Subscription">$399.99</td>
                            <td aria-label="ROI">125x - 250x</td>
                        </tr>
                        <tr>
                            <td aria-label="Sales">
                                100k+
                            </td>
                            <td aria-label="Subscription">$499.99</td>
                            <td aria-label="ROI">200x +</td>
                        </tr>
                        </tbody>
                    </table>

                </div>}
                {packageName === 'plat_plus_beta' && <div>
                        <table className="diamond-table plat-plus-beta">
                        <thead>
                        <tr>
                            <th>Orders Per Month</th>
                            <th>Feature</th>
                            <th>Original Price</th>
                            <th>Discounted Price</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr>
                            <td aria-label="Orders">
                                0 - 50
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Original Price">FREE</td>
                            <td aria-label="Discounted Price">FREE</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                51 - 100
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Original Price">$7.99</td>
                            <td aria-label="Discounted Price">$6.39</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                101 - 200
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Original Price">$14.99</td>
                            <td aria-label="Discounted Price">$11.39</td>
                        </tr>
                        <tr>
                            <td aria-label="Orders">
                                201+
                            </td>
                            <td aria-label="Features">Full features</td>
                            <td aria-label="Original Price">$19.99 + $0.01 per additional order</td>
                            <td aria-label="Discounted Price">$15.99 + $0.008 per additional order, capped by $29.99</td>
                        </tr>

                        </tbody>
                    </table>
                    <div className="diamond-pricing-slider">
                        <VisibleOurPricingPlatPlusBeta/>
                    </div>
                </div>}
            </div>
        }
    </div>
);


export default Plans;
