import React from 'react';
import {
    Button,
    Card,
    Icon,
    List,
    SettingToggle,
    TextContainer,
    TextStyle,
    Spinner,
    Modal,
    ProgressBar,
} from "@shopify/polaris";
import { FlagMajor, CancelSmallMinor } from "@shopify/polaris-icons";
import '../css/Status.css'
import Moment from "react-moment";
import StarRatings from 'react-star-ratings';
import 'font-awesome/css/font-awesome.min.css';
import VisibleOurPricing from "../containers/plans/VisibleOurPricing";
import VisibleOurPricingDiamond from "../containers/plans/VisibleOurPricingDiamond";
import VisibleOurPricingPlatPlusBeta from "../containers/plans/VisibleOurPricingPlatPlusBeta";
import VisibleOurPricingDiamondFlat from "../containers/plans/VisibleOurPricingDiamondFlat";
import VisibleEmeraldTable from "../containers/plans/VisibleEmeraldTable";
import VisibleOurPricingQuartz from "../containers/plans/VisibleOurPricingQuartz";

const approvePayment = (packageName, referralOfferingDiscount) => <div>
    {packageName !== 'plat_plus_beta' && <span>Congratulations, you are 5 minutes away from <b>boosting your revenue!</b><br/><br/></span>}
    {referralOfferingDiscount > 0 && <span>You are eligible for <b>{referralOfferingDiscount}% referral discount</b> on all prices below.<br/><br/></span>}
    {packageName === 'gold' && <div>
        In the next page, you will approve charges for the Gold plan (up to $7.99/month).&nbsp;
        <b>We will not charge you $7.99</b> unless you have over 50 store orders/month. <br/><br/></div>}
    {packageName === 'platinum' && <div>
        In the next page, you will approve charges for the Platinum plan (up to $19.99/month).&nbsp;
        <b>We will not charge you $19.99</b> unless you have over 200 store orders/month. <br/><br/></div>}
    {packageName === 'plat_plus_beta' && <div>
        In the next page, you will approve charges for the Silver plan (up to $29.99/month).&nbsp;
        <b>We will not charge you $29.99</b> unless you have more than 1950 store orders/month. <br/><br/></div>}
    {(packageName === 'platinum_plus' || packageName === 'diamond' || packageName === 'quartz' || packageName === 'sapphire') && <div>
        In the next page, you will approve charges for the highest possible plan (up to $999.99/month).&nbsp;<br/><br/>
        <b>We will not charge you $999.99</b>.<br/><br/></div>}
    {packageName === 'emerald' && <div>
        In the next page, you will approve charges for the highest possible plan (up to $999.99/month).&nbsp;
        <b>We will not charge you $999.99</b>.<br/><br/></div>}
    {packageName === 'diamond_flat' && <div>
        In the next page, you will approve charges for the highest possible plan (up to $99.99/month).&nbsp;<br/><br/>
        <b>We will not charge you $99.99</b> unless you have over 10000 store orders/month.<br/><br/></div>}
    {packageName === 'titanium' && <div>
        In the next page, you will approve charges for $19.99/month.&nbsp;<br/><br/>
        <b>We will not charge you $19.99</b> unless you have over 50 store orders/month.<br/><br/></div>}
    {packageName === 'sapphire' && <span>
        The app is 100% FREE if Zoorix generated up to $100/month in additional sales, and you get 30 free trial days either way.
    </span>}
    {packageName !== 'sapphire' && <span>The app is 100% FREE if you have less than {packageName === 'emerald' ? '10 orders/month generated by Zoorix' : '50 store orders/month'} and you get
    a {packageName === 'plat_plus_beta' ? <span><b>60 days</b></span> : '30 days'} free trial either way.</span>}
    <br/>
    <br/>
</div>;

export const Status = ({ onDisable, onEnable, onResync, enabled, buttonText, content, processing, syncStarted, lastSync,
    syncedProducts, resyncLoading, resyncError, syncedCollections,
    cartButtonText, cartEnabled, onCartDisable, onCartEnable, cartContent, feedbackPopupActive,
    onActivateFeedbackPopup, onDismissFeedbackPopup, userLeftReview, closeReviewCard,
    onRate, mappings, cartProcessing, installingTheme, installation,
    belowAddToCart, productBottomEnabled, cartTop, cartBottom, fixProductWidget, fixCartWidget,
    waitingForProductPageWidget, waitingForCartPageWidget, packageName,
    createCharge, chargeActive, bundleButtonText, toggleBundleWidget, bundleEnabled,
    bundleProcessing, bundleContent, fixBundleWidget, warrantyButtonText, toggleWarrantyWidget, warrantyEnabled,
    warrantyProcessing, fixWarrantyWidget, warrantyContent, cartDrawerButtonText, cartDrawerEnabled, cartDrawerContent,
    toggleCartDrawerWidget, cartDrawerProcessing, bundles, closePaymentModal, paymentModalOpen, numOfOrders,
    trialDaysLeft, syncStartedText, installationStatusText, volumeDiscountButtonText, toggleVolumeDiscountWidget,
    volumeDiscountEnabled, volumeDiscountProcessing, volumeDiscountContent, referralOfferingDiscount,
    onlineStore2, shopName, showInstallZoorixHead, onHideDeepLink, showCloseDeepLink, onShowCloseDeepLinkCard,
    appEmbeds, appType, planName, devPlans, passwordEnabled, zoorixMEnabled, zoorixMEnabledLoading, showWizard }) => {

    return (
        <div className="status-tab">
            <Modal open={['gold', 'platinum', 'platinum_plus', 'diamond', 'plat_plus_beta', 'diamond_flat', 'emerald', 'quartz', 'titanium', 'sapphire'].includes(packageName) && !chargeActive && installation &&
                            !installation.loading_plans && paymentModalOpen && trialDaysLeft >= 0 && !appType && !devPlans.includes(planName) && planName !== 'trial'}
                   title={packageName === 'plat_plus_beta' ? 'Zoorix - Important Message' : "Welcome to Zoorix!"}
                   onClose={closePaymentModal}
                   primaryAction={{
                       content: 'Approve',
                       onAction: createCharge,
                   }}
                   secondaryActions={[
                       {
                           content: 'Later',
                           onAction: closePaymentModal,
                       },
                   ]}>
                <Modal.Section>
                    <div>{approvePayment(packageName, referralOfferingDiscount)}</div>
                    {packageName === 'platinum_plus' && <VisibleOurPricing />}
                    {packageName === 'diamond' && <VisibleOurPricingDiamond />}
                    {packageName === 'diamond_flat' && <VisibleOurPricingDiamondFlat />}
                    {packageName === 'quartz' && <VisibleOurPricingQuartz />}
                    {packageName === 'plat_plus_beta' && <VisibleOurPricingPlatPlusBeta />}
                    {packageName === 'emerald' && <VisibleEmeraldTable />}
                </Modal.Section>
            </Modal>
            <Modal open={['gold', 'platinum', 'platinum_plus', 'diamond', 'plat_plus_beta', 'diamond_flat', 'emerald', 'quartz', 'titanium', 'sapphire'].includes(packageName) && !chargeActive && installation &&
                            !installation.loading_plans && paymentModalOpen && trialDaysLeft < 0 && !appType && !devPlans.includes(planName)}
                   title="Zoorix was deactivated"
                   primaryAction={{
                       content: 'Approve',
                       onAction: createCharge,
                   }}
                   >
                <Modal.Section>
                    <div>Your trial period has expired. Please approve charges to activate the app.</div>
                    {packageName === 'platinum_plus' && <VisibleOurPricing />}
                    {packageName === 'diamond' && <VisibleOurPricingDiamond />}
                    {packageName === 'plat_plus_beta' && <VisibleOurPricingPlatPlusBeta />}
                    {packageName === 'diamond_flat' && <VisibleOurPricingDiamondFlat />}
                    {packageName === 'quartz' && <VisibleOurPricingQuartz />}
                    {packageName === 'emerald' && <VisibleEmeraldTable />}
                </Modal.Section>
            </Modal>

            {devPlans && devPlans.includes(planName) && <div>

                        <div className="dev-plan">
                            <span className="dev-plan-inner">Zoorix is a partner-friendly app!</span> Enjoy free access to all Zoorix features while the store is in development mode.
                        </div>
            </div>}

            {(onlineStore2 || appEmbeds) && <div>
                {passwordEnabled === 'false' && false && <div>
                    <Card.Section title="App status">
                        <SettingToggle action={{
                            content: zoorixMEnabled ? 'Disable' : 'Enable',
                            loading: zoorixMEnabledLoading,
                            onAction: () => window.open(`https://${shopName}/admin/themes/current/editor?context=apps`, '_blank'),
                        }}
                                       enabled={zoorixMEnabled}
                        >
                            {zoorixMEnabledLoading ? <span>Loading...</span> :
                                <span>Zoorix is <TextStyle
                                    variation="strong">{zoorixMEnabled ? 'Enabled' : 'Disabled'}</TextStyle>.
                                </span>
                            }
                        </SettingToggle>
                    </Card.Section>
                </div>}
                {(passwordEnabled !== 'false' || true ) &&
                <Card.Section>
                    <Card>
                        <div className="card-wrapper">
                            <div className="embedded-app-link-wrapper">
                                <div className="embedded-app-link-title">Zoorix Status</div>
                                <div className="embedded-app-buttons-wrapper">
                                    <Button plain onClick={() => window.open(`https://${shopName}/admin/themes/current/editor?context=apps`, '_blank')}>Enable / Disable Zoorix on the Shopify Editor</Button>
                                </div>
                            </div>
                        </div>
                    </Card>
                </Card.Section>}
            </div>}

            {(onlineStore2 || appEmbeds) &&  <div className="wizard-meeting-status-header">
                <div className="Polaris-Card__SectionHeader"><h3 aria-label="App status" className="Polaris-Subheading">Onboarding</h3></div>
            </div> }
            <div className="wizard-meeting-wrapper">
                <Card.Section>
                    <Card>
                        <div className="card-wrapper">
                            <div className="offers-wizard-wrapper">
                                <span>Offers Wizard</span>
                                <Button primary onClick={showWizard}>Create Offers</Button>
                            </div>
                        </div>
                    </Card>
                </Card.Section>

                 <Card.Section>
                    <Card>
                        <div className="card-wrapper">
                            <div className="book-meeting-wrapper">
                                <span>Onboarding Meeting</span>
                                <Button primary onClick={ () => window.open('https://calendly.com/zoorix/onboarding')}>Book Onboarding Meeting</Button>
                            </div>
                        </div>
                    </Card>
                </Card.Section>

            </div>

            {false && !userLeftReview && ((mappings && mappings.length > 0) || (bundles && bundles.length > 0)) && lastSync ? (
                <Card.Section>
                    <Card>
                        <div className="card-wrapper">
                            <span className="x-icon-wrapper" onClick={closeReviewCard}>
                                <Icon source={CancelSmallMinor} />
                            </span>
                            <div className="title">One last step!</div>
                            <div className="progress-line">
                                <ProgressBar progress={80} />
                            </div>
                            <List type="number">
                                <span className="underline">
                                    <List.Item>Install app</List.Item>
                                </span>
                                <span className="underline">
                                    <List.Item>Sync your products</List.Item>
                                </span>
                                <span className="underline">
                                    <List.Item>Configure settings</List.Item>
                                </span>
                                <span>
                                    <List.Item><span onClick={onActivateFeedbackPopup} className="call-to-action">Rate your experience with Zoorix</span></List.Item>
                                </span>
                            </List>
                        </div>
                    </Card>
                </Card.Section>
            ) : null}
            <div className="features-status">
                <div className="features-status-header">
                    <div className="Polaris-Card__SectionHeader"><h3 aria-label="App status" className="Polaris-Subheading">Feature Status</h3></div>
                </div>

            <Card.Section>
                {appType !== 'bundles' && <SettingToggle
                    action={{
                        content: buttonText,
                        onAction: enabled ? onDisable : onEnable,
                        loading: processing,
                    }}
                    enabled={enabled}
                >
                    {processing || installingTheme ? (
                        <div>{processing ? (<span>Processing...</span>) : (<span>Installing...</span>)}</div>
                    ) : (
                            <div>
                                {installation && ((!installation.product_main && belowAddToCart) || (!installation.product_bottom && productBottomEnabled)) ? (
                                    <div>
                                        {waitingForProductPageWidget ?
                                            <span className="waiting-spinner"><Spinner size="small" /><span className="waiting-text">Waiting for the product page widget to be loaded on your store</span></span> :

                                            <TextStyle variation="negative">Product page automatic installation failed (
                                            { !installation.product_main && belowAddToCart ? <span>Main</span> : null }
                                            { (!installation.product_main && belowAddToCart) && (!installation.product_bottom && productBottomEnabled) ? <span>, </span> : null }
                                            { !installation.product_bottom && productBottomEnabled ? <span>Bottom</span> : null }
                                            ) on your theme
                                            <span style={{cursor: 'pointer', paddingLeft: '15px'}} onClick={fixProductWidget}>
                                                <TextStyle variation="strong"><Button primary>Fix</Button></TextStyle></span></TextStyle>}
                                    </div>

                                ) : (
                                    <span>Slider on Product Page is <TextStyle variation="strong">{content}</TextStyle>.</span>
                                )}

                            </div>
                        )}

                </SettingToggle>}
                {appType !== 'bundles' && <SettingToggle
                    action={{
                        content: cartButtonText,
                        onAction: cartEnabled ? onCartDisable : onCartEnable,
                        loading: cartProcessing,
                    }}
                    enabled={cartEnabled}
                >
                    {cartProcessing || installingTheme ? (
                        <div>{processing ? (<span>Processing...</span>) : (<span>Installing...</span>)}</div>
                    ) : (
                        <div>
                            {installation && ((!installation.cart_top && cartTop) || (!installation.cart_bottom && cartBottom) ||
                                                !installation.cart_items) ? (
                                <div>
                                    {waitingForCartPageWidget ?
                                        <span className="waiting-spinner"><Spinner size="small" /><span className="waiting-text">Waiting for the cart page widget to be loaded on your store</span></span>
                                        :
                                        <TextStyle variation="negative">Cart page installation failed (
                                            { !installation.cart_top && cartTop ? <span>Top</span> : null }
                                            { (!installation.cart_top && cartTop) && (!installation.cart_bottom && cartBottom) ? <span>, </span> : null }
                                            { !installation.cart_bottom && cartBottom ? <span>Bottom</span> : null }
                                            { !installation.cart_items && ((!installation.cart_top && cartTop) || (!installation.cart_bottom && cartBottom)) ? <span>, </span> : null }
                                            { !installation.cart_items ? <span>Cart Items</span> : null }
                                            ) on your theme
                                            <span style={{cursor: 'pointer', paddingLeft: '15px'}} onClick={fixCartWidget}><TextStyle variation="strong"><Button primary>Fix</Button></TextStyle></span>
                                        </TextStyle>
                                    }
                                </div>

                            ) : (
                                <span>Slider on Cart is <TextStyle variation="strong">{cartContent}</TextStyle>.</span>
                            )}

                        </div>

                        )}

                </SettingToggle>}
                <SettingToggle
                    action={{
                        content: bundleButtonText,
                        onAction: toggleBundleWidget,
                        loading: bundleProcessing,
                    }}
                    enabled={bundleEnabled}
                >
                    {bundleProcessing || installingTheme ? (
                        <div>{bundleProcessing ? <span>Processing...</span> : <span>Installing...</span>}</div>
                    ) : (
                        <div>
                            {installation && !installation.product_main ? (
                                <div>
                                    {waitingForProductPageWidget ?
                                        <span className="waiting-spinner"><Spinner size="small" /><span className="waiting-text">Waiting for the bundle widget to be loaded on your store</span></span> :

                                        <TextStyle variation="negative">Bundle automatic installation failed on your theme
                                            <span style={{cursor: 'pointer', paddingLeft: '15px'}} onClick={fixBundleWidget}>
                                                <TextStyle variation="strong"><Button primary>Fix</Button></TextStyle></span></TextStyle>}
                                </div>

                            ) : (
                                <span>Bundle is <TextStyle variation="strong">{bundleContent}</TextStyle>.</span>
                            )}

                        </div>

                        )}

                </SettingToggle>
                {appType !== 'bundles' && <SettingToggle
                    action={{
                        content: cartDrawerButtonText,
                        onAction: toggleCartDrawerWidget,
                        loading: cartDrawerProcessing,
                    }}
                    enabled={cartDrawerEnabled}
                >
                    {cartDrawerProcessing || installingTheme ? (
                        <div>{cartDrawerProcessing ? <span>Processing...</span> : <span>Installing...</span>}</div>
                    ) : (
                        <div>
                            <span>Cart Drawer is <TextStyle variation="strong">{cartDrawerContent}</TextStyle>.</span>
                        </div>
                        )}
                </SettingToggle>}
                {appType !== 'bundles' && <SettingToggle
                    action={{
                        content: volumeDiscountButtonText,
                        onAction: toggleVolumeDiscountWidget,
                        loading: volumeDiscountProcessing,
                    }}
                    enabled={volumeDiscountEnabled}
                >
                    {volumeDiscountProcessing ?
                        <span>Processing...</span>
                        :
                        <span>Volume Discount is <TextStyle variation="strong">{volumeDiscountContent}</TextStyle>.</span>
                        }
                </SettingToggle>}
                {appType !== 'bundles' && <SettingToggle
                    action={{
                        content: warrantyButtonText,
                        onAction: toggleWarrantyWidget,
                        loading: warrantyProcessing,
                    }}
                    enabled={warrantyEnabled}
                >
                    {warrantyProcessing || installingTheme ? (
                        <div>{warrantyProcessing ? <span>Processing...</span> : <span>Installing...</span>}</div>
                    ) : (
                        <div>
                            {installation && !installation.product_main ?
                                <div>
                                    {waitingForProductPageWidget ?
                                        <span className="waiting-spinner"><Spinner size="small" />
                                        <span className="waiting-text">Waiting for the warranty widget to be loaded
                                            on your store</span></span>
                                        :
                                        <TextStyle variation="negative">Warranty automatic installation failed on your theme
                                            <span style={{cursor: 'pointer', paddingLeft: '15px'}} onClick={fixWarrantyWidget}>
                                                <TextStyle variation="strong"><Button primary>Fix</Button></TextStyle>
                                            </span>
                                        </TextStyle>}
                                </div>
                                :
                                <span>Warranty Upsell is <TextStyle variation="strong">{warrantyContent}</TextStyle>.</span>
                            }
                        </div>
                    )}
                </SettingToggle>}
            </Card.Section>
            </div>
            <div className='dashboard-status-partner-wrapper'>
                <Card.Section title="Sync status">
                    <TextContainer>
                        <List type="bullet">
                            <span className={"sync-li" + (syncStarted ? " syncing" : "")}>
                                <List.Item>{syncStarted ?
                                    <span><span className="doing-sync">{syncStartedText ? syncStartedText : installationStatusText}</span><span className="sync-spinner"><Spinner size='small' /></span>
                                        <TextStyle variation="subdued"> (Started at <Moment format="YYYY/MM/DD HH:mm:ss">{syncStarted}</Moment>)</TextStyle></span>
                                    : 'Sync complete'}</List.Item>
                            </span>
                            {lastSync && <List.Item>Last sync: <Moment format="YYYY/MM/DD HH:mm:ss">{lastSync}</Moment></List.Item>}
                            <List.Item># of orders last month: {numOfOrders}</List.Item>
                            <List.Item>Synced products: {syncedProducts}</List.Item>
                            <List.Item>Synced collections: {syncedCollections}</List.Item>
                        </List>
                        <p><Icon source={FlagMajor} />&nbsp;&nbsp;
                        <TextStyle variation="subdued">We sync your catalogue in the background regularly. If major
                        changes made to your catalogue, please manually resync the widget.</TextStyle></p>
                        <Button primary onClick={onResync} loading={resyncLoading}>Resync</Button>
                        {resyncError ?
                            (<TextStyle variation="negative">&nbsp;&nbsp;Something went wrong, please try to resync again</TextStyle>)
                            : null}
                    </TextContainer>
                </Card.Section>
                <Card.Section title="Partner Spotlight">
                        <div className="partner-spotlight-title">This month's partner spotlight is - <span className='partner-spotlight-name'>PageFly</span>!</div>
                        <div className='partner-spotligh-box' onClick={() => {window.open('https://pagefly.link/yFxAVJTm')}}>
                            <img alt="partners" className='partner-spotlight-pagefly-image' src="https://public.zoorix.com/partners/PageFly-BFCM2022.png"></img>
                        </div>
                </Card.Section>
            </div>
            <Modal
                open={feedbackPopupActive}
                onClose={onDismissFeedbackPopup}
            >
                <Modal.Section>

                        <TextContainer>
                            <p className="rate-title">
                                How would you rate your experience with Zoorix app?
                            </p>
                            <div className="review-wrapper">
                                <div className="review-container" onClick={onRate(5)}>
                                    <StarRatings
                                        rating={5}
                                        starRatedColor="#4C4CFF"
                                        numberOfStars={5}
                                        starDimension='26px'
                                        starSpacing='2px'
                                        name='rating'
                                    /><span className="ratings-title">Love it!</span></div>
                                <div className="review-container" onClick={onRate(4)}>
                                    <StarRatings
                                        rating={4}
                                        starRatedColor="#4C4CFF"
                                        numberOfStars={4}
                                        starDimension='26px'
                                        starSpacing='2px'
                                        name='rating'
                                    />Like it</div>
                                <div className="review-container" onClick={onRate(3)}>
                                    <StarRatings
                                        rating={3}
                                        starRatedColor="#4C4CFF"
                                        numberOfStars={3}
                                        starDimension='26px'
                                        starSpacing='2px'
                                        name='rating'
                                    />Okay</div>
                                <div className="review-container" onClick={onRate(2)}>
                                    <StarRatings
                                        rating={2}
                                        starRatedColor="#4C4CFF"
                                        numberOfStars={2}
                                        starDimension='26px'
                                        starSpacing='2px'
                                        name='rating'
                                    />Didn't like it</div>
                                <div className="review-container" onClick={onRate(1)}>
                                    <StarRatings
                                        rating={1}
                                        starRatedColor="#4C4CFF"
                                        numberOfStars={1}
                                        starDimension='26px'
                                        starSpacing='2px'
                                        name='rating'
                                    />Hate it</div>
                            </div>
                        </TextContainer>

                </Modal.Section>
            </Modal>
        </div>
    )
};

export default Status;
