import { connect } from 'react-redux'
import {
    sortProducts
} from "../../actions/analytics";
import AnalyticsProducts from "../../components/analytics/AnalyticsProducts";

const mapStateToProps = state => ({
    sortedProducts: state.analytics.sortedProducts,
    products: state.analytics.products,
    currency: state.analytics.currency,
});

const mapDispatchToProps = (dispatch) => ({
    handleSortProducts: (index, direction) => dispatch(sortProducts(index, direction)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AnalyticsProducts);
