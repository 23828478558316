import React from 'react';
import '../css/ManualInstallation.css';
import { Card, TextContainer, List, Heading, TextStyle } from "@shopify/polaris";

const ManualInstallation = ({ installation, jsSelectors }) => (
    <Card.Section>
        {installation && installation.product_main === false ? (
            <Card>
                <Card.Section sectioned>
                    <TextContainer>
                        <Heading>Install the product page main widget</Heading>
                        <List type="number">
                            <List.Item>Open your your theme editor : Online-Store -> Actions -> Edit Code</List.Item>
                            <List.Item>Find your product page liquid file, might be one of the following: <p><span className="padding-top">product-template.liquid, product.liquid, product-form.liquid, product-page-description.liquid, product-grid-item-design.liquid, etc.</span></p></List.Item>
                            <List.Item>Add <span className="faq-snippet-code"><TextStyle variation="code">{productMainSnippetCode()}</TextStyle></span> to the desired location - usually under add to cart button</List.Item>
                            <List.Item>Open your store and make sure the widget is showing</List.Item>
                        </List>
                    </TextContainer>
                </Card.Section>
            </Card>
        ) : null}
        {installation && installation.product_bottom === false ? (
            <Card>
                <Card.Section sectioned>
                    <TextContainer>
                        <Heading>Install the product page bottom widget</Heading>
                        <List type="number">
                            <List.Item>Open your your theme editor : Online-Store -> Actions -> Edit Code</List.Item>
                            <List.Item>Find your root product page liquid file, might be one of the following: <p>product-template.liquid, product.liquid</p></List.Item>
                            <List.Item>Add <span className="faq-snippet-code"><TextStyle variation="code">{jsSelectors ? productBottomSnippetCode2() : producBottomSnippetCode()}</TextStyle></span> to the desired location - usually at the bottom of the file</List.Item>
                            <List.Item>Make sure the product page widget is enabled</List.Item>
                            <List.Item>Open your store and make sure the widget is showing</List.Item>
                        </List>
                    </TextContainer>
                </Card.Section>
            </Card>
        ) : null}
        {installation && installation.cart_top === false ? (
            <Card>
                <Card.Section sectioned>
                    <TextContainer>
                        <Heading>Install cart page top widget</Heading>
                        <List type="number">
                            <List.Item>Open your your theme editor : Online-Store -> Actions -> Edit Code</List.Item>
                            <List.Item>Find your cart liquid file - might be cart-template.liquid, cart.liquid, etc.</List.Item>
                            <List.Item>Add <span className="faq-snippet-code"><TextStyle variation="code">{cartTopSnippetCode()}</TextStyle></span> on top of your cart liquid</List.Item>
                            <List.Item>Check that cart widget is enabled in the 'Status' tab</List.Item>
                            <List.Item>In the 'Settings' tab, check that the cart position is set to 'Top'</List.Item>
                            <List.Item>Open your store and make sure the widget is showing</List.Item>
                        </List>
                    </TextContainer>
                </Card.Section>
            </Card>
        ) : null}
        {installation && installation.cart_items === false ? (
            <Card>
                <Card.Section sectioned>
                    <TextContainer>
                        <Heading>{jsSelectors ? 'Install Zoorix head' : 'Install cart items'}</Heading>
                        <List type="number">
                            <List.Item>Open your your theme editor : Online-Store -> Actions -> Edit Code</List.Item>
                            <List.Item>Go to your theme.liquid file</List.Item>
                            <List.Item>Add <span className="faq-snippet-code"><TextStyle variation="code">{jsSelectors ? zoorixHeadCode() : cartItemsCode()}</TextStyle></span> just before the &lt;/head&gt;</List.Item>
                            <List.Item>Check that cart widget is enabled in the 'Status' tab</List.Item>
                            <List.Item>Open your store and make sure the widget is showing</List.Item>
                        </List>
                    </TextContainer>
                </Card.Section>
            </Card>
        ) : null}
        {installation && installation.cart_bottom === false ? (
            <Card>
                <Card.Section sectioned>
                    <TextContainer>
                        <Heading>Install cart page bottom widget</Heading>
                        <List type="number">
                            <List.Item>Find your cart liquid file - might be cart-template.liquid, cart.liquid, etc.</List.Item>
                            <List.Item>Add <span className="faq-snippet-code"><TextStyle variation="code">{cartBottomSnippetCode()}</TextStyle></span> on bottom of your cart liquid</List.Item>
                            <List.Item>Check that cart widget is enabled in the 'Status' tab</List.Item>
                            <List.Item>In the 'Settings' tab, check that the cart position is set to 'Bottom'</List.Item>
                            <List.Item>Open your store and make sure the widget is showing</List.Item>
                        </List>
                    </TextContainer>
                </Card.Section>
            </Card>
        ) : null}
        {installation && installation.product_main && installation.product_bottom && installation.cart_top && installation.cart_bottom && installation.cart_items ?
            (
                <Card>
                    <Card.Section sectioned>
                        <Heading>Installation finished successfully! Hooray!!! <span aria-label='yahh' role="img">🥳</span><span aria-label='yahh' role="img">🎉</span><span aria-label='yahh' role="img">🎊</span></Heading>
                    </Card.Section>
                </Card>
            ) : null}
    </Card.Section>
);


function cartBottomSnippetCode() {
    return "{% render 'zoorix-cart-bottom' %}"
}

function cartTopSnippetCode() {
    return "{% render 'zoorix-cart-top' %}"
}

function productMainSnippetCode() {
    return "{% render 'zoorix-product-main' %}"
}

function producBottomSnippetCode() {
    return "{% render 'zoorix-product-bottom' %}"
}

function productBottomSnippetCode2() {
    return '<div class="zrx-product-bottom"></div>';
}

function cartItemsCode(){
    return "{% render 'zoorix-cart-items' %}";
}

function zoorixHeadCode(){
    return "{% render 'zoorix-head' %}";
}

export default ManualInstallation;
