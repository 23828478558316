import {combineReducers} from "redux";
import support from "./support";
import settings from "./settings";
import data from "./data";
import status from "./status";
import analytics from "./analytics";
import products from "./products";
import nav from "./nav";
import feedback from "./feedback";
import productsBundle from "./productsBundle";
import settingsBundle from "./settingsBundle";
import settingsWarranty from "./settingsWarranty";
import productsWarranty from "./productsWarranty";
import cssEditor from "./cssEditor";
import redeem from "./redeem";
import warrantyModeration from "./warrantyModeration";
import settingsApp from "./settingsApp";
import settingsCartDrawer from "./settingsCartDrawer";
import texts from "./texts";
import settingsVolume from "./settingsVolume";
import productsVolume from "./productsVolume";
import plans from "./plans";
import partners from "./partners";
import install from "./install";
import appV2 from "./v2/app_v2";
import appV2Wizard from "./v2/app_v2_wizard";
import productsCartDrawer from "./productsCartDrawer";
import stickyAtc from "./sticky-atc/stickyAtc";
import mixAndMatch from "./mixAndMatch";
import freeShippingBar from "./free-shipping-bar/freeShippingBar";

export default combineReducers({
    support,
    settings,
    data,
    status,
    analytics,
    products,
    nav,
    feedback,
    productsBundle,
    settingsBundle,
    settingsWarranty,
    productsWarranty,
    cssEditor,
    redeem,
    warrantyModeration,
    settingsApp,
    settingsCartDrawer,
    texts,
    settingsVolume,
    productsVolume,
    plans,
    partners,
    install,
    appV2,
    appV2Wizard,
    productsCartDrawer,
    stickyAtc,
    mixAndMatch,
    freeShippingBar,
})
