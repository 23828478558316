import React from 'react';
import '../../css/Volume.css';
import {
    Button, Checkbox,
    FormLayout,
    Icon,
    Modal, Popover,
    Select,
    Spinner,
    TextField,
    TextStyle
} from "@shopify/polaris";
import NoImage from "./NoImage";
import {DeleteMajor} from "@shopify/polaris-icons";
import {addByFilterOptions} from "./ProductsSlider";

const navigateToOptions = [
    { label: 'Checkout', value: 'checkout' },
    { label: 'Cart', value: 'cart' },
    { label: 'None', value: 'none' },
];

const cartDisplayOptions = [
    { label: 'None', value: 'none' },
    { label: 'Cart bottom', value: 'bottom' },
    { label: 'Cart top', value: 'top' },
    { label: 'Both bottom & top', value: 'both' },
];

const productDisplayOptions = [
    { label: 'None', value: 'none' },
    { label: 'Below add to cart', value: 'main' },
    { label: 'Bottom of product page', value: 'bottom' },
    { label: 'Both bottom & below add to cart', value: 'both' },
];

const displayOptions = [
    { label: 'Display on the discount product', value: 'on_product' },
    { label: 'Display on selected items', value: 'on_selected' },
];

const offerTypeOptions = [
    { label: 'Discount on single product', value: 'single' },
    { label: 'Discount on single product from a group', value: 'group' },
];

export const discountTypeOptions = currencySymbol => [
    { label: currencySymbol, value: 'fixed' },
    { label: '%', value: 'percentage' },
];

const predefinedVariantOptions = variants => variants.map(v => ({
    label: v.title, value: v.shopify_id
}));

const VolumeDiscountEdit = ({ onBack, locales, onLocaleChange, locale, isSaving, onSave, discount, saveError,
                                onDiscountTitleChange, onDiscountSubtitleChange, onAddVariantTextChange,
                                onNavigateToChange, chooseProduct, chooseProductOpen, onCloseChooseProduct,
                                searchBoxText, onChangeSearchText, onFocusSearchText, showSearchSpinner,
                                searchError, searchedProducts, chooseProductItem, addTier, onQtyChange,
                                onDiscountChange, onMarkAsPopular, removeTier, onDiscountNameChange,
                                onDisplayOnChange, removeSourceProduct, removeSourceGroup, addProduct,
                                groupAddition, toggleAddGroupActive, onGroupTypeChange, onGroupValueChange,
                                onAddGroup, collectionsReady, onCartDisplayOnChange, onProductDisplayOnChange,
                                onOfferTypeChange, dynamicGroupAddition, toggleDynamicAddGroupActive,
                                onDynamicGroupTypeChange, onDynamicGroupValueChange, onChooseDynamicGroup,
                                currencySymbol, onDiscountTypeChange, onPredefinedVariantsChange, onVariantQtyChange,
                                addVariant, removeVariant, onVariantChange }) => (
        <FormLayout>
            <div>
                <span className="navigation-button">
                    <Button onClick={onBack} disabled={isSaving}>Back</Button>
                </span>
                <span className="navigation-button">
                    <Button onClick={onSave} primary loading={isSaving}
                            disabled={(discount.dynamic && !discount.filter1_value) ||
                            (!discount.dynamic && !discount.product) || discount.tiers.length === 0}
                    >Save</Button>
                </span>
            </div>
            {saveError && <TextStyle variation="negative">There was an error sending your request. Please try again in a
            few minutes</TextStyle>}
            <div>
                <span>
                    <TextField label="Discount Name:" value={discount.name}
                               onChange={onDiscountNameChange} disabled={isSaving} autoComplete={false} />
                    <TextField label="Discount Title:" value={discount.translations[locale].title}
                               onChange={onDiscountTitleChange} disabled={isSaving} autoComplete={false} />
                    <TextField label="Discount Subtitle:" value={discount.translations[locale].subtitle}
                               autoComplete={false} onChange={onDiscountSubtitleChange} disabled={isSaving} />
                    <TextField label="Add Variant Text:" value={discount.translations[locale].add_variant_text}
                               autoComplete={false} onChange={onAddVariantTextChange} disabled={isSaving} />
                </span>
                {locales.length > 1 && <span><Select label="Locale:" options={locales} onChange={onLocaleChange}
                                                     value={locale} /></span>}

            </div>
            <Select label="Navigate to:" options={navigateToOptions} onChange={onNavigateToChange}
                    value={discount.nav_after_add} disabled={isSaving} />
            <Select label="Location on cart page:" options={cartDisplayOptions} onChange={onCartDisplayOnChange}
                    value={discount.display_on_cart} />
            <Select label="Location on product page:" options={productDisplayOptions} onChange={onProductDisplayOnChange}
                    value={discount.display_on_product} />
            <Select label="Offer type:" options={offerTypeOptions} onChange={onOfferTypeChange}
                    value={discount.dynamic ? 'group' : 'single'} />
            {discount.dynamic ? <div>
                    {collectionsReady ? <div>
                        <Popover
                            active={dynamicGroupAddition.addGroupActive}
                            activator={<Button onClick={toggleDynamicAddGroupActive} disclosure>Choose group</Button>}
                            onClose={toggleDynamicAddGroupActive}
                            ariaHaspopup={false}
                            sectioned
                        >
                            <FormLayout>
                                <Select options={addByFilterOptions} onChange={onDynamicGroupTypeChange}
                                        value={dynamicGroupAddition.groupType} />
                                <Select options={dynamicGroupAddition.groupValues} onChange={onDynamicGroupValueChange}
                                        value={dynamicGroupAddition.groupValue} />
                                <Button primary onClick={onChooseDynamicGroup}>Choose</Button>
                            </FormLayout>
                        </Popover>
                        {discount.filter1_type && <div className="vd-display-group-wrapper">
                        <span className="vd-display-group-">
                                {addByFilterOptions.find(o => o.value === discount.filter1_type).label}:&nbsp;
                            {discount.filter1_value}
                        </span>
                        </div>}
                    </div> : <Spinner size="small" />}
                </div> :
                <div>
                    <div>
                     <span >
                        <Button onClick={chooseProduct}>Select Product</Button>
                    </span>
                        {discount.product && <div className="bundle-line-item-wrapper"> <span className="bundle-line-item">

                        {discount.product.image_src ?
                            <div className="bundle-product-image vd-item-image" style={{ 'backgroundImage': 'url(' + discount.product.image_src + ')' }} />
                            :
                            <NoImage itemId={discount.product.id} />
                        }
                            <div>{discount.product.title}</div>
                    </span></div>}
                    </div>
                </div>}
            {!discount.dynamic && <div>
            <Select label="Display options:" options={displayOptions} onChange={onDisplayOnChange}
                    value={discount.display_on_products} />
            {discount.display_on_products === 'on_selected' && <div className="vd-display-items-wrapper">
                {discount.source_display_on.filter(p => p.source_type === 'single_product').map(product =>
                    <div className="vd-display-item" key={product.id}>
                        {product.image_src ?
                            <div className="vd-display-item-image" style={{ 'backgroundImage': 'url(' + product.image_src + ')' }} />
                            :
                            <NoImage itemId={product.shopify_product_id} />
                        }
                        <div className="vd-product-to-show-on-title">{product.title}</div>
                        <span className="vd-remove-display-item" onClick={removeSourceProduct(product.id)}>
                            <Icon source={DeleteMajor} />
                        </span>
                    </div>)}
                {discount.source_display_on.filter(g => g.source_type === 'product_group').map(group =>
                    <div className="vd-display-item" key={group.id}>

                        <span>
                            {addByFilterOptions.find(o => o.value === group.filter1_type).label}:&nbsp;
                            {group.filter1_value}
                        </span>
                        <span className="vd-remove-display-item" onClick={removeSourceGroup(group.id)}>
                            <Icon source={DeleteMajor} />
                        </span>
                    </div>)}
                {collectionsReady ? <div className="display-options-buttons">
                    <div className="add-product-button-wrapper">
                        <Button onClick={addProduct}>Add product</Button>
                    </div>
                    <div className="add-group-button-wrapper">
                        <Popover
                            active={groupAddition.addGroupActive}
                            activator={<Button onClick={toggleAddGroupActive} disclosure>Add group</Button>}
                            onClose={toggleAddGroupActive}
                            ariaHaspopup={false}
                            sectioned
                        >
                            <FormLayout>
                                <Select options={addByFilterOptions} onChange={onGroupTypeChange} value={groupAddition.groupType} />
                                <Select options={groupAddition.groupValues} onChange={onGroupValueChange} value={groupAddition.groupValue} />
                                <Button primary onClick={onAddGroup}>Add</Button>
                            </FormLayout>
                        </Popover>
                    </div>
                </div> : <Spinner size="small" />}
            </div>}
            </div>}

            <Button onClick={addTier} primary>Add Tier</Button>
            {discount.tiers.map((tier, index) => <div key={index}>
                <div className="vd-tier-wrapper">
                    <TextField label="Quantity:" value={tier.qty && tier.qty.toString()} type="number"
                               onChange={onQtyChange(index)} disabled={isSaving || (tier.predefined_variants && !discount.dynamic)}
                               autoComplete={false} />
                    <div className="vd-type-amount-wrapper">
                        <TextField label="Discount:" value={tier.discount && tier.discount.toString()} type="number"
                                   onChange={onDiscountChange(index)} disabled={isSaving} autoComplete={false} />
                        <Select label="Type:" options={discountTypeOptions(currencySymbol)}
                                onChange={onDiscountTypeChange(index)}
                                value={tier.discount_type || 'percentage'} disabled={isSaving} />
                    </div>
                    <div className="mark-popular-remove-wrapper">
                        <Checkbox label="Mark as popular" checked={tier.popular}
                                  onChange={onMarkAsPopular(index)} />
                    </div>
                    {discount.product && discount.product.variants && discount.product.variants.length > 1 && !discount.dynamic && <div className="pre-defined-variants-wrapper">
                        <Checkbox label="Predefined variants" checked={tier.predefined_variants}
                                  onChange={onPredefinedVariantsChange(index)} disabled={isSaving} />
                        {tier.predefined_variants && tier.predefined_variants.map((variant, index2) =>
                            <div className="pre-defined-variants-box" key={index2}>
                                <Select label="Variant:" options={predefinedVariantOptions(discount.product.variants)}
                                        onChange={onVariantChange(index, index2)}
                                        value={variant.id} disabled={isSaving} />
                                <TextField label="Quantity:" value={variant.qty && variant.qty.toString()} type="number"
                                       onChange={onVariantQtyChange(index, index2)} disabled={isSaving} autoComplete={false} />
                                <Button plan onClick={removeVariant(index, index2)}> <Icon source={DeleteMajor} /></Button>
                        </div>)}
                        {tier.predefined_variants && <Button onClick={addVariant(index)}>
                            Add variant
                        </Button>}
                    </div>}
                    <div className="delete-tier-button-wrapper">
                        <Button plain onClick={removeTier(index)}>
                            <Icon source={DeleteMajor} />
                        </Button>
                    </div>
                </div>

            </div>)}

            <Modal open={chooseProductOpen} onClose={onCloseChooseProduct}
                title="Select a product"
                secondaryActions={[{ content: 'Cancel', onAction: onCloseChooseProduct }]}>
                <Modal.Section>
                    <div className="search-products bundle">
                        <TextField placeholder='Search phrase, e.g. Shirts for men' autoComplete={false}
                            label="Search:"
                            value={searchBoxText} onChange={onChangeSearchText}
                            onFocus={onFocusSearchText} />
                        {showSearchSpinner && <div className="fetch-search-wrapper"><span
                            className="searching">Searching...</span><span className="fetch-spinner"><Spinner
                                size="small" /></span></div>}
                        {searchError && <TextStyle variation="negative">Something went wrong</TextStyle>}
                        {searchedProducts && searchedProducts.map((product) => (
                            <div className={'product-container searchable bundle'} key={product.id}
                                onClick={chooseProductItem(product.id)}>
                                <span className="image-wrapper">
                                    {product.image_src ? (
                                        <div className="item-image bundle"
                                            style={{ 'backgroundImage': 'url(' + product.image_src + ')' }} />
                                    ) : (
                                            <NoImage itemId={product.id} className={'item-image bundle'} />
                                        )}
                                </span>
                                <span className="bundle-searchable-item">{product.title}</span>
                            </div>
                        ))}
                    </div>
                </Modal.Section>
            </Modal>
        </FormLayout >
    );

export default VolumeDiscountEdit;
