import { connect } from 'react-redux'
import {
    restoreSliderTextsDefaults,
    sliderAddToCartButtonAddedTextChange,
    sliderAddToCartButtonTitleChange,
    sliderFullProductDetailsChange,
    sliderPopupJustAddedTextChange,
    sliderQuickViewTextChange,
    sliderContinueShoppingTextChange,
    sliderSubTitleChange,
    sliderTitleChange,
    sliderPopupViewCartTextChange,
    sliderTitleCartSliderChange,
    sliderTitleBottomSliderChange,
    sliderTitleMainSliderChange, sliderNotifPopupQtyTextChange, textsChangeText
} from "../../actions/texts";
import TextsSlider from "../../components/texts/TextsSlider";

const mapStateToProps = state => ({
    translations: state.texts.translations,
    locale: state.texts.locale,
    saving: state.texts.saving,
});

const mapDispatchToProps = (dispatch) => ({
    onTitleChange: text => dispatch(sliderTitleChange(text)),
    onSubTitleChange: text => dispatch(sliderSubTitleChange(text)),
    restoreDefaults: () => dispatch(restoreSliderTextsDefaults()),
    onAddToCartButtonTitleChange: text => dispatch(sliderAddToCartButtonTitleChange(text)),
    onAddToCartButtonAddedTextChange: text => dispatch(sliderAddToCartButtonAddedTextChange(text)),
    onQuickViewTextChange: text => dispatch(sliderQuickViewTextChange(text)),
    onFullProductDetailsChange: text => dispatch(sliderFullProductDetailsChange(text)),
    onSliderContinueShoppingTextChange: text => dispatch(sliderContinueShoppingTextChange(text)),
    onSliderPopupJustAddedTextChange: text => dispatch(sliderPopupJustAddedTextChange(text)),
    onSliderPopupViewCartTextChange: text => dispatch(sliderPopupViewCartTextChange(text)),
    onTitleCartSliderChange: text => dispatch(sliderTitleCartSliderChange(text)),
    onTitleBottomSliderChange: text => dispatch(sliderTitleBottomSliderChange(text)),
    onTitleMainSliderChange: text => dispatch(sliderTitleMainSliderChange(text)),
    onSliderNotifPopupQtyTextChange: text => dispatch(sliderNotifPopupQtyTextChange(text)),
    onTextChange: (widget, attr) => text => dispatch(textsChangeText(widget, attr, text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TextsSlider);
