import { connect } from 'react-redux'
import FreeShippingBarTexts from "../../components/free-shipping-bar/FreeShippingBarTexts";
import {
    freeShippingBarChangeSaveTexts,
    freeShippingBarLocaleChange,
    freeShippingBarTextsChange
} from "../../actions/free-shipping-bar/freeShippingBar";

const mapStateToProps = state => ({
    freeShippingBar: state.freeShippingBar,
});

const mapDispatchToProps = (dispatch) => ({
    onTextChange: attribute => text => dispatch(freeShippingBarTextsChange(attribute, text)),
    onLocaleChange: locale => dispatch(freeShippingBarLocaleChange(locale)),
    save: () => dispatch(freeShippingBarChangeSaveTexts()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FreeShippingBarTexts);
