import { connect } from 'react-redux'
import AppV2 from "../../components/v2/AppV2";
import {initAppV2} from "../../actions/v2/appV2";

const mapStateToProps = state => ({
    appV2: state.appV2,
});

const mapDispatchToProps = (dispatch) => ({
    initApp: params => dispatch(initAppV2(params)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppV2);
