import React from 'react';
import '../../css/Plans.css';
import '../../css/Plans.scss';

const EmeraldTable = () => <div id="emerald-table">
    <table>
        <thead>
        <tr>
            <th>Orders Genrated By Zoorix</th>
            <th>Features</th>
            <th>Price</th>
        </tr>
        </thead>
        <tbody>
        <tr>
            <td aria-label="Orders">
                First 10 orders
            </td>
            <td aria-label="Features">Full features</td>
            <td aria-label="Price">FREE</td>
        </tr>
        <tr>
            <td aria-label="Orders">
                Next 100 orders
            </td>
            <td aria-label="Features">Full features</td>
            <td aria-label="Price">99𝇍 per order</td>
        </tr>
        <tr>
            <td aria-label="Orders">
                Next 1000 orders
            </td>
            <td aria-label="Features">Full features</td>
            <td aria-label="Price">49𝇍 per order</td>
        </tr>
        <tr>
            <td aria-label="Orders">
                Everything after
            </td>
            <td aria-label="Features">Full features</td>
            <td aria-label="Price">FREE</td>
        </tr>
        </tbody>
    </table>
</div>

export default EmeraldTable;
