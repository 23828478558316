import React from 'react';
import '../../../css/v2/AppV2Wizard.scss';
import {select, trigger} from "./AppV2WizardSelectTrigger";
import {Spinner, TextField} from "@shopify/polaris";
import {Modal} from 'antd';


const AppV2WizardConfigCrossSell = ({
    appV2Wizard, selectContentType, toggleSelectProductsModal,
    toggleSelectCollectionsModal, toggleSelectTagsModal, approveSelectedProducts,
    onProductSearchTextChange, removeSelectedProduct, toggleSelectProduct,
    approveSelectedCollections, onCollectionSearchTextChange,
    removeSelectedCollection, toggleSelectCollection, approveSelectedTags,
    onTagSearchTextChange, removeSelectedTag, toggleSelectTag
}) =>
    <div>
        <div className="select-trigger-wrapper">
            {trigger(appV2Wizard.contentType, 'specific_products', selectContentType, <div>
                Show specific products in offer
            </div>)}
            {trigger(appV2Wizard.contentType, 'collections', selectContentType, <div>
                Show specific collections in offer
            </div>)}
            {trigger(appV2Wizard.contentType, 'tags', selectContentType, <div>
                Show specific tags offer
            </div>)}

            <Modal
                visible={appV2Wizard.selectProductsModalOpen}
                className="offer-items-modal"
                title={'Select offer products'}
                onCancel={toggleSelectProductsModal}
                onOk={approveSelectedProducts}
                width="50%"
                cancelText="Close"
                okText="Select"
            >
                <TextField label=""
                           value={appV2Wizard.searchText} placeholder={'Search products'}
                           onChange={onProductSearchTextChange} autoComplete={false}/>
                <div className="selected-items-wrapper">
                    {appV2Wizard.selectedProducts && appV2Wizard.selectedProducts.map(p => <div
                        className="selected-item"
                        key={p.id}>
                        <span className="selected-item-title">{p.title}</span>
                        <span className="selected-item-x" onClick={removeSelectedProduct(p.id)}>X</span>
                    </div>)}</div>
                {appV2Wizard.searchedProductsSpinner && <span className="spinner-wrapper"><Spinner /></span>}
                <div className="search-items-wrapper">
                    {appV2Wizard.searchedProducts && appV2Wizard.searchedProducts.map(p =>
                        <div key={p.node.id} onClick={toggleSelectProduct(p.node.id)}
                             className={p.selected ? 'search-item app-v2-wizard-selected-product' : 'search-item  app-v2-wizard-not-selected-product'}>
                            <span>{p.node.title}</span>
                            {p.node.images.edges.length > 0 && <img width={'30px'} height={'30px'} alt="" src={p.node.images.edges[0].node.originalSrc}/>}
                            <span className={'checkbox'}>
                                <div className={'container'}>
                                    <div className={'round'}>
                                        <input type="checkbox" readOnly checked={p.selected} id={p.node.id}/>
                                        <label htmlFor={p.node.id}></label>
                                    </div>
                                </div>
                             </span>
                        </div>)}
                </div>
            </Modal>


            <Modal
                visible={appV2Wizard.selectCollectionsModalOpen}
                className="offer-collection-modal"
                title={'Select offer collections'}
                onCancel={toggleSelectCollectionsModal}
                onOk={approveSelectedCollections}
                width="50%"
                cancelText="Close"
                okText="Select"
            >
                <TextField label=""
                           value={appV2Wizard.searchText} placeholder={'Search collections'}
                           onChange={onCollectionSearchTextChange} autoComplete={false}/>
                <div className="selected-items-wrapper">

                    {appV2Wizard.selectedCollections && appV2Wizard.selectedCollections.map(c => <div
                        className="selected-item" key={c.id}>
                        <span className="selected-item-title">{c.title}</span>
                        <span className="selected-item-x" onClick={removeSelectedCollection(c.id)}>X</span>
                    </div>)}</div>
                {appV2Wizard.searchedCollectionsSpinner && <span className="spinner-wrapper"><Spinner /></span>}
                <div className="search-items-wrapper">
                    {appV2Wizard.searchedCollections && appV2Wizard.searchedCollections.map(p =>
                        <div key={p.node.id} onClick={toggleSelectCollection(p.node.id)}
                             className={p.selected ? 'search-item app-v2-wizard-selected-collection' : 'search-item app-v2-wizard-not-selected-collection'}>
                            <span>{p.node.title}</span>
                            <span> ({p.node.productsCount} products)</span>
                            <span className={'checkbox'}>
                                <div className={'container'}>
                                    <div className={'round'}>
                                        <input type="checkbox" readOnly checked={p.selected} id={p.node.id}/>
                                        <label htmlFor={p.node.id}></label>
                                    </div>
                                </div>
                            </span>
                        </div>)}
                </div>
            </Modal>

            <Modal
                visible={appV2Wizard.selectTagsModalOpen}
                className="offer-collection-modal"
                title={"Select offer tags"}
                onCancel={toggleSelectTagsModal}
                onOk={approveSelectedTags}
                width="50%"
                cancelText="Close"
                okText="Select"
            >
                <TextField label=""
                           value={appV2Wizard.searchText} placeholder={'Search tags'}
                           onChange={onTagSearchTextChange} autoComplete={false}/>
                <div className="selected-items-wrapper">
                    {appV2Wizard.selectedTags && appV2Wizard.selectedTags.map(tag => <div className="selected-item"
                                                                                          key={tag}>
                        <span className="selected-item-title">{tag}</span>
                        <span className="selected-item-x" onClick={removeSelectedTag(tag)}>X</span>
                    </div>)}</div>
                <div className="search-items-wrapper">
                    {appV2Wizard.searchedTags && appV2Wizard.searchedTags.map(edge =>
                    edge.node && <div key={edge.node} onClick={toggleSelectTag(edge.node)}
                             className={edge.selected ? 'search-item  app-v2-wizard-selected-tag' : 'search-item app-v2-wizard-not-selected-tag'}>
                            <span>{edge.node}</span>
                            <span className={'checkbox'}>
                                <div className={'container'}>
                                    <div className={'round'}>
                                        <input type="checkbox" readOnly checked={edge.selected} id={edge.id}/>
                                        <label htmlFor={edge.id}></label>
                                    </div>
                                </div>
                            </span>
                        </div>)}
                </div>
            </Modal>
        </div>

        <div className={'open-modal-btn-wrapper'}>
            {select(appV2Wizard, 'contentType', 'specific_products', toggleSelectProductsModal,
                'confirmedCrossSellSelectedProducts', 'Select products', 'Product', 'Products')}
            {select(appV2Wizard, 'contentType', 'collections', toggleSelectCollectionsModal,
                'confirmedCrossSellSelectedCollections', 'Select collections', 'Collection', 'Collections')}
            {select(appV2Wizard, 'contentType', 'tags', toggleSelectTagsModal,
                'confirmedCrossSellSelectedTags', 'Select tags', 'Tag', 'Tags')}
        </div>
    </div>;

export default AppV2WizardConfigCrossSell;
