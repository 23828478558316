import { connect } from 'react-redux'
import FreeShippingBar from "../../components/free-shipping-bar/FreeShippingBar";
import {
    freeShippingBarDismissToast,
    freeShippingBarSelectTab,
    initFreeShippingBar
} from "../../actions/free-shipping-bar/freeShippingBar";

const mapStateToProps = state => ({
    freeShippingBar: state.freeShippingBar,
});

const mapDispatchToProps = (dispatch) => ({
    init: params => dispatch(initFreeShippingBar(params)),
    handleTabSelect: index => dispatch(freeShippingBarSelectTab(index)),
    onDismissToast: () => dispatch(freeShippingBarDismissToast()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FreeShippingBar);
