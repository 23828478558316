import React from 'react';
import '../../css/free-shipping-bar/freeShippingBar.scss';
import { Frame, Spinner, Tabs, Toast } from "@shopify/polaris";
import {freeShippingBarTabs} from "../../actions/free-shipping-bar/freeShippingBar";
import VisibleFreeShippingBarDashboard from "../../containers/free-shipping-bar/VisibleFreeShippingBarDashboard";
import VisibleFreeShippingBarSettings from "../../containers/free-shipping-bar/VisibleFreeShippingBarSettings";
import VisibleFreeShippingBarCodeEditor from "../../containers/free-shipping-bar/VisibleFreeShippingBarCodeEditor";
import VisibleFreeShippingBarSupport from "../../containers/free-shipping-bar/VisibleFreeShippingBarSupport";
import VisibleFreeShippingBarFaq from "../../containers/free-shipping-bar/VisibleFreeShippingBarFaq";
import VisibleFreeShippingBarTexts from "../../containers/free-shipping-bar/VisibleFreeShippingBarTexts";
import VisibleFreeShippingBarUpgrade from "../../containers/free-shipping-bar/VisibleFreeShippingBarUpgrade";


const FreeShippingBar = ({ match: { params }, init, freeShippingBar, handleTabSelect, onDismissToast }) => {
    init(params);
    return <Frame>
        {
            !freeShippingBar.initialized ?
                <div><Spinner /></div> :
                <div>
                    <Tabs selected={freeShippingBar.selectedTab} tabs={freeShippingBarTabs(freeShippingBar)} onSelect={handleTabSelect}>
                        {freeShippingBarTabs(freeShippingBar)[freeShippingBar.selectedTab].id === 'dashboard' && <VisibleFreeShippingBarDashboard />}
                        {freeShippingBarTabs(freeShippingBar)[freeShippingBar.selectedTab].id === 'settings' && <VisibleFreeShippingBarSettings />}
                        {freeShippingBarTabs(freeShippingBar)[freeShippingBar.selectedTab].id === 'css-editor' && <VisibleFreeShippingBarCodeEditor />}
                        {freeShippingBarTabs(freeShippingBar)[freeShippingBar.selectedTab].id === 'support' && <VisibleFreeShippingBarSupport />}
                        {freeShippingBarTabs(freeShippingBar)[freeShippingBar.selectedTab].id === 'faq' && <VisibleFreeShippingBarFaq />}
                        {freeShippingBarTabs(freeShippingBar)[freeShippingBar.selectedTab].id === 'texts' && <VisibleFreeShippingBarTexts />}
                        {freeShippingBarTabs(freeShippingBar)[freeShippingBar.selectedTab].id === 'zoorix-m' && <VisibleFreeShippingBarUpgrade />}
                    </Tabs>
                </div>
        }
        {freeShippingBar.toastActive && <Toast content={freeShippingBar.toastContent} onDismiss={onDismissToast} />}
    </Frame>
};

export default FreeShippingBar;
