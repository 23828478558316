import { connect } from 'react-redux'
import FAQ from '../components/FAQ'

const mapStateToProps = state => ({});

const mapDispatchToProps = dispatch => ({
    runIframeCode: () => {
        console.log('FAQ');
    }
});


export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FAQ);
