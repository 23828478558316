import { connect } from 'react-redux'
import AnalyticsMappings from "../../components/analytics/AnalyticsMappings";
import {analyticsToggleMapping} from "../../actions/analytics";

const mapStateToProps = state => ({
    isFetchingMappings: state.analytics.isFetchingMappings,
    mappings: state.analytics.mappings,
    mappingOpen: state.analytics.mappingOpen,
    currency: state.analytics.currency,
});

const mapDispatchToProps = (dispatch) => ({
    openMapping: id => () => dispatch(analyticsToggleMapping(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AnalyticsMappings);
