import { connect } from 'react-redux'
import AnalyticsMain from "../../components/analytics/AnalyticsMain";
import {
    analyticsDismissToast,
    analyticsExport,
    analyticsMinitabClicked, analyticsToggleExportModal,
    applyDateRange,
    dateRangeChange,
    dateRangeMonthChange,
    dateRangePresetChange,
    toggleSelectDateActive
} from "../../actions/analytics";
import {emailChange} from "../../actions/data";

const mapStateToProps = state => ({
    isFetching: state.analytics.isFetching,
    visibleTab: state.analytics.visibleTab,
    isSelectDateActive: state.analytics.isSelectDateActive,
    dateRangePreset: state.analytics.dateRangePreset,
    dateRangeMonth: state.analytics.dateRangeMonth,
    dateRangeYear: state.analytics.dateRangeYear,
    dateRangeSelectedDates: state.analytics.dateRangeSelectedDates,
    isFetchingDateRange: state.analytics.isFetchingDateRange,
    appType: state.analytics.appType,
    analytics: state.analytics,
    data: state.data,
});

const mapDispatchToProps = (dispatch) => ({
    toggleSelectDateActive: () => dispatch(toggleSelectDateActive()),
    onDateRangePresetChange: preset => dispatch(dateRangePresetChange(preset)),
    onDateRangeChange: range => dispatch(dateRangeChange(range)),
    onDateRangeMonthChange: (month, year) => dispatch(dateRangeMonthChange(month, year)),
    applyDateRange: () => dispatch(applyDateRange()),
    toggleExportModal: () => dispatch(analyticsToggleExportModal()),
    onExport: () => dispatch(analyticsExport()),
    onDismissToast: () => dispatch(analyticsDismissToast()),
    minitabClicked: tabName => () => dispatch(analyticsMinitabClicked(tabName)),
    onEmailChanged: text => dispatch(emailChange(text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AnalyticsMain);
