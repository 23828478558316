import { connect } from 'react-redux'
import {
    initFeedbackIfNeeded,
    ratingTextChange,
    submitSupportRating
} from "../../actions/feedback";
import Feedback from "../../components/feedback/Feedback";

const mapStateToProps = state => ({
    ratingText: state.feedback.ratingText,
    isRatingSubmitError: state.feedback.isRatingSubmitError,
    isShowThankYou: state.feedback.isShowThankYou,
    isRatingSubmitting: state.feedback.isRatingSubmitting,
    gifSrc: state.feedback.gifSrc,
    type: 'support',
});

const mapDispatchToProps = (dispatch) => ({
    initFeedback: (_, rating) => dispatch(initFeedbackIfNeeded(null, rating)),
    onRatingTextChanged: (text) => dispatch(ratingTextChange(text)),
    submitRating: () => dispatch(submitSupportRating()),
    submitFiveStars: () => dispatch(submitSupportRating()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Feedback);
