import React from 'react';
import {Button, Icon} from "@shopify/polaris";
import '../../css/Pages.css'
import {DeleteMajor} from "@shopify/polaris-icons";


const Pages = ({pages, back, editPage, deletePage, pageDeleting, newPage}) => (
        <div>
            <div className="navigation-buttons bundle-pages">
                <span className="navigation-button">
            <Button onClick={back}>Back</Button>
                    </span>
                <span className="navigation-button">

            <Button onClick={newPage}>New page</Button>
                    </span>
            </div>
            {pages.map(page => <div className="page-of-bundles" key={page.id}>
                <span className="page-of-bundles-title">{page.title}</span>
                <span className="page-of-bundles-details">
                    <span className="page-of-bundles-view">
                <a href={page.href} target="_blank" rel="noopener noreferrer">View Page</a>
                </span>
                <span className="page-of-bundles-edit">
                <Button onClick={editPage(page.id)}>Edit</Button>
                </span>

                     <span className="page-of-bundles-delete">
                <Button onClick={deletePage(page.id)} loading={pageDeleting} disabled={pageDeleting}>{!pageDeleting && <Icon source={DeleteMajor} /> }</Button>
                    </span>
                    </span>
            </div>)}
        </div>
    );

export default Pages;
