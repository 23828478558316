import { connect } from 'react-redux'
import AppV2Wizard from "../../../components/v2/wizard/AppV2Wizard";

const mapStateToProps = state => ({
    appV2Wizard: state.appV2Wizard,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppV2Wizard);
