import { connect } from 'react-redux'
import Ref from "../../components/partners/Ref";
import {partnersSaveCodeAndRedirect} from "../../actions/partners";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    saveCodeAndRedirect: code => dispatch(partnersSaveCodeAndRedirect(code)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Ref);
