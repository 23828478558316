import React from 'react';
import '../../../css/v2/AppV2Wizard.scss';

const AppV2WizardGetStarted = ({ getStarted, skip }) => <div className={'app-v2-wizard-get-started'}>
    <div className={'title'}>Ready For More Profits?</div>
    <div className={'subtitle'}>Boost sales with multiple types of discounted offers</div>
    <div className={'nav-buttons-wrapper'}>
        <button className={'app-v2-wizard-next-btn'} onClick={getStarted}>Yes! I want to start</button>
        <span className={'skip'} onClick={skip}>Skip</span>
    </div>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/product-main.svg"}/>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/product-bottom.svg"}/>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/cart-bottom.svg"}/>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/cart-top.svg"}/>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/product.svg"}/>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/cart.svg"}/>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/specific_products.svg"}/>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/collections.svg"}/>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/tags.svg"}/>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/cat-ball2.png"}/>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/cat-ball.png"}/>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/bundle_items.svg"}/>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/wizard-done.png"}/>
    <img className="hidden" alt="bla" src={"https://public.zoorix.com/images/jumping-man.svg"}/>



</div>;

export default AppV2WizardGetStarted;
