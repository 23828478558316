import {connect} from 'react-redux'
import CssEditor from "../components/CssEditor";
import {
    cssChange,
    cssEditorRemoveVersionLock,
    cssEditorToggleRemoveVersionLockModal,
    cssSave,
    dismissToast,
    jsChange
} from "../actions/cssEditor";

const mapStateToProps = state => ({
    css: state.cssEditor.css,
    error: state.cssEditor.error,
    isSaving: state.cssEditor.isSaving,
    toastIsActive: state.cssEditor.toastIsActive,
    cssEditor: state.cssEditor,
});


const mapDispatchToProps = dispatch => ({
    onSave: () => dispatch(cssSave()),
    onCssChange: text => dispatch(cssChange(text)),
    onJsChange: text => dispatch(jsChange(text)),
    onDismissToast: () => dispatch(dismissToast()),
    removeLock: () => dispatch(cssEditorRemoveVersionLock()),
    toggleRemoveVersionLockModal: bool => () => dispatch(cssEditorToggleRemoveVersionLockModal(bool)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CssEditor);
