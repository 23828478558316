import { connect } from 'react-redux'
import {
    approveMnmSelectedCollections,
    collectionSearchTextChange,
    removeSelectedCollection,
    toggleMnmSelectCollectionsModal, toggleSelectCollection,
    wizardMnmDiscountAmountChange, wizardMnmMinQtyChange, wizardMnmTrashProductGroup,
    wizardSelectMnmDiscountType,
    wizardSetInvalidSelectOffer
} from "../../../actions/v2/appV2Wizard";
import AppV2WizardConfigMixAndMatch from "../../../components/v2/wizard/AppV2WizardConfigMixAndMatch";

const mapStateToProps = state => ({
    appV2Wizard: state.appV2Wizard,
});

const mapDispatchToProps = (dispatch) => ({
    onDiscountAmountChange: text => dispatch(wizardMnmDiscountAmountChange(text)),
    onMinQtyChange: idx => text => dispatch(wizardMnmMinQtyChange(idx, text)),
    onDiscountTypeChange: text => dispatch(wizardSelectMnmDiscountType(text)),
    toggleSelectCollectionsModal: () => dispatch(toggleMnmSelectCollectionsModal()),
    approveSelectedCollections: () => {dispatch(approveMnmSelectedCollections()); dispatch(wizardSetInvalidSelectOffer(false))},
    onCollectionSearchTextChange: text => dispatch(collectionSearchTextChange(text)),
    removeSelectedCollection: id => () => dispatch(removeSelectedCollection(id)),
    toggleSelectCollection: id => () => dispatch(toggleSelectCollection(id)),
    trashProductGroup: idx => () => dispatch(wizardMnmTrashProductGroup(idx)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppV2WizardConfigMixAndMatch);
