import React from 'react';
import '../../css/Plans.css';
import {RangeSlider} from "@shopify/polaris";

const valueToText = {
    0: ['0 - 50', <b>Free</b>],
    1: ['51 - 100', <b>$6.39</b>],
    2: ['101 - 200', <b>$11.39</b>],
    3: ['201+', <span><b>$15.99</b> + $0.008/order, capped by $29.99</span>],
}

const OurPricingPlatPlusBeta = ({platPlusBetaRange, platPlusBetaChangeRange}) => <div>
    <div className="our-pricing">Our pricing:</div>
    <div className="pricing-number-of-orders">How many monthly store orders do you have?</div>
    <div className="pricing-plans-picker">
        <RangeSlider
            label=""
            min={0}
            max={3}
            value={platPlusBetaRange}
            onChange={platPlusBetaChangeRange}
            output={false}
        />
    </div>
    <div className="pricing-calc-div">
        <div className="plans-calc-wrapper">
            <span className="plans-calc-left">Orders</span>
            <span className="plans-calc-right">Price</span>
        </div>
        <div className="plans-calc-wrapper">
            <span className="plans-calc-left">{valueToText[platPlusBetaRange || 0][0]} orders/month</span>
            <span className="plans-calc-right">{valueToText[platPlusBetaRange || 0][1]}</span>
        </div>
    </div>
</div>;

export default OurPricingPlatPlusBeta;
