import React from 'react';
import '../../css/Partners.css'
import {Spinner, Frame} from "@shopify/polaris";
import VisiblePartnersLogin from "../../containers/partners/VisiblePartnersLogin";
import VisiblePartnersDashboard from "../../containers/partners/VisiblePartnersDashboard";

const Partners = ({ tryToLogin, navState }) => {
    tryToLogin();

    return <Frame>
        {!navState && <div className="partner-dash-spinner-wrapper">
            <Spinner />
        </div>}

        {navState && navState && navState.startsWith('login') && <VisiblePartnersLogin />}

        {navState && navState === 'dashboard' && <VisiblePartnersDashboard />}

    </Frame>;
}

export default Partners;
