import { connect } from 'react-redux'
import Partners from "../../components/partners/Partners";
import {partnersTryToLogin} from "../../actions/partners";

const mapStateToProps = state => ({
    navState: state.partners.navState,
});

const mapDispatchToProps = (dispatch) => ({
    tryToLogin: () => dispatch(partnersTryToLogin()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Partners);
