import React from 'react';
import '../../../css/v2/AppV2Wizard.scss';
import {Spinner} from "@shopify/polaris";
import {Modal} from 'antd';


const AppV2WizardTestOffer = ({appV2Wizard, closeEnableZoorixModal, data, preview, done, createAnotherOffer, abort}) =>
        <div className={'app-v2-wizard-test-offer'}>

            <div className="text-offer-title-wrapper">
                {appV2Wizard.previewReady && <div className={'test-offer-title'}>Congrats!</div>}
                {!appV2Wizard.previewReady && <div className={'test-offer-title'}>Almost done!</div>}

                {appV2Wizard.previewReady && <div className={'test-offer-subtitle'}>You're done creating the offer <span aria-label="" role="img">🎉</span></div>}
                {!appV2Wizard.previewReady && <span className="test-offer-subtitle">Status: {appV2Wizard.statusText}...</span>}

                {appV2Wizard.previewReady && <div className={'test-offer-desc'}>Now you can view the offer, create another offer, or go to the app dashboard</div>}
                {!appV2Wizard.previewReady && <div className={'test-offer-desc'}>In a minute you'll be able to view the offer</div>}


            </div>


            <div className="nav-buttons-wrapper">
                {appV2Wizard.previewReady && <div className="action-btn done-btn" onClick={done}>Go To Dashboard</div>}
                {!appV2Wizard.previewReady && <div className="action-btn abort-btn" onClick={abort}>Abort</div>}
                {appV2Wizard.doneLoading && false && <Spinner />}
                {appV2Wizard.previewReady && false && <div className="action-btn create-more-btn" onClick={createAnotherOffer}>Create Another Offer</div>}
                <div className={`action-btn status-btn ${appV2Wizard.previewReady && 'view-offer-btn'}`} onClick={appV2Wizard.previewReady && preview}>
                    {!appV2Wizard.previewReady && <span className="status-text"> {appV2Wizard.statusText}...<span
                        className="progress-spinner"><Spinner size="small"/></span></span>}
                    {!appV2Wizard.previewReady && appV2Wizard.previewProgress &&
                    <span className="offer-progress"><span className="view-percentage">{appV2Wizard.previewProgress}%</span><span
                        className="progress-spinner"><Spinner size="small"/></span></span>}

                    {appV2Wizard.previewReady && <span className="view-text">View Offer</span>}
                </div>
            </div>

            <div className="jumping-man">


            </div>


            <Modal
                visible={appV2Wizard.enableZoorixModalOpen}
                className="enable-zoorix-modal"
                title={'Enable Zoorix on the Shopify Editor'}
                onCancel={closeEnableZoorixModal}
                width="50%"
                cancelText="Close"
                footer={null}
            >
                <div className="enable-zoorix-btn-wrapper">
                    <div className="enable-zoorix-btn"
                         onClick={() => window.open(`https://${data.shopName}/admin/themes/current/editor?context=apps`, '_blank')}>
                        Enable Zoorix
                    </div>
                </div>
            </Modal>

        </div>
    ;

export default AppV2WizardTestOffer;
