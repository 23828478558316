import { connect } from 'react-redux'
import AnalyticsVolumeDiscounts from "../../components/analytics/AnalyticsVolumeDiscounts";
import {analyticsToggleVolume} from "../../actions/analytics";

const mapStateToProps = state => ({
    isFetchingVolumeDiscounts: state.analytics.isFetchingVolumeDiscounts,
    volumeDiscounts: state.analytics.volumeDiscounts,
    volumeOpen: state.analytics.volumeOpen,
    currency: state.analytics.currency,
});

const mapDispatchToProps = (dispatch) => ({
    openVolume: id => () => dispatch(analyticsToggleVolume(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AnalyticsVolumeDiscounts);
