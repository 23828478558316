import { connect } from 'react-redux'
import {
    bundleClicked,
    sliderClicked,
    warrantyClicked,
    cartDrawerClicked,
    volumeDiscountClicked, mixAndMatchClicked
} from "../../actions/nav";
import TextsMain from "../../components/texts/TextsMain";
import {
    addLocale,
    closeAddLocaleModal, closeRemoveLocaleModal, copyFromChange,
    dismissTranslationsSavedToast,
    loadTranslations,
    localeChange, newLocaleChange,
    openAddLocaleModal, openRemoveLocaleModal, removeLocale,
    saveTranslations, syncProductTranslations
} from "../../actions/texts";

const mapStateToProps = state => ({
    isSliderVisible: state.nav.isSliderVisible,
    isBundleVisible: state.nav.isBundleVisible,
    isWarrantyVisible: state.nav.isWarrantyVisible,
    isVolumeVisible: state.nav.isVolumeVisible,
    isCartDrawerVisible: state.nav.isCartDrawerVisible || state.nav.isAppVisible,
    isMixAndMatchVisible: state.nav.isMixAndMatchVisible,

    translations: state.texts.translations,
    locales: state.texts.locales,
    locale: state.texts.locale,
    saving: state.texts.saving,
    savingError: state.texts.savingError,
    translationsSavedToastIsActive: state.texts.translationsSavedToastIsActive,
    addLocaleModalOpen: state.texts.addLocaleModalOpen,
    newLocaleOptions: state.texts.newLocaleOptions,
    desiredNewLocale: state.texts.desiredNewLocale,
    copyFromOptions: state.texts.copyFromOptions,
    copyFromLocale: state.texts.copyFromLocale,
    addingLocale: state.texts.addingLocale,
    removeLocaleModalOpen: state.texts.removeLocaleModalOpen,
    manuallyAddedLocales: state.texts.manuallyAddedLocales,
    removingLocale: state.texts.removingLocale,
    syncProductTranslationsProgress: state.texts.syncProductTranslationsProgress,

    appType: state.data.appType,
});

const mapDispatchToProps = (dispatch) => ({
    onSliderClicked: () => dispatch(sliderClicked()),
    onBundleClicked: () => dispatch(bundleClicked()),
    onWarrantyClicked: () => dispatch(warrantyClicked()),
    onCartDrawerClicked: () => dispatch(cartDrawerClicked()),
    onVolumeClicked: () => dispatch(volumeDiscountClicked()),
    onMixAndMatchClicked: () => dispatch(mixAndMatchClicked()),
    loadTranslations: () => dispatch(loadTranslations()),
    save: () => dispatch(saveTranslations()),
    removeLocale: () => dispatch(removeLocale()),
    addLocale: () => dispatch(addLocale()),
    syncProductTranslations: () => dispatch(syncProductTranslations()),
    onDismissTranslationsSavedToast: () => dispatch(dismissTranslationsSavedToast()),
    openAddLocaleModal: () => dispatch(openAddLocaleModal()),
    openRemoveLocaleModal: () => dispatch(openRemoveLocaleModal()),
    closeAddLocaleModal: () => dispatch(closeAddLocaleModal()),
    closeRemoveLocaleModal: () => dispatch(closeRemoveLocaleModal()),
    onLocaleChange: text => dispatch(localeChange(text)),
    onNewLocaleChange: text => dispatch(newLocaleChange(text)),
    onCopyFromChange: text => dispatch(copyFromChange(text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TextsMain);
