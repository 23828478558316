import {connect} from 'react-redux'
import Upgrade from "../../components/Upgrade";
import {upgradeFreeShippingBarToZoorixM} from "../../actions/free-shipping-bar/freeShippingBar";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    upgrade: () => dispatch(upgradeFreeShippingBarToZoorixM()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Upgrade);
