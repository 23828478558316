import { connect } from 'react-redux'
import AppV2WizardConfigBundle from "../../../components/v2/wizard/AppV2WizardConfigBundle";
import {
    searchBundleProductsApi,
    selectBundleType, wizardBundleAddProductAndChoose,
    wizardBundleAmountChange,
    wizardBundleCloseSelectProductsModal, wizardBundleRemoveProduct,
    wizardBundleSelectProduct,
    wizardBundleSelectProductTextChange,
    wizardChooseBundleProduct, wizardSetInvalidSelectOffer
} from "../../../actions/v2/appV2Wizard";

const mapStateToProps = state => ({
    appV2Wizard: state.appV2Wizard,
});

const mapDispatchToProps = (dispatch) => ({
    onBundleAmountChange: text => dispatch(wizardBundleAmountChange(text)),
    onBundlesDiscountTypeChange: text => dispatch(selectBundleType(text)),
    chooseProduct: idx => () => {
        dispatch(wizardChooseBundleProduct(idx));
        dispatch(searchBundleProductsApi());
    },
    closeSelectProductsModal: () => dispatch(wizardBundleCloseSelectProductsModal()),
    selectProduct: id => () => {dispatch(wizardBundleSelectProduct(id)); dispatch(wizardSetInvalidSelectOffer(false))},
    onProductSearchTextChange: text => dispatch(wizardBundleSelectProductTextChange(text)),
    addProduct: () => dispatch(wizardBundleAddProductAndChoose()),
    removeProduct: idx => () => dispatch(wizardBundleRemoveProduct(idx)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppV2WizardConfigBundle);
