import React from 'react';
import '../../css/Feedback.css'
import StarRatings from "react-star-ratings";
import {TextContainer} from "@shopify/polaris";

const FeedbackLandingPage = ({match: {params}, initFeedback, onRate}) => {

    initFeedback(params.token);

    setTimeout(() => {
        document.querySelector('body').style.backgroundColor = 'white'
    },0);

    return <div >
        <div className="feedback-logo-wrapper">
        <img width="100px" height="100px"
             src="https://public.zoorix.com/images/zoorix-logo.png"
             alt="" />
        </div>
        <TextContainer>
            <p className="rate-title">
                How is your experience with Zoorix so far?
            </p>
            <div className="review-wrapper landing-page">
                <div className="review-container" onClick={onRate(5)}>
                    <StarRatings
                        rating={5}
                        starRatedColor="#4C4CFF"
                        numberOfStars={5}
                        starDimension='26px'
                        starSpacing='2px'
                        name='rating'
                    /><span className="ratings-title">Love it!</span></div>
                <div className="review-container" onClick={onRate(4)}>
                    <StarRatings
                        rating={4}
                        starRatedColor="#4C4CFF"
                        numberOfStars={4}
                        starDimension='26px'
                        starSpacing='2px'
                        name='rating'
                    />Like it</div>
                <div className="review-container" onClick={onRate(3)}>
                    <StarRatings
                        rating={3}
                        starRatedColor="#4C4CFF"
                        numberOfStars={3}
                        starDimension='26px'
                        starSpacing='2px'
                        name='rating'
                    />Okay</div>
                <div className="review-container" onClick={onRate(2)}>
                    <StarRatings
                        rating={2}
                        starRatedColor="#4C4CFF"
                        numberOfStars={2}
                        starDimension='26px'
                        starSpacing='2px'
                        name='rating'
                    />Didn't like it</div>
                <div className="review-container" onClick={onRate(1)}>
                    <StarRatings
                        rating={1}
                        starRatedColor="#4C4CFF"
                        numberOfStars={1}
                        starDimension='26px'
                        starSpacing='2px'
                        name='rating'
                    />Hate it</div>
            </div>
        </TextContainer>
    </div>;
};

export default FeedbackLandingPage;
