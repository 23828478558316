import { connect } from 'react-redux'
import {
    enableZoorixIfNotPosting,
    disableZoorixIfNotPosting,
    postResync,
    disableCart,
    enableCart,
    activateFeedbackPopup,
    dismissFeedbackPopup,
    closeReviewCard,
    fixProductWidget,
    fixCartWidget,
    toggleBundleWidget,
    fixBundleWidget,
    toggleWarrantyWidget,
    fixWarrantyWidget,
    toggleCartDrawerWidget,
    createCharge,
    closePaymentModal,
    toggleVolumeDiscountWidget,
    hideDeepLink,
    showCloseDeepLinkCard
} from '../actions/status'
import Status from '../components/Status'
import {openFeedback} from "../actions/feedback";
import {setWizardBool} from "../actions/data";

const mapStateToProps = state => {
    return {
        enabled: state.status.enabled,
        buttonText: state.status.buttonText,
        content: state.status.content,
        processing: state.status.isPosting,
        resyncLoading: state.status.isPostingResync,
        resyncError: state.status.resyncError,
        syncStarted: state.status.syncStarted,
        lastSync: state.status.lastSync,
        syncedProducts: state.status.productCount,
        syncedCollections: state.status.collectionCount,
        cartButtonText: state.status.cartButtonText,
        cartEnabled: state.status.cartEnabled,
        cartContent: state.status.cartContent,
        feedbackPopupActive: state.status.feedbackPopupActive,
        userLeftReview: state.status.userLeftReview,
        cartProcessing: state.status.cartProcessing,
        installingTheme: state.status.installingTheme,
        installation: state.status.installation,
        packageName: state.status.packageName,
        chargeActive: state.status.chargeActive,
        waitingForProductPageWidget: state.status.waitingForProductPageWidget,
        waitingForCartPageWidget: state.status.waitingForCartPageWidget,
        bundleButtonText: state.status.bundleButtonText,
        bundleEnabled: state.status.bundleEnabled,
        bundleProcessing: state.status.bundleProcessing,
        bundleContent: state.status.bundleContent,
        warrantyButtonText: state.status.warrantyButtonText,
        warrantyEnabled: state.status.warrantyEnabled,
        warrantyProcessing: state.status.warrantyProcessing,
        warrantyContent: state.status.warrantyContent,
        cartDrawerButtonText: state.status.cartDrawerButtonText,
        cartDrawerEnabled: state.status.cartDrawerEnabled,
        cartDrawerContent: state.status.cartDrawerContent,
        cartDrawerProcessing: state.status.cartDrawerProcessing,
        planName: state.status.planName,
        paymentModalOpen: state.status.paymentModalOpen,
        numOfOrders: state.status.numOfOrders,
        trialDaysLeft: state.status.trialDaysLeft,
        syncStartedText: state.status.syncStartedText,
        installationStatusText: state.status.installationStatusText,
        volumeDiscountProcessing: state.status.volumeDiscountProcessing,
        volumeDiscountEnabled: state.status.volumeDiscountEnabled,
        volumeDiscountButtonText: state.status.volumeDiscountButtonText,
        volumeDiscountContent: state.status.volumeDiscountContent,
        onlineStore2: state.status.onlineStore2,
        appEmbeds: state.status.appEmbeds,
        showInstallZoorixHead: state.status.showInstallZoorixHead,
        showCloseDeepLink: state.status.showCloseDeepLink,
        devPlans: state.status.devPlans,

        mappings: state.products.mappings,

        belowAddToCart: state.settings.belowAddToCart,
        productBottomEnabled: state.settings.productBottomEnabled,
        cartTop: state.settings.cartTop,
        cartBottom: state.settings.cartBottom,
        bundles: state.productsBundle.bundles,

        referralOfferingDiscount: state.data.referralOfferingDiscount,
        shopName: state.data.shopName,
        appType: state.data.appType,
        passwordEnabled: state.data.passwordEnabled,
        zoorixMEnabled: state.data.zoorixMEnabled,
        zoorixMEnabledLoading: state.data.zoorixMEnabledLoading,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
    onDisable: () => dispatch(disableZoorixIfNotPosting(ownProps.params.storeName, ownProps.params.token)),
    onEnable: () => dispatch(enableZoorixIfNotPosting(ownProps.params.storeName, ownProps.params.token)),
    onCartDisable: () => dispatch(disableCart()),
    onCartEnable: () => dispatch(enableCart()),
    onResync: () => dispatch(postResync()),
    onActivateFeedbackPopup: () => dispatch(activateFeedbackPopup()),
    onDismissFeedbackPopup: () => dispatch(dismissFeedbackPopup()),
    closeReviewCard: () => dispatch(closeReviewCard()),
    onRate: (rating) => () => dispatch(openFeedback(rating)),
    fixProductWidget: () => dispatch(fixProductWidget()),
    fixCartWidget: () => dispatch(fixCartWidget()),
    createCharge: () => dispatch(createCharge()),
    toggleBundleWidget: () => dispatch(toggleBundleWidget()),
    fixBundleWidget: () => dispatch(fixBundleWidget()),
    toggleWarrantyWidget: () => dispatch(toggleWarrantyWidget()),
    toggleCartDrawerWidget: () => dispatch(toggleCartDrawerWidget()),
    toggleVolumeDiscountWidget: () => dispatch(toggleVolumeDiscountWidget()),
    fixWarrantyWidget: () => dispatch(fixWarrantyWidget()),
    closePaymentModal: () => dispatch(closePaymentModal()),
    onHideDeepLink: () => dispatch(hideDeepLink()),
    onShowCloseDeepLinkCard: () => dispatch(showCloseDeepLinkCard()),
    showWizard: () => dispatch(setWizardBool(true)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Status);
