import { connect } from 'react-redux'
import ProductsBundle from "../../components/products/ProductsBundle";
import {dismissToast} from "../../actions/productsBundle";

const mapStateToProps = state => ({
    toastIsActive: state.productsBundle.toastIsActive,
    isShowBundles: state.productsBundle.isShowBundles,
    isShowEdit: state.productsBundle.isShowEdit,
    isShowGroupedEdit: state.productsBundle.isShowGroupedEdit,
    isShowEditPage: state.productsBundle.isShowEditPage,
    isShowPages: state.productsBundle.isShowPages,
    productsBundle: state.productsBundle,
});

const mapDispatchToProps = (dispatch) => ({
    onDismissToast: () => dispatch(dismissToast()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductsBundle);
