import { connect } from 'react-redux'
import WarrantyEdit from "../../components/products/WarrantyEdit";
import {
    cancelUploadWarrantyImage,
    changeSearchText,
    chooseWarrantyItem, priceTypeChange,
    restoreToWarrantyDefaultImage,
    restoreToWarrantyOriginalImage,
    restoreToWarrantyStoreImage,
    save,
    uploadWarrantyFailure,
    uploadWarrantyImage,
    uploadWarrantySuccess,
    warrantyBack,
    warrantyChooseProduct, warrantyCloseChooseProduct,
    warrantyNameChange,
    warrantyPriceChange
} from "../../actions/productsWarranty";

const mapStateToProps = state => ({
    isSaving: state.productsWarranty.isSaving,
    saveError: state.productsWarranty.saveError,
    warranty: state.productsWarranty.warranty,
    currencySymbol: state.productsWarranty.currencySymbol,
    chooseProductOpen: state.productsWarranty.chooseProductOpen,
    searchBoxText: state.productsWarranty.searchBoxText,
    showSearchSpinner: state.productsWarranty.showSearchSpinner,
    searchError: state.productsWarranty.searchError,
    searchedProducts: state.productsWarranty.searchedProducts,
    isWarrantyDefaultImage: state.productsWarranty.isWarrantyDefaultImage,
    tokenId: state.productsWarranty.tokenId,
    isUploading: state.productsWarranty.isUploading,
    uploadError: state.productsWarranty.uploadError,
    imageBackup: state.productsWarranty.imageBackup,

    settings: state.settingsWarranty.settings,

    shopName: state.data.shopName,
    token: state.data.token,
});

const mapDispatchToProps = (dispatch) => ({
    onBack: () => dispatch(warrantyBack()),
    onSave: () => dispatch(save()),
    onWarrantyNameChange: name => dispatch(warrantyNameChange(name)),
    chooseProduct: () => dispatch(warrantyChooseProduct()),
    onCloseChooseProduct: () => dispatch(warrantyCloseChooseProduct()),
    onChangeSearchText: text => dispatch(changeSearchText(text)),
    onPriceTypeChange: text => dispatch(priceTypeChange(text)),
    onFocusSearchText: () => dispatch(changeSearchText('')),
    chooseWarrantyItem: id => () => dispatch(chooseWarrantyItem(id)),
    upload: () => dispatch(uploadWarrantyImage()),
    uploadSuccess: () => dispatch(uploadWarrantySuccess()),
    cancelUpload: () => dispatch(cancelUploadWarrantyImage()),
    uploadFailure: () => dispatch(uploadWarrantyFailure()),
    restoreToWarrantyDefaultImage: () => dispatch(restoreToWarrantyDefaultImage()),
    restoreToWarrantyOriginalImage: () => dispatch(restoreToWarrantyOriginalImage()),
    restoreToWarrantyStoreImage: () => dispatch(restoreToWarrantyStoreImage()),
    onWarrantyPriceChange: num => dispatch(warrantyPriceChange(num)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(WarrantyEdit);
