import { connect } from 'react-redux'
import FreeShippingBarFaq from "../../components/free-shipping-bar/FreeShippingBarFaq";

const mapStateToProps = state => ({
    freeShippingBar: state.freeShippingBar,
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FreeShippingBarFaq);
