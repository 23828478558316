import { connect } from 'react-redux'
import Bundles from "../../components/products/Bundles";
import {
    bundlesImportExport,
    closeNoAutoBundlesModal,
    closeRemoveAutoBundleModal,
    closeRemoveBundleModal,
    closeShortCodeModal,
    createBundleApi,
    createGroupedBundleApi,
    createOrManagePages,
    delAutoBundles,
    dismissDuplicateBundleToast,
    duplicateBundle,
    editBundleApi,
    genAutoBundlesApi,
    loadAutoBundles,
    loadImportedBundles,
    loadPages,
    nextAutoBundlePage,
    nextImportedBundlePage,
    previewBundle,
    previousAutoBundlePage,
    previousImportedBundlePage,
    removeAutoBundlesAction,
    removeBundle,
    removeBundleAction,
    showBundlesSemanticNamesChange,
    showShortCode,
    switchEnable
} from "../../actions/productsBundle";

const mapStateToProps = state => ({
    bundles: state.productsBundle.bundles,
    currencySymbol: state.productsBundle.currencySymbol,
    removeBundleModalOpen: state.productsBundle.removeBundleModalOpen,
    removeBundleSpinner: state.productsBundle.removeBundleSpinner,
    bundleName: state.productsBundle.bundleName,
    removeBundleError: state.productsBundle.removeBundleError,
    showShortCodeModalOpen: state.productsBundle.showShortCodeModalOpen,
    shortCodeBundleId: state.productsBundle.shortCodeBundleId,
    switchEnableLoading: state.productsBundle.switchEnableLoading,
    switchEnableError: state.productsBundle.switchEnableError,
    removeAutoBundleModalOpen: state.productsBundle.removeAutoBundleModalOpen,
    removeAutoBundlesSpinner: state.productsBundle.removeAutoBundlesSpinner,
    removeAutoBundlesError: state.productsBundle.removeAutoBundlesError,
    noAutoBundlesModalOpen: state.productsBundle.noAutoBundlesModalOpen,
    duplicateBundleStarted: state.productsBundle.duplicateBundleStarted,
    duplicateBundleFailure: state.productsBundle.duplicateBundleFailure,
    pages: state.productsBundle.pages,
    editingBundle: state.productsBundle.editingBundle,
    creatingBundle: state.productsBundle.creatingBundle,
    creatingAutoBundle: state.productsBundle.creatingAutoBundle,
    creatingGroupedBundle: state.productsBundle.creatingGroupedBundle,
    autoBundles: state.productsBundle.autoBundles,
    showBundlesSemanticNames: state.productsBundle.showBundlesSemanticNames,
    isShowBundlesSemanticNamesSaving: state.productsBundle.isShowBundlesSemanticNamesSaving,
    importedBundlesCount: state.productsBundle.importedBundlesCount,
    productsBundle: state.productsBundle,
});

const mapDispatchToProps = (dispatch) => ({
    onCreateBundle: () => dispatch(createBundleApi()),
    onCreateGroupedBundle: () => dispatch(createGroupedBundleApi()),
    onGenAutoBundles: () => dispatch(genAutoBundlesApi()),
    onDelAutoBundles: () => dispatch(delAutoBundles()),
    closeRemoveAutoBundleModal: () => dispatch(closeRemoveAutoBundleModal()),
    removeAutoBundlesAction: () => dispatch(removeAutoBundlesAction()),
    editBundle: id => () => dispatch(editBundleApi(id)),
    removeBundle: id => () => dispatch(removeBundle(id)),
    showShortCode: id => () => dispatch(showShortCode(id)),
    switchEnable: id => () => dispatch(switchEnable(id)),
    duplicateBundle: id => () => dispatch(duplicateBundle(id)),
    previewBundle: id => () => dispatch(previewBundle(id)),
    onShowBundlesSemanticNamesChange: text => dispatch(showBundlesSemanticNamesChange(text)),
    closeRemoveBundleModal: () => dispatch(closeRemoveBundleModal()),
    removeBundleAction: () => dispatch(removeBundleAction()),
    closeShortCodeModal: () => dispatch(closeShortCodeModal()),
    closeNoAutoBundlesModal: () => dispatch(closeNoAutoBundlesModal()),
    onDismissDuplicateBundleToast: () => dispatch(dismissDuplicateBundleToast()),
    loadPages: () => dispatch(loadPages()),
    onManagePages: () => dispatch(createOrManagePages()),
    importExport: () => dispatch(bundlesImportExport()),
    loadAutoBundles: () => dispatch(loadAutoBundles()),
    loadImportedBundles: () => dispatch(loadImportedBundles()),
    onNextAutoBundlePage: () => dispatch(nextAutoBundlePage()),
    onNextImportedBundlePage: () => dispatch(nextImportedBundlePage()),
    onPreviousAutoBundlePage: () => dispatch(previousAutoBundlePage()),
    onPreviousImportedBundlePage: () => dispatch(previousImportedBundlePage()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Bundles);
