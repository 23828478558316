import { connect } from 'react-redux';
import {
    closeRemoveBulkWarrantyModal,
    closeRemoveWarrantyModal, createBulkWarranty,
    createWarranty,
    editWarranty, removeBulkWarranty, removeBulkWarrantyAction,
    removeWarranty,
    removeWarrantyAction
} from "../../actions/productsWarranty";
import Warranties from "../../components/products/Warranties";

const mapStateToProps = state => ({
    warranties: state.productsWarranty.warranties,
    currencySymbol: state.productsWarranty.currencySymbol,
    removeWarrantyModalOpen: state.productsWarranty.removeWarrantyModalOpen,
    removeWarrantySpinner: state.productsWarranty.removeWarrantySpinner,
    warrantyName: state.productsWarranty.warrantyName,
    removeWarrantyError: state.productsWarranty.removeWarrantyError,
    bulkWarranties: state.productsWarranty.bulkWarranties,
    tagsCount: state.productsWarranty.tagsCount,
    deletableBulkWarranties: state.productsWarranty.deletableBulkWarranties,
    removeBulkWarrantyModalOpen: state.productsWarranty.removeBulkWarrantyModalOpen,
    removeBulkWarrantySpinner: state.productsWarranty.removeBulkWarrantySpinner,
    removeBulkWarrantyError: state.productsWarranty.removeBulkWarrantyError,
});

const mapDispatchToProps = (dispatch) => ({
    onCreateWarranty: () => dispatch(createWarranty()),
    onCreateBulkWarranty: () => dispatch(createBulkWarranty()),
    editWarranty: id => () => dispatch(editWarranty(id)),
    removeWarranty: id => () => dispatch(removeWarranty(id)),
    removeBulkWarranty: id => () => dispatch(removeBulkWarranty(id)),
    closeRemoveWarrantyModal: () => dispatch(closeRemoveWarrantyModal()),
    closeRemoveBulkWarrantyModal: () => dispatch(closeRemoveBulkWarrantyModal()),
    removeWarrantyAction: () => dispatch(removeWarrantyAction()),
    removeBulkWarrantyAction: () => dispatch(removeBulkWarrantyAction()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Warranties);
