import { connect } from 'react-redux'
import BundleEdit from "../../components/products/BundleEdit";
import {
    amount2Change,
    amountChange,
    back,
    bundleAddGroup,
    bundleAlwaysShownOnCartChange,
    bundleAutoGenChange,
    bundleCartBottomChange,
    bundleCartTopChange, bundleDiscountTypeChanged, bundleLocaleChange,
    bundleNameChange, bundleQtyModifiersChange, bundleSemanticNameChange,
    bundleSubtitleChange,
    changeSearchText,
    chooseBundleItem,
    chooseProduct,
    chooseVariants,
    chooseVariantsAction,
    closeChooseProduct,
    closeChooseVariants,
    closeQuantitativeModal,
    displayChange,
    gen,
    groupTypeChange,
    groupValueChange,
    inBundleChanged,
    openQuantitativeModal,
    removeProduct,
    removeSourceGroup,
    removeSourceProduct,
    save, showOnProductPageChange,
    singleBundleNavigateToChange,
    toggleAddGroupActive,
    typeChange
} from "../../actions/productsBundle";

const mapStateToProps = state => ({
    isSaving: state.productsBundle.isSaving,
    saveError: state.productsBundle.saveError,
    bundle: state.productsBundle.bundle,
    currencySymbol: state.productsBundle.currencySymbol,
    chooseProductOpen: state.productsBundle.chooseProductOpen,
    searchBoxText: state.productsBundle.searchBoxText,
    showSearchSpinner: state.productsBundle.showSearchSpinner,
    searchError: state.productsBundle.searchError,
    searchedProducts: state.productsBundle.searchedProducts,
    chooseVariantsOpen: state.productsBundle.chooseVariantsOpen,
    variants: state.productsBundle.variants,
    isGenAutoBundle: state.productsBundle.isGenAutoBundle,
    quantitativeModalOpen: state.productsBundle.quantitativeModalOpen,
    groupAddition: state.productsBundle.groupAddition,
    locales: state.productsBundle.locales,
    locale: state.productsBundle.locale,
});

const mapDispatchToProps = (dispatch) => ({
    onBack: () => dispatch(back()),
    onSave: () => dispatch(save()),
    onGen: () => dispatch(gen()),
    onCloseChooseVariants: () => dispatch(closeChooseVariants()),
    chooseVariantsAction: () => dispatch(chooseVariantsAction()),
    openQuantitativeModal: () => dispatch(openQuantitativeModal()),
    onCloseQuantitativeModal: () => dispatch(closeQuantitativeModal()),
    toggleAddGroupActive: () => dispatch(toggleAddGroupActive()),
    onAddGroup: () => dispatch(bundleAddGroup()),
    onBundleNameChange: name => dispatch(bundleNameChange(name)),
    onAmountChange: amount => dispatch(amountChange(amount)),
    onAmount2Change: i => amount => dispatch(amount2Change(i, amount)),
    onTypeChange: type => dispatch(typeChange(type)),
    chooseProduct: id => () => dispatch(chooseProduct(id)),
    onCloseChooseProduct: () => dispatch(closeChooseProduct()),
    onChangeSearchText: text => dispatch(changeSearchText(text)),
    onDisplayChange: text => dispatch(displayChange(text)),
    onNavigateToChange: text => dispatch(singleBundleNavigateToChange(text)),
    onBundleSubtitleChange: text => dispatch(bundleSubtitleChange(text)),
    onAutoGenChange: text => dispatch(bundleAutoGenChange(text)),
    onGroupTypeChange: text => dispatch(groupTypeChange(text)),
    onGroupValueChange: text => dispatch(groupValueChange(text)),
    onShowOnProductPageChange: text => dispatch(showOnProductPageChange(text)),
    onLocaleChange: text => dispatch(bundleLocaleChange(text)),
    onQtyModifiersChange: text => dispatch(bundleQtyModifiersChange(text)),
    onBundleSemanticNameChange: text => dispatch(bundleSemanticNameChange(text)),
    onFocusSearchText: () => dispatch(changeSearchText('')),
    chooseBundleItem: id => () => dispatch(chooseBundleItem(id)),
    removeSourceProduct: id => () => dispatch(removeSourceProduct(id)),
    removeProduct: id => () => dispatch(removeProduct(id)),
    chooseVariants: id => () => dispatch(chooseVariants(id)),
    removeSourceGroup: id => () => dispatch(removeSourceGroup(id)),
    onBundleCartTopChange: bool => dispatch(bundleCartTopChange(bool)),
    onBundleCartBottomChange: bool => dispatch(bundleCartBottomChange(bool)),
    onAlwaysShownOnCartChange: bool => dispatch(bundleAlwaysShownOnCartChange(bool)),
    onInBundleChanged: id => bool => dispatch(inBundleChanged(id, bool)),
    onDiscountTypeChange: id => text => dispatch(bundleDiscountTypeChanged(id, text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(BundleEdit);
