import React from 'react';
import '../../css/TextsMain.css'
import {Button, Select, Spinner, TextField, Card} from "@shopify/polaris";

const text = (label, field, onTextChange, freeShippingBar) =>
    <TextField label={label} onChange={onTextChange(field)}
               value={freeShippingBar.settings.texts[freeShippingBar.locale][field]}
               disabled={freeShippingBar.savingSettings} autoComplete={false} />

const FreeShippingBarTexts = ({ freeShippingBar, onTextChange, onLocaleChange, save }) =>
    <div className="sticky-atc-texts">
        {freeShippingBar.locales ? <div>
                <Select options={freeShippingBar.locales} onChange={onLocaleChange} value={freeShippingBar.locale}
                        disabled={freeShippingBar.savingSettings}/>
            <Card>
                {text('Initial Goal:', 'initial_goal', onTextChange, freeShippingBar)}
                {text('Goal Progress:', 'goal_progress', onTextChange, freeShippingBar)}
                {text('Goal Achieved:', 'goal_achieved', onTextChange, freeShippingBar)}
                {text('Button Initial Goal:', 'button_initial_goal', onTextChange, freeShippingBar)}
                {text('Button Goal Progress:', 'button_goal_progress', onTextChange, freeShippingBar)}
                {text('Button Goal Achieved:', 'button_goal_achieved', onTextChange, freeShippingBar)}
                <div className="save-button">
                    <Button onClick={save} loading={freeShippingBar.savingSettings} primary>Save</Button>
                </div>
            </Card>
        </div> : <div>
            <Spinner />
        </div>}

</div>;

export default FreeShippingBarTexts;
