import React from 'react';
import '../css/FAQ.css'

const FAQ = ({runIframeCode}) => {
    return <div className="help-iframe-wrapper">
        <iframe id="help-iframe" title="Zoorix - Help" onLoad={runIframeCode()} className="faq-help-iframe" src="https://help.zoorix.com"></iframe>
    </div >;
}

export default FAQ;
