import {connect} from 'react-redux'
import Integrations from "../components/Integrations";
import {changeIntegrationsTab} from "../actions/nav";

const mapStateToProps = state => ({
    nav: state.nav,
});

const mapDispatchToProps = dispatch => ({
    changeTab: tab => dispatch(changeIntegrationsTab(tab)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Integrations);
