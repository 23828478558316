import { connect } from 'react-redux'
import {
    appV2WizardBack,
    toggleSelectProductsModal,
    approveSelectedProducts,
    onProductSearchTextChange,
    toggleSelectProduct,
    removeSelectedProduct,
    selectTriggerAndSearch,
    toggleSelectCollectionsModal,
    approveSelectedCollections,
    collectionSearchTextChange,
    toggleSelectCollection,
    removeSelectedCollection,
    toggleSelectTagsModal,
    approveSelectedTags,
    tagSearchTextChange,
    removeSelectedTag, toggleSelectTag, wizardFetchStage4Products, wizardSetInvalidSelectTrigger
} from "../../../actions/v2/appV2Wizard";
import AppV2WizardSelectTrigger from "../../../components/v2/wizard/AppV2WizardSelectTrigger";

const mapStateToProps = state => ({
    appV2Wizard: state.appV2Wizard,
});

const mapDispatchToProps = (dispatch) => ({
    selectTrigger: triggerType => () => dispatch(selectTriggerAndSearch(triggerType)),
    onProductSearchTextChange: text => dispatch(onProductSearchTextChange(text)),
    onCollectionSearchTextChange: text => dispatch(collectionSearchTextChange(text)),
    onTagSearchTextChange: text => dispatch(tagSearchTextChange(text)),
    toggleSelectProduct: id => () => dispatch(toggleSelectProduct(id)),
    toggleSelectCollection: id => () => dispatch(toggleSelectCollection(id)),
    toggleSelectTag: id => () => dispatch(toggleSelectTag(id)),
    removeSelectedProduct: id => () => dispatch(removeSelectedProduct(id)),
    removeSelectedCollection: id => () => dispatch(removeSelectedCollection(id)),
    removeSelectedTag: id => () => dispatch(removeSelectedTag(id)),
    next: () => dispatch(wizardFetchStage4Products()),
    back: () => dispatch(appV2WizardBack()),
    toggleSelectProductsModal: () => dispatch(toggleSelectProductsModal()),
    toggleSelectCollectionsModal: () => dispatch(toggleSelectCollectionsModal()),
    toggleSelectTagsModal: () => dispatch(toggleSelectTagsModal()),
    approveSelectedProducts: () => {dispatch(approveSelectedProducts()); dispatch(wizardSetInvalidSelectTrigger(false))},
    approveSelectedCollections: () => {dispatch(approveSelectedCollections()); dispatch(wizardSetInvalidSelectTrigger(false))},
    approveSelectedTags: () => {dispatch(approveSelectedTags()); dispatch(wizardSetInvalidSelectTrigger(false))},
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppV2WizardSelectTrigger);
