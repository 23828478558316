import { connect } from 'react-redux'
import StickyAtcDowntime from "../../components/sticky-atc/StickyAtcDowntime";

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(StickyAtcDowntime);
