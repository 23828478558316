import React from 'react';
import '../../css/sticky-atc/StickyAtc.scss';
import {Spinner, Card, Select, TextField, Button} from "@shopify/polaris";
import {ChromePicker} from "react-color";

const buttonBorderRadiusOptions = [
    { label: 'None', value: '0' },
    { label: 'Small', value: '0.5em' },
    { label: 'Big', value: '1.9em' },
];

const barPositionOptions = [
    { label: 'Sticky (always visible)', value: 'sticky' },
    { label: 'Fixed (hidden on scroll)', value: 'fixed' },
];

const barDesktopPlacementOptions = [
    { label: 'Top', value: 'top' },
    { label: 'Bottom', value: 'bottom' },
];

const barMobilePlacementOptions = [
    { label: 'Top', value: 'top' },
    { label: 'Bottom', value: 'bottom' },
];

const barShowOnOptions = [
    { label: 'Desktop', value: 'desktop' },
    { label: 'Mobile', value: 'mobile' },
    { label: 'Both', value: 'both' },
];

const showShadowOptions = [
    { label: 'Show shadow', value: 'yes' },
    { label: "Don't show shadow", value: 'no' },
];

const showButtonOptions = [
    { label: 'Show', value: 'yes' },
    { label: "Don't show", value: 'no' },
];

const showOnHomePageOptions = [
    { label: 'Show', value: 'yes' },
    { label: "Don't show", value: 'no' },
];

const showOnProductPageOptions = [
    { label: 'Show', value: 'yes' },
    { label: "Don't show", value: 'no' },
];

const showOnCollectionPagesOptions = [
    { label: 'Show', value: 'yes' },
    { label: "Don't show", value: 'no' },
];

const showOnCartPageOptions = [
    { label: 'Show', value: 'yes' },
    { label: "Don't show", value: 'no' },
];

const allowVisitorsToDismissOptions = [
    { label: 'Allow', value: 'yes' },
    { label: "Don't allow", value: 'no' },
];

const barEnableClickOptions = [
    { label: 'Enable', value: 'yes' },
    { label: 'Disable', value: 'no' },
];

const barEnableInitialGoalOptions = [
    { label: 'Enable', value: 'yes' },
    { label: 'Disable', value: 'no' },
];

const barEnableGoalProgressOptions = [
    { label: 'Enable', value: 'yes' },
    { label: 'Disable', value: 'no' },
];

const barEnableGoalAchievedOptions = [
    { label: 'Enable', value: 'yes' },
    { label: 'Disable', value: 'no' },
];

const barOpenInNewWindowOptions = [
    { label: 'Enable', value: 'yes' },
    { label: 'Disable', value: 'no' },
];

const enableTargetCountriesOptions = [
    { label: 'Enable', value: 'yes' },
    { label: 'Disable', value: 'no' },
];

const showOnSpecificPagesOptions = [
    { label: 'Specific pages', value: 'yes' },
    { label: 'All pages', value: 'no' },
];

const FreeShippingBarSettings = ({ freeShippingBar, changeText, changeBool, changeColor, save, changeDefaultGoal,
                                     addCountry, selectNewCountry, cancelAddingNewCountry, changeGoal, removeGoal,
                                     selectNewTargetCountry, removeTargetCountry }) => {
    return <div className="sticky-atc-settings">
        {freeShippingBar.settings ? <div>
            <Card title="Bar Design">
                <div className="color-field-title">Text Color:</div>
                <ChromePicker color={freeShippingBar.settings.bar_text_color} onChangeComplete={changeColor('bar_text_color')} />
                <div className="color-field-title">Amount Color:</div>
                <ChromePicker color={freeShippingBar.settings.bar_amount_color} onChangeComplete={changeColor('bar_amount_color')} />
                <div className="color-field-title">Background Color:</div>
                <ChromePicker color={freeShippingBar.settings.bar_bg_color} onChangeComplete={changeColor('bar_bg_color')} />
                <Select label="Show Shadow" disabled={freeShippingBar.savingSettings}
                        onChange={changeBool('bar_show_shadow')}
                        options={showShadowOptions} value={freeShippingBar.settings.bar_show_shadow ? 'yes' : 'no'} />
                <TextField label="Desktop font size" value={freeShippingBar.settings.bar_desktop_font_size}
                           disabled={freeShippingBar.savingSettings}
                           autoComplete={false} onChange={changeText('bar_desktop_font_size')} />
                <TextField label="Mobile font size" value={freeShippingBar.settings.bar_mobile_font_size}
                           disabled={freeShippingBar.savingSettings}
                           autoComplete={false} onChange={changeText('bar_mobile_font_size')} />
            </Card>
            <Card title="Free Shipping Goals">
                <TextField label={'Default goal'} value={freeShippingBar.settings.free_shipping_goal.default.toString()}
                           type={'number'} disabled={freeShippingBar.savingSettings} autoComplete={false}
                           onChange={changeDefaultGoal} />
                {typeof freeShippingBar.settings.free_shipping_goal.new === 'undefined' && <Button onClick={addCountry}>Add Country</Button>}
                {Object.keys(freeShippingBar.settings.free_shipping_goal).map(code => <div key={code}>
                    {code === 'new' && <div>
                        <Select label={'Select a country'} disabled={freeShippingBar.savingSettings}
                                onChange={selectNewCountry} options={[{label: 'Choose country:', value: 'no_country'}].concat(freeShippingBar.countries)} />
                        <Button onClick={cancelAddingNewCountry}>X</Button>
                    </div>}
                    {code.length === 2 && <div>
                        <div>{freeShippingBar.countries && freeShippingBar.countries.find(c => c.value === code).label}:</div>
                        <div>
                            <TextField label={'Goal:'} disabled={freeShippingBar.savingSettings} onChange={changeGoal(code)}
                                       type={'number'}
                                       value={freeShippingBar.settings.free_shipping_goal[code] && freeShippingBar.settings.free_shipping_goal[code].toString()}
                                       autoComplete={false}/>
                        </div>
                        <div><Button onClick={removeGoal(code)}>X</Button> </div>
                    </div>}
                </div>)}
            </Card>
            <Card title={'Placement'}>
                <Select label={'Position'} disabled={freeShippingBar.savingSettings} onChange={changeText('bar_position')}
                        options={barPositionOptions} value={freeShippingBar.settings.bar_position} />
                <Select label={'Show on'} disabled={freeShippingBar.savingSettings} onChange={changeText('bar_show_on')}
                        options={barShowOnOptions} value={freeShippingBar.settings.bar_show_on} />
                <Select label={'Desktop Placement'} disabled={freeShippingBar.savingSettings} onChange={changeText('bar_desktop_placement')}
                        options={barDesktopPlacementOptions} value={freeShippingBar.settings.bar_desktop_placement} />
                <Select label={'Mobile Placement'} disabled={freeShippingBar.savingSettings} onChange={changeText('bar_mobile_placement')}
                        options={barMobilePlacementOptions} value={freeShippingBar.settings.bar_mobile_placement} />
            </Card>
            <Card title={'Bar Actions'}>
                <Select label={'Enable Bar Click'} disabled={freeShippingBar.savingSettings} onChange={changeBool('bar_enable_click')}
                        options={barEnableClickOptions} value={freeShippingBar.settings.bar_enable_click ? 'yes' : 'no'} />
                {freeShippingBar.settings.bar_enable_click && <div>
                    <Select label={'Initial Goal'} disabled={freeShippingBar.savingSettings} onChange={changeBool('enable_initial_goal_nav')}
                            options={barEnableInitialGoalOptions} value={freeShippingBar.settings.enable_initial_goal_nav ? 'yes' : 'no'} />
                    {freeShippingBar.settings.enable_initial_goal_nav && <div>
                        <TextField label={'Initial Goal Url'} disabled={freeShippingBar.savingSettings}
                                   onChange={changeText('initial_goal_nav_url')} value={freeShippingBar.settings.initial_goal_nav_url}
                                   autoComplete={false} />
                    </div>}
                    <Select label={'Goal Progress'} disabled={freeShippingBar.savingSettings} onChange={changeBool('enable_goal_progress_nav')}
                            options={barEnableGoalProgressOptions} value={freeShippingBar.settings.enable_goal_progress_nav ? 'yes' : 'no'} />
                    {freeShippingBar.settings.enable_goal_progress_nav && <div>
                        <TextField label={'Goal Progress Url'} disabled={freeShippingBar.savingSettings}
                                   onChange={changeText('goal_progress_nav_url')} value={freeShippingBar.settings.goal_progress_nav_url}
                                   autoComplete={false} />
                    </div>}
                    <Select label={'Goal Achieved'} disabled={freeShippingBar.savingSettings} onChange={changeBool('enable_goal_achieved_nav')}
                            options={barEnableGoalAchievedOptions} value={freeShippingBar.settings.enable_goal_achieved_nav ? 'yes' : 'no'} />
                    {freeShippingBar.settings.enable_goal_achieved_nav && <div>
                        <TextField label={'Goal Achieved Url'} disabled={freeShippingBar.savingSettings}
                                   onChange={changeText('goal_achieved_nav_url')} value={freeShippingBar.settings.goal_achieved_nav_url}
                                   autoComplete={false} />
                    </div>}
                    <Select label={'Open in New Window'} disabled={freeShippingBar.savingSettings} onChange={changeBool('nav_open_in_new_window')}
                            options={barOpenInNewWindowOptions} value={freeShippingBar.settings.nav_open_in_new_window ? 'yes' : 'no'} />
                    <Select label={'Show Button'} disabled={freeShippingBar.savingSettings} onChange={changeBool('show_button')}
                            options={showButtonOptions} value={freeShippingBar.settings.show_button ? 'yes' : 'no'} />
                    {freeShippingBar.settings.show_button && <div>
                        <div className="color-field-title">Button Text Color:</div>
                        <ChromePicker color={freeShippingBar.settings.button_text_color} onChangeComplete={changeColor('button_text_color')} />
                        <div className="color-field-title">Button Background Color:</div>
                        <ChromePicker color={freeShippingBar.settings.button_bg_color} onChangeComplete={changeColor('button_bg_color')} />
                        <Select label={'Button Border Radius'} disabled={freeShippingBar.savingSettings} onChange={changeText('button_border_radius')}
                                options={buttonBorderRadiusOptions} value={freeShippingBar.settings.button_border_radius} />
                    </div>}
                </div>}
            </Card>
            <Card title={'Display Settings'}>
                <Select label={'Show on'} disabled={freeShippingBar.savingSettings} onChange={changeBool('show_on_specific_pages')}
                        options={showOnSpecificPagesOptions} value={freeShippingBar.settings.show_on_specific_pages ? 'yes' : 'no'} />
                {freeShippingBar.settings.show_on_specific_pages && <div>
                    <Select label={'Home Page'} disabled={freeShippingBar.savingSettings} onChange={changeBool('show_on_home_page')}
                            options={showOnHomePageOptions} value={freeShippingBar.settings.show_on_home_page ? 'yes' : 'no'} />
                    <Select label={'Product Pages'} disabled={freeShippingBar.savingSettings} onChange={changeBool('show_on_product_pages')}
                            options={showOnProductPageOptions} value={freeShippingBar.settings.show_on_product_pages ? 'yes' : 'no'} />
                    <Select label={'Collection Pages'} disabled={freeShippingBar.savingSettings} onChange={changeBool('show_on_collection_pages')}
                            options={showOnCollectionPagesOptions} value={freeShippingBar.settings.show_on_collection_pages ? 'yes' : 'no'} />
                    <Select label={'Cart Page'} disabled={freeShippingBar.savingSettings} onChange={changeBool('show_on_cart_page')}
                            options={showOnCartPageOptions} value={freeShippingBar.settings.show_on_cart_page ? 'yes' : 'no'} />
                </div>}
                <Select label={'Allow Visitors to Dismiss Bar'} disabled={freeShippingBar.savingSettings} onChange={changeBool('allow_visitor_to_dismiss_bar')}
                        options={allowVisitorsToDismissOptions} value={freeShippingBar.settings.allow_visitor_to_dismiss_bar ? 'yes' : 'no'} />
                <Select label={'Target Countries'} disabled={freeShippingBar.savingSettings} onChange={changeBool('enable_target_countries')}
                        options={enableTargetCountriesOptions} value={freeShippingBar.settings.enable_target_countries ? 'yes' : 'no'} />
                {freeShippingBar.settings.enable_target_countries && <div>
                    <Select label={'Add a country'} disabled={freeShippingBar.savingSettings}
                            onChange={selectNewTargetCountry}
                            options={[{label: 'Choose country:', value: 'no_country'}].concat(freeShippingBar.countries)} />
                    {freeShippingBar.settings.target_countries && freeShippingBar.settings.target_countries.map(code => <div key={code}>
                        {freeShippingBar.countries && freeShippingBar.countries.find(c => c.value === code).label}
                        <Button onClick={removeTargetCountry(code)}>X</Button>
                    </div>)}
                </div>}
            </Card>
            <div className="save-button">
                <Button onClick={save} loading={freeShippingBar.savingSettings} primary>Save</Button>
            </div>
        </div> : <div>
            <Spinner />
        </div>}
    </div>
};

export default FreeShippingBarSettings;
