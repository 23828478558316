import React from 'react';
import '../../css/ProductsWarranty.css'
import {
    Button,
    FormLayout,
    Modal, Select,
    Spinner,
    TextField,
    TextStyle
} from "@shopify/polaris";
import NoImage from "./NoImage";
import ImageUpload from "../ImageUpload";
import {host} from "../../actions/data";

const priceOptions = [
    { label: 'Fixed', value: 'fixed' },
    { label: 'Percentage', value: 'percentage' },
];

const WarrantyEdit = ({
    onBack, onSave, saveError, isSaving, warranty, onWarrantyNameChange,
    currencySymbol, chooseProduct, chooseProductOpen, onCloseChooseProduct,
    searchBoxText, onChangeSearchText, onFocusSearchText, showSearchSpinner, searchError,
    searchedProducts, chooseWarrantyItem, isWarrantyDefaultImage, settings, tokenId, upload, isUploading, uploadError,
    cancelUpload, uploadSuccess, uploadFailure, shopName, token, restoreToWarrantyDefaultImage, onWarrantyPriceChange,
    restoreToWarrantyOriginalImage, imageBackup, restoreToWarrantyStoreImage, onPriceTypeChange,
}) => (
        <FormLayout>
            <div className="navigation">
                <span className="navigation-button">
                    <Button onClick={onBack} disabled={isSaving}>Back</Button>
                </span>
                <span className="navigation-button">
                    <Button onClick={onSave} primary loading={isSaving}
                            disabled={!warranty.name || !warranty.product || warranty.price === ''}
                    >Save</Button>
                </span>
            </div>
            {saveError && <TextStyle variation="negative">There was an error sending your request. Please try again in a
            few minutes</TextStyle>}
            <div>
                <div className="warranty-product-label">
                    Assign warranty to product:
                </div>
                <div className="warranty-line-item-wrapper">
                    {warranty.product && <span className="warranty-line-item">

                        {warranty.product.image_src ? (
                            <img alt="Warranty" width={'45px'} src={warranty.product.image_src}/>
                        ) : (
                            <NoImage itemId={warranty.product.shopify_product_id} klassName={'warranty-product-image'} />
                        )}
                        <div className="warranty-item-title">{warranty.product.title}</div>
                    </span>}
                    <span className="choose-bundle-product">
                        <Button onClick={chooseProduct}>Choose Product</Button>
                    </span>
                </div>
            </div>
            <TextField label="Warranty name:" value={warranty.name} onChange={onWarrantyNameChange} disabled={isSaving}
                autoComplete={false} />

            <div>
                <Select label="Price Type:" options={priceOptions} onChange={onPriceTypeChange} value={warranty.price_type}
                        disabled={isSaving} />
                <TextField label={`Warranty price in ${warranty.price_type === 'fixed' ? currencySymbol : '%'}:`}
                           value={warranty.price}
                           onChange={onWarrantyPriceChange} type="number"
                           disabled={isSaving} autoComplete={false} />
            </div>

            <div>
                {warranty.image_src ?
                <div>
                    <img src={warranty.image_src} alt="Warranty" height={'200px'} width={'200px'}/>
                    <div>
                        <Button onClick={restoreToWarrantyStoreImage}>Restore to store default image</Button>
                    </div>
                </div>
                :
                <div>
                    {isWarrantyDefaultImage ?
                        <div>
                            { settings.is_zoorix_default_warranty_image ?
                                <div>
                                    <img src="https://public.zoorix.com/images/warranty.png" alt="zoorix default"
                                         height={'200px'} width={'200px'}/>
                                </div>
                                :
                                <div>

                                    <img src={`https://public.zoorix.com/shops/${tokenId}/images/default_warranty`}
                                         alt="store default" height={'200px'} width={'200px'}/>

                                </div>
                            }
                        </div>
                        :
                        <div>
                            <img src={`https://public.zoorix.com/shops/${tokenId}/images/warranties/${warranty.id}/warranty_image?${Date.now()}`}
                                 alt="custom" height={'200px'} width={'200px'}/>
                             <div className="restore-image-button"><span><Button onClick={restoreToWarrantyDefaultImage}>Restore default image</Button></span></div>
                        </div>
                    }
                    {imageBackup && <Button onClick={restoreToWarrantyOriginalImage}>Restore to original image</Button>}
                </div>
                }
                {isUploading ?
                    <div>
                        {uploadError &&
                        <TextStyle variation="negative">There was an error uploading your file. Please try
                            again in a few minutes</TextStyle>}
                        <ImageUpload onCancel={cancelUpload}
                                     presignedUrl={`https://${host}/image/get_url_for_warranty?shop_name=${shopName}&token=${token}&warranty_id=${warranty.id}`}
                                     uploadSuccess={uploadSuccess}
                                     uploadFailure={uploadFailure}

                        />
                    </div>
                    :
                    <div className="upload-warranty-image">
                        <div className="upload-warranty-image-div">
                            <Button onClick={upload}>Replace image</Button>
                        </div>
                    </div>
                }
            </div>

            <Modal open={chooseProductOpen} onClose={onCloseChooseProduct}
                title="Choose a product"
                secondaryActions={[{ content: 'Cancel', onAction: onCloseChooseProduct }]}>
                <Modal.Section>
                    <div className="search-products warranty">
                        <TextField placeholder='Search phrase, e.g. Shirts for men' autoComplete={false}
                            label="Choose a product:"
                            value={searchBoxText} onChange={onChangeSearchText}
                            onFocus={onFocusSearchText} />
                        {showSearchSpinner && <div className="fetch-search-wrapper"><span
                            className="searching">Searching...</span><span className="fetch-spinner"><Spinner
                                size="small" /></span></div>}
                        {searchError && <TextStyle variation="negative">Something went wrong</TextStyle>}
                        {searchedProducts && searchedProducts.map((item) => (
                            <div className={'product-container searchable warranty'} key={item.id}
                                onClick={chooseWarrantyItem(item.id)}>
                                <span className="image-wrapper">
                                    {item.image_src ? (
                                        <div className="item-image warranty"
                                            style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                    ) : (
                                            <NoImage itemId={item.id} className={'item-image warranty'} />
                                        )}
                                </span>
                                <span className="warranty-searchable-item">{item.title}</span>
                            </div>
                        ))}
                    </div>
                </Modal.Section>
            </Modal>
        </FormLayout >
    );

export default WarrantyEdit;
