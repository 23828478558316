import { connect } from 'react-redux'
import {
    freeShippingBarChangeSettingText, freeShippingBarSaveCode,
} from "../../actions/free-shipping-bar/freeShippingBar";
import FreeShippingBarCodeEditor from "../../components/free-shipping-bar/FreeShippingBarCodeEditor";

const mapStateToProps = state => ({
    freeShippingBar: state.freeShippingBar,
});

const mapDispatchToProps = (dispatch) => ({
    save: () => dispatch(freeShippingBarSaveCode()),
    changeText: attribute => text => dispatch(freeShippingBarChangeSettingText(attribute, text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FreeShippingBarCodeEditor);
