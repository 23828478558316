import {connect} from 'react-redux'
import WarrantyModeration from "../components/WarrantyModeration";
import {
    warrantyModerationCloseOrderModal,
    onWarrantyModerationNextPage,
    onWarrantyModerationPreviousPage,
    onWarrantyModerationTypeChange,
    onWarrantyModerationViewOrder,
    onWarrantyModerationViewRequest,
    warrantyModerationCloseRequestModal,
    rejectWarrantyRedemptionRequest,
    warrantyModerationCloseRejectModal,
    warrantyModerationRejectReasonChange,
    warrantyModerationRejectAction,
    warrantyModerationDismissToast,
    acceptWarrantyRedemptionRequest,
    warrantyModerationCloseAcceptModal,
    warrantyModerationAcceptAction,
    warrantyModerationDiscountAmountChange,
    warrantyModerationCreateManual,
    warrantyModerationCloseCreateManualModal,
    warrantyModerationOrderNameChange,
    warrantyModerationSelectVariant,
    warrantyModerationSelectedVariantChange,
    warrantyModerationOrderEmailChange, warrantyModerationCreateManualAction
} from "../actions/warrantyModeration";

const mapStateToProps = state => ({
    type: state.warrantyModeration.type,
    fetchingItems: state.warrantyModeration.fetchingItems,
    filterError: state.warrantyModeration.filterError,
    page: state.warrantyModeration.page,
    maxPage: state.warrantyModeration.maxPage,
    currencySymbol: state.warrantyModeration.currencySymbol,
    items: state.warrantyModeration.items,
    viewOrderLoading: state.warrantyModeration.viewOrderLoading,
    orderModalOpen: state.warrantyModeration.orderModalOpen,
    order: state.warrantyModeration.order,
    requestModalOpen: state.warrantyModeration.requestModalOpen,
    requestBody: state.warrantyModeration.requestBody,
    redeemHasImage: state.warrantyModeration.redeemHasImage,
    redeemImageUrl: state.warrantyModeration.redeemImageUrl,
    rejectModalOpen: state.warrantyModeration.rejectModalOpen,
    rejectReason: state.warrantyModeration.rejectReason,
    rejectProcessing: state.warrantyModeration.rejectProcessing,
    rejectError: state.warrantyModeration.rejectError,
    rejectToastIsActive: state.warrantyModeration.rejectToastIsActive,
    acceptModalOpen: state.warrantyModeration.acceptModalOpen,
    acceptProcessing: state.warrantyModeration.acceptProcessing,
    acceptError: state.warrantyModeration.acceptError,
    discountAmount: state.warrantyModeration.discountAmount,
    acceptToastIsActive: state.warrantyModeration.acceptToastIsActive,
    acceptedItemPrice: state.warrantyModeration.acceptedItemPrice,
    acceptedItemPercentage: state.warrantyModeration.acceptedItemPercentage,
    createManualModal: state.warrantyModeration.createManualModal,
    orderName: state.warrantyModeration.orderName,
    fetchingVariants: state.warrantyModeration.fetchingVariants,
    fetchingVariantsError: state.warrantyModeration.fetchingVariantsError,
    variants: state.warrantyModeration.variants,
    selectedVariants: state.warrantyModeration.selectedVariants,
    selectedVariantPrice: state.warrantyModeration.selectedVariantPrice,
    discountPercentage: state.warrantyModeration.settings.percentage,
    orderEmail: state.warrantyModeration.orderEmail,
    isCreateManualDisabled: state.warrantyModeration.isCreateManualDisabled,
    createManualSpinner: state.warrantyModeration.createManualSpinner,
    createManualError: state.warrantyModeration.createManualError,
});

const mapDispatchToProps = dispatch => ({
    onTypeChange: type => dispatch(onWarrantyModerationTypeChange(type)),
    onPrevious: () => dispatch(onWarrantyModerationPreviousPage()),
    onNext: () => dispatch(onWarrantyModerationNextPage()),
    createManualAction: () => dispatch(warrantyModerationCreateManualAction()),
    viewOrder: id => () => dispatch(onWarrantyModerationViewOrder(id)),
    viewRequest: id => () => dispatch(onWarrantyModerationViewRequest(id)),
    rejectWarrantyRedemptionRequest: id => () => dispatch(rejectWarrantyRedemptionRequest(id)),
    acceptWarrantyRedemptionRequest: id => () => dispatch(acceptWarrantyRedemptionRequest(id)),
    closeOrderModal: () => dispatch(warrantyModerationCloseOrderModal()),
    closeRequestModal: () => dispatch(warrantyModerationCloseRequestModal()),
    closeRejectModal: () => dispatch(warrantyModerationCloseRejectModal()),
    closeAcceptModal: () => dispatch(warrantyModerationCloseAcceptModal()),
    rejectAction: () => dispatch(warrantyModerationRejectAction()),
    acceptAction: () => dispatch(warrantyModerationAcceptAction()),
    onDismissToast: () => dispatch(warrantyModerationDismissToast()),
    createManual: () => dispatch(warrantyModerationCreateManual()),
    selectVariant: () => dispatch(warrantyModerationSelectVariant()),
    closeCreateManualModal: () => dispatch(warrantyModerationCloseCreateManualModal()),
    onRejectReasonChange: text => dispatch(warrantyModerationRejectReasonChange(text)),
    onDiscountAmountChange: text => dispatch(warrantyModerationDiscountAmountChange(text)),
    onOrderNameChange: text => dispatch(warrantyModerationOrderNameChange(text)),
    onOrderEmailChange: text => dispatch(warrantyModerationOrderEmailChange(text)),
    onSelectedVariantChange: choice => dispatch(warrantyModerationSelectedVariantChange(choice)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(WarrantyModeration);
