import React from 'react';
import '../../css/free-shipping-bar/freeShippingBar.scss';
import {Button, Spinner, Card} from "@shopify/polaris";

const FreeShippingBarDashboard = ({freeShippingBar}) => {
    return <div className="sticky-add-to-cart-dashboard">
        <Card.Section>
            <Card>
                <div className="card-wrapper">
                    <div className="embedded-app-link-wrapper">
                        <div className="embedded-app-link-title">App Status</div>
                        <div className="embedded-app-buttons-wrapper">
                            <Button plain
                                    onClick={() => window.open(`https://${freeShippingBar.shop}/admin/themes/current/editor?context=apps`,
                                        '_blank')}>Enable / Disable Zoorix on the Shopify Editor</Button></div>
                    </div>
                </div>
            </Card>
        </Card.Section>

        <div className="sticky-add-to-cart-stats-wrapper">
            <div className="sticky-add-to-cart-stats-title">
                <span>Statistics</span>
            </div>
            <div className="sticky-add-to-cart-stats-subtitle">See your bar performance.</div>
            {freeShippingBar.dashboardData ? <div>
            <div className="sticky-add-to-cart-stats">
                <div className="sticky-add-to-cart-stats-item"><span className="sticky-add-to-cart-stats-item-label">Views:</span>
                    <span className="sticky-add-to-cart-stats-item-count"> {freeShippingBar.dashboardData.views}</span>
                </div>
                <div className="sticky-add-to-cart-stats-item"><span className="sticky-add-to-cart-stats-item-label">Goal Progress:</span>
                    <span className="sticky-add-to-cart-stats-item-count"> {freeShippingBar.dashboardData.goalProgress}</span>
                </div>
                <div className="sticky-add-to-cart-stats-item"><span className="sticky-add-to-cart-stats-item-label">Goal Achieved:</span>
                    <span className="sticky-add-to-cart-stats-item-count"> {freeShippingBar.dashboardData.goalAchieved}</span>
                </div>
                <div className="sticky-add-to-cart-stats-item"><span className="sticky-add-to-cart-stats-item-label">Clicks:</span>
                    <span className="sticky-add-to-cart-stats-item-count">{freeShippingBar.dashboardData.clicks}</span>
                </div>
            </div></div> : <div><Spinner size={'small'}/> Waiting for data...</div>}
        </div>
    </div>
};

export default FreeShippingBarDashboard;
