import React from 'react';
import '../../css/Plans.css';
import {RangeSlider} from "@shopify/polaris";

const OurPricing = ({platinumPlusRange, platinumPlusChangeRange}) => <div>
    <div className="our-pricing">Our pricing:</div>
    <div className="pricing-number-of-orders">How many monthly store orders do you have?</div>
    <div className="pricing-plans-picker">
        <RangeSlider
            label=""
            min={0}
            max={8}
            value={platinumPlusRange}
            onChange={platinumPlusChangeRange}
            output={false}
        />
    </div>
    <div className="pricing-calc-div">
        <div className="plans-calc-wrapper"><span className="plans-calc-left">Orders</span><span className="plans-calc-right">Price</span></div>

        <div>
        {platinumPlusRange === 0 && <div className="plans-calc-wrapper"><span className="plans-calc-left">Up to 50 orders/month</span><span className="plans-calc-right"><b>Free</b></span></div>}
        {platinumPlusRange === 1 && <div className="plans-calc-wrapper"><span className="plans-calc-left">Up to 100 orders/month</span><span className="plans-calc-right"><b>$7.99</b></span></div>}
        {platinumPlusRange === 2 && <div className="plans-calc-wrapper"><span className="plans-calc-left">Up to 200 orders/month</span><span className="plans-calc-right"><b>$14.99</b></span></div>}
        {platinumPlusRange === 3 && <div className="plans-calc-wrapper"><span className="plans-calc-left">200+ orders/month </span><span className="plans-calc-right"><b>$19.99</b> +$0.01/order</span></div>}
        {platinumPlusRange === 4 && <div className="plans-calc-wrapper"><span className="plans-calc-left">500+ orders/month </span><span className="plans-calc-right"><b>$22.99</b> +$0.01/order</span></div>}
        {platinumPlusRange === 5 && <div className="plans-calc-wrapper"><span className="plans-calc-left">1000+ orders/month </span><span className="plans-calc-right"><b>$27.99</b> +$0.01/order</span></div>}
        {platinumPlusRange === 6 && <div className="plans-calc-wrapper"><span className="plans-calc-left">2000+ orders/month </span><span className="plans-calc-right"><b>$37.99</b> +$0.01/order</span></div>}
        {platinumPlusRange === 7 && <div className="plans-calc-wrapper"><span className="plans-calc-left">5000+ orders/month </span><span className="plans-calc-right"><b>$67.99</b> +$0.01/order</span></div>}
        {platinumPlusRange === 8 && <div className="plans-calc-wrapper"><span className="plans-calc-left">10000+ orders/month </span><span className="plans-calc-right"><b>$117.99</b> +$0.01/order</span></div>}
    </div>
    </div>
</div>;

export default OurPricing;
