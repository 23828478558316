import React from 'react';
import '../../css/Partners.css'
import VisiblePartnersDashboard from "../../containers/partners/VisiblePartnersDashboard";
import {Frame} from '@shopify/polaris';

const PartnersAdmin = ({ match: { params }, login }) => {
    login(params.sub, params.secret);

    return <Frame>
        <VisiblePartnersDashboard />
    </Frame>;
}

export default PartnersAdmin;
