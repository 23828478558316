import { connect } from 'react-redux'
import {
    restoreWarrantyTextsDefaults, warrantyCallToActionChange, warrantyTitleChange, warrantyTooltipTemplateChange
} from "../../actions/texts";
import TextsWarranty from "../../components/texts/TextsWarranty";

const mapStateToProps = state => ({
    translations: state.texts.translations,
    locale: state.texts.locale,
    saving: state.texts.saving,
});

const mapDispatchToProps = (dispatch) => ({
    restoreDefaults: () => dispatch(restoreWarrantyTextsDefaults()),
    onTitleChange: text => dispatch(warrantyTitleChange(text)),
    onCallToActionChange: text => dispatch(warrantyCallToActionChange(text)),
    onTooltipTemplateChange: text => dispatch(warrantyTooltipTemplateChange(text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(TextsWarranty);
