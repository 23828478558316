import { connect } from 'react-redux'
import {submit, textChange} from '../actions/support'
import Support from '../components/Support'
import {emailChange, nameChange} from "../actions/data";

const mapStateToProps = state => {
    return {
        name: state.data.ownerName,
        email: state.data.ownerEmail,
        text: state.support.text,
        submitted: state.support.submitted,
        error: state.support.error,
        textError: state.support.textError,
    };
};

const mapDispatchToProps = dispatch => ({
    onNameChange: text => dispatch(nameChange(text)),
    onEmailChanged: text => dispatch(emailChange(text)),
    onTextChanged: text => dispatch(textChange(text)),
    onSubmit: () => dispatch(submit()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Support);
