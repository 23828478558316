import { connect } from 'react-redux'
import {
    adminTrack, wizardAbort,
    wizardCreateAnotherOffer,
    wizardDone,
    wizardHideEnableZoorix,
    wizardPreviewOffer
} from "../../../actions/v2/appV2Wizard";
import AppV2WizardTestOffer from "../../../components/v2/wizard/AppV2WizardTestOffer";

const mapStateToProps = state => ({
    appV2Wizard: state.appV2Wizard,
    data: state.data,
});

const mapDispatchToProps = (dispatch) => ({
    closeEnableZoorixModal: () => dispatch(wizardHideEnableZoorix()),
    preview: () => dispatch(wizardPreviewOffer()),
    createAnotherOffer: () => {
        dispatch(adminTrack('onboarding_wizard_create_another_offer', {}));
        dispatch(wizardCreateAnotherOffer());
    },
    done: () => dispatch(wizardDone()),
    abort: () => dispatch(wizardAbort()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppV2WizardTestOffer);
