import React from 'react';
import {Frame, TextField, Button } from "@shopify/polaris";
import '../css/ManualInstallation.css'

const Install = ({shopDomain, onShopDomainChange, install, installing}) => {


    return <Frame>
        <div className="install-dashboard">
            <div className="install-container">
                <div className="install-welcome">
                    <img alt="zoorix default" className="install-welcome-image"
                         src="https://public.zoorix.com/images/zoorix-partner-logo.png"/>
                    <div className="install-welcome-text">Welcome to Zoorix</div>
                </div>
                <div className="install-fields-wrapper">
                    <span className="install-shop-name">
                <TextField label="" value={shopDomain}
                           placeholder={'shop.myshopify.com'}
                           onChange={onShopDomainChange}
                           autoComplete={false}/>
                        </span>
                    <span className="install-button">
                    <Button primary onClick={install} loading={installing}>Install</Button>
                </span>
                </div>
            </div>
        </div>
    </Frame>;
}

export default Install;
