import React from 'react';
import {Button, Icon, Modal, Spinner, TextContainer, TextStyle} from "@shopify/polaris";
import NoImage from "./NoImage";
import '../../css/Volume.css';
import { DeleteMajor, ViewMajor, ClipboardMinor } from "@shopify/polaris-icons";
import {addByFilterOptions} from "./ProductsSlider";

const VolumeDiscounts = ({discounts, creatingDiscount, createDiscount, removeDiscount, removeDiscountModalOpen,
                             closeRemoveDiscountModal, removeDiscountAction, removeDiscountSpinner,
                             discountIdToDelete, removeDiscountError, editDiscount, editingDiscount,
                             switchEnable, switchEnableLoading, productsVolume, previewDiscount, showShortCode,
                             copyShortCode}) => (
        <div>
            <Button primary loading={creatingDiscount} onClick={createDiscount}>Create volume discount offer</Button>
            <div className="vd-items-wrapper">
            {discounts.map((discount, index) => <div className="vd-item" key={discount.id}>
                {!discount.dynamic && discount.image_src ? (
                    <div className="bundle-product-image vd-item-image" style={{ 'backgroundImage': 'url(' + discount.image_src + ')' }} />
                ) : (
                    <div className="bundle-product-image vd-item-image"><NoImage itemId={discount.id} /></div>
                )}
                <span className="vd-offer-name">{discount.name}</span>
                {discount.dynamic ?
                    <span>
                        {addByFilterOptions.find(o => o.value === discount.filter1_type).label}:&nbsp;
                        {discount.filter1_value}
                    </span> :
                    <span>{discount.product_title}</span>}
                <span className="vd-edit-wrapper">
                    <span className="vd-short-code-wrapper">
                        <Button plain={true} onClick={showShortCode(discount.id, true)}>Short Code</Button>
                    </span>
                    <span className="vd-enable-disable-wrapper">
                        <Button primary={!discount.is_enabled} onClick={switchEnable(discount.id)}
                            loading={switchEnableLoading[discount.id]}>
                            {discount.is_enabled ? 'Disable' : 'Enable'}</Button>
                    </span>
                    <Button onClick={editDiscount(discount.id)} loading={editingDiscount[discount.id]}>Edit</Button>
                    <span className="vd-item-remove" onClick={removeDiscount(index)}>
                        <Icon source={DeleteMajor} />
                    </span>
                    <span className="vd-item-view">{productsVolume.previewDiscountLoading[discount.id] ?
                        <Spinner size="small"/> :
                        <span onClick={previewDiscount(discount.id)}>
                            <Icon source={ViewMajor}/>
                        </span>
                    }</span>
                </span>
            </div>)}</div>
            <Modal open={removeDiscountModalOpen} onClose={closeRemoveDiscountModal}
                   title="Delete discount"
                   primaryAction={{
                       content: 'Delete',
                       onAction: removeDiscountAction,
                   }}
                   secondaryActions={[
                       {
                           content: 'Cancel',
                           onAction: closeRemoveDiscountModal,
                       },
                   ]}>
                <Modal.Section>
                    {removeDiscountSpinner ? (
                        <span className="spinner-wrapper">
                            <Spinner />
                        </span>
                    ) : <TextContainer>
                        <p>
                            Are you sure you want to delete discount
                            <b> {discounts[discountIdToDelete] && discounts[discountIdToDelete].name}</b>?
                        </p>
                        {removeDiscountError && <TextStyle variation="negative">Something went wrong</TextStyle>}
                    </TextContainer>}

                </Modal.Section>
            </Modal>
            <Modal open={productsVolume.shortCodeModalOpen} onClose={showShortCode(null, false)}
                   title="Short Code"
                   primaryAction={{
                       content: 'Close',
                       onAction: showShortCode(null, false),
                   }}
                   >
                <Modal.Section>
                    <code>{productsVolume.shortCode}</code>
                    <div className="vd-short-code-copy" onClick={copyShortCode}><Icon source={ClipboardMinor}/></div>
                </Modal.Section>
            </Modal>
        </div>
    );

export default VolumeDiscounts;
