import React from 'react';
import '../../../css/v2/AppV2Wizard.scss';
import {Icon} from "@shopify/polaris";
import {DeleteMajor} from "@shopify/polaris-icons";
import {InputNumber, Select} from 'antd';
const {Option} = Select;

const selectAfter = (appV2Wizard, onDiscountTypeChange) =>
    <Select defaultValue="percentage" className="select-after" onChange={onDiscountTypeChange}>
        <Option value="percentage">% Off</Option>
        <Option value="fixed">{appV2Wizard.currencySymbol + " Off"}</Option>
    </Select>;


const AppV2WizardConfigVolume = ({
    appV2Wizard, onQtyChange, onDiscountTypeChange, onDiscountChange, onPopularChange,
    trash, addTier
}) =>
    <div className={'app-v2-wizard-config-volume'}>
        {appV2Wizard.vd.tiers.map((tier, idx) => <div className="volume-tier-wrapper" key={idx}>
            <span className="field-wrapper">
                <InputNumber addonBefore="Quantity" value={tier.qty.toString()} size="large" min={1}
                             defaultValue={1} onChange={onQtyChange(idx)}/>
            </span>
            <span className="field-wrapper">
                <InputNumber addonAfter={selectAfter(appV2Wizard, onDiscountTypeChange(idx))} addonBefore="Discount"
                             value={tier.discount.toString()} size="large" min={0}
                             max={tier.discountType === 'percentage' ? 100 : undefined}
                             defaultValue={1} onChange={onDiscountChange(idx)}/>
            </span>

            <span className="field-wrapper">
                <span className={'checkbox'}>
                     <div className={'container'}>
                        <div className={'round'}>
                        <input onChange={onPopularChange(idx)} type="checkbox" checked={tier.popular}
                               id={'popular-' + idx}/>
                              <label htmlFor={'popular-' + idx}></label>
                        </div>
                    </div>
                </span>
            </span>

            <div onClick={onPopularChange(idx)} className="vd-tier-most-popular">Most Popular</div>


            {idx > 0 && <span className="vd-tier-delete" onClick={trash(idx)}><Icon source={DeleteMajor}/></span>}
        </div>)}
        <div className="add-tier" onClick={addTier}>Add Tier</div>
    </div>;

export default AppV2WizardConfigVolume;
