import React from 'react';
import '../../css/TextsMain.css'
import { TextField} from "@shopify/polaris";

const TextsVolume = ({ translations, locale, saving, restoreDefaults, onAddToCartTextChange, onPopularTextChange,
                             onTotalTextChange, onSaveTextChange, onEachItemTextChange, onJustAddedTextChange,
                             onQtyTextChange, onSavePerItemChange, onOutOfStockTextChange, onTiersTableQtyTextChange,
                             onTiersTableDiscountTextChange }) =>
    <div>
        <TextField label="Add To Cart:" value={translations[locale].volume.add_to_cart_text}
                   onChange={onAddToCartTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Most Popular:" value={translations[locale].volume.popular_text}
                   onChange={onPopularTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Total:" value={translations[locale].volume.total_text}
                   onChange={onTotalTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Save:" value={translations[locale].volume.save_text}
                   onChange={onSaveTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Each Item:" value={translations[locale].volume.each_item_text}
                   onChange={onEachItemTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Just Added:" value={translations[locale].volume.just_added}
                   onChange={onJustAddedTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Quantity:" value={translations[locale].volume.qty}
                   onChange={onQtyTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Save per item:" value={translations[locale].volume.save_per_item_text}
                   onChange={onSavePerItemChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Out of stock:" value={translations[locale].volume.out_of_stock_text}
                   onChange={onOutOfStockTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Tiers table qty:" value={translations[locale].volume.tiers_table_qty_text}
                   onChange={onTiersTableQtyTextChange}
                   disabled={saving} autoComplete={false} />
        <TextField label="Tiers table discount:" value={translations[locale].volume.tiers_table_discount_text}
                   onChange={onTiersTableDiscountTextChange}
                   disabled={saving} autoComplete={false} />
        {/*<span className="texts-restore">*/}
        {/*        <Button onClick={restoreDefaults}>Restore defaults</Button>*/}
        {/*</span>*/}
</div>;

export default TextsVolume;
