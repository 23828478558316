import { connect } from 'react-redux'
import {
    approveCrossSellSelectedCollections,
    approveCrossSellSelectedProducts, approveCrossSellSelectedTags,
    collectionSearchTextChange,
    onProductSearchTextChange,
    removeSelectedCollection,
    removeSelectedProduct, removeSelectedTag,
    selectCrossSellContentAndSearch, tagSearchTextChange,
    toggleCrossSellSelectCollectionsModal,
    toggleCrossSellSelectProductsModal,
    toggleCrossSellSelectTagsModal, toggleSelectCollection,
    toggleSelectProduct, toggleSelectTag, wizardSetInvalidSelectOffer,
} from "../../../actions/v2/appV2Wizard";
import AppV2WizardConfigCrossSell from "../../../components/v2/wizard/AppV2WizardConfigCrossSell";

const mapStateToProps = state => ({
    appV2Wizard: state.appV2Wizard,
});

const mapDispatchToProps = (dispatch) => ({
    selectContentType: text => () => dispatch(selectCrossSellContentAndSearch(text)),
    toggleSelectProductsModal: () => dispatch(toggleCrossSellSelectProductsModal()),
    toggleSelectCollectionsModal: () => dispatch(toggleCrossSellSelectCollectionsModal()),
    toggleSelectTagsModal: () => dispatch(toggleCrossSellSelectTagsModal()),
    approveSelectedProducts: () => {dispatch(approveCrossSellSelectedProducts()); dispatch(wizardSetInvalidSelectOffer(false))},
    approveSelectedCollections: () => {dispatch(approveCrossSellSelectedCollections()); dispatch(wizardSetInvalidSelectOffer(false))},
    approveSelectedTags: () => {dispatch(approveCrossSellSelectedTags()); dispatch(wizardSetInvalidSelectOffer(false))},
    onProductSearchTextChange: text => dispatch(onProductSearchTextChange(text)),
    onCollectionSearchTextChange: text => dispatch(collectionSearchTextChange(text)),
    onTagSearchTextChange: text => dispatch(tagSearchTextChange(text)),
    removeSelectedProduct: id => () => dispatch(removeSelectedProduct(id)),
    removeSelectedCollection: id => () => dispatch(removeSelectedCollection(id)),
    removeSelectedTag: id => () => dispatch(removeSelectedTag(id)),
    toggleSelectProduct: id => () => dispatch(toggleSelectProduct(id)),
    toggleSelectCollection: id => () => dispatch(toggleSelectCollection(id)),
    toggleSelectTag: id => () => dispatch(toggleSelectTag(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppV2WizardConfigCrossSell);
