import React from 'react';
import {
    Button,
    Modal,
    TextField,
    Toast,
    RangeSlider,
    Heading,
    Banner,
    Card,
    DisplayText,
    Spinner,
Icon
} from "@shopify/polaris";
import {
    MobileAcceptMajor, MobileCancelMajor
} from '@shopify/polaris-icons';

import '../../css/Partners.css'
import Moment from "react-moment";

const PartnersDashboard = ({
    onLogout, paypalEmail, openChangePasswordModal, changePasswordModalOpen,
    closeChangePasswordModal, changePassword, oldPassword, newPassword,
    onOldPasswordChange, onNewPasswordChange, changingPassword, toastActive,
    toastContent, onDismissToast, onPaypalEmailChange, onUpdatePaypalEmail,
    updatingPaypalEmail, offeringValue, onOfferingValueChange, refUrl,
    copyRefUrl, visits, referrals, totalEarnings, totalPaid, referredStores,
    partnerPayments, partners, onBrandChange, onSaveBrand
}) => {

    return <div className="partner-dashboard-body">
        <div className="partner-dashboard-header">
            <img alt="zoorix default" className="partner-dashboard-header-image"
                 src="https://public.zoorix.com/images/zoorix-partner-logo-nobckg.png"/>
            <span className="partner-dashboard-header-text">Zoorix</span>
            <div className="partner-dashboard-header-buttons">
                <Button onClick={openChangePasswordModal}>Change password</Button>
                <Button onClick={onLogout}>Logout</Button>
            </div>
        </div>
        <div className="partner-dashboard-content">
            <div className="partner-dashboard-banner">
                <Banner status="success" title="Referral Program">
                    <p>This is where you can share Zoorix with other friendly stores and earn some money in the process.
                        You may get up to {partners.package && partners.package.max_commission}% commission for life on the amount stores referred by you will pay.
                        {partners.package && partners.package.extra_commission > 0 && partners.package.extra_commission_months > 0 && <span>
                            <br/>
                            In addition, you will get <b>{partners.package.extra_commission}% extra commission</b> for the first&nbsp;
                            {partners.package.extra_commission_months} months - Up to <b>{partners.package.extra_commission + partners.package.max_commission}% commission in total</b>.
                        </span>}
                    </p>
                </Banner>
            </div>
            <div className="partner-dashboard-cards">
                <Card>
                    <Card.Section>
                        { Number.isInteger(offeringValue) && <div>
                            <div className="partner-dashboard-payment-cards">
                                <Card>
                                    <Card.Section title="Affiliate link">
                                        <div className="partner-dashboard-affiliate-link-cards">
                                            <Card>
                                                <Card.Section>
                                                    <div className="partner-dashboard-give-get">
                                                        <div className="partner-dashboard-get">You get
                                                            <b> {partners.offeringValueToCommission[offeringValue]}%</b>
                                                            &nbsp;commission
                                                        </div>
                                                        <div className="partner-dashboard-give">
                                                            You give <b>{partners.offeringValueToDiscount[offeringValue]}%</b>
                                                            &nbsp;discount
                                                        </div>
                                                    </div>
                                                    <RangeSlider label="" value={offeringValue}
                                                                 onChange={onOfferingValueChange}
                                                                 min={0} max={partners.maxSliderStep} output={false}/>
                                                </Card.Section>
                                            </Card>
                                            <Card>
                                                <Card.Section>
                                                    <div className="partner-dash-url-copy-wrapper">
                                                        <span className="partner-dash-ref-url-wrapper">
                                                            <TextField id="refUrl" readOnly value={refUrl}/>
                                                        </span>
                                                        <span className="partner-dash-copy-btn-wrapper">
                                                            <Button onClick={copyRefUrl}>Copy</Button>
                                                        </span>
                                                    </div>
                                                </Card.Section>
                                            </Card>
                                        </div>
                                    </Card.Section>
                                </Card>
                                <Card>
                                    <Card.Section>
                                        <Card>
                                            <Card.Section title="Paypal email">
                                                <div>
                                                    <TextField value={paypalEmail}
                                                               onChange={onPaypalEmailChange}
                                                               disabled={updatingPaypalEmail}/>
                                                    <div className="partner-dash-paypal-btn-wrapper">
                                                        <Button onClick={onUpdatePaypalEmail}
                                                                loading={updatingPaypalEmail}>Update
                                                            paypal
                                                            email</Button>
                                                    </div>
                                                </div>
                                            </Card.Section>
                                        </Card>
                                    </Card.Section>
                                </Card>
                                <Card>
                                    <Card.Section>
                                        <Card>
                                            <Card.Section title="Brand">
                                                <div className="partner-brand-wrapper">
                                                    <TextField value={partners.brand}
                                                               onChange={onBrandChange}
                                                               autoComplete={false}
                                                               />
                                                    {partners.checkingBrandAvailability ?

                                                        <span className="check-brand-avail-spinner"><Spinner size="small"/></span> :
                                                        <div className="check-brand-avail-check"> { partners.brand && <span>  <span>{partners.brandAvailable ?
                                                            <span className={'green'}><Icon
                                                                source={MobileAcceptMajor}
                                                                color="base" /></span> :
                                                            <span className={'red'}><Icon
                                                                source={MobileCancelMajor}
                                                                color="base" /></span>}
                                                        </span></span>}</div>}
                                                    {partners.brandTooShort && <span className="check-brand-avail-text" style={{color: 'red'}}>Brand too short</span>}
                                                    <div className="partner-dash-paypal-btn-wrapper">
                                                        <Button onClick={onSaveBrand}
                                                                disabled={!partners.brandAvailable}
                                                                loading={partners.savingBrand}>Save</Button>
                                                    </div>
                                                </div>
                                            </Card.Section>
                                        </Card>
                                    </Card.Section>
                                </Card>
                            </div>

                        </div>}
                        { !Number.isInteger(offeringValue) && <div className="partner-dashboard-payment-cards">

                            <Card>
                                <Card.Section>
                                    <Heading element={"h1"}>Affiliate link</Heading>
                                    <div className="partner-dash-spinner-wrapper">
                                        <Spinner size="large"/>
                                    </div>
                                </Card.Section>
                            </Card>
                            <Card>
                                <Card.Section>
                                    <Heading element={"h1"}>Paypal email setup</Heading>
                                    <Card>
                                        <Card.Section title="Paypal email">
                                            <div className="partner-dash-spinner-wrapper">
                                                <Spinner size="large"/>
                                            </div>
                                        </Card.Section>
                                    </Card>
                                </Card.Section>
                            </Card>
                            <Card>
                                <Card.Section>
                                    <Heading element={"h1"}>Brand setup</Heading>
                                    <Card>
                                        <Card.Section title="Brand">
                                            <div className="partner-dash-spinner-wrapper">
                                                <Spinner size="large"/>
                                            </div>
                                        </Card.Section>
                                    </Card>
                                </Card.Section>
                            </Card>

                        </div>}
                        <div className="partner-dashboard-general-cards">

                            <Card>
                                <Card.Section title="Visits">
                                    <DisplayText size="large">{visits}</DisplayText>
                                </Card.Section>
                            </Card>
                            <Card>
                                <Card.Section title="Referrals">
                                    <DisplayText size="large">{referrals}</DisplayText>
                                </Card.Section>
                            </Card>
                            <Card>
                                <Card.Section title="Total earnings">
                                    <DisplayText size="large">{totalEarnings}</DisplayText>
                                </Card.Section>
                            </Card>
                            <Card>
                                <Card.Section title="Total paid">
                                    <DisplayText size="large">{totalPaid}</DisplayText>
                                </Card.Section>
                            </Card>
                        </div>
                        <div>
                            <Card>
                                <Card.Section title="Referred stores">
                                    <table className="partner-dash-tbl partner-dash-ref-stores">
                                        <thead>
                                        <tr>
                                            <th>Shop name</th>
                                            <th>Commission</th>
                                            <th>Last Commission</th>
                                            <th>Total Commission</th>
                                            <th>Discount</th>
                                            <th>Last Discount</th>
                                            <th>Total Discount</th>
                                            <th>Date Registered</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {referredStores && referredStores.length > 0 ?
                                            referredStores.map(rs => <tr key={rs.shop_name}>
                                                <td>{rs.shop_name}</td>
                                                <td>{rs.commission}%</td>
                                                <td>${rs.last_commission}</td>
                                                <td>${rs.total_commissions}</td>
                                                <td>{rs.discount}%</td>
                                                <td>${rs.last_discount}</td>
                                                <td>${rs.total_discount}</td>
                                                <td><Moment
                                                    format="YYYY/MM/DD">{rs.referral_offering_reg_date}</Moment>
                                                </td>
                                            </tr>) :
                                            <tr>
                                                 <td className="partner-dash-no-data" colSpan={8}>
                                                     { Number.isInteger(offeringValue) ? <span> No affiliate stores found </span> : <Spinner size="small"/> }
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                </Card.Section>
                            </Card>
                        </div>
                        <div>
                            <Card>
                                <Card.Section title="Payment history">
                                    <table className="partner-dash-tbl partner-dash-payment">
                                        <thead>
                                        <tr>
                                            <th>Paypal Email</th>
                                            <th>Paid Amount</th>
                                            <th>Payment Date</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        {partnerPayments && partnerPayments.length > 0 ?
                                            partnerPayments.map(pp => <tr key={pp.created_at}>
                                                <td>{pp.paypal_email}</td>
                                                <td>${pp.paid_amount}</td>
                                                <td><Moment format="YYYY/MM/DD">{pp.created_at}</Moment></td>
                                            </tr>) :
                                            <tr>
                                                <td className="partner-dash-no-data" colSpan={3}>
                                                    { Number.isInteger(offeringValue) ? <span>No data found</span> : <Spinner size="small"/> }
                                                </td>
                                            </tr>
                                        }
                                        </tbody>
                                    </table>
                                </Card.Section>
                            </Card>
                        </div>

                    </Card.Section>
                </Card>
            </div>
            <Modal open={changePasswordModalOpen} onClose={closeChangePasswordModal}
                   title="Change password"
                   primaryAction={{
                       content: 'Change',
                       loading: changingPassword,
                       onAction: changePassword,
                   }}
                   secondaryActions={[
                       {
                           content: 'Cancel',
                           onAction: closeChangePasswordModal,
                       },
                   ]}>
                <Modal.Section>
                    <TextField label="Old password" value={oldPassword} type={"password"} autoComplete={false}
                               onChange={onOldPasswordChange}/>
                    <TextField label="New password" value={newPassword} type={"password"} autoComplete={false}
                               onChange={onNewPasswordChange}/>
                </Modal.Section>
            </Modal>
            {toastActive && <Toast content={toastContent} onDismiss={onDismissToast}/>}
        </div>
    </div>;
}

export default PartnersDashboard;
