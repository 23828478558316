import { connect } from 'react-redux'
import AppV2WizardGetStarted from "../../../components/v2/wizard/AppV2WizardGetStarted";
import {appV2WizardNext, adminTrack} from "../../../actions/v2/appV2Wizard";
import {appV2CloseWizard} from "../../../actions/v2/appV2";
import {closeWizard, setWizardBool} from "../../../actions/data";

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch) => ({
    getStarted: () => {
        dispatch(appV2WizardNext());
        dispatch(adminTrack('onboarding_wizard_get_started', {}));
    },
    skip: () => {
        dispatch(appV2CloseWizard());
        dispatch(closeWizard());
        dispatch(setWizardBool(false));
        dispatch(adminTrack('onboarding_wizard_skip', {}));
        },
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppV2WizardGetStarted);
