import React from 'react'
import { Provider } from 'react-redux'
import App from './App'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import PropTypes from 'prop-types'
import PrivacyPolicy from "../components/PrivacyPolicy";
import WarrantyInfo from "../components/WarrantyInfo";
import { AppProvider } from "@shopify/polaris";
import enTranslations from '@shopify/polaris/locales/en.json';
import VisibleRedeem from "./VisibleRedeem";
import VisibleFeedback from "./feedback/VisibleFeedback";
import VisibleUnsubscribed from "./VisibleUnsubscribed";
import VisibleSubscribed from "./VisibleSubscribed";
import VisibleFeedbackLandingPage from "./feedback/VisibleFeedbackLandingPage";
import VisiblePartners from "./partners/VisiblePartners";
import VisibleRef from "./partners/VisibleRef";
import VisiblePartnersAdmin from "./partners/VisiblePartnersAdmin";
import VisibleSupportFeedback from "./feedback/VisibleSupportFeedback";
import VisibleInstall from "./VisibleInstall";
import VisibleAppV2 from "./v2/VisibleAppV2";
import VisibleRefBrand from "./partners/VisibleRefBrand";
import VisibleFreeShippingBar from "./free-shipping-bar/VisibleFreeShippingBar";
import {Provider as AppBridgeProvider} from '@shopify/app-bridge-react';
import ExitIframe from "./ExitIframe";

// import VisibleStickyAtc from "./sticky-atc/VisibleStickyAtc";
import VisibleStickyAtcDowntime from "./sticky-atc/VisibleStickyAtcDowntime";

const config = {
    apiKey: '878ee40e7b3300a1eea9cbb74df480fb',
    host: new URLSearchParams(window.location.search).get("host") || btoa('localhost'),
    forceRedirect: false
};

const Root = ({ store }) => (
    <AppBridgeProvider config={config}>
    <Provider store={store}>
        <Router>
            <AppProvider i18n={enTranslations}>
                <Switch>
                    <Route path="/v2/:shop/:token" component={VisibleAppV2} store={store}/>
                    <Route path="/sticky-atc/:shop/:token" component={VisibleStickyAtcDowntime} store={store}/>
                    <Route path="/free-shipping-bar/:shop/:token" component={VisibleFreeShippingBar} store={store}/>
                    <Route path="/install" component={VisibleInstall} store={store}/>
                    <Route path="/partners" component={VisiblePartners} store={store}/>
                    <Route path="/partners_admin/:sub/:secret" component={VisiblePartnersAdmin} store={store}/>
                    <Route path="/ref/:code/:brand" component={VisibleRefBrand} store={store}/>
                    <Route path="/ref/:code" component={VisibleRef} store={store}/>
                    <Route path="/support_feedback/:rating" component={VisibleSupportFeedback} store={store}/>
                    <Route path="/feedback_lp/:token" component={VisibleFeedbackLandingPage} store={store}/>
                    <Route path="/feedback/:token/:rating" component={VisibleFeedback} store={store}/>
                    <Route path="/unsubscribed_from_app/:app/:shop_id" component={VisibleUnsubscribed} store={store}/>
                    <Route path="/unsubscribed/:token" component={VisibleUnsubscribed} store={store}/>
                    <Route path="/subscribed_to_app/:app/:shop_id" component={VisibleSubscribed} store={store}/>
                    <Route path="/subscribed/:token" component={VisibleSubscribed} store={store}/>
                    <Route path="/rdm/:redeem_token" component={VisibleRedeem} store={store}/>
                    <Route path="/:storeName/:token/create_mapping/:action/:product_id" component={App} store={store} />
                    <Route path="/sub_app/:app_type/:storeName/:token" component={App} store={store} />
                    <Route path="/home/index" component={App} store={store}/>
                    <Route path="/:storeName/:token" component={App} store={store} />
                    <Route path="/front/exit_iframe" component={ExitIframe} />
                    <Route exact path="/privacy" component={PrivacyPolicy} />
                    <Route exact path="/warranty-info" component={WarrantyInfo} />
                    {/*<Route path="/sticky-atc/:shop/:token" component={VisibleStickyAtc} store={store}/>*/}
                </Switch>
            </AppProvider>
        </Router>
    </Provider>
    </AppBridgeProvider>
);

Root.propTypes = {
    store: PropTypes.object.isRequired
};

export default Root
