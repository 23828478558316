import { connect } from 'react-redux'
import ProductsSlider from "../../components/products/ProductsSlider";
import {
    cancel,
    removeSourceItem,
    changeSourceSearchText,
    chooseSourceProduct,
    chooseDestProducts,
    showSourceProducts,
    removeDestItem,
    chooseDestProduct,
    changeDestSearchText,
    moveDestItemUp,
    moveDestItemDown,
    changeMappingName,
    saveMapping,
    showRemoveMappingModal,
    closeRemoveMappingModal,
    removeMapping,
    addMappingAndSearch,
    showMapping,
    showGlobal,
    showInclude,
    backToMain,
    changeGlobalSearchText,
    chooseGlobalProduct,
    removeGlobalItem,
    removeIncludedItem,
    chooseIncludedProduct,
    saveIncluded,
    changeIncludedSearchText,
    onlyIncluded,
    dismissToast,
    changeSrcFilter,
    changeSrcFilterOption,
    toggleAddAllToSrcByFilterActive,
    addAllToSrcByFilter,
    toggleAddAllToDestByFilterActive,
    changeDestFilter,
    changeDestFilterOption,
    addAllToDestByFilter,
    addAnotherSrcFilter,
    changeSrcFilter2,
    changeSrcFilterOption2,
    addAnotherDestFilter,
    changeDestFilter2,
    changeDestFilterOption2,
    showOthersAfterGlobals,
    moveGlobalItemUp,
    moveGlobalItemDown,
    chooseMappingRules,
    showMappingRules,
    showGlobalRules,
    showExcluded,
    changeMappingTitle,
    mappingsSearchBoxTextChange,
    mappingSearchTypeChange,
    filterMappingByProduct,
    removeProductFilter,
    mappingSearchBoxFocus,
    toggleAddAllToGlobalByFilterActive,
    globalFilterChange,
    globalFilterOptionChange,
    addAnotherGlobalFilterChange,
    globalFilter2Change,
    globalFilter2OptionChange,
    addAllToGlobalByFilter,
    destinationDiscountChange,
    showImportMappings,
    changeMappingSubtitle,
    dynamicGroupingChange,
    sortDestProducts,
    closeSortDestProductsModal,
    moveTempDestItemUp,
    moveTempDestItemDown,
    sortDestProductsDone,
    sortGlobalProducts,
    closeSortGlobalProductsModal,
    sortGlobalProductsDone,
    moveTempGlobalItemUp,
    moveTempGlobalItemDown,
    minItemsForDiscountEnabledChanged,
    minItemsForDiscountChange,
    maxItemsForDiscountEnabledChanged,
    maxItemsForDiscountChange,
    chooseDestVariants,
    closeChooseDestVariantsModal,
    destVariantChecked,
    chooseDestVariantsDone,
    chooseSourceVariants,
    closeChooseSourceVariantsModal,
    sourceVariantChecked,
    chooseSourceVariantsDone,
    onChooseGlobalVariants,
    closeChooseGlobalVariantsModal,
    chooseGlobalVariantsDone,
    onGlobalVariantChecked,
    moveMappingItemAction,
    editMappingApiCall,
    randomizeChange,
    switchSourceDest,
    saveGlobals,
    deleteGlobalsAndSave,
    dupMapping,
    productSliderDismissDynamicToast,
    onEnableDisableMapping, mappingPreviewOffer
} from "../../actions/products";

const mapStateToProps = state => {
    return {
        isShowMappings: state.products.isShowMappings,
        isShowChooseSourceProducts: state.products.isShowChooseSourceProducts,
        mappings: state.products.mappings,
        sourceProducts: state.products.sourceProducts,
        destProducts: state.products.destProducts,
        searchBoxText: state.products.searchBoxText,
        searchedProducts: state.products.searchedProducts,
        isShowChooseDestProducts: state.products.isShowChooseDestProducts,
        sourceProductsEmptyError: state.products.sourceProductsEmptyError,
        destProductsEmptyError: state.products.destProductsEmptyError,
        isShowChooseMappingName: state.products.isShowChooseMappingName,
        mappingName: state.products.mappingName,
        saveError: state.products.saveError,
        errorEmptyName: state.products.errorEmptyName,
        removeMappingModalOpen: state.products.removeMappingModalOpen,
        removeMappingSpinner: state.products.removeMappingSpinner,
        removeMappingError: state.products.removeMappingError,
        isSyncing: state.products.isSyncing,
        showSearchSpinner: state.products.showSearchSpinner,
        searchError: state.products.searchError,
        isSavingMapping: state.products.isSavingMapping,
        isShowMain: state.products.isShowMain,
        isShowGlobal: state.products.isShowGlobal,
        globalProducts: state.products.globalProducts,
        isShowIncluded: state.products.isShowIncluded,
        isSavingIncluded: state.products.isSavingIncluded,
        includedProducts: state.products.includedProducts,
        isOnlySource: state.products.isOnlySource,
        isSubmittingOnlyIncluded: state.products.isSubmittingOnlyIncluded,
        toastIsActive: state.products.toastIsActive,
        isFetchingProduct: state.products.isFetchingProduct,
        srcFilter: state.products.srcFilter,
        srcFilter2: state.products.srcFilter2,
        srcFilterOptions: state.products.srcFilterOptions,
        srcFilterOptions2: state.products.srcFilterOptions2,
        srcFilterOption: state.products.srcFilterOption,
        srcFilterOption2: state.products.srcFilterOption2,
        addAllToSrcByFilterActive: state.products.addAllToSrcByFilterActive,
        isAddingAllToSrcByFilter: state.products.isAddingAllToSrcByFilter,
        addAllToDestByFilterActive: state.products.addAllToDestByFilterActive,
        destFilter: state.products.destFilter,
        destFilterOptions: state.products.destFilterOptions,
        destFilterOption: state.products.destFilterOption,
        isAddingAllToDestByFilter: state.products.isAddingAllToDestByFilter,
        isAddAnotherSrcFilter: state.products.isAddAnotherSrcFilter,
        isAddAnotherDestFilter: state.products.isAddAnotherDestFilter,
        destFilter2: state.products.destFilter2,
        destFilterOptions2: state.products.destFilterOptions2,
        destFilterOption2: state.products.destFilterOption2,
        isShowOthersAfterGlobals: state.products.isShowOthersAfterGlobals,
        isSubmittingShowOthersAfterGlobals: state.products.isSubmittingShowOthersAfterGlobals,
        isShowChooseMappingRules: state.products.isShowChooseMappingRules,
        isShowGlobalRules: state.products.isShowGlobalRules,
        currencySymbol: state.products.currencySymbol,
        isShowExcluded: state.products.isShowExcluded,
        mappingTitle: state.products.mappingTitle,
        mappingsSearchBoxText: state.products.mappingsSearchBoxText,
        mappingSearchType: state.products.mappingSearchType,
        mappingsResults: state.products.mappingsResults,
        mappingsProductFilter: state.products.mappingsProductFilter,
        addAllToGlobalByFilterActive: state.products.addAllToGlobalByFilterActive,
        globalFilter: state.products.globalFilter,
        globalFilterOptions: state.products.globalFilterOptions,
        globalFilterOption: state.products.globalFilterOption,
        isAddAnotherGlobalFilter: state.products.isAddAnotherGlobalFilter,
        globalFilter2: state.products.globalFilter2,
        globalFilterOptions2: state.products.globalFilterOptions2,
        globalFilterOption2: state.products.globalFilterOption2,
        isAddingAllToGlobalByFilter: state.products.isAddingAllToGlobalByFilter,
        destinationDiscount: state.products.destinationDiscount,
        isShowImport: state.products.isShowImport,
        mappingSubtitle: state.products.mappingSubtitle,
        isDynamicGrouping: state.products.isDynamicGrouping,
        sortDestProductsModalOpen: state.products.sortDestProductsModalOpen,
        tempSortDestItem: state.products.tempSortDestItem,
        exceedMaxDestProducts: state.products.exceedMaxDestProducts,
        maxProducts: state.products.maxProducts,
        tempSortGlobalItem: state.products.tempSortGlobalItem,
        sortGlobalProductsModalOpen: state.products.sortGlobalProductsModalOpen,
        globalProductsLength: state.products.globalProductsLength,
        excludedProductsLength: state.products.excludedProductsLength,
        minItemsForDiscountEnabled: state.products.minItemsForDiscountEnabled,
        minItemsForDiscount: state.products.minItemsForDiscount,
        maxItemsForDiscountEnabled: state.products.maxItemsForDiscountEnabled,
        maxItemsForDiscount: state.products.maxItemsForDiscount,
        isChooseDestVariantsModalOpen: state.products.isChooseDestVariantsModalOpen,
        tempVariants: state.products.tempVariants,
        isChooseSourceVariantsModalOpen: state.products.isChooseSourceVariantsModalOpen,
        isChooseGlobalVariantsModalOpen: state.products.isChooseGlobalVariantsModalOpen,
        editMappingLoading: state.products.editMappingLoading,
        productSearchDone: state.products.productSearchDone,
        randomizeDestProducts: state.products.randomizeDestProducts,
        switchingSourceDest: state.products.switchingSourceDest,
        dupMappingLoading: state.products.dupMappingLoading,
        dynamicToastIsActive: state.products.dynamicToastIsActive,
        dynamicToastError: state.products.dynamicToastError,
        dynamicToastContent: state.products.dynamicToastContent,
        enableMappingLoading: state.products.enableMappingLoading,
    };
};

const mapDispatchToProps = (dispatch) => ({
    onAddMapping: () => dispatch(addMappingAndSearch()),
    onSaveGlobals: () => dispatch(saveGlobals()),
    onDeleteGlobals: () => dispatch(deleteGlobalsAndSave()),
    onEditMapping: (id) => () => dispatch(editMappingApiCall(id)),
    switchSourceDest: id => () => dispatch(switchSourceDest(id)),
    onDupMapping: id => () => dispatch(dupMapping(id)),
    removeSourceItem: (itemId) => () => dispatch(removeSourceItem(itemId)),
    removeDestItem: (itemId) => () => dispatch(removeDestItem(itemId)),
    removeIncludedItem: (itemId) => () => dispatch(removeIncludedItem(itemId)),
    removeGlobalItem: (itemId) => () => dispatch(removeGlobalItem(itemId)),
    addSourceItem: (id) => () => dispatch(chooseSourceProduct(id)),
    addGlobalItem: (id) => () => dispatch(chooseGlobalProduct(id)),
    addDestItem: (id) => () => dispatch(chooseDestProduct(id)),
    addIncludedItem: (id) => () => dispatch(chooseIncludedProduct(id)),
    moveDestItemUp: (id) => () => dispatch(moveDestItemUp(id)),
    moveGlobalItemUp: (id) => () => dispatch(moveGlobalItemUp(id)),
    moveDestItemDown: (id) => () => dispatch(moveDestItemDown(id)),
    moveGlobalItemDown: (id) => () => dispatch(moveGlobalItemDown(id)),
    showRemoveMappingModal: (id) => () => dispatch(showRemoveMappingModal(id)),
    filterMappingByProduct: id => () => dispatch(filterMappingByProduct(id)),
    sortDestProducts: id => () => dispatch(sortDestProducts(id)),
    sortGlobalProducts: id => () => dispatch(sortGlobalProducts(id)),
    moveTempDestItemUp: id => () => dispatch(moveTempDestItemUp(id)),
    moveTempGlobalItemUp: id => () => dispatch(moveTempGlobalItemUp(id)),
    moveTempDestItemDown: id => () => dispatch(moveTempDestItemDown(id)),
    moveTempGlobalItemDown: id => () => dispatch(moveTempGlobalItemDown(id)),
    onChooseDestVariants: id => () => dispatch(chooseDestVariants(id)),
    onChooseSourceVariants: id => () => dispatch(chooseSourceVariants(id)),
    onChooseGlobalVariants: id => () => dispatch(onChooseGlobalVariants(id)),
    onDestVariantChecked: id => bool => dispatch(destVariantChecked(id, bool)),
    onSourceVariantChecked: id => bool => dispatch(sourceVariantChecked(id, bool)),
    onGlobalVariantChecked: id => bool => dispatch(onGlobalVariantChecked(id, bool)),
    onEnableMapping: id => () => dispatch(onEnableDisableMapping(id)),
    previewOffer: id => () => dispatch(mappingPreviewOffer(id)),
    cancel: () => dispatch(cancel()),
    onShowChooseDest: () => dispatch(chooseDestProducts()),
    showSourceProducts: () => dispatch(showSourceProducts()),
    chooseMappingRules: () => dispatch(chooseMappingRules()),
    showMappingRules: () => dispatch(showMappingRules()),
    closeRemoveMappingModal: () => dispatch(closeRemoveMappingModal()),
    closeChooseDestVariantsModal: () => dispatch(closeChooseDestVariantsModal()),
    closeChooseSourceVariantsModal: () => dispatch(closeChooseSourceVariantsModal()),
    closeChooseGlobalVariantsModal: () => dispatch(closeChooseGlobalVariantsModal()),
    chooseDestVariantsDone: () => dispatch(chooseDestVariantsDone()),
    chooseSourceVariantsDone: () => dispatch(chooseSourceVariantsDone()),
    chooseGlobalVariantsDone: () => dispatch(chooseGlobalVariantsDone()),
    removeMapping: () => dispatch(removeMapping()),
    onSave: () => dispatch(saveMapping()),
    onSaveIncluded: () => dispatch(saveIncluded()),
    onChangeSourceSearchText: (text) => dispatch(changeSourceSearchText(text)),
    onChangeGlobalSearchText: (text) => dispatch(changeGlobalSearchText(text)),
    onChangeIncludedSearchText: (text) => dispatch(changeIncludedSearchText(text)),
    onFocusSourceSearchText: () => dispatch(changeSourceSearchText('')),
    onFocusGlobalSearchText: () => dispatch(changeGlobalSearchText('')),
    onFocusDestSearchText: () => dispatch(changeDestSearchText('')),
    onFocusIncludedSearchText: () => dispatch(changeIncludedSearchText('')),
    onChangeDestSearchText: (text) => dispatch(changeDestSearchText(text)),
    onChangeMappingName: (text) => dispatch(changeMappingName(text)),
    onChangeMappingTitle: text => dispatch(changeMappingTitle(text)),
    onChangeMappingSubtitle: text => dispatch(changeMappingSubtitle(text)),
    onSrcFilterChange: text => dispatch(changeSrcFilter(text)),
    onSrcFilterChange2: text => dispatch(changeSrcFilter2(text)),
    onDestFilterChange: text => dispatch(changeDestFilter(text)),
    onDestFilterChange2: text => dispatch(changeDestFilter2(text)),
    onGlobalFilterChange: text => dispatch(globalFilterChange(text)),
    onGlobalFilterChange2: text => dispatch(globalFilter2Change(text)),
    onSrcFilterOptionChange: text => dispatch(changeSrcFilterOption(text)),
    onSrcFilterOptionChange2: text => dispatch(changeSrcFilterOption2(text)),
    onDestFilterOptionChange: text => dispatch(changeDestFilterOption(text)),
    onDestFilterOptionChange2: text => dispatch(changeDestFilterOption2(text)),
    onGlobalFilterOptionChange: text => dispatch(globalFilterOptionChange(text)),
    onGlobalFilterOptionChange2: text => dispatch(globalFilter2OptionChange(text)),
    onMappingsSearchBoxTextChange: text => dispatch(mappingsSearchBoxTextChange(text)),
    onMappingSearchTypeChange: text => dispatch(mappingSearchTypeChange(text)),
    onDestinationDiscountChange: num => dispatch(destinationDiscountChange(num)),
    onMinItemsForDiscountChange: num => dispatch(minItemsForDiscountChange(num)),
    onMaxItemsForDiscountChange: num => dispatch(maxItemsForDiscountChange(num)),
    onShowMapping: () => dispatch(showMapping()),
    onShowImportMappings: () => dispatch(showImportMappings()),
    onShowExcluded: () => dispatch(showExcluded()),
    onShowGlobal: () => dispatch(showGlobal()),
    onShowInclude: () => dispatch(showInclude()),
    backToMain: () => dispatch(backToMain()),
    onShowGlobalRules: () => dispatch(showGlobalRules()),
    onOnlyIncludedChanged: bool => dispatch(onlyIncluded(bool)),
    onShowOthersAfterGlobalsChanged: bool => dispatch(showOthersAfterGlobals(bool)),
    onDynamicGroupingChange: bool => dispatch(dynamicGroupingChange(bool)),
    onMinItemsForDiscountEnabledChanged: bool => dispatch(minItemsForDiscountEnabledChanged(bool)),
    onMaxItemsForDiscountEnabledChanged: bool => dispatch(maxItemsForDiscountEnabledChanged(bool)),
    onRandomizeChange: bool => dispatch(randomizeChange(bool)),
    onDismissToast: () => dispatch(dismissToast()),
    onDismissDynamicToast: () => dispatch(productSliderDismissDynamicToast()),
    toggleAddAllToSrcByFilterActive: () => dispatch(toggleAddAllToSrcByFilterActive()),
    toggleAddAllToGlobalByFilterActive: () => dispatch(toggleAddAllToGlobalByFilterActive()),
    toggleAddAllToDestByFilterActive: () => dispatch(toggleAddAllToDestByFilterActive()),
    onAddAllToSrcByFilter: () => dispatch(addAllToSrcByFilter()),
    onAddAllToDestByFilter: () => dispatch(addAllToDestByFilter()),
    onAddAllToGlobalByFilter: () => dispatch(addAllToGlobalByFilter()),
    onAddAnotherSrcFilterChange: () => dispatch(addAnotherSrcFilter()),
    onAddAnotherDestFilterChange: () => dispatch(addAnotherDestFilter()),
    onAddAnotherGlobalFilterChange: bool => dispatch(addAnotherGlobalFilterChange(bool)),
    removeProductFilter: () => dispatch(removeProductFilter()),
    onMappingSearchBoxFocus: () => dispatch(mappingSearchBoxFocus()),
    closeSortDestProductsModal: () => dispatch(closeSortDestProductsModal()),
    closeSortGlobalProductsModal: () => dispatch(closeSortGlobalProductsModal()),
    sortDestProductsDone: () => dispatch(sortDestProductsDone()),
    sortGlobalProductsDone: () => dispatch(sortGlobalProductsDone()),
    moveMappingItem: (direction, index) => () => dispatch(moveMappingItemAction(direction, index)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductsSlider);
