import React from 'react';
import '../../css/TextsMain.css'
import {
    Button,
    Select,
    Spinner,
    TextStyle,
    Frame,
    Toast,
    Form,
    FormLayout,
    Modal, ProgressBar,
} from "@shopify/polaris";
import VisibleTextsSlider from "../../containers/texts/VisibleTextsSlider";
import VisibleTextsBundle from "../../containers/texts/VisibleTextsBundle";
import VisibleTextsWarranty from "../../containers/texts/VisibleTextsWarranty";
import VisibleTextsMinicart from "../../containers/texts/VisibleTextsMinicart";
import VisibleTextsVolume from "../../containers/texts/VisibleTextsVolume";
import VisibleTextsMixAndMatch from "../../containers/texts/VisibleTextsMixAndMatch";

const TextsMain = ({ onSliderClicked, onBundleClicked, isSliderVisible,
    isBundleVisible, isWarrantyVisible, onWarrantyClicked, onCartDrawerClicked,
    isCartDrawerVisible, translations, locales, onLocaleChange, locale, loadTranslations, save, saving, savingError,
    translationsSavedToastIsActive, onDismissTranslationsSavedToast, openAddLocaleModal, addLocaleModalOpen,
    closeAddLocaleModal, addLocale, newLocaleOptions, onNewLocaleChange, desiredNewLocale, copyFromOptions,
    onCopyFromChange, copyFromLocale, addingLocale, openRemoveLocaleModal, removeLocaleModalOpen, closeRemoveLocaleModal,
    removeLocale, removingLocale, manuallyAddedLocales, syncProductTranslations, syncProductTranslationsProgress,
    isVolumeVisible, onVolumeClicked, appType , isMixAndMatchVisible, onMixAndMatchClicked}) => {

    loadTranslations();
    return <Form>
            <FormLayout>
                <Frame>
                    <div className="translations-wrapper">
                        {!appType && <div className="slider settings">
                            <div className={'sidebar-item' + (isSliderVisible ? ' selected' : '')}
                                 onClick={onSliderClicked}>Slider
                            </div>
                            <div className={'sidebar-item' + (isBundleVisible ? ' selected' : '')}
                                 onClick={onBundleClicked}>Bundle
                            </div>
                            <div className={'sidebar-item' + (isWarrantyVisible ? ' selected' : '')}
                                 onClick={onWarrantyClicked}>Warranty
                            </div>
                            <div className={'sidebar-item' + (isCartDrawerVisible ? ' selected' : '')}
                                 onClick={onCartDrawerClicked}>Cart Drawer
                            </div>
                            <div className={'sidebar-item' + (isVolumeVisible ? ' selected' : '')}
                                 onClick={onVolumeClicked}>Volume Discount
                            </div>
                            <div className={'sidebar-item' + (isMixAndMatchVisible ? ' selected' : '')}
                                 onClick={onMixAndMatchClicked}>Mix & Match
                            </div>
                        </div>}
                    <div className={`translations-content-wrapper ${translations && 'texts-loaded'}`}>
                        {translations ? <div>
                            {savingError && <TextStyle variation="negative">There was an error sending your request.
                                Please try again in a few minutes</TextStyle>}

                            {syncProductTranslationsProgress < 100 &&
                                <div className="sync-products-progress-bar"><ProgressBar
                                    progress={syncProductTranslationsProgress} size={"small"} /></div>}
                            <div className="zrx-tab-wrapper-thin">
                                <div className="zrx-tab-title">
                                    <span> {isSliderVisible ? 'Slider' : ''} </span>
                                    <span> {isBundleVisible ? 'Bundle' : ''} </span>
                                    <span> {isWarrantyVisible ? 'Warranty' : ''} </span>
                                    <span> {isCartDrawerVisible ? 'Cart Drawer' : ''} </span>
                                    <span> {isVolumeVisible ? 'Volume Discount' : ''} </span>
                                    Texts</div>
                                <div className="zrx-tab-subtitle">Manage the texts and the tranlsation of the
                                    <span> {isSliderVisible ? 'slider' : ''} </span>
                                    <span> {isBundleVisible ? 'bundle' : ''} </span>
                                    <span> {isWarrantyVisible ? 'warranty' : ''} </span>
                                    <span> {isCartDrawerVisible ? 'cart drawer' : ''} </span>
                                    <span> {isVolumeVisible ? 'volume discount' : ''} </span>
                                    widget</div>
                            </div>
                            <div className="locales-mgmt">

                                {<span className="local-select">
                            <Select options={locales} onChange={onLocaleChange} value={locale} disabled={saving}/>
                        </span>}
                                {<span className="add-locale-button"><Button
                                    onClick={openAddLocaleModal}>Add locale</Button></span>}
                                {manuallyAddedLocales && manuallyAddedLocales.indexOf(locale) >= 0 &&
                                <span className="remove-locale-button"><Button onClick={openRemoveLocaleModal}>Remove this locale</Button></span>}
                                {locales.length > 1 &&
                                <span className="sync-products-button"><Button onClick={syncProductTranslations}
                                                                               loading={syncProductTranslationsProgress < 100}
                                >Sync product translations</Button></span>}
                            </div>
                            {isSliderVisible && <VisibleTextsSlider/>}
                            {isBundleVisible && <VisibleTextsBundle/>}
                            {isWarrantyVisible && <VisibleTextsWarranty/>}
                            {isCartDrawerVisible && <VisibleTextsMinicart/>}
                            {isVolumeVisible && <VisibleTextsVolume/>}
                            {isMixAndMatchVisible && <VisibleTextsMixAndMatch/>}
                            <div className="save-button">
                                <Button id="save-settings" onClick={save} primary loading={saving}>Save</Button>
                            </div>
                        </div> : <span className="spinner-texts"><Spinner/></span>}</div>
                        {translationsSavedToastIsActive &&
                        <Toast content="Texts saved" onDismiss={onDismissTranslationsSavedToast}/>}
                    </div>
                </Frame>
            </FormLayout>
            <Modal open={addLocaleModalOpen} onClose={closeAddLocaleModal}
                   title="Add locale"
                   primaryAction={{
                       content: 'Add',
                       onAction: addLocale,
                       loading: addingLocale,
                   }}
                   secondaryActions={[
                       {
                           content: 'Cancel',
                           onAction: closeAddLocaleModal,
                       },
                   ]}>
                <Modal.Section>
                    <Select label="New locale:" options={newLocaleOptions} onChange={onNewLocaleChange}
                            value={desiredNewLocale}/>
                    <Select label="Copy texts from:" options={copyFromOptions} onChange={onCopyFromChange}
                            value={copyFromLocale}/>
                </Modal.Section>
            </Modal>
            <Modal open={removeLocaleModalOpen} onClose={closeRemoveLocaleModal}
                   title="Remove locale"
                   primaryAction={{
                       content: 'Remove',
                       onAction: removeLocale,
                       loading: removingLocale,
                   }}
                   secondaryActions={[
                       {
                           content: 'Cancel',
                           onAction: closeRemoveLocaleModal,
                       },
                   ]}>
                <Modal.Section>
                    Are you sure you want to remove all texts for the locale
                    "{locales && locales.filter(l => l.value === locale)[0].label}"? <br/>
                    This action cannot be undone!
                </Modal.Section>
            </Modal>
        </Form>
    };

export default TextsMain;
