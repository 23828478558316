import { connect } from 'react-redux'
import AppV2WizardConfigVolume from "../../../components/v2/wizard/AppV2WizardConfigVolume";
import {
    wizardVolumeAddTier,
    wizardVolumeDiscountChange,
    wizardVolumeDiscountTypeChange, wizardVolumePopularChange,
    wizardVolumeQtyChange, wizardVolumeTrashTier
} from "../../../actions/v2/appV2Wizard";

const mapStateToProps = state => ({
    appV2Wizard: state.appV2Wizard,
});

const mapDispatchToProps = (dispatch) => ({
    onQtyChange: idx => qty => dispatch(wizardVolumeQtyChange(idx, qty)),
    onDiscountTypeChange: idx => text => dispatch(wizardVolumeDiscountTypeChange(idx, text)),
    onDiscountChange: idx => text => dispatch(wizardVolumeDiscountChange(idx, text)),
    onPopularChange: idx => event => dispatch(wizardVolumePopularChange(idx, event.target.checked)),
    trash: idx => () => dispatch(wizardVolumeTrashTier(idx)),
    addTier: () => dispatch(wizardVolumeAddTier()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppV2WizardConfigVolume);
