import React from 'react';
import { Button, Icon, Modal, Spinner, TextContainer, TextStyle } from "@shopify/polaris";
import NoImage from "./NoImage";
import '../../css/ProductsWarranty.css'
import { DeleteMajor } from "@shopify/polaris-icons";

const Warranties = ({ onCreateWarranty, warranties, currencySymbol, editWarranty, removeWarranty, removeWarrantyModalOpen,
                        closeRemoveWarrantyModal, removeWarrantyAction, removeWarrantySpinner, warrantyName,
                        removeWarrantyError, onCreateBulkWarranty, bulkWarranties, tagsCount, deletableBulkWarranties,
                        removeBulkWarranty, removeBulkWarrantyModalOpen, closeRemoveBulkWarrantyModal,
                        removeBulkWarrantySpinner, removeBulkWarrantyAction, removeBulkWarrantyError}) => (
        <div className='warranties'>
            <div className="create-warranty-button-wrapper">
                <span className="create-warranty-button">
                    <Button onClick={onCreateWarranty} primary>Create warranty</Button>
                </span>
                {false && <Button onClick={onCreateBulkWarranty} primary>Create bulk warranty</Button>}
            </div>
            {((bulkWarranties && bulkWarranties.length > 0) || (warranties && warranties.length > 0)) && <div className="warranty-list-item header">
                <div className="warranty-name">Warranty name</div>
                <div className="warranty-price">Price</div>
                <div className="warranty-percentage">Percentage</div>
                <div className="warranty-product-title">Covered product</div>
                <div className="warranty-product-title">Image</div>
            </div> }
            {bulkWarranties && bulkWarranties.map(bulkWarranty => (
                <div className="warranty-list-item" key={bulkWarranty.id}>
                    {bulkWarranty.filter_type === 'tag' &&  <div className="warranty-name">Tag: {bulkWarranty.type_value}</div>}
                    {bulkWarranty.filter_type === 'smart-collection' && <div className="warranty-name">Smart collection: {bulkWarranty.type_value}</div>}
                    {bulkWarranty.filter_type === 'custom-collection' && <div className="warranty-name">Custom collection: {bulkWarranty.type_value}</div>}
                    <span className="warranty-bulk-item-price">{bulkWarranty.price_type === 'fixed' &&
                        <span>{bulkWarranty.price}{bulkWarranty.price && currencySymbol}</span>}</span>
                    <span className="warranty-bulk-item-percentage">{(!bulkWarranty.price_type || bulkWarranty.price_type === 'percentage') &&
                    <span>{bulkWarranty.percentage}%</span>}</span>
                    <span className="warranty-product-title">{Number.isInteger(bulkWarranty.count) && <span>{bulkWarranty.count} products</span>}</span>
                    {bulkWarranty.is_deleting ? (
                        <span className="in-progress-warranty-wrapper"><Spinner size="small" /> <span  className="in-progress-warranty">Deleting...</span></span>
                    ) : (

                        <span className="warranty-list-buttons bulk">
                            {deletableBulkWarranties[bulkWarranty.id] ? (
                                <span>
                                    <span onClick={removeBulkWarranty(bulkWarranty.id)} className="remove-item remove-bulk-warranty">
                                        <Icon source={DeleteMajor} />
                                    </span>
                                </span>
                            ) : (
                                <span className="in-progress-warranty-wrapper"><Spinner size="small" /><span className="in-progress-warranty">Creating...</span></span>
                            )}
                        </span>
                    )}
                </div>
            ))}
            {warranties && warranties.map((warranty, index) => (
                <div className="warranty-list-item" key={warranty.id}>
                    <div className="warranty-image"
                         style={{ 'backgroundImage': 'url(' + warranty.image_src + ')' }} />
                    <div className="warranty-name">{warranty.name}</div>
                    <div className="warranty-price">{warranty.price}{currencySymbol}</div>
                    <div className="warranty-percentage">{warranty.percentage}{warranty.percentage && '%'}</div>
                    <div className="warranty-product-title">{warranty.product.title}</div>
                    <div className="warranty-product-image">
                        {warranty.product.image_src ?
                            <div className="warranty-product-image"
                                style={{ 'backgroundImage': 'url(' + warranty.product.image_src + ')' }} />
                        :
                                <NoImage itemId={warranty.product.shopify_product_id} klassName={'warranty-product-image'} />
                        }
                    </div>

                    <span className="warranty-list-buttons">
                        <span className="warranty-edit-button">
                            <Button onClick={editWarranty(warranty.id)}>Edit</Button>
                        </span>
                        <span onClick={removeWarranty(warranty.id)} className="remove-item">
                            <Icon source={DeleteMajor} />
                        </span>
                    </span>
                </div>
            ))}

            <Modal open={removeWarrantyModalOpen} onClose={closeRemoveWarrantyModal}
                title="Delete warranty"
                primaryAction={{
                    content: 'Remove',
                    onAction: removeWarrantyAction,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: closeRemoveWarrantyModal,
                    },
                ]}>
                <Modal.Section>
                    {removeWarrantySpinner ? (
                        <span className="spinner-wrapper">
                            <Spinner />
                        </span>
                    ) : <TextContainer>
                            <p>
                                Are you sure you want to delete warranty <b>{warrantyName}</b>?
                    </p>
                            {removeWarrantyError && <TextStyle variation="negative">Something went wrong</TextStyle>}
                        </TextContainer>}

                </Modal.Section>
            </Modal>
            <Modal open={removeBulkWarrantyModalOpen} onClose={closeRemoveBulkWarrantyModal}
                title="Delete bulk warranty"
                primaryAction={{
                    content: 'Remove',
                    onAction: removeBulkWarrantyAction,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: closeRemoveBulkWarrantyModal,
                    },
                ]}>
                <Modal.Section>
                    {removeBulkWarrantySpinner ? (
                        <span className="spinner-wrapper">
                            <Spinner />
                        </span>
                    ) : <TextContainer>
                            <p>
                                Are you sure you want to delete the bulk warranty?
                            </p>
                            {removeBulkWarrantyError && <TextStyle variation="negative">Something went wrong</TextStyle>}
                        </TextContainer>}

                </Modal.Section>
            </Modal>
        </div>
    );

export default Warranties;
