import React from 'react';
import '../../css/Partners.css'
import { TextField, Button, Heading } from "@shopify/polaris";

const PartnersLogin = ({ email, password, onEmailChanged, onPasswordChanged, onLogin, loggingIn, onSignUp, navState,
    onBack, onNavToSignUp, signingUp, verificationCode, onVerificationCodeChange,
    onVerifyCode, verifyingCode, onForgotPassword, onSendForgotPasswordCode,
    sendingForgotPasswordCode, onSetNewPassword }) => {

    return <div className="partner-dashboard">

        {navState === 'login' && <div className="partner-container">
            <div className="partner-welcome">
                <img alt="zoorix default" className="partner-welcome-image" src="https://public.zoorix.com/images/zoorix-partner-logo.png" />
                <div className="partner-welcome-text">Welcome to Zoorix</div>
            </div>
            <div className='partner-login-wrapper'>
                <Heading>Login</Heading>
                <span className='existing-partners'>(Don't have a user? <Button plain onClick={onNavToSignUp}>Register</Button>)</span>
            </div>
            <TextField label="Email" value={email} onChange={onEmailChanged} autoComplete={true} disabled={loggingIn} />
            <TextField label="Password" value={password} onChange={onPasswordChanged} autoComplete={true}
                type="password" disabled={loggingIn} />
            <div className="partner-buttons">
                <Button primary onClick={onLogin} loading={loggingIn}>Login</Button>
                <Button onClick={onForgotPassword}>Forgot password</Button>
                <Button plain onClick={onNavToSignUp}>New partner? Register</Button>
            </div>
        </div>
        }

        {
            navState === 'login_signup' && <div className="partner-container">
                <div className="partner-welcome">
                    <img alt="zoorix default" className="partner-welcome-image" src="https://public.zoorix.com/images/zoorix-partner-logo.png" />
                    <div className="partner-welcome-text">Welcome to Zoorix</div>
                </div>
                <div>
                    <div className='partner-login-wrapper'>

                        <Heading>Register</Heading>
                        <span className='existing-partners'>(For new partners)</span>
                    </div>

                    <TextField label="New Partner Email" value={email} onChange={onEmailChanged} autoComplete={true} disabled={signingUp} />
                    <TextField label="New Password" value={password} onChange={onPasswordChanged} autoComplete={false}
                        type="password" disabled={signingUp} />
                    <div className="partner-buttons">
                        <Button primary onClick={onSignUp} loading={signingUp}>Register</Button>
                        <Button onClick={onBack}>Back</Button>
                    </div>
                </div>
            </div>
        }

        {
            navState === 'login_verify_code' && <div className="partner-container">
                <div className="partner-welcome">
                    <img alt="zoorix default" className="partner-welcome-image" src="https://public.zoorix.com/images/zoorix-partner-logo.png" />
                    <div className="partner-welcome-text">Welcome to Zoorix</div>
                </div>
                <Heading>Verify code</Heading>
                <TextField label="Code" value={verificationCode} onChange={onVerificationCodeChange} autoComplete={false} />
                <div className="partner-buttons">
                    <Button primary onClick={onVerifyCode} loading={verifyingCode}>Verify</Button>
                </div>
            </div>
        }

        {
            navState === 'login_forgot_password' && <div className="partner-container">
                <div className="partner-welcome">
                    <img alt="zoorix default" className="partner-welcome-image" src="https://public.zoorix.com/images/zoorix-partner-logo.png" />
                    <div className="partner-welcome-text">Welcome to Zoorix</div>
                </div>
                <Heading>Forgot password</Heading>
                <TextField label="Email" value={email} onChange={onEmailChanged} autoComplete={true}
                    disabled={sendingForgotPasswordCode} />
                <div className="partner-buttons">
                    <Button primary onClick={onSendForgotPasswordCode} loading={sendingForgotPasswordCode}>Send code</Button>
                    <Button onClick={onBack}>Back</Button>
                </div>
            </div>
        }

        {
            navState === 'login_forgot_password_verify_code' && <div className="partner-container">
                <div className="partner-welcome">
                    <img alt="zoorix default" className="partner-welcome-image" src="https://public.zoorix.com/images/zoorix-partner-logo.png" />
                    <div className="partner-welcome-text">Welcome to Zoorix</div>
                </div>
                <Heading>Forgot password</Heading>
                <div className="partner-dash-verify-msg">We sent you a verification code to your email</div>
                <TextField label="Code" value={verificationCode} onChange={onVerificationCodeChange} autoComplete={false}
                    disabled={verifyingCode} />
                <TextField label="New password" value={password} onChange={onPasswordChanged} autoComplete={false}
                    type="password" disabled={verifyingCode} />
                <div className="partner-buttons">
                    <Button primary onClick={onSetNewPassword} loading={verifyingCode}>Set Password</Button>
                    <Button onClick={onBack}>Back</Button>
                </div>
            </div>
        }
    </div >;
}

export default PartnersLogin;
