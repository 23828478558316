import React from 'react';
import '../../css/sticky-atc/StickyAtc.scss';


const StickyAtcDowntime = () => {
    return <div className="maintanage-message-wrapper"><div className="maintanage-message">
        <div className="maintanage-message-header">System Maintanance</div>
        <div className="maintanage-message-content">The system is currently undergoing a routine maintanance which is expected to continue for few hours at most</div>
        <div className="maintanage-message-contact-us">For any question please contact <a href = "mailto:support@zoorix.com">support@zoorix.com</a></div>
    </div></div>
};

export default StickyAtcDowntime;
