import React from 'react';
import VisibleAnalytics from "../../containers/analytics/VisibleAnalytics";
import {Button, DatePicker, FormLayout, Popover, Select, Spinner, Modal, TextField, Toast} from "@shopify/polaris";
import '../../css/Analytics.css'
import VisibleAnalyticsMappings from "../../containers/analytics/VisibleAnalyticsMappings";
import VisibleAnalyticsBundles from "../../containers/analytics/VisibleAnalyticsBundles";
import VisibleAnalyticsVolumeDiscounts from "../../containers/analytics/VisibleAnalyticsVolumeDiscounts";
import VisibleAnalyticsOrders from "../../containers/analytics/VisibleAnalyticsOrders";
import VisibleAnalyticsProducts from "../../containers/analytics/VisibleAnalyticsProducts";

const AnalyticsMain = ({visibleTab, isSelectDateActive, toggleSelectDateActive, onDateRangePresetChange, dateRangePreset,
                           dateRangeMonth, dateRangeYear, onDateRangeChange, onDateRangeMonthChange, dateRangeSelectedDates,
                           applyDateRange, isFetchingDateRange, minitabClicked, isFetching, appType, toggleExportModal,
                           analytics, onExport, data, onEmailChanged, onDismissToast}) =>
    <div className="roi-dashboard-wrapper">
        {!isFetching && <div className="slider settings roi-dashboard">
            <div onClick={minitabClicked('general')} className={`analytics-minitab-nav ${visibleTab === 'general' ? 'selected' : ''}`}>General</div>
            <div onClick={minitabClicked('orders')} className={`analytics-minitab-nav ${visibleTab === 'orders' ? 'selected' : ''}`}>Orders</div>
            <div onClick={minitabClicked('products')} className={`analytics-minitab-nav ${visibleTab === 'products' ? 'selected' : ''}`}>Products</div>
            {appType !== 'bundles' && <div onClick={minitabClicked('mappings')} className={`analytics-minitab-nav ${visibleTab === 'mappings' ? 'selected' : ''}`}>Slider</div>}
            <div onClick={minitabClicked('bundles')} className={`analytics-minitab-nav ${visibleTab === 'bundles' ? 'selected' : ''}`}>Bundle</div>
            {appType !== 'bundles' && <div onClick={minitabClicked('volume_discounts')} className={`analytics-minitab-nav ${visibleTab === 'volume_discounts' ? 'selected' : ''}`}>Volume Discount</div>}
        </div>}
        {isFetching ?
            <div className="roi-spinner-wrapper">

            <div className="roi-spinner">
                <Spinner size="large" /><span className="fetch-spinner">Fetching...</span>
            </div>
                </div>
         :
            <div className="roi-dashboard-content">

                <div className="zrx-tab-wrapper-thin">

                    <div className="zrx-tab-title">ROI Dashboard</div>
                    <div className="zrx-tab-subtitle">Analyse the performance of the offers and the value generated by the app</div>

                </div>

                <div className="roi-dashboard-date-picker-email-wrapper">
                <div className="roi-dashboard-date-picker">
                    <Popover
                        active={isSelectDateActive}
                        activator={<Button onClick={toggleSelectDateActive}>
                            {dateRangeSelectedDates.start.toISOString().slice(0, 10)} to&nbsp;
                            {dateRangeSelectedDates.end.toISOString().slice(0, 10)}
                        </Button>}
                        onClose={toggleSelectDateActive}
                        ariaHaspopup={false}
                        sectioned
                    >

                        <FormLayout>
                            <Select label="Select date range" options={['Last 7 days', 'Last 30 days', 'Last 90 days']} onChange={onDateRangePresetChange} value={dateRangePreset} />
                            <DatePicker month={dateRangeMonth} year={dateRangeYear} onChange={onDateRangeChange}
                                        onMonthChange={onDateRangeMonthChange} selected={dateRangeSelectedDates}
                                        multiMonth={false} allowRange={true} disableDatesAfter={new Date()} />
                            <Button onClick={applyDateRange} primary loading={isFetchingDateRange}>Apply</Button>
                        </FormLayout>

                    </Popover>
                </div>
                {analytics.totalOrders > 0 && <span className="roi-dashboard-email-data"><Button onClick={toggleExportModal}>Email Report</Button></span>}
                </div>
                <div>
                    {visibleTab === 'general' && <VisibleAnalytics />}
                    {visibleTab === 'orders' && <VisibleAnalyticsOrders />}
                    {visibleTab === 'products' && <VisibleAnalyticsProducts />}
                    {visibleTab === 'mappings' && <VisibleAnalyticsMappings />}
                    {visibleTab === 'bundles' && <VisibleAnalyticsBundles />}
                    {visibleTab === 'volume_discounts' && <VisibleAnalyticsVolumeDiscounts />}
                </div>
            </div>}
        <Modal open={analytics.exportModalVisible} onClose={toggleExportModal}
               title="Email Report"
               primaryAction={{
                   content: 'Send',
                   onAction: onExport,
                   loading: analytics.exporting,
               }}
               secondaryActions={[
                   {
                       content: 'Cancel',
                       onAction: toggleExportModal,
                   },
               ]}>
            <Modal.Section>
                <TextField type="email" label="Email to send the report:*" value={data.ownerEmail} onChange={onEmailChanged}/>
            </Modal.Section>
        </Modal>
        {analytics.toastActive && <Toast content={analytics.toastContent} onDismiss={onDismissToast} />}
    </div>;

export default AnalyticsMain;
