import { connect } from 'react-redux'
import MixAndMatches from "../../components/products/MixAndMatches";
import {
    mixAndMatchDeleteOffer,
    mixAndMatchEditOffer,
    mixAndMatchFetchTranslationsAndLocales,
    mixAndMatchOpenDeleteOfferModal,
    mixAndMatchOpenShortCodeModal,
    mixAndMatchPreviewOffer,
    mixAndMatchToggleEnabled
} from "../../actions/mixAndMatch";

const mapStateToProps = state => ({
    mixAndMatch: state.mixAndMatch,
    data: state.data,
});

const mapDispatchToProps = dispatch => ({
    createOffer: () => dispatch(mixAndMatchFetchTranslationsAndLocales()),
    deleteOffer: id => () => dispatch(mixAndMatchDeleteOffer(id)),
    toggleEnabled: id => () => dispatch(mixAndMatchToggleEnabled(id)),
    editOffer: id => () => dispatch(mixAndMatchEditOffer(id)),
    previewOffer: id => () => dispatch(mixAndMatchPreviewOffer(id)),
    openDeleteOfferModal: (id , bool)=> () => dispatch(mixAndMatchOpenDeleteOfferModal(id, bool)),
    openShortCodeModal: (id, bool) => () => dispatch(mixAndMatchOpenShortCodeModal(id, bool)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(MixAndMatches);
