import React from 'react';
import {
    Icon,
    TextContainer,
    TextStyle, Tooltip
} from "@shopify/polaris";
import {Bar} from 'react-chartjs-2';
import '../../css/Analytics.css'
import {InfoMinor} from "@shopify/polaris-icons";

const chartOptions = {
    tooltips: {
        callbacks: {
            label: tooltipItem => `${tooltipItem.value}%`
        }
    },
    maintainAspectRatio: false,
    scales: {
        yAxes: [{
            ticks: {
                min: 0,
                callback: (value, index, values) => `${value}%`
            }

        }],
        xAxes: [{
            offset: true,
            type: 'time',
            time: {
                unit: "day",
                parser: "YYYY-MM-DD"
            },
        }]
    },
}


const Analytics = ({ totalOrders, zoorixOrders, payments, revenue, orders, currency, multiCurrency, totalSales,
                       totalZoorixSales, zoorixRevenue, zoorixRevenueUsd, analytics }) => (
        <div>
                    <TextContainer>
                        {totalOrders > 0 ?
                                <div>
                                    <div className="roi-summary">
                                        <div className="roi-summary-cards">
                                        <div className="roi-card">
                                            <div className="roi-title-wrapper">
                                                <div className="roi-card-title">Total orders</div>
                                                <span className="roi-tooltip">
                                                    <Tooltip light content="Total orders from the store and orders generated by Zoorix">
                                                        <Icon source={InfoMinor} />
                                                    </Tooltip>
                                                </span>
                                            </div>
                                            <div className="total-orders">
                                                {totalOrders > 0 && <div className="total-orders-item">Total orders:  <span className="roi-number"><TextStyle variation="strong">{analytics.displayedValues.totalOrders.totalOrders}</TextStyle></span></div>}
                                                {zoorixOrders > 0 && <div className="total-zoorix-orders-item">Zoorix orders: <span className="roi-number"><TextStyle variation="strong">{analytics.displayedValues.totalOrders.zoorixOrders}</TextStyle></span></div>}
                                                {totalOrders > 0 && <div className="total-zoorix-orders-item">Zoorix orders %: <span className="roi-number"><TextStyle variation="strong">{analytics.displayedValues.totalOrders.zoorixOrdersPercentage}</TextStyle></span></div>}
                                            </div>
                                        </div>
                                        <div className="roi-card">
                                            <div className="roi-title-wrapper">
                                                <div className="roi-card-title">Total revenue</div>
                                                <span className="roi-tooltip">
                                                    <Tooltip light content="Total revenue from the store and revenue generated by Zoorix">
                                                        <Icon source={InfoMinor} />
                                                    </Tooltip>
                                                </span>
                                            </div>
                                            <div>Total {currency} orders: <span className="roi-number"><TextStyle variation="strong">{analytics.displayedValues.totalRevenue.totalOrders}</TextStyle></span></div>
                                            <div>Total {currency} by Zoorix: <span className="roi-number"><TextStyle variation="strong">{analytics.displayedValues.totalRevenue.totalByZoorix}</TextStyle></span></div>
                                            {totalSales > 0 && <div>Revenue %: <span className="roi-number"><TextStyle variation="strong">{analytics.displayedValues.totalRevenue.revenuePercentage}</TextStyle></span></div>}
                                        </div>
                                        {<div className="roi-card">
                                            <div className="roi-title-wrapper">
                                                <div className="roi-card-title">ROI</div>
                                                <span className="roi-tooltip">
                                                    <Tooltip light content="How much value received from Zoorix vs how much was paid to Zoorix">
                                                        <Icon source={InfoMinor} />
                                                    </Tooltip>
                                                </span>
                                            </div>
                                            <div>Revenue by Zoorix (USD): <span className="roi-number">{analytics.displayedValues.roi.revenueByZoorixUsd}</span></div>
                                            <div>Paid to Zoorix (USD): <span className="roi-number">{analytics.displayedValues.roi.paidToZoorixUsd}</span></div>
                                            <div>ROI: <span className="roi-number">{analytics.displayedValues.roi.roi}</span></div>
                                        </div>}
                                        {<div className="roi-card">
                                            <div className="roi-title-wrapper">
                                                <div className="roi-card-title">AOV</div>
                                                <span className="roi-tooltip">
                                                    <Tooltip light content="Average Order Value of orders not generated by Zoorix vs orders generated by Zoorix">
                                                        <Icon source={InfoMinor} />
                                                    </Tooltip>
                                                </span>
                                            </div>
                                            {totalOrders > 0 && <div>Store AOV: <span className="roi-number">{analytics.displayedValues.aov.storeAov}</span></div>}
                                            {zoorixOrders > 0 && <div>Zoorix AOV: <span className="roi-number">{analytics.displayedValues.aov.zoorixAov}</span></div>}
                                            {zoorixOrders > 0 && totalOrders > 0 && totalSales > 0 && <div>AOV %: <span className="roi-number">{analytics.displayedValues.aov.aovPercentage}</span></div>}
                                        </div>}
                                        </div>
                                        <div className="roi-graph-cards">
                                        <div className="roi-card width-50">
                                            <div className="roi-title-wrapper">
                                                <div className="roi-card-title">Revenue by Zoorix (% from Store revenue)</div>
                                                <span className="roi-tooltip">
                                                    <Tooltip light content="Revenues generated by Zoorix as a percentage of store revenues">
                                                        <Icon source={InfoMinor} />
                                                    </Tooltip>
                                                </span>
                                            </div>
                                            {revenue && <Bar
                                                data={revenue}
                                                width={300}
                                                height={200}
                                                options={chartOptions}
                                            />}
                                        </div>
                                        <div className="roi-card width-50">
                                            <div className="roi-title-wrapper">
                                                <div className="roi-card-title">Orders by Zoorix (% from Store orders)</div>
                                                <span className="roi-tooltip">
                                                    <Tooltip light content="Orders generated by Zoorix as a percentage of store orders">
                                                        <Icon source={InfoMinor} />
                                                    </Tooltip>
                                                </span>
                                            </div>
                                            {orders && <Bar
                                                data={orders}
                                                width={300}
                                                height={200}
                                                options={chartOptions}
                                            />}
                                        </div>
                                        </div>
                                    </div>
                                </div>
                         :
                                        <p className="orders-status">Status: No orders in this date range</p>
                        }
                    </TextContainer>

        </div>

    );

export default Analytics;
