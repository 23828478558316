import React from 'react';
import '../../css/sticky-atc/StickyAtc.scss';
import {Spinner, TextField, Button} from "@shopify/polaris";

const FreeShippingBarCodeEditor = ({ freeShippingBar, changeText, save }) => {
    return <div>
        {freeShippingBar.settings ? <div className="sticky-add-to-cart-css-editor">
            <TextField label="Custom CSS:" multiline={15} value={freeShippingBar.settings.custom_css}
                       onChange={changeText('custom_css')} />
            <TextField label="Custom JS:" multiline={15} value={freeShippingBar.settings.custom_js}
                       onChange={changeText('custom_js')} />
            <div className="save-button">
                <Button onClick={save} loading={freeShippingBar.savingSettings} primary>Save</Button>
            </div>
        </div> : <div>
            <Spinner />
        </div>}
    </div>
};

export default FreeShippingBarCodeEditor;
