import React from 'react';
import {Button, Checkbox, Icon, Select, Spinner, TextField} from "@shopify/polaris";
import '../../css/MixAndMatch.scss';
import {Modal} from "antd";
import { DeleteMajor } from "@shopify/polaris-icons";

const triggerDisplayOptions = [
    {label: 'Offer Items', value: 'offer_items'},
    {label: 'Specific Collections', value: 'specific'},
];

const discountTypes = [
    {label: 'Fixed', value: 'fixed'},
    {label: 'Percentage', value: 'percentage'},
];

const openExpandedInModal = [
    {label: 'Open offer in modal', value: 'yes'},
    {label: 'Open offer in dedicated page', value: 'no'},
];

const navigateToOptions = [
    { label: 'Checkout', value: 'checkout' },
    { label: 'Cart', value: 'cart' },
    { label: 'None', value: 'none' },
];


const EditMixAndMatch = ({mixAndMatch, cancel, nameChange, save, onLocaleChange, textChange,
                             toggleSearchCollectionsModal, selectCollection, searchCollectionTitleChange,
                             deleteProductGroup, minQtyChange, openSearchCollectionsModal,
                             onTriggerDisplayOptionsChange, deleteTriggerProductGroup,
                             discountTypeChange, discountAmountChange, triggerLocationChange, onNavToChange,
                             onBoolChange}) =>
        <div>
            <Button onClick={cancel}>Cancel</Button>
            <span className="mm-save-btn">
                <Button onClick={save} primary loading={mixAndMatch.savingOffer}>Save</Button>
            </span>
            <div className="mm-offer-name">Offer Name <span className="mm-offer-name-tip">(will not be visible to shoppers)</span></div>
            <TextField autoComplete={false}
                       onChange={nameChange} value={mixAndMatch.offer.name} />
            <div className='mm-locale-wrapper'>
                { mixAndMatch.locales.length > 1 && <Select label="Locale" options={mixAndMatch.locales} onChange={onLocaleChange} value={mixAndMatch.locale} /> }
                <TextField label="Offer Title" autoComplete={false} onChange={textChange('title')}
                           value={mixAndMatch.offer.texts[mixAndMatch.locale].title} />
                <TextField label="Offer Subtitle" autoComplete={false} onChange={textChange('subtitle')}
                           value={mixAndMatch.offer.texts[mixAndMatch.locale].subtitle} />
                <TextField label="Bundle Builder Title" autoComplete={false} onChange={textChange('expanded_title')}
                           value={mixAndMatch.offer.texts[mixAndMatch.locale].expanded_title} />
                <TextField label="Bundle Builder Subtitle" autoComplete={false} onChange={textChange('expanded_subtitle')}
                           value={mixAndMatch.offer.texts[mixAndMatch.locale].expanded_subtitle} />
            </div>
            <div className="mm-discount-wrapper">
            <Select label="Discount Type" options={discountTypes} onChange={discountTypeChange}
                    value={mixAndMatch.offer.offer.discount.type} />
            <TextField label="Discount Amount" autoComplete={false} onChange={discountAmountChange}
                       value={mixAndMatch.offer.offer.discount.amount.toString()} type="number" />
            </div>
            <div className="mm-collection-wrapper">
            <span className="mm-add-collection">
                <Button onClick={openSearchCollectionsModal('offer')}>Add Collection</Button>
            </span>
            <div className={mixAndMatch.offer.offer.product_groups && mixAndMatch.offer.offer.product_groups.lenght > 0 ? 'mm-selected-collections-wrapper' : 'mm-selected-collections-wrapper-empty' }>
            {mixAndMatch.offer.offer.product_groups.map((pg, idx) => <div className="mm-selected-collection" key={idx}>
                <span className="mm-selected-collection-name-wrapper">
                    <span className="mm-selected-collection-name">{pg.title}</span>
                </span>
                <div className="mm-selected-collection-qty-wrapper">
                    <span className="mm-selected-collection-qty-prefix">Number of required items:</span>
                    <div className="mm-selected-collection-qty">
                        <TextField value={pg.min_qty.toString()} autoComplete={false}
                               type="number" onChange={minQtyChange(idx)} />
                    <span onClick={deleteProductGroup(idx)} className="remove-item">
                        <Icon source={DeleteMajor}/>
                    </span>
                    </div>
                </div>
            </div>)}
            </div>
            </div>
            <div className="mm-more-settings">
            <Select label="Follow up action" options={navigateToOptions} onChange={onNavToChange}
                    value={mixAndMatch.offer.navigate_to} />
            <Select label="Display on" options={triggerDisplayOptions} onChange={onTriggerDisplayOptionsChange}
                    value={mixAndMatch.offer.triggers.on_offer_items ? 'offer_items' : 'specific'} />
            {!mixAndMatch.offer.triggers.on_offer_items && <div>
                <div className="mm-add-trigger-collection"><Button onClick={openSearchCollectionsModal('trigger')}>Add Trigger Collection</Button></div>
                {mixAndMatch.offer.triggers.product_groups.map((pg, idx) => <div className="mm-add-trigger-collection-item" key={idx}>
                    <span className="mm-add-trigger-collection-text">{pg.title}</span>
                    <span onClick={deleteTriggerProductGroup(idx)} className="mm-trigger-collection-remove">
                    <Icon source={DeleteMajor}/>
                </span>
                </div>)}
            </div>}
                <Select label="Open offer in..." options={openExpandedInModal}
                        onChange={onBoolChange('open_expanded_in_modal')}
                        value={mixAndMatch.offer.open_expanded_in_modal ? 'yes' : 'no'} />
            </div>

            <div className="mm-offer-location">
                <div className="mm-config-title">Product page location</div>
                <div className="mm-offer-locations-wrapper">
                    <Checkbox label="Middle" checked={mixAndMatch.offer.trigger_locations.product_main}
                              onChange={triggerLocationChange('product_main')} />
                    <Checkbox label="Bottom" checked={mixAndMatch.offer.trigger_locations.product_bottom}
                              onChange={triggerLocationChange('product_bottom')} />
                </div>
                <div className="mm-config-title">Cart page location</div>
                <div className="mm-offer-locations-wrapper">
                    <Checkbox label="Top" checked={mixAndMatch.offer.trigger_locations.cart_top}
                          onChange={triggerLocationChange('cart_top')} />
                    <Checkbox label="Bottom" checked={mixAndMatch.offer.trigger_locations.cart_bottom}
                          onChange={triggerLocationChange('cart_bottom')} />
                </div>
            </div>



            <Modal
                visible={mixAndMatch.selectCollectionsModalOpen}
                className="offer-collection-modal"
                title={'Search collection'}
                onCancel={toggleSearchCollectionsModal}
                width="50%"
                cancelText="Close"
            >
                <TextField autoComplete={false} onChange={searchCollectionTitleChange}
                           value={mixAndMatch.searchCollectionTitle} />
                {(mixAndMatch.searchedCollectionsSpinner) && <span className="spinner-wrapper"><Spinner /></span>}
                {!mixAndMatch.searchedCollectionsSpinner && mixAndMatch.searchedCollections && mixAndMatch.searchedCollections.map(collection =>
                    <div className="mm-search-item" key={collection.node.id} onClick={selectCollection(collection.node.id, collection.node.title)}>
                        <span className="mm-collection-title">{collection.node.title}</span>
                        <span className="mm-collection-count"> ({collection.node.productsCount} products)</span>
                    </div>)}
            </Modal>
        </div>;

export default EditMixAndMatch;
