import React from 'react';
import '../../../css/v2/AppV2Wizard.scss';

const page = (pageTypeSet, pageType, selectPage, block) =>
    <div className={pageTypeSet[pageType] ? 'page-box selected' : 'page-box not-selected'}
         onClick={selectPage(pageType)}>
        <span className={'checkbox'}>
            <div className={'container'}>
                <div className={'round'}>
                    <input type="checkbox" readOnly checked={!!pageTypeSet[pageType]} id={pageType}/>
                    <label htmlFor={pageType}></label>
                </div>
            </div>
        </span>
        <span className={'page-box-details'}>
            <span className={'page-box-text'}>{block}</span>
             <img alt={pageType} className={'page-box-image'} src={"https://public.zoorix.com/images/" + pageType + ".svg"}/>
        </span>

    </div>

const AppV2WizardSelectPage = ({appV2Wizard, selectPage, next, back}) =>
    <div className={'app-v2-wizard-select-page'}>
        <div className="wizard-select-page-title">Select A Page</div>
        <div className="wizard-select-page-subtitle">Select a page where you'd like to show the offer on</div>
        {(appV2Wizard.offerType === 'volume_discount' || appV2Wizard.offerType === 'bundle' || appV2Wizard.offerType === 'mix_and_match') &&
            <div className={'select-page-box'}>
                {page(appV2Wizard.pageType, 'product-main', selectPage, <div>
                    Product page (middle)
                </div>)}
                {page(appV2Wizard.pageType, 'product-bottom', selectPage, <div>
                    Product page (bottom)
                </div>)}
                {page(appV2Wizard.pageType, 'cart-top', selectPage, <div>
                    Cart page (top)
                </div>)}
                {page(appV2Wizard.pageType, 'cart-bottom', selectPage, <div>
                    Cart page (bottom)
                </div>)}
            </div>}

        {appV2Wizard.offerType === 'cross_sell' && <div className={'select-page-box'}>
            {page(appV2Wizard.pageType, 'product', selectPage, <div>
                Product page
            </div>)}
            {page(appV2Wizard.pageType, 'cart', selectPage, <div>
                Cart page
            </div>)}
        </div>}

        <div className={"select-something-wrapper"}>
            {appV2Wizard.invalidSelectPage && <div>Please select a page</div>}
        </div>

        <div className={'drawings-wrapper'}>
            <img alt="cat-ball" src="https://public.zoorix.com/images/cat-ball2.png" className={'cat-ball'}></img>
        </div>

        <div className={'nav-buttons-wrapper'}>
            <div onClick={back} className={'app-v2-wizard-back-btn'}>Back</div>
            <div onClick={next} className={'app-v2-wizard-next-btn'}>Next</div>
        </div>
    </div>;

export default AppV2WizardSelectPage;
