import React from 'react';
import {
    Button,
    TextField,
    TextStyle,
    Frame, Toast,
    Modal
} from "@shopify/polaris";
import '../css/CssEditor.css'

const versionText = {
    '0': <div>There is no such thing as v0</div>,
    '1': <div>Removing lock might mess up slider customizations</div>,
}

const CssEditor = ({ onSave, onCssChange, css, error, isSaving, toastIsActive, onDismissToast, cssEditor, onJsChange,
                       toggleRemoveVersionLockModal, removeLock }) =>
    <Frame>
        <div className="css-editor-wrapper">
            {false && cssEditor.widgetVersionLock && cssEditor.widgetVersionLock < cssEditor.latestMajor && <div>
                <div>You are locked on widget version: v{cssEditor.widgetVersionLock}</div>
                <Button onClick={toggleRemoveVersionLockModal(true)}>Remove lock</Button>
            </div>}
            {error && <TextStyle variation="negative">There was an error sending your request. Please try again
                in a few minutes</TextStyle>}
            <div className="zrx-tab-wrapper-thin-plus">
                <div className="zrx-tab-title">CSS Editor</div>
                <div className="zrx-tab-subtitle">Advanced customizations using CSS code</div>
            </div>
            <TextField multiline={15} value={css} onChange={onCssChange} />
            <div className="zrx-tab-wrapper-thin-plus" style={{ marginTop: '10px' }}>
                <div className="zrx-tab-title">JS Editor</div>
                <div className="zrx-tab-subtitle">Advanced customizations using JS code</div>
            </div>
            <TextField multiline={15} value={cssEditor.js} onChange={onJsChange} />
            {toastIsActive && <Toast content="Settings saved" onDismiss={onDismissToast} />}
            <div className="save-button" style={{ marginTop: '10px' }}>
                <Button id="save-settings" primary onClick={onSave} loading={isSaving}>Save</Button>
            </div>
        </div>
        <Modal open={cssEditor.removeVersionLockModalOpen} onClose={toggleRemoveVersionLockModal(false)}
        title={'Remove Widget Version Lock'}
               primaryAction={{
                   content: 'Remove Lock',
                   loading: cssEditor.removingWidgetVersionLock,
                   onAction: removeLock,
               }}
               secondaryActions={[
                   {
                       content: 'Cancel',
                       onAction: toggleRemoveVersionLockModal(false),
                   },
               ]}>

            <Modal.Section>
                {versionText[cssEditor.widgetVersionLock]}
            </Modal.Section>
        </Modal>
    </Frame>

export default CssEditor;
