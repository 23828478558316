import React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card,
    Form,
    FormLayout,
    Heading,
    Layout,
    Stack,
    TextContainer,
    TextField,
    TextStyle
} from "@shopify/polaris";

const Support = ({ onSubmit, onNameChange, onEmailChanged, onTextChanged, name, email, text, submitted, error, textError }) => {
    return (
        <div className="zrx-support-page">
        <Layout>
            <Layout.AnnotatedSection
                title="Get in touch"
                description="We would be happy to help with every inquiry you may have, simply complete this form."
            >
                <Card sectioned>
                    {submitted ? (
                        <TextContainer>
                            <Heading>Thanks for submitting!</Heading>
                            <p>We'll try to get back to you in a few hours.</p>
                        </TextContainer>
                    ) : (
                            <Form onSubmit={onSubmit}>
                                <FormLayout>
                                    {
                                        error ? (
                                            <TextStyle variation="negative">There was an error sending your request. Please try again in a few minutes</TextStyle>
                                        ) : null
                                    }
                                    <Stack distribution="fill">
                                        <Stack.Item>
                                            <TextField label="Your name *" value={name} onChange={onNameChange} />
                                        </Stack.Item>
                                        <Stack.Item>
                                            <TextField type="email" label="Your email *" value={email} onChange={onEmailChanged} />
                                        </Stack.Item>
                                    </Stack>
                                    <TextField label="How can we help you? *" multiline={5} value={text}
                                        onChange={onTextChanged} error={textError} />
                                    <Button submit primary>Submit</Button>
                                </FormLayout>
                            </Form>
                        )}

                </Card>
            </Layout.AnnotatedSection>
        </Layout>
        </div>
    )
};

Support.propTypes = {
    onNameChange: PropTypes.func.isRequired,
    onEmailChanged: PropTypes.func.isRequired,
    onTextChanged: PropTypes.func.isRequired,
};

export default Support;
