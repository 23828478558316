import { connect } from 'react-redux'
import PartnersDashboard from "../../components/partners/PartnersDashboard";
import {
    partnersBrandChange,
    partnersChangePassword, partnersCopyRefUrl, partnersDismissToast,
    partnersLogout,
    partnersNewPasswordChange, partnersOfferingValueChange,
    partnersOldPasswordChange,
    partnersOpenChangePasswordModal, partnersPaypalEmailChange, partnersSaveBrand, partnersUpdatePaypalEmail
} from "../../actions/partners";

const mapStateToProps = state => ({
    partners: state.partners,
    paypalEmail: state.partners.paypalEmail,
    changePasswordModalOpen: state.partners.changePasswordModalOpen,
    oldPassword: state.partners.oldPassword,
    newPassword: state.partners.newPassword,
    changingPassword: state.partners.changingPassword,
    toastActive: state.partners.toastActive,
    toastContent: state.partners.toastContent,
    updatingPaypalEmail: state.partners.updatingPaypalEmail,
    offeringValue: state.partners.offeringValue,
    offerings: state.partners.offerings,
    refUrl: state.partners.refUrl,
    visits: state.partners.visits,
    referrals: state.partners.referrals,
    totalEarnings: state.partners.totalEarnings,
    totalPaid: state.partners.totalPaid,
    referredStores: state.partners.referredStores,
    partnerPayments: state.partners.partnerPayments,
});

const mapDispatchToProps = (dispatch) => ({
    onLogout: () => dispatch(partnersLogout()),
    changePassword: () => dispatch(partnersChangePassword()),
    onDismissToast: () => dispatch(partnersDismissToast()),
    openChangePasswordModal: () => dispatch(partnersOpenChangePasswordModal(true)),
    closeChangePasswordModal: () => dispatch(partnersOpenChangePasswordModal(false)),
    onUpdatePaypalEmail: () => dispatch(partnersUpdatePaypalEmail()),
    copyRefUrl: () => dispatch(partnersCopyRefUrl()),
    onSaveBrand: () => dispatch(partnersSaveBrand()),
    onOldPasswordChange: text => dispatch(partnersOldPasswordChange(text)),
    onNewPasswordChange: text => dispatch(partnersNewPasswordChange(text)),
    onPaypalEmailChange: text => dispatch(partnersPaypalEmailChange(text)),
    onOfferingValueChange: text => dispatch(partnersOfferingValueChange(text)),
    onBrandChange: text => dispatch(partnersBrandChange(text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PartnersDashboard);
