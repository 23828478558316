import { connect } from 'react-redux'
import Plans from "../../components/plans/Plans";
import {loadPlansIfNeeded, plansPromoCodeChange, plansSetWhiteLabel, plansSubmitPromoCode} from "../../actions/plans";
import {createCharge} from "../../actions/status";

const mapStateToProps = state => ({
    installation: state.status.installation,
    numOfOrders: state.status.numOfOrders,
    packageName: state.status.packageName,
    trialDays: state.status.trialDays,
    customPackagePrice: state.status.customPackagePrice,

    referralOfferingDiscount: state.data.referralOfferingDiscount,

    zoorixRevenueUsd: state.analytics.zoorixRevenueUsd,

    plans: state.plans,
});

const mapDispatchToProps = (dispatch) => ({
    load: () => dispatch(loadPlansIfNeeded()),
    setWhiteLabel: bool => () => dispatch(plansSetWhiteLabel(bool)),
    createCharge: () => dispatch(createCharge()),
    submitPromoCode: () => dispatch(plansSubmitPromoCode()),
    promoCodeChange: text => dispatch(plansPromoCodeChange(text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Plans);
