import React from 'react';
import '../../../css/v2/AppV2Wizard.scss';
import VisibleAppV2WizardGetStarted from "../../../containers/v2/wizard/VisibleAppV2WizardGetStarted";
import VisibleAppV2WizardSelectOfferType from "../../../containers/v2/wizard/VisibleAppV2WizardSelectOfferType";
import VisibleAppV2WizardSelectPage from "../../../containers/v2/wizard/VisibleAppV2WizardSelectPage";
import VisibleAppV2WizardSelectTrigger from "../../../containers/v2/wizard/VisibleAppV2WizardSelectTrigger";
import VisibleAppV2WizardConfigOffer from "../../../containers/v2/wizard/VisibleAppV2WizardConfigOffer";
import VisibleAppV2WizardTestOffer from "../../../containers/v2/wizard/VisibleAppV2WizardTestOffer";

const stageNumToDisplayMapping = {
    2: 1,
    3: 2,
    4: 3,
    5: 4,
    6: 5,
}

const stage = (currentStage, stageNum, text) =>
    <span className={currentStage > stageNum ? 'wiz-item past' : currentStage < stageNum ? 'wiz-item future' : 'wiz-item current'}>
        <span className="stage-wrapper">
            <span className={'circle-wrapper'}>
                <span className={'circle'}>{stageNumToDisplayMapping[stageNum]}</span>
                <span className={'line'}></span>
            </span>
            <span className={'text'}>{text}</span>
        </span>
    </span>;

const AppV2Wizard = ({ appV2Wizard }) => <div className={'app-v2-wizard'}>
    <div className={'wizard-stages'}>
        {/*{stage(appV2Wizard.stage, 1, 'Get Started')}*/}
        {stage(appV2Wizard.stage, 2, 'Select Offer')}
        {stage(appV2Wizard.stage, 3, 'Select Page')}
        {stage(appV2Wizard.stage, 4, 'Select Trigger')}
        {stage(appV2Wizard.stage, 5, 'Create Offer')}
        {stage(appV2Wizard.stage, 6, 'Test Offer')}
    </div>
    { appV2Wizard.stage === 1 ? <div className={'wizard-step'}>
        <VisibleAppV2WizardGetStarted />
    </div> : appV2Wizard.stage === 2 ? <div className={'wizard-step'}>
        <VisibleAppV2WizardSelectOfferType />
    </div> : appV2Wizard.stage === 3 ? <div className={'wizard-step'}>
        <VisibleAppV2WizardSelectPage />
    </div> : appV2Wizard.stage === 4 ? <div className={'wizard-step'}>
        <VisibleAppV2WizardSelectTrigger />
    </div> : appV2Wizard.stage === 5 ? <div className={'wizard-step'}>
        <VisibleAppV2WizardConfigOffer />
    </div> :
    <div className={'wizard-step'}>
        <VisibleAppV2WizardTestOffer />
    </div>}
</div>;

export default AppV2Wizard;
