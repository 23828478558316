import React from 'react';
import '../../../css/v2/AppV2Wizard.scss';
import {Spinner, TextField, Icon} from "@shopify/polaris";
import {InputNumber, Modal} from 'antd';
import {DeleteMajor} from "@shopify/polaris-icons";
import {selectAfter} from "./AppV2WizardConfigBundle";

const AppV2WizardConfigMixAndMatch = ({
                                          appV2Wizard, onDiscountTypeChange, onDiscountAmountChange,
                                          toggleSelectCollectionsModal, approveSelectedCollections,
                                          onCollectionSearchTextChange, removeSelectedCollection, toggleSelectCollection,
                                          onMinQtyChange, trashProductGroup
}) =>
    <div className="app-v2-wizard-mnm-offer">

         <span className="field-wrapper">
                <InputNumber addonAfter={selectAfter(appV2Wizard, onDiscountTypeChange)} addonBefore="Discount"
                             value={appV2Wizard.mnm.discount.amount && appV2Wizard.mnm.discount.amount.toString()} size="large" min={0}
                             max={appV2Wizard.mnm.discount.type === 'percentage' ? 100 : undefined}
                             defaultValue={1} onChange={onDiscountAmountChange}/>
            </span>

        <div>

            {appV2Wizard.mnm.product_groups.map((pg, idx) => <div className="wizard-mnm-offer-collection-row" key={idx}>

                <span className="wizard-mnm-offer-collection-title">{pg.title}</span>
                <div className="wizard-mnm-offer-collection-details">
                    <InputNumber addonBefore="Min Qty" value={pg.min_qty.toString()} min={1}
                                 defaultValue={1} onChange={onMinQtyChange(idx)} />
                    <span className="wizard-mnm-offer-delete-collection" onClick={trashProductGroup(idx)}><Icon source={DeleteMajor}/></span>
                </div>
            </div>)}
            <div className={appV2Wizard.invalidSelectOffer ? 'wizard-mnm-offer-add-collection invalid' : 'wizard-mnm-offer-add-collection'} onClick={toggleSelectCollectionsModal}>
                Add Collection
            </div>

        </div>

        <Modal
            visible={appV2Wizard.selectCollectionsModalOpen}
            className="collection-modal"
            title={'Select collection'}
            onCancel={toggleSelectCollectionsModal}
            onOk={approveSelectedCollections}
            width="50%"
            cancelText="Close"
            okText="Select"
        >

            <div>
                <TextField label=""
                           value={appV2Wizard.searchText} placeholder={'Search collections'}
                           onChange={onCollectionSearchTextChange} autoComplete={false}/></div>
            <div
                className="selected-items-wrapper">{appV2Wizard.selectedCollections && appV2Wizard.selectedCollections.map(c =>
                <div className="selected-item" key={c.id}>
                    <span className="selected-item-title">{c.title}</span>
                    <span className="selected-item-x" onClick={removeSelectedCollection(c.id)}>X</span>
                </div>)}</div>
            {appV2Wizard.searchedCollectionsSpinner && <span className="spinner-wrapper"><Spinner /></span>}
            <div className="search-items-wrapper">
                {appV2Wizard.searchedCollections && appV2Wizard.searchedCollections.map(p =>
                    <div key={p.node.id} onClick={toggleSelectCollection(p.node.id)}
                         className={p.selected ? 'search-item app-v2-wizard-selected-collection' : 'search-item app-v2-wizard-not-selected-collection'}>
                         <span className={'checkbox'}>
                             <div className={'container'}>
                                  <div className={'round'}>
                                      <input type="checkbox" readOnly checked={p.selected} id={p.node.id}/>
                                      <label htmlFor={p.node.id}></label>
                                  </div>
                             </div>
                         </span>
                        <span>{p.node.title}</span>
                        <span> ({p.node.productsCount} products)</span>
                    </div>)}
            </div>

        </Modal>

    </div>;

export default AppV2WizardConfigMixAndMatch;
