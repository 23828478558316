import React from 'react';
import {Frame, Card, Spinner, Button, Modal, TextField, Icon} from "@shopify/polaris";
import '../../css/ProductsCartDrawer.scss'
import NoImage from "./NoImage";
import {CaretDownMinor, CaretUpMinor} from "@shopify/polaris-icons";

const ProductsCartDrawer = ({ toastIsActive, onDismissToast, ready, load, offers, addOffer, deleteOffer,
                                deletingOffer, chooseProductOpen, onCloseChooseProduct, searchBoxText,
                                onChangeSearchText, onFocusSearchText, showSearchSpinner, searchedProducts,
                                chooseOfferProduct, selectingOfferProduct, moveItem }) => {
    load();
    return <div className="products-cart-drawer">
        <Frame className="frame-wrapper">
            <div className={`cart-drawer-card-wrapper ${ready && 'cart-drawer-loaded'}`}>
                {ready ?
                    <Card>
                        <Card.Section sectioned>
                            <Button primary onClick={addOffer} loading={selectingOfferProduct}>Add Offer</Button>
                            {offers.length > 0 && <div className="cart-drawer-offer-wrapper">
                                {offers.map((offer, index) => <div className="cart-drawer-offer-inner" key={offer.id}>
                                    <span className="zrx-cart-drawer-carets">
                                    {index > 0 && <span className={'caret-up'} onClick={moveItem('up', index)}>
                                        <Icon source={CaretUpMinor} /></span>}
                                    {index < offers.length - 1 &&
                                        <span className={'caret-down'} onClick={moveItem('down', index)}>
                                        <Icon source={CaretDownMinor} /></span>}</span>
                                    <img src={offer.image_src} width="20px" alt={offer.title}/>
                                    <div className="cart-drawer-offer-title">
                                        {offer.title}
                                    </div>
                                    <Button onClick={deleteOffer(offer.id)} loading={deletingOffer}>Delete Offer</Button>
                                </div>)}
                            </div>}
                        </Card.Section>
                    </Card>
                    :
                    <span className="spinner-wrapper"><Spinner/></span>}
            </div>
            <Modal open={chooseProductOpen} onClose={onCloseChooseProduct}
                   title="Choose a product"
                   secondaryActions={[{ content: 'Cancel', onAction: onCloseChooseProduct }]}>
                <Modal.Section>
                    <div className="search-products cart-drawer-search">
                        <TextField placeholder='Search phrase, e.g. Shirts for men' autoComplete={false}
                                   label="Choose a product:"
                                   value={searchBoxText} onChange={onChangeSearchText}
                                   onFocus={onFocusSearchText} />
                        {showSearchSpinner && <div className="fetch-search-wrapper"><span
                            className="searching">Searching...</span><span className="fetch-spinner"><Spinner
                            size="small" /></span></div>}
                        {searchedProducts && searchedProducts.map((item) => (
                            <div className={'product-container searchable'} key={item.id}
                                 onClick={chooseOfferProduct(item.id)}>
                                <span className="image-wrapper">
                                    {item.image_src ? (
                                        <div className="item-image"
                                             style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                    ) : (
                                        <NoImage itemId={item.id} className={'item-image'} />
                                    )}
                                </span>
                                <span className="cart-drawer-searchable-item">{item.title}</span>
                            </div>
                        ))}
                    </div>
                </Modal.Section>
            </Modal>
            {/*{toastIsActive && <Toast content="Settings saved" onDismiss={onDismissToast}/>}*/}

        </Frame>
    </div>
};

export default ProductsCartDrawer;
