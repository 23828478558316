import { connect } from 'react-redux'
import EditMixAndMatch from "../../components/products/EditMixAndMatch";
import {
    mixAndMatchBoolChange,
    mixAndMatchDeleteProductGroup,
    mixAndMatchDeleteTriggerProductGroup,
    mixAndMatchDiscountAmountChange,
    mixAndMatchDiscountTypeChange,
    mixAndMatchLocaleChange,
    mixAndMatchMinQtyChange, mixAndMatchNavToChange,
    mixAndMatchOfferNameChange,
    mixAndMatchOfferTextChange,
    mixAndMatchOpenSearchCollectionsModal,
    mixAndMatchSaveOffer,
    mixAndMatchSearchCollectionTitleChange,
    mixAndMatchSelectCollection,
    mixAndMatchSetState,
    mixAndMatchToggleSearchCollectionsModal,
    mixAndMatchTriggerDisplayOptionsChange, mixAndMatchTriggerLocationChange
} from "../../actions/mixAndMatch";

const mapStateToProps = state => ({
    mixAndMatch: state.mixAndMatch,
});

const mapDispatchToProps = dispatch => ({
    cancel: () => dispatch(mixAndMatchSetState('offer_list')),
    save: () => dispatch(mixAndMatchSaveOffer()),
    toggleSearchCollectionsModal: () => dispatch(mixAndMatchToggleSearchCollectionsModal()),
    openSearchCollectionsModal: modalType => () => dispatch(mixAndMatchOpenSearchCollectionsModal(modalType)),
    nameChange: text => dispatch(mixAndMatchOfferNameChange(text)),
    discountTypeChange: text => dispatch(mixAndMatchDiscountTypeChange(text)),
    discountAmountChange: text => dispatch(mixAndMatchDiscountAmountChange(text)),
    onNavToChange: text => dispatch(mixAndMatchNavToChange(text)),
    onTriggerDisplayOptionsChange: text => dispatch(mixAndMatchTriggerDisplayOptionsChange(text)),
    searchCollectionTitleChange: text => dispatch(mixAndMatchSearchCollectionTitleChange(text)),
    onLocaleChange: locale => dispatch(mixAndMatchLocaleChange(locale)),
    textChange: attr => text => dispatch(mixAndMatchOfferTextChange(attr, text)),
    triggerLocationChange: attr => bool => dispatch(mixAndMatchTriggerLocationChange(attr, bool)),
    selectCollection: (id, title) => () => dispatch(mixAndMatchSelectCollection(id, title)),
    deleteProductGroup: id => () => dispatch(mixAndMatchDeleteProductGroup(id)),
    deleteTriggerProductGroup: id => () => dispatch(mixAndMatchDeleteTriggerProductGroup(id)),
    minQtyChange: idx => num => dispatch(mixAndMatchMinQtyChange(idx, num)),
    onBoolChange: attr => text => dispatch(mixAndMatchBoolChange(attr, text)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EditMixAndMatch);
