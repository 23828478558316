import React from 'react';
import { Link } from "@shopify/polaris";
import { host } from "../actions/data";
import '../css/Unsubscribed.css';

export const appToHost = {
    'sticky-atc': 'sticky-atc-api.zoorix.com',
}

const Subscribed = ({
    match: { params },
}) => {

    return (
        <div className="unsubscribed">
            <div className="unsubscribed-title">You are now subscribed</div>
            {params.app ?
                <div className="change-mind">Changed your mind? <Link
                    url={`https://${appToHost[params.app]}/subscription/unsubscribe?app=${params.app}&shop_id=${params.shop_id}`}>unsubscribe</Link>
                </div>
                :
                <div className="change-mind">Changed your mind? <Link
                    url={`https://${host}/subscription/unsubscribe?token=${params.token}`}>unsubscribe</Link>
                </div>
            }
        </div>
    );
};

export default Subscribed;
