import { connect } from 'react-redux'
import PartnersAdmin from "../../components/partners/PartnersAdmin";
import {partnersAdminLogin} from "../../actions/partners";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    login: (sub, secret) => dispatch(partnersAdminLogin(sub, secret)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(PartnersAdmin);
