import { connect } from 'react-redux'
import FreeShippingBarSupport from "../../components/free-shipping-bar/FreeShippingBarSupport";
import {
    freeShippingBarSupportSend,
    freeShippingBarSupportTextChange
} from "../../actions/free-shipping-bar/freeShippingBar";

const mapStateToProps = state => ({
    freeShippingBar: state.freeShippingBar,
});

const mapDispatchToProps = (dispatch) => ({
    onTextChange: attribute => text => dispatch(freeShippingBarSupportTextChange(attribute, text)),
    send: () => dispatch(freeShippingBarSupportSend()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FreeShippingBarSupport);
