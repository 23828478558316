import React from 'react';
import {Button, Icon, Modal, Spinner} from "@shopify/polaris";
import '../../css/MixAndMatch.scss';
import { DeleteMajor, ClipboardMinor, ViewMajor } from "@shopify/polaris-icons";

const MixAndMatches = ({mixAndMatch, createOffer, deleteOffer, toggleEnabled, editOffer, data, openShortCodeModal,
                           previewOffer, openDeleteOfferModal}) =>
        <div className="mix-and-matches-list-wrapper">
            <span className="mm-create-offer-btn">
                <Button onClick={createOffer} loading={mixAndMatch.creatingOffer} primary>Create Offer</Button>
            </span>
            {mixAndMatch.offers && mixAndMatch.offers.map(offer => <div className="mix-and-matches-list-item" key={offer.id}>
                <span className="mm-item-name">{offer.name}</span>
                <span className="mm-list-action-wrapper">
                    <span className="mm-item-short-code">
                    <Button plain onClick={openShortCodeModal(offer.id, true)}>Short Code</Button>
                </span>
                <a className="mm-promo-page-link" href={`https://${data.shopName}/pages/${mixAndMatch.promoPage.handle}?mnm_oid=${offer.id}`}
                   rel="noopener noreferrer" target="_blank">Promo Page</a>
                    <span className="mm-item-enable-disable-btn">
                    <Button primary={!offer.enabled} onClick={toggleEnabled(offer.id)}>{offer.enabled ? 'Disable' : 'Enable'}</Button>
                </span>
                    <span className="mm-item-edit-btn">
                    <Button onClick={editOffer(offer.id)}>Edit</Button>
                </span>

                <span onClick={openDeleteOfferModal(offer.id, true)} className="remove-item mm-remove-list-item">
                    <Icon source={DeleteMajor}/>
                </span>
                <span className="view-mnm-item">
                    {mixAndMatch.previewOfferLoading[offer.id] ?
                        <Spinner size="small" /> :
                        <span onClick={previewOffer(offer.id)}>
                            <Icon source={ViewMajor}/>
                        </span>}
                </span>
                </span>
            </div>)}
            <Modal title={'Short code'} open={mixAndMatch.shortCodeModalOpen} onClose={openShortCodeModal(null, false)}>
                <span onClick={() => navigator.clipboard.writeText(mixAndMatch.shortCode)} className="copy-short-code">
                        <Icon source={ClipboardMinor}/>
                    </span>
                <div className="mm-short-code-wrapper">

                    <code>
                        {mixAndMatch.shortCode}
                    </code>
                </div>
            </Modal>
            <Modal title={'Delete Offer'} open={mixAndMatch.deleteOfferModalOpen} onClose={openDeleteOfferModal(null, false)}
                   primaryAction={{
                       content: 'Delete',
                       onAction: deleteOffer(mixAndMatch.offerToDelete),
                       loading: mixAndMatch.deletingOffer,
                   }}>
                <Modal.Section>
                    Are you sure you want to delete the offer? This action cannot be undone!
                </Modal.Section>
            </Modal>
        </div>;

export default MixAndMatches;
