import { connect } from 'react-redux'
import EmeraldTable from "../../components/plans/EmeraldTable";

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(EmeraldTable);
