import { connect } from 'react-redux'
import ProductsMain from "../../components/products/ProductsMain";
import {
    bundleClicked,
    cartDrawerClicked, mixAndMatchClicked,
    sliderClicked,
    volumeDiscountClicked,
    warrantyClicked
} from "../../actions/nav";
import {loadProducts} from "../../actions/products";

const mapStateToProps = state => ({
    isSliderVisible: state.nav.isSliderVisible,
    isBundleVisible: state.nav.isBundleVisible,
    isVolumeVisible: state.nav.isVolumeVisible,
    isWarrantyVisible: state.nav.isWarrantyVisible || state.nav.isAppVisible,
    isCartDrawerVisible: state.nav.isCartDrawerVisible,
    isMixAndMatchVisible: state.nav.isMixAndMatchVisible,
    ready: state.products.ready,

    appType: state.data.appType,
});

const mapDispatchToProps = (dispatch) => ({
    onSliderClicked: () => dispatch(sliderClicked()),
    onBundleClicked: () => dispatch(bundleClicked()),
    onWarrantyClicked: () => dispatch(warrantyClicked()),
    onVolumeClicked: () => dispatch(volumeDiscountClicked()),
    onCartDrawerClicked: () => dispatch(cartDrawerClicked()),
    onMixAndMatchClicked: () => dispatch(mixAndMatchClicked()),
    load: () => dispatch(loadProducts()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(ProductsMain);
