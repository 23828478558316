import React from 'react';
import '../../css/ProductsBundle.css'
import {
    Button, Checkbox,
    FormLayout,
    Icon,
    Modal, Popover,
    Select,
    Spinner,
    TextField,
    TextStyle
} from "@shopify/polaris";
import NoImage from "./NoImage";
import {DeleteMajor} from "@shopify/polaris-icons";
import {addByFilterOptions} from "./ProductsSlider";
import {discountTypeOptions} from "./VolumeDiscountEdit";

export const typeOptions = [
    { label: 'Fixed amount', value: 'fixed' },
    { label: 'Percentage', value: 'percentage' },
    { label: 'Quantitative', value: 'quantitative' },
];

export const qtyModifiersOptions = [
    { label: 'Quantity modifier', value: 'qty' },
    { label: 'Single selection', value: 'single' },
];

const autoTypeOptions = [
    { label: 'Fixed amount', value: 'fixed' },
    { label: 'Percentage', value: 'percentage' },
];

const autoGenOptions = [
    { label: 'Using orders', value: 'orders' },
    { label: 'Randomly', value: 'random' },
];

export const navigateToOptions = [
    { label: 'Default (Settings)', value: 'default' },
    { label: 'Checkout', value: 'checkout' },
    { label: 'Cart', value: 'cart' },
    { label: 'None', value: 'none' },
];

export const showOnProductPageOptions = [
    { label: 'Default (Settings)', value: 'default' },
    { label: 'None', value: 'none' },
    { label: 'Below add to cart', value: 'main' },
    { label: 'Bottom', value: 'bottom' },
    { label: 'Both Bottom and below add to cart', value: 'main_and_bottom' },
];

const displayOptions = [
    { label: 'Display on bundle items', value: 'bundle' },
    { label: 'Display on selected items', value: 'following' },
];

export const titles = {
    1: 'Second product:',
    2: 'Third product:',
    3: 'Forth product:',
    4: 'Fifth product:',
    5: 'Sixth product:',
    6: 'Seventh product:',
    7: 'Eighth product:',
}

export const numbers = {
    1: 'two',
    2: 'three',
    3: 'four',
    4: 'five',
    5: 'six',
    6: 'seven',
    7: 'eight or more',
}


const BundleEdit = ({
    onBack, onSave, saveError, isSaving, bundle, onBundleNameChange, onAmountChange, onTypeChange,
    currencySymbol, chooseProduct, chooseProductOpen, onCloseChooseProduct,
    searchBoxText, onChangeSearchText, onFocusSearchText, showSearchSpinner, searchError,
    searchedProducts, chooseBundleItem, onAmount2Change, removeSourceProduct, removeProduct,
    onDisplayChange, onBundleCartTopChange, onBundleCartBottomChange, onAlwaysShownOnCartChange, chooseVariants,
    chooseVariantsOpen, onCloseChooseVariants, chooseVariantsAction, variants, onInBundleChanged, onBundleSubtitleChange,
    onNavigateToChange, isGenAutoBundle, onGen, onAutoGenChange, openQuantitativeModal, quantitativeModalOpen,
    onCloseQuantitativeModal, removeSourceGroup, groupAddition, toggleAddGroupActive, onGroupTypeChange,
    onGroupValueChange, onAddGroup, onShowOnProductPageChange, locales, onLocaleChange, locale,
    onBundleSemanticNameChange, onQtyModifiersChange, onDiscountTypeChange,
}) => (
    <div className="bundle-edit-form-layout">
        <FormLayout>
            <div className="navigation bundle-edit-nav">
                <span className="navigation-button">
                    <Button onClick={onBack} disabled={isSaving}>Back</Button>
                </span>
                {isGenAutoBundle ?
                <span className="navigation-button">
                    <Button onClick={onGen} primary loading={isSaving}>Generate</Button>
                </span>
                    :
                <span className="navigation-button">
                    <Button onClick={onSave} primary loading={isSaving}
                            disabled={!bundle.products[0]}
                    >Save</Button>
                </span>}
            </div>
            {saveError && <TextStyle variation="negative">There was an error sending your request. Please try again in a
            few minutes</TextStyle>}
            {isGenAutoBundle && <Select label="Bundle generation method:" options={autoGenOptions}
                                        onChange={onAutoGenChange} value={bundle.auto_type} />}
            {!isGenAutoBundle &&  <div className="bundle-name-wrapper">
                <span className="bundle-name-label">Bundle name:</span>
                <span className="field-tip">(for internal use, will not be visible on your store)</span>
                <div className="bundle-name-text-field">
                    <TextField value={bundle.semantic_name}
                               onChange={onBundleSemanticNameChange}
                               disabled={isSaving} autoComplete={false} />
                </div>
            </div> }
            <div className="bundle-titles-locales">
            <span className="bundle-titles-wrapper"><TextField label="Bundle title:" value={bundle.bundleTranslations[locale].name} onChange={onBundleNameChange}
                       disabled={isSaving} autoComplete={false} />
            <TextField label="Bundle subtitle:" value={bundle.bundleTranslations[locale].subtitle} autoComplete={false}
                       onChange={onBundleSubtitleChange} disabled={isSaving} /></span>
                {locales.length > 1 && <span className="bundle-locale"><Select label="Locale:" options={locales} onChange={onLocaleChange}
                                                                               value={locale} /></span>}

            </div>
            {!isGenAutoBundle && <Select label="Bundle type:" options={typeOptions} onChange={onTypeChange}
                                         value={bundle.type} />}
            {isGenAutoBundle && <Select label="Bundle type:" options={autoTypeOptions} onChange={onTypeChange}
                                         value={bundle.type} />}
            {(bundle.type === 'fixed' || bundle.type === 'percentage') &&
            <TextField label={`Discount amount in ${bundle.type === 'fixed' ? currencySymbol : '%'}`} type="number"
                       value={bundle.amount && bundle.amount.toString()} autoComplete={false}
                       onChange={onAmountChange} disabled={isSaving} />}
            {bundle.type === 'quantitative' && <div>
                <Select label={'Selection options'} options={qtyModifiersOptions} onChange={onQtyModifiersChange}
                        value={bundle.qty_modifiers ? 'qty' : 'single'} />
                <div style={{marginTop: '17px'}}>
                    <Button primary onClick={openQuantitativeModal}>Discounts</Button>
                </div>
            </div>}
            {!isGenAutoBundle && <div>
                <Select label="Display options:" options={displayOptions} onChange={onDisplayChange} value={bundle.display} />
                {bundle.display === 'following' && <div>
                    {bundle.source_products && bundle.source_products.map((product, index) =>
                        <div className="bundle-display-group" key={product.shopify_product_id}>
                            <span onClick={removeSourceProduct(product.shopify_product_id)} className="remove-item">
                                <Icon source={DeleteMajor} />
                            </span>
                            {product.image_src ? (
                                <div className="bundle-product-image show-on"
                                     style={{ 'backgroundImage': 'url(' + product.image_src + ')' }} />
                            ) : (
                                <NoImage itemId={product.shopify_product_id} className={'bundle-product-image show-on'} />
                            )}
                            <div className="product-to-show-on-title">{product.title}</div>

                        </div>)}
                    {bundle.source_groups && bundle.source_groups.map(group =>
                        <div className="bundle-display-group" key={group.id}>
                            <span onClick={removeSourceGroup(group.id)}>
                                <Icon source={DeleteMajor} />
                            </span>
                            <span className="bundle-display-group-text">
                            {addByFilterOptions.filter(o => o.value === group.filter1_type)[0].label}:&nbsp;
                            {group.filter1_value}
                            </span>
                        </div>)}
                    <div className="display-options-buttons">
                        <div className="add-product-button-wrapper">
                            <Button onClick={chooseProduct('source')}>Add product</Button>
                        </div>
                        <div className="add-group-button-wrapper">
                            <Popover
                                active={groupAddition.addGroupActive}
                                activator={<Button onClick={toggleAddGroupActive} disclosure>Add group</Button>}
                                onClose={toggleAddGroupActive}
                                ariaHaspopup={false}
                                sectioned
                            >
                                <FormLayout>
                                    <Select options={addByFilterOptions} onChange={onGroupTypeChange} value={groupAddition.groupType} />
                                    <Select options={groupAddition.groupValues} onChange={onGroupValueChange} value={groupAddition.groupValue} />
                                    <Button primary onClick={onAddGroup} loading={groupAddition.isAddingGroup}>Add</Button>
                                </FormLayout>
                            </Popover>
                        </div>
                    </div>
                </div>}
            </div>}
            <Select label="Navigate to:" options={navigateToOptions} onChange={onNavigateToChange}
                    value={bundle.navigate_to} disabled={isSaving} />
            {<Select label="Location on product page:" options={showOnProductPageOptions}
                    onChange={onShowOnProductPageChange}
                    value={bundle.show_on_product_page} disabled={isSaving} />}
            <div>
                <Checkbox label="Show at cart top" checked={bundle.show_on_cart_top} onChange={onBundleCartTopChange}
                          disabled={isSaving} />
                <span style={{marginLeft: '20px'}}>
                <Checkbox label="Show at cart bottom" checked={bundle.show_on_cart_bottom} onChange={onBundleCartBottomChange}
                          disabled={isSaving} />
                </span>
                <span style={{marginLeft: '20px'}}>
                <Checkbox label="Keep showing when all bundle items in cart" checked={bundle.always_shown_on_cart} onChange={onAlwaysShownOnCartChange}
                          disabled={isSaving} />
                </span>
            </div>

            {!isGenAutoBundle && <div>
                <div className="bundle-product-label">
                    First product:
                </div>
                <div className="bundle-line-item-wrapper">
                    {bundle.products[0] && <span className="bundle-line-item">

                        {bundle.products[0].image_src ? (
                            <div className="bundle-product-image"
                                style={{ 'backgroundImage': 'url(' + bundle.products[0].image_src + ')' }} />
                        ) : (
                                <NoImage itemId={bundle.products[0].shopify_product_id} className={'bundle-product-image'} />
                            )}
                        <div className="bundle-item-title">{bundle.products[0].title}</div>
                    </span>}

                    <span className="choose-bundle-product">
                        {bundle.products[0] && bundle.products[0].variants && bundle.products[0].variants.length > 1 && <span className="bundle-variant-button">
                            <Button onClick={chooseVariants(0)}>Select Variants</Button>
                        </span>}
                        <Button onClick={chooseProduct(0)}>Select Product</Button>
                    </span>
                </div>
            </div>}

            {!isGenAutoBundle && [1,2,3,4,5,6,7].map(i => (
                <div key={i}>
                    <div className="bundle-product-label">
                        {titles[i]}
                    </div>
                    <div className="bundle-line-item-wrapper">
                        {bundle.products[i] && <span className="bundle-line-item">
                        {bundle.products[i].image_src ? (
                            <div className="bundle-product-image"
                                 style={{ 'backgroundImage': 'url(' + bundle.products[i].image_src + ')' }} />
                        ) : (
                            <NoImage itemId={bundle.products[i].shopify_product_id} className={'bundle-product-image'} />
                        )}
                            <div className="bundle-item-title">{bundle.products[i].title}</div>
                    </span>}

                        <span className="choose-bundle-product">
                            {bundle.products[i] && bundle.products[i].variants && bundle.products[i].variants.length > 1 && <span className="bundle-variant-button">
                                <Button onClick={chooseVariants(i)}>Select Variants</Button>
                            </span>}
                        <Button onClick={chooseProduct(i)}>Select Product</Button>
                    </span>
                        {bundle.products[i] && <span onClick={removeProduct(i)} className="remove-item">
                        <Icon source={DeleteMajor} />
                    </span>}
                    </div>
                </div>
            ))}

            {!isGenAutoBundle && <div className="bundle-save-button">
                <Button onClick={onSave} primary loading={isSaving}
                        disabled={!bundle.products[0]}
                >Save</Button>
            </div>}

            <Modal open={chooseVariantsOpen} onClose={onCloseChooseVariants} title="Select variants"
                   primaryAction={{
                       content: 'Select',
                       onAction: chooseVariantsAction,
                   }}
                   secondaryActions={[
                       {
                           content: 'Cancel',
                           onAction: onCloseChooseVariants,
                       },
                   ]}>
                <Modal.Section>
                    <div className="bundle-variant-selection">
                    {variants && variants.map(variant => (
                        <div className="bundle-variant-item" key={variant.shopify_id}>
                            <Checkbox checked={variant.in_bundle}
                                      onChange={onInBundleChanged(variant.shopify_id)} />
                            {variant.image_src && <img alt="zoorix default" src={variant.image_src} className="bundle-variant-item-img"/>}

                            <span className="bundle-variant-item-title">{variant.title}</span>

                        </div>
                    ))}
                    </div>
                </Modal.Section>
            </Modal>
            <Modal secondaryActions={{
                content: 'Done',
                onAction: onCloseQuantitativeModal,
            }} open={quantitativeModalOpen} onClose={onCloseQuantitativeModal} title="Quantitative discounts"
                   >
                <Modal.Section>
                    <div className="qty-bundle-discount-wrapper">
                        <TextField label={<span>Discount for selecting <b>one</b> bundle item</span>} type="number"
                                   value={(typeof bundle.amount === 'number') && bundle.amount.toString()} autoComplete={false}
                                   onChange={onAmountChange} disabled={isSaving} />
                        <Select label="Type" options={discountTypeOptions(currencySymbol)}
                                onChange={onDiscountTypeChange(0)} value={bundle.discount_types[0] || 'percentage'} />
                    </div>
                    {[1,2,3,4,5,6,7].map(i =>
                        <div className="qty-bundle-discount-wrapper" key={i}>
                            <TextField label={<span>Discount for selecting <b>{numbers[i]}</b> bundle items</span>} type="number"
                                       value={(typeof bundle[`amount${i + 1}`] === 'number') && bundle[`amount${i + 1}`].toString()} autoComplete={false}
                                       onChange={onAmount2Change(i)} disabled={isSaving} />
                            <Select label="Type" options={discountTypeOptions(currencySymbol)}
                                    onChange={onDiscountTypeChange(i)} value={bundle.discount_types[i] || 'percentage'} />
                        </div>)}
                </Modal.Section>
            </Modal>
            <Modal open={chooseProductOpen} onClose={onCloseChooseProduct}
                title="Select a product"
                secondaryActions={[{ content: 'Cancel', onAction: onCloseChooseProduct }]}>
                <Modal.Section>
                    <div className="search-products bundle">
                        <TextField placeholder='Search phrase, e.g. Shirts for men' autoComplete={false}
                            label="Search:"
                            value={searchBoxText} onChange={onChangeSearchText}
                            onFocus={onFocusSearchText} />
                        {showSearchSpinner && <div className="fetch-search-wrapper"><span
                            className="searching">Searching...</span><span className="fetch-spinner"><Spinner
                                size="small" /></span></div>}
                        {searchError && <TextStyle variation="negative">Something went wrong</TextStyle>}
                        {searchedProducts && searchedProducts.map((item) => (
                            <div className={'product-container searchable bundle'} key={item.id}
                                onClick={chooseBundleItem(item.id)}>
                                <span className="image-wrapper">
                                    {item.image_src ? (
                                        <div className="item-image bundle"
                                            style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                    ) : (
                                            <NoImage itemId={item.id} className={'item-image bundle'} />
                                        )}
                                </span>
                                <span className="bundle-searchable-item">{item.title}</span>
                            </div>
                        ))}
                    </div>
                </Modal.Section>
            </Modal>
        </FormLayout >
        </div>
    );

export default BundleEdit;
