import React from 'react';
import {host} from "../actions/data";
import ImageUpload from "./ImageUpload";
import '../css/WarrantyRedeem.css'
import {Button, Modal, Spinner, TextContainer, TextField, TextStyle, Card} from "@shopify/polaris";

const Redeem = ({match: { params }, initRedeem, failInit, lineItems, redeemItem, itemId, redeemModalOpen,
                    closeRedeemModal, redeemAction, redeemSpinner, redeemError, requestBody, onRequestBodyChange,
                    redeemImageUploadSuccess, showUploadedImage, redeemImageUploadFailure, imageUploadError, currency,
                    noImageError }) => {
    initRedeem(params.redeem_token);
    return <div className="warranty-redeem-container">

            <Card title="Covered products">
        {failInit ? <TextStyle variation="negative">There was an error finding your warranty</TextStyle> :
        <div className="warranty-redeem-list">
            <Card.Section>
                <div className="warranty-redeem-list-item header">
                <span className="warranty-redeem-list-item-name">Name</span>
                <span className="warranty-redeem-list-item-price">Price</span>
                <span className="warranty-redeem-list-item-discount">Discount</span>
                <span className="warranty-redeem-list-item-status">Status</span>
                <span className="warranty-redeem-list-item-redeem-btn">Action</span>
                </div>
            </Card.Section>
            {!lineItems && <span className="spinner-wrapper"><Spinner /></span>}
            {lineItems && lineItems.map((item, index) =>
                <Card.Section key={item.id}>
            <div className="warranty-redeem-list-item">
                <span className="warranty-redeem-list-item-name">{item.name}</span>
                <span className="warranty-redeem-list-item-price"><span className="mobile-prefix">Price: </span>{item.price}{currency}</span>
                <span className="warranty-redeem-list-item-discount"><span className="mobile-prefix">Discount: </span>{item.discount_percentage}%</span>
                <span className="warranty-redeem-list-item-status">
                {item.status === 'pending' && <span>Request pending</span>}
                {item.status === 'accepted' && <span>Request accepted</span>}
                {item.status === 'rejected' && <span>Request rejected. Reason: {item.reason}</span>}
                </span>
                <span className="warranty-redeem-list-item-redeem-btn">
                    <Button primary onClick={redeemItem(item.id)} disabled={item.status === 'accepted'}>Redeem</Button>
                </span>
            </div></Card.Section>)}
            <Modal open={redeemModalOpen} onClose={closeRedeemModal}
                   title="Redeem"
                   primaryAction={{
                       content: 'Redeem',
                       onAction: redeemAction(params.redeem_token),
                   }}
                   secondaryActions={[
                       {
                           content: 'Cancel',
                           onAction: closeRedeemModal,
                       },
                   ]}>
                <Modal.Section>
                    {redeemSpinner ? (
                        <span className="spinner-wrapper">
                            <Spinner />
                        </span>
                    ) : <TextContainer>
                        {redeemError && <TextStyle variation="negative">Something went wrong. Please try again in a few
                            minutes</TextStyle>}
                        {noImageError && <TextStyle variation="negative">You must upload am image</TextStyle>}
                        <TextField label="Request details:" multiline={3} value={requestBody} onChange={onRequestBodyChange} />
                        {showUploadedImage && <img src={`https://public.zoorix.com/images/warranty_redemption_line_items/${itemId}?${Date.now()}`}
                             alt="uploaded" height={'100px'} width={'100px'}/>} <br/>
                        {imageUploadError && <TextStyle variation="negative">There was an error uploading your image <br/></TextStyle>}
                        Attach a picture: <br/>
                        <ImageUpload
                            presignedUrl={`https://${host}/image/get_url_for_redeem?item_id=${itemId}`}
                            uploadSuccess={redeemImageUploadSuccess}
                            uploadFailure={redeemImageUploadFailure}
                        />
                    </TextContainer>}
                </Modal.Section>
            </Modal>

        </div>
        }

            </Card>

    </div>
};

export default Redeem;
