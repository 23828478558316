import { connect } from 'react-redux'
import FeedbackLandingPage from "../../components/feedback/FeedbackLandingPage";
import {feedbackLandingPageRate, initFeedbackToken} from "../../actions/feedback";

const mapStateToProps = state => ({
});

const mapDispatchToProps = (dispatch) => ({
    initFeedback: token => dispatch(initFeedbackToken(token)),
    onRate: rating => () => dispatch(feedbackLandingPageRate(rating)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(FeedbackLandingPage);
