import React from 'react';
import {
    Button, Modal,
    Frame, Pagination, Select, Spinner, TextStyle, TextField, Toast, ChoiceList,
} from "@shopify/polaris";
import '../css/WarrantyModeration.css'
import Moment from "react-moment";

const typeOptions = [
    { label: 'Pending', value: 'pending' },
    { label: 'Rejected', value: 'rejected' },
    { label: 'Accepted', value: 'accepted' },
];

const WarrantyModeration = ({ onTypeChange, type, fetchingItems, filterError, page, onPrevious, maxPage, onNext, items,
    currencySymbol, viewOrder, viewOrderLoading, orderModalOpen, order, closeOrderModal,
    viewRequest, requestModalOpen, requestBody, redeemHasImage, redeemImageUrl,
    closeRequestModal, rejectWarrantyRedemptionRequest, rejectModalOpen, closeRejectModal,
    rejectAction, rejectReason, onRejectReasonChange, rejectProcessing, rejectError,
    rejectToastIsActive, onDismissToast, acceptWarrantyRedemptionRequest, acceptModalOpen,
    closeAcceptModal, acceptAction, acceptProcessing, acceptError, discountAmount,
    onDiscountAmountChange, acceptToastIsActive, acceptedItemPrice, acceptedItemPercentage, createManual,
    createManualModal, closeCreateManualModal, createManualAction, orderName, onOrderNameChange, selectVariant,
    fetchingVariants, fetchingVariantsError, variants, selectedVariants, onSelectedVariantChange, discountPercentage,
    selectedVariantPrice, orderEmail, onOrderEmailChange, isCreateManualDisabled, createManualSpinner,
    createManualError }) =>
    <Frame>
        <div className="warranty-mgmt-container">
            <div className="zrx-tab-wrapper-thin">
                <div className="zrx-tab-title">Warrany Managment</div>
                <div className="zrx-tab-subtitle">Manage your warranty redemption process</div>
            </div>
            <div className="warranty-mgmt-top">
                <span className="warranty-mgmt-select-wrapper">
                <Select label="Request Type:" options={typeOptions} onChange={onTypeChange} value={type} />
                    </span>
                <span className="warranty-mgmt-button-wrapper">
                <Button onClick={createManual}>Create Request</Button>
                    </span>

            </div>

            {maxPage > 0 && <Pagination
                label={`Page ${page + 1}`}
                hasPrevious={page > 0}
                onPrevious={onPrevious}
                hasNext={page < maxPage}
                onNext={onNext}
            />}
            {filterError && <TextStyle variation="negative">Something went wrong</TextStyle>}
            {fetchingItems ? <Spinner /> :
                <div>
                    {(!items || items.length === 0) && <div>No items found</div>}
                    {(items && items.length !== 0) && <div className="warranty-mgmt-items">
                        <span className="warranty-mgmt-item-time">Request Time</span>
                        <span className="warranty-mgmt-item-name">Product Name</span>
                        <span className="warranty-mgmt-item-price">Price</span>
                        {items[0] && items[0].status === 'accepted' && <span className="warranty-mgmt-item-discount">
                                Discount
                            </span>}
                        <span className="warranty-mgmt-item-buttons"></span>

                    </div>}
                    {items && items.map((item, index) =>
                        <div className="warranty-mgmt-items" key={item.id}>
                            <span className="warranty-mgmt-item-time">
                                <Moment format="YYYY/MM/DD HH:mm:ss">{item.updated_at}</Moment>
                            </span>
                            <span className="warranty-mgmt-item-name">
                                {item.name}
                            </span>
                            <span className="warranty-mgmt-item-price">
                                {item.price}{currencySymbol}
                            </span>
                            {item.status === 'accepted' && <span className="warranty-mgmt-item-discount">
                                {item.discount_amount}{currencySymbol}
                            </span>}
                            <span className="warranty-mgmt-item-buttons">

                                <Button onClick={viewOrder(item.id)} loading={viewOrderLoading[item.id]}>View order</Button>
                                <Button onClick={viewRequest(item.id)}>View request</Button>
                                {item.status === 'pending' && <div className="warranty-mgmt-item-pending-actions">
                                    <Button onClick={acceptWarrantyRedemptionRequest(item.id)}>Accept</Button>
                                    <Button destructive onClick={rejectWarrantyRedemptionRequest(item.id)}>Reject</Button>
                                </div>}
                            </span>
                        </div>)}
                </div>}
            <Modal open={orderModalOpen} onClose={closeOrderModal} title="Order">
                <Modal.Section>
                    {order && order.data ?
                        <div>
                            <b>Processed at:</b> <Moment format="YYYY/MM/DD HH:mm:ss">{order.data.data.order.processedAt}</Moment> <br />
                            {order.data.data.order.email && <div><b>Email:</b> {order.data.data.order.email}</div>}
                            {order.data.data.order.phone && <div><b>Phone:</b> {order.data.data.order.phone}</div>}
                        <b>Order items:</b> <br />
                            {order.data.data.order.lineItems.edges.map(item =>
                                <div key={item.node.id}>
                                    {item.node.name}, Quantity: {item.node.quantity}, Price: {item.node.variant && item.node.variant.price} {order.data.data.order.currencyCode}
                                </div>)}
                        </div>
                        :
                        <div>
                            This request is not linked to an order
                        </div>
                    }
                </Modal.Section>
            </Modal>
            <Modal open={requestModalOpen} onClose={closeRequestModal} title="Request">
                <Modal.Section>
                    Request body: {requestBody} <br />
                    {redeemHasImage && <img src={redeemImageUrl} alt="redemption" width={'100%'} />}
                </Modal.Section>
            </Modal>
            <Modal open={rejectModalOpen} onClose={closeRejectModal} title="Reject"
                primaryAction={{
                    content: 'Reject',
                    onAction: rejectAction,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: closeRejectModal,
                    },
                ]}>
                <Modal.Section>
                    {rejectProcessing ? <Spinner />
                        :
                        <div>
                            {rejectError && <TextStyle variation="negative">Something went wrong. Please try again later.</TextStyle>}
                            <TextField label="Reject reason:" value={rejectReason} onChange={onRejectReasonChange} />
                        </div>
                    }
                </Modal.Section>
            </Modal>
            <Modal open={acceptModalOpen} onClose={closeAcceptModal} title="Accept"
                primaryAction={{
                    content: 'Accept',
                    onAction: acceptAction,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: closeAcceptModal,
                    },
                ]}>
                <Modal.Section>
                    {acceptProcessing ? <Spinner />
                        :
                        <div>
                            {acceptError && <TextStyle variation="negative">Something went wrong. Please try again later.<br /></TextStyle>}
                            {acceptedItemPercentage}% off of {acceptedItemPrice}{currencySymbol} is&nbsp;
                        {acceptedItemPrice * acceptedItemPercentage / 100}{currencySymbol}.
                        <TextField label={`Discount in ${currencySymbol}:`} type="number"
                                   value={discountAmount && discountAmount.toString()}
                                   onChange={onDiscountAmountChange} />
                        </div>
                    }
                </Modal.Section>
            </Modal>
            <Modal open={createManualModal} onClose={closeCreateManualModal} title="Create Request"
                primaryAction={{
                    content: 'Create',
                    onAction: createManualAction,
                    disabled: isCreateManualDisabled,
                }}
                secondaryActions={[
                    {
                        content: 'Cancel',
                        onAction: closeCreateManualModal,
                    },
                ]}>
                <Modal.Section>
                    {createManualSpinner ? <Spinner /> : <div>
                        <TextField label="Order name:" value={orderName} onChange={onOrderNameChange} />
                        <div className="warranty-mgmt-select-order-button">
                            <Button onClick={selectVariant} loading={fetchingVariants}>Select</Button>
                        </div>
                        {(fetchingVariantsError || createManualError) &&
                            <TextStyle variation="negative">Something went wrong</TextStyle>}
                        {variants.length > 0 && <div>
                            <ChoiceList
                                title="Choose item:"
                                choices={variants}
                                selected={selectedVariants}
                                onChange={onSelectedVariantChange}
                            />
                            <div className="warranty-mgmt-select-order-discount">
                            {discountPercentage}% off of {selectedVariantPrice}{currencySymbol} is&nbsp;
                            {discountPercentage * selectedVariantPrice / 100}{currencySymbol}.
                            </div>
                        </div>}
                        <TextField label={`* Discount in ${currencySymbol}:`} type="number"
                                   value={discountAmount && discountAmount.toString()}
                                   onChange={onDiscountAmountChange} />
                        <TextField label="* Customer email:" value={orderEmail} onChange={onOrderEmailChange} />
                    </div>}
                </Modal.Section>
            </Modal>
            {rejectToastIsActive && <Toast content="Request rejected" onDismiss={onDismissToast} />}
            {acceptToastIsActive && <Toast content="Request accepted" onDismiss={onDismissToast} />}
        </div>
    </Frame>

export default WarrantyModeration;
