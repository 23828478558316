import React from 'react';
import {Frame, Toast, Card, Spinner, TextStyle} from "@shopify/polaris";
import '../../css/Volume.css'
import VisibleVolumeDiscounts from "../../containers/products/VisibleVolumeDiscounts";
import VisibleVolumeDiscountEdit from "../../containers/products/VisibleVolumeDiscountEdit";

const ProductsVolume = ({ toastIsActive, onDismissToast, isShowEdit, isShowDiscounts, ready, load, loadError,
                            productsVolume, onDismissGeneralToast }) => {
    load();
    return <div>
        <Frame className="frame-wrapper">
            {loadError && <TextStyle variation="negative">There was an error loading volume discounts.</TextStyle>}
            <div className={`warranty-card-wrapper ${ready && 'warranty-loaded'}`}>
                {ready ?
                    <Card>
                        <Card.Section sectioned>
                            {isShowDiscounts && <VisibleVolumeDiscounts/>}
                            {isShowEdit && <VisibleVolumeDiscountEdit/>}
                            {toastIsActive && <Toast content="Settings saved" onDismiss={onDismissToast}/>}
                        </Card.Section>
                    </Card>
                    :
                    <span className="spinner-wrapper"><Spinner/></span>}
            </div>
            {productsVolume.generalToastIsActive && <Toast content={productsVolume.generalToastCotent} onDismiss={onDismissGeneralToast}/>}
        </Frame>
    </div>
};

export default ProductsVolume;
