import { connect } from 'react-redux'
import VolumeDiscounts from "../../components/products/VolumeDiscounts";
import {
    createVolumeDiscountApi,
    volumeCloseRemoveDiscountModal, volumeCopyShortCode,
    volumeEditDiscount,
    volumeOpenRemoveDiscountModal,
    volumePreviewDiscount,
    volumeRemoveDiscountAction,
    volumeShowShortCode,
    volumeSwitchEnable,
} from "../../actions/productsVolume";

const mapStateToProps = state => ({
    discounts: state.productsVolume.discounts,
    creatingDiscount: state.productsVolume.creatingDiscount,
    removeDiscountModalOpen: state.productsVolume.removeDiscountModalOpen,
    removeDiscountSpinner: state.productsVolume.removeDiscountSpinner,
    discountIdToDelete: state.productsVolume.discountIdToDelete,
    removeDiscountError: state.productsVolume.removeDiscountError,
    editingDiscount: state.productsVolume.editingDiscount,
    switchEnableLoading: state.productsVolume.switchEnableLoading,
    productsVolume: state.productsVolume,
});

const mapDispatchToProps = dispatch => ({
    createDiscount: () => dispatch(createVolumeDiscountApi()),
    copyShortCode: () => dispatch(volumeCopyShortCode()),
    closeRemoveDiscountModal: () => dispatch(volumeCloseRemoveDiscountModal()),
    removeDiscountAction: () => dispatch(volumeRemoveDiscountAction()),
    removeDiscount: id => () => dispatch(volumeOpenRemoveDiscountModal(id)),
    editDiscount: id => () => dispatch(volumeEditDiscount(id)),
    switchEnable: id => () => dispatch(volumeSwitchEnable(id)),
    previewDiscount: id => () => dispatch(volumePreviewDiscount(id)),
    showShortCode: (id, bool) => () => dispatch(volumeShowShortCode(id, bool)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VolumeDiscounts);
