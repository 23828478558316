import React from 'react';
import '../../../css/v2/AppV2Wizard.scss';
import VisibleAppV2WizardConfigCrossSell from "../../../containers/v2/wizard/VisibleAppV2WizardConfigCrossSell";
import VisibleAppV2WizardConfigBundle from "../../../containers/v2/wizard/VisibleAppV2WizardConfigBundle";
import VisibleAppV2WizardConfigVolume from "../../../containers/v2/wizard/VisibleAppV2WizardConfigVolume";
import VisibleAppV2WizardConfigMixAndMatch from "../../../containers/v2/wizard/VisibleAppV2WizardConfigMixAndMatch";

const AppV2WizardConfigOffer = ({ appV2Wizard, next, back }) =>
    <div className={'app-v2-wizard-config-offer'}>
        <div className="wizard-select-trigger-title">Create the offer</div>
        <div className="wizard-select-trigger-subtitle">Select products to offer and set a discount</div>
        {appV2Wizard.offerType === 'cross_sell' && <div className="select-trigger-wrapper">
            <VisibleAppV2WizardConfigCrossSell />
        </div>}
        {appV2Wizard.offerType === 'bundle' && <div>
            <VisibleAppV2WizardConfigBundle />
        </div>}
        {appV2Wizard.offerType === 'volume_discount' && <div>
            <VisibleAppV2WizardConfigVolume />
        </div>}
        {appV2Wizard.offerType === 'mix_and_match' && <div>
            <VisibleAppV2WizardConfigMixAndMatch />
        </div>}
        <div className={'nav-buttons-wrapper'}>
            <div onClick={back} className={'app-v2-wizard-back-btn'}>Back</div>
            <div onClick={next} className={'app-v2-wizard-next-btn'}>Finalize Offer</div>
        </div>
    </div>;

export default AppV2WizardConfigOffer;
