import React from 'react';
import {Spinner} from '@shopify/polaris'
import VisibleProductsSlider from "../../containers/products/VisibleProductsSlider";
import '../../css/ProductsMain.css'
import VisibleProductsBundle from "../../containers/products/VisibleProductsBundle";
import VisibleProductsWarranty from "../../containers/products/VisibleProductsWarranty";
import VisibleProductsVolume from "../../containers/products/VisibleProductsVolume";
import VisibleProductsCartDrawer from "../../containers/products/VisibleProductsCartDrawer";
import VisibleProductsMixAndMatch from "../../containers/products/VisibleProductsMixAndMatch";

const ProductsMain = ({ onSliderClicked, onBundleClicked, isSliderVisible,
                          isBundleVisible, isWarrantyVisible, onWarrantyClicked, load, ready, isVolumeVisible,
                          onVolumeClicked, appType, isCartDrawerVisible, onCartDrawerClicked, isMixAndMatchVisible,
                          onMixAndMatchClicked }) => {
    load();
    return <div className="products-wrapper">
        {!appType && <div className="slider">
            <div className={'sidebar-item' + (isSliderVisible ? ' selected' : '')} onClick={onSliderClicked}>Slider
            </div>
            <div className={'sidebar-item' + (isBundleVisible ? ' selected' : '')} onClick={onBundleClicked}>Bundle
            </div>
            <div className={'sidebar-item' + (isWarrantyVisible ? ' selected' : '')}
                 onClick={onWarrantyClicked}>Warranty
            </div>
            <div className={'sidebar-item' + (isCartDrawerVisible ? ' selected' : '')}
                 onClick={onCartDrawerClicked}>Cart Drawer
            </div>
            <div className={'sidebar-item' + (isVolumeVisible ? ' selected' : '')} onClick={onVolumeClicked}>Volume
                Discount
            </div>
            <div className={'sidebar-item' + (isMixAndMatchVisible ? ' selected' : '')} onClick={onMixAndMatchClicked}>Mix & Match</div>
        </div>}
        {!ready && <span className="spinner-wrapper product-mapping-spinner"><Spinner /></span>}
        {isSliderVisible && ready && <div className="products-item">
            <div className="zrx-tab-wrapper-settings">
                <div className="zrx-tab-title">Slider Offers</div>
                <div className="zrx-tab-subtitle">Select triggers and products to offer on the slider</div>
            </div>
            <VisibleProductsSlider /></div>}
        {isBundleVisible && <div className="products-item bundle-products-item">
            <div className="zrx-tab-wrapper-settings">
                <div className="zrx-tab-title">Bundle Offers</div>
                <div className="zrx-tab-subtitle">Build bundle offers and set fixed, precentage or quantitative discounts</div>
            </div>
            <VisibleProductsBundle />
        </div>}
        {isWarrantyVisible && <div className="products-item warranty-products-item">
            <div className="zrx-tab-wrapper-settings">
                <div className="zrx-tab-title">Warranty Offers</div>
                <div className="zrx-tab-subtitle">Offer a warranty upsell and manage the redemption process.</div>
            </div>
            <VisibleProductsWarranty />
        </div>}
        {isCartDrawerVisible && <div className="products-item">
            <div className="zrx-tab-wrapper-settings">
                <div className="zrx-tab-title">Cart Drawer Offers</div>
                <div className="zrx-tab-subtitle">Build one-click offers inside the cart drawer</div>
            </div>
            <VisibleProductsCartDrawer />

        </div>}
        {isVolumeVisible && <div className="products-item">
            <div className="zrx-tab-wrapper-settings">
                <div className="zrx-tab-title">Volume Discount Offers</div>
                <div className="zrx-tab-subtitle">Build discounted offers based on the quantity purchased</div>
            </div>
            <VisibleProductsVolume />

        </div>}
        {isMixAndMatchVisible && <div className="products-item">
            <div className="zrx-tab-wrapper-settings">
                <div className="zrx-tab-title">Mix & Match Offers</div>
                <div className="zrx-tab-subtitle">Build discounted offers based on collections</div>
            </div>
            <VisibleProductsMixAndMatch/>

        </div>}

    </div>
};

export default ProductsMain;
