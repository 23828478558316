import React from 'react';
import '../../css/ProductsBundle.css'
import {
    Button, Checkbox,
    FormLayout,
    Icon,
    Modal, Popover,
    Select,
    Spinner,
    TextField,
    TextStyle
} from "@shopify/polaris";
import NoImage from "./NoImage";
import {DeleteMajor} from "@shopify/polaris-icons";
import {addByFilterOptions} from "./ProductsSlider";
import {
    navigateToOptions,
    numbers,
    qtyModifiersOptions,
    showOnProductPageOptions,
    titles,
    typeOptions
} from "./BundleEdit";
import {discountTypeOptions} from "./VolumeDiscountEdit";

const GroupedBundleEdit = ({
    onBack, onSave, saveError, isSaving, bundle, onBundleNameChange, onAmountChange, onTypeChange,
    currencySymbol, chooseProduct, chooseProductOpen, onCloseChooseProduct,
    searchBoxText, onChangeSearchText, onFocusSearchText, showSearchSpinner, searchError,
    searchedProducts, chooseBundleItem, onAmount2Change, removeProduct,
    onBundleCartTopChange, onBundleCartBottomChange, onAlwaysShownOnCartChange, chooseVariants,
    chooseVariantsOpen, onCloseChooseVariants, chooseVariantsAction, variants, onInBundleChanged, onBundleSubtitleChange,
    onNavigateToChange, openQuantitativeModal, quantitativeModalOpen,
    onCloseQuantitativeModal, groupAddition, toggleAddGroupActive, onGroupTypeChange,
    onGroupValueChange, onChooseGroup, onShowOnProductPageChange, locales, onLocaleChange, locale,
    onBundleSemanticNameChange, onQtyModifiersChange, onDiscountTypeChange,
}) => (
    <div className="bundle-edit-form-layout">
    <FormLayout>
            <div className="navigation bundle-edit-nav">
                <span className="navigation-button">
                    <Button onClick={onBack} disabled={isSaving}>Back</Button>
                </span>
                <span className="navigation-button">
                    <Button onClick={onSave} primary loading={isSaving}
                            disabled={!bundle.filter1_value}
                    >Save</Button>
                </span>
            </div>
            {saveError && <TextStyle variation="negative">There was an error sending your request. Please try again in a
            few minutes</TextStyle>}
            <div className="bundle-name-wrapper">
                <span className="bundle-name-label">Bundle name:</span>
                <span className="field-tip">(for internal use, will not be visible on your store)</span>
                <div className="bundle-name-text-field">
                <TextField value={bundle.semantic_name}
                           onChange={onBundleSemanticNameChange}
                           disabled={isSaving} autoComplete={false} />
                    </div>
            </div>
            <div className="bundle-titles-locales">
            <span className="bundle-titles-wrapper">
            <TextField label="Bundle title:" value={bundle.bundleTranslations[locale].name} onChange={onBundleNameChange}
                       disabled={isSaving} autoComplete={false} />
            <TextField label="Bundle subtitle:" value={bundle.bundleTranslations[locale].subtitle} autoComplete={false}
                       onChange={onBundleSubtitleChange} disabled={isSaving} /></span>
                {locales.length > 1 && <span className="bundle-locale"><Select label="Locale:" options={locales} onChange={onLocaleChange}
                                               value={locale} /></span>}
            </div>
            <Select label="Bundle type:" options={typeOptions} onChange={onTypeChange}
                                         value={bundle.type} />
            {(bundle.type === 'fixed' || bundle.type === 'percentage') &&
            <TextField label={`Discount amount in ${bundle.type === 'fixed' ? currencySymbol : '%'}`} type="number"
                       value={bundle.amount && bundle.amount.toString()} autoComplete={false}
                       onChange={onAmountChange} disabled={isSaving} />}
            {bundle.type === 'quantitative' && <div>
                <Select label={'Selection options'} options={qtyModifiersOptions} onChange={onQtyModifiersChange}
                        value={bundle.qty_modifiers ? 'qty' : 'single'} />
                <div style={{marginTop: '17px'}}>
                <Button primary onClick={openQuantitativeModal}>Discounts</Button>
                </div>
            </div> }
            <Select label="Navigate to:" options={navigateToOptions} onChange={onNavigateToChange}
                    value={bundle.navigate_to} disabled={isSaving} />
            {<Select label="Location on product page:" options={showOnProductPageOptions}
                    onChange={onShowOnProductPageChange}
                    value={bundle.show_on_product_page} disabled={isSaving} />}
            <div>
                <Checkbox label="Show at cart top" checked={bundle.show_on_cart_top} onChange={onBundleCartTopChange}
                          disabled={isSaving} />
                <span style={{marginLeft: '20px'}}>
                <Checkbox label="Show at cart bottom" checked={bundle.show_on_cart_bottom} onChange={onBundleCartBottomChange}
                          disabled={isSaving} />
                </span>
                <span style={{marginLeft: '20px'}}>
                <Checkbox label="Keep showing when all bundle items in cart" checked={bundle.always_shown_on_cart}
                          onChange={onAlwaysShownOnCartChange} disabled={isSaving} />
                </span>
            </div>

            <div>
                <div>
                    <div className="display-options-buttons">
                        <div className="add-group-button-wrapper">
                            <Popover
                                active={groupAddition.addGroupActive}
                                activator={<Button onClick={toggleAddGroupActive} disclosure>Choose pivot group</Button>}
                                onClose={toggleAddGroupActive}
                                ariaHaspopup={false}
                                sectioned
                            >
                                <FormLayout>
                                    <Select options={addByFilterOptions} onChange={onGroupTypeChange} value={groupAddition.groupType} />
                                    <Select options={groupAddition.groupValues} onChange={onGroupValueChange} value={groupAddition.groupValue} />
                                    <Button primary onClick={onChooseGroup} loading={groupAddition.isChoosingGroup}>Choose</Button>
                                </FormLayout>
                            </Popover>
                        </div>
                    </div>
                    {bundle.filter1_type && <div className="bundle-display-group">
                        <span className="bundle-display-group-text">
                            <span>
                                {addByFilterOptions.filter(o => o.value === bundle.filter1_type)[0].label}:&nbsp;
                                {bundle.filter1_value}
                            </span>
                        </span>
                    </div>}
                </div>
            </div>

            {[1,2,3,4,5,6,7].map(i =>
                <div key={i}>
                    <div className="bundle-product-label">
                        {titles[i]}
                    </div>
                    <div className="bundle-line-item-wrapper">
                        {bundle.products[i] && <span className="bundle-line-item">
                        {bundle.products[i].image_src ? (
                            <div className="bundle-product-image"
                                 style={{ 'backgroundImage': 'url(' + bundle.products[i].image_src + ')' }} />
                        ) : (
                            <NoImage itemId={bundle.products[i].shopify_product_id} className={'bundle-product-image'} />
                        )}
                            <div className="bundle-item-title">{bundle.products[i].title}</div>
                    </span>}

                        <span className="choose-bundle-product">
                            {bundle.products[i] && bundle.products[i].variants && bundle.products[i].variants.length > 1 && <span className="bundle-variant-button">
                                <Button onClick={chooseVariants(i)}>Select Variants</Button>
                            </span>}
                        <Button onClick={chooseProduct(i)}>Select Product</Button>
                    </span>
                        {bundle.products[i] && <span onClick={removeProduct(i)} className="remove-item">
                        <Icon source={DeleteMajor} />
                    </span>}
                    </div>
                </div>
            )}

            <div className="bundle-save-button">
                <Button onClick={onSave} primary loading={isSaving}
                        disabled={!bundle.filter1_value}
                >Save</Button>
            </div>

            <Modal open={chooseVariantsOpen} onClose={onCloseChooseVariants} title="Select variants"
                   primaryAction={{
                       content: 'Select',
                       onAction: chooseVariantsAction,
                   }}
                   secondaryActions={[
                       {
                           content: 'Cancel',
                           onAction: onCloseChooseVariants,
                       },
                   ]}>
                <Modal.Section>
                    <div className="bundle-variant-selection">
                        {variants && variants.map(variant => (
                            <div className="bundle-variant-item" key={variant.shopify_id}>
                                <Checkbox checked={variant.in_bundle}
                                          onChange={onInBundleChanged(variant.shopify_id)} />
                                {variant.image_src && <img alt="zoorix default" src={variant.image_src} className="bundle-variant-item-img"/>}

                                <span className="bundle-variant-item-title">{variant.title}</span>

                            </div>
                        ))}
                    </div>
                </Modal.Section>
            </Modal>
            <Modal secondaryActions={{
                content: 'Done',
                onAction: onCloseQuantitativeModal,
            }} open={quantitativeModalOpen} onClose={onCloseQuantitativeModal} title="Quantitative discounts"
                   >
                <Modal.Section>
                    <div className="qty-bundle-discount-wrapper">
                        <TextField label={<span>Discount for selecting <b>one</b> bundle item</span>} type="number"
                                   value={(typeof bundle.amount === 'number') && bundle.amount.toString()} autoComplete={false}
                                   onChange={onAmountChange} disabled={isSaving} />
                        <Select label="Type" options={discountTypeOptions(currencySymbol)}
                                onChange={onDiscountTypeChange(0)} value={bundle.discount_types[0] || 'percentage'} />
                    </div>
                    {[1,2,3,4,5,6,7].map(i =>
                        <div className="qty-bundle-discount-wrapper" key={i}>
                            <TextField label={<span>Discount for selecting <b>{numbers[i]}</b> bundle items</span>} type="number"
                                       value={(typeof bundle[`amount${i + 1}`] === 'number') && bundle[`amount${i + 1}`].toString()} autoComplete={false}
                                       onChange={onAmount2Change(i)} disabled={isSaving} />
                            <Select label="Type" options={discountTypeOptions(currencySymbol)}
                                    onChange={onDiscountTypeChange(i)} value={bundle.discount_types[i] || 'percentage'} />
                        </div>)}
                </Modal.Section>
            </Modal>
            <Modal open={chooseProductOpen} onClose={onCloseChooseProduct}
                title="Select a product"
                secondaryActions={[{ content: 'Cancel', onAction: onCloseChooseProduct }]}>
                <Modal.Section>
                    <div className="search-products bundle">
                        <TextField placeholder='Search phrase, e.g. Shirts for men' autoComplete={false}
                            label="Search:"
                            value={searchBoxText} onChange={onChangeSearchText}
                            onFocus={onFocusSearchText} />
                        {showSearchSpinner && <div className="fetch-search-wrapper"><span
                            className="searching">Searching...</span><span className="fetch-spinner"><Spinner
                                size="small" /></span></div>}
                        {searchError && <TextStyle variation="negative">Something went wrong</TextStyle>}
                        {searchedProducts && searchedProducts.map((item) => (
                            <div className={'product-container searchable bundle'} key={item.id}
                                onClick={chooseBundleItem(item.id)}>
                                <span className="image-wrapper">
                                    {item.image_src ? (
                                        <div className="item-image bundle"
                                            style={{ 'backgroundImage': 'url(' + item.image_src + ')' }} />
                                    ) : (
                                            <NoImage itemId={item.id} className={'item-image bundle'} />
                                        )}
                                </span>
                                <span className="bundle-searchable-item">{item.title}</span>
                            </div>
                        ))}
                    </div>
                </Modal.Section>
            </Modal>
        </FormLayout >
        </div>
    );

export default GroupedBundleEdit;
