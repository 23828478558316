import React from 'react';
import { Heading, List, TextContainer } from "@shopify/polaris";

import "../css/WarrantyInfo.css";

const WarrantyInfo = () => (
    <div className="warranty-info-wrapper">
        <TextContainer>
            <Heading>Zoorix warranty has 2 main purposes:</Heading>
            <List type="number">
                <List.Item>Help you sell more warranties and increase your AOV</List.Item>
                <List.Item>Help you manage the warranties redemption process</List.Item>
            </List>
            <Heading>How does it work?</Heading>
            <List type="number">
                <List.Item>You sell a warranty on your store</List.Item>
                <List.Item>User gets an email with a redemption link</List.Item>
                <List.Item>In case the item got broken, the user can use the link to upload an image of the broken item</List.Item>
                <List.Item>You can approve or reject the request</List.Item>
                <List.Item>If approved, the user gets a link with a discount code to buy in your store</List.Item>
                <List.Item>You decide how much discount the user will get as a percentage from the product price</List.Item>
            </List>
        </TextContainer>
    </div>
);

export default WarrantyInfo;
