import { connect } from 'react-redux'
import {diamondChangeRange} from "../../actions/plans";
import OurPricingQuartz from "../../components/plans/OurPricingQuartz";

const mapStateToProps = state => ({
    diamondRange: state.plans.diamondRange,
});

const mapDispatchToProps = (dispatch) => ({
    diamondChangeRange: value => dispatch(diamondChangeRange(value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OurPricingQuartz);
