import { connect } from 'react-redux'
import {diamondChangeRange} from "../../actions/plans";
import OurPricingDiamondFlat from "../../components/plans/OurPricingDiamondFlat";

const mapStateToProps = state => ({
    diamondRange: state.plans.diamondRange,
});

const mapDispatchToProps = (dispatch) => ({
    diamondChangeRange: value => dispatch(diamondChangeRange(value)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OurPricingDiamondFlat);
