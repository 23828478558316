import { connect } from 'react-redux'
import Pages from "../../components/products/Pages";
import {back, createPage, deletePage, editPage} from "../../actions/productsBundle";

const mapStateToProps = state => ({
    pages: state.productsBundle.pages,
    pageDeleting: state.productsBundle.pageDeleting,
});

const mapDispatchToProps = (dispatch) => ({
    back: () => dispatch(back()),
    newPage: () => dispatch(createPage()),
    editPage: id => () => dispatch(editPage(id)),
    deletePage: id => () => dispatch(deletePage(id)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Pages);
