import React from 'react';
import '../../css/Plans.css';
import {RangeSlider} from "@shopify/polaris";

const valueToText = {
    0: ['0 - 50', <b>Free</b>],
    1: ['51 - 100', <b>$7.99</b>],
    2: ['101 - 200', <b>$14.99</b>],
    3: ['201 - 500', <b>$19.99</b>],
    4: ['501 - 1,000', <b>$29.99</b>],
    5: ['1,001 - 2,000', <b>$39.99</b>],
    6: ['2,001 - 5,000', <b>$49.99</b>],
    7: ['5,001 - 10,000', <b>$79.99</b>],
    8: ['10,001 - 20,000', <b>$99.99</b>],
    9: ['20,001+', <span><b>$99.99</b> + $0.02/order</span>],
}

const OurPricingDiamond = ({diamondRange, diamondChangeRange}) => <div>
    <div className="our-pricing">Our pricing:</div>
    <div className="pricing-number-of-orders">How many monthly store orders do you have?</div>
    <div className="pricing-plans-picker">
        <RangeSlider
            label=""
            min={0}
            max={9}
            value={diamondRange}
            onChange={diamondChangeRange}
            output={false}
        />
    </div>
    <div className="pricing-calc-div">
        <div className="plans-calc-wrapper">
            <span className="plans-calc-left">Orders</span>
            <span className="plans-calc-right">Price</span>
        </div>
        <div className="plans-calc-wrapper">
            <span className="plans-calc-left">{valueToText[diamondRange || 0][0]} orders/month</span>
            <span className="plans-calc-right">{valueToText[diamondRange || 0][1]}</span>
        </div>
    </div>
</div>;

export default OurPricingDiamond;
