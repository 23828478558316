import { connect } from 'react-redux'
import {partnersSaveCodeBrandAndRedirect} from "../../actions/partners";
import RefBrand from "../../components/partners/RefBrand";

const mapStateToProps = state => ({
});

const mapDispatchToProps = dispatch => ({
    saveCodeAndRedirect: (code, brand) => dispatch(partnersSaveCodeBrandAndRedirect(code, brand)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(RefBrand);
