import { connect } from 'react-redux'
import VolumeDiscountEdit from "../../components/products/VolumeDiscountEdit";
import {
    onVolumeLocaleChange,
    volumeBack,
    volumeDiscountChooseProduct,
    volumeDiscountAddVariantTextChange,
    volumeDiscountNavigateToChange,
    volumeDiscountSubtitleChange,
    volumeDiscountTitleChange,
    volumeDiscountCloseChooseProduct,
    volumeDiscountChangeSearchText,
    volumeDiscountChooseProductItem,
    volumeDiscountAddTier,
    volumeDiscountQtyChange,
    volumeDiscountDiscountChange,
    volumeDiscountMarkAsPopular,
    volumeDiscountRemoveTier,
    volumeDiscountSave,
    volumeDiscountNameChange,
    volumeDiscountDisplayOnChange,
    volumeDiscountAddProduct,
    volumeDiscountRemoveSourceProduct,
    volumeDiscountToggleAddGroupActive,
    volumeDiscountGroupTypeChange,
    volumeDiscountGroupValueChange,
    volumeDiscountAddGroup,
    volumeDiscountRemoveSourceGroup,
    volumeDiscountCartDisplayOnChange,
    volumeDiscountProductDisplayOnChange,
    volumeDiscountOfferTypeChange,
    volumeDiscountToggleDynamicAddGroupActive,
    volumeDiscountDynamicGroupTypeChange,
    volumeDiscountDynamicGroupValueChange,
    volumeDiscountChooseDynamicGroup,
    volumeDiscountDiscountTypeChange,
    volumePredefinedVariantsChange,
    volumeVariantQtyChange,
    volumeAddVariant,
    volumeRemoveVariant, volumeVariantChange
} from "../../actions/productsVolume";

const mapStateToProps = state => ({
    locales: state.productsVolume.locales,
    locale: state.productsVolume.locale,
    isSaving: state.productsVolume.isSaving,
    discount: state.productsVolume.discount,
    saveError: state.productsVolume.saveError,
    chooseProductOpen: state.productsVolume.chooseProductOpen,
    searchBoxText: state.productsVolume.searchBoxText,
    showSearchSpinner: state.productsVolume.showSearchSpinner,
    searchError: state.productsVolume.searchError,
    searchedProducts: state.productsVolume.searchedProducts,
    groupAddition: state.productsVolume.groupAddition,
    collectionsReady: state.productsVolume.collectionsReady,
    dynamicGroupAddition: state.productsVolume.dynamicGroupAddition,

    currencySymbol: state.products.currencySymbol,
});

const mapDispatchToProps = dispatch => ({
    onBack: () => dispatch(volumeBack()),
    onSave: () => dispatch(volumeDiscountSave()),
    addTier: () => dispatch(volumeDiscountAddTier()),
    chooseProduct: () => dispatch(volumeDiscountChooseProduct()),
    onCloseChooseProduct: () => dispatch(volumeDiscountCloseChooseProduct()),
    addProduct: () => dispatch(volumeDiscountAddProduct()),
    toggleAddGroupActive: () => dispatch(volumeDiscountToggleAddGroupActive()),
    toggleDynamicAddGroupActive: () => dispatch(volumeDiscountToggleDynamicAddGroupActive()),
    onAddGroup: () => dispatch(volumeDiscountAddGroup()),
    onChooseDynamicGroup: () => dispatch(volumeDiscountChooseDynamicGroup()),
    onLocaleChange: text => dispatch(onVolumeLocaleChange(text)),
    onDiscountTitleChange: text => dispatch(volumeDiscountTitleChange(text)),
    onDiscountSubtitleChange: text => dispatch(volumeDiscountSubtitleChange(text)),
    onAddVariantTextChange: text => dispatch(volumeDiscountAddVariantTextChange(text)),
    onNavigateToChange: text => dispatch(volumeDiscountNavigateToChange(text)),
    onChangeSearchText: text => dispatch(volumeDiscountChangeSearchText(text)),
    onDiscountNameChange: text => dispatch(volumeDiscountNameChange(text)),
    onDisplayOnChange: text => dispatch(volumeDiscountDisplayOnChange(text)),
    onGroupTypeChange: text => dispatch(volumeDiscountGroupTypeChange(text)),
    onDynamicGroupTypeChange: text => dispatch(volumeDiscountDynamicGroupTypeChange(text)),
    onGroupValueChange: text => dispatch(volumeDiscountGroupValueChange(text)),
    onDynamicGroupValueChange: text => dispatch(volumeDiscountDynamicGroupValueChange(text)),
    onCartDisplayOnChange: text => dispatch(volumeDiscountCartDisplayOnChange(text)),
    onProductDisplayOnChange: text => dispatch(volumeDiscountProductDisplayOnChange(text)),
    onOfferTypeChange: text => dispatch(volumeDiscountOfferTypeChange(text)),
    onFocusSearchText: () => dispatch(volumeDiscountChangeSearchText('')),
    chooseProductItem: id => () => dispatch(volumeDiscountChooseProductItem(id)),
    onQtyChange: id => text => dispatch(volumeDiscountQtyChange(id, text)),
    onDiscountTypeChange: id => text => dispatch(volumeDiscountDiscountTypeChange(id, text)),
    onDiscountChange: id => text => dispatch(volumeDiscountDiscountChange(id, text)),
    onMarkAsPopular: id => bool => dispatch(volumeDiscountMarkAsPopular(id, bool)),
    removeTier: id => () => dispatch(volumeDiscountRemoveTier(id)),
    removeSourceProduct: id => () => dispatch(volumeDiscountRemoveSourceProduct(id)),
    removeSourceGroup: id => () => dispatch(volumeDiscountRemoveSourceGroup(id)),
    onPredefinedVariantsChange: id => () => dispatch(volumePredefinedVariantsChange(id)),
    addVariant: id => () => dispatch(volumeAddVariant(id)),
    onVariantQtyChange: (id, id2) => num => dispatch(volumeVariantQtyChange(id, id2, num)),
    onVariantChange: (id, id2) => text => dispatch(volumeVariantChange(id, id2, text)),
    removeVariant: (id, id2) => () => dispatch(volumeRemoveVariant(id, id2)),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(VolumeDiscountEdit);
