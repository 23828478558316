import React from 'react';
import {Button, Checkbox, List, Modal, Spinner} from '@shopify/polaris';
import '../../css/Pages.css'
import CsvUpload from "../CsvUpload";

const states = state => ({
    initializing: <span>Initializing...</span>,
    started: <span>Import started</span>,
    checking_file: <span>Checking file. Line: {state.line}</span>,
    creating_bundles: <span>Creating bundles. Progress: {state.i}/{state.total}</span>,
    success: <span>Import success!</span>,
    error: <div>
        <div className="upload-failed-msg"><span className="upload-status">Upload failed.</span> Here are
            the errors:
        </div>
        <List>{state.errors && state.errors.map((item, index) =>
            <List.Item key={index}>
                {item}
            </List.Item>)}
        </List>
    </div>,
})

const ImportExportBundles = ({ productsBundle, back, overrideExistingBundlesOnImportChange, uploadSuccess,
                                 uploadFailure, getSignedUrlCallback, toggleOverrideExistingBundlesModal,
                                 overrideExistingBundlesOnImport, data }) =>
    <div>
        <Button onClick={back}>Back</Button>
        <div className="import-mapping-wrapper">
            <div className="import-title">Import</div>
            <div className="import-example-csv">Use product handles to refer products - here's an <a
                href="https://public.zoorix.com/csv/import_bundles2.csv">example CSV</a>.
            </div>
            <div>
                {productsBundle.importState && productsBundle.importState.status && <div>
                    {states(productsBundle.importState)[productsBundle.importState.status]}
                </div>}
                <div className="upload-csv-msg">Upload a CSV:</div>
                <CsvUpload
                    uploadSuccess={uploadSuccess}
                    uploadFailure={uploadFailure}
                    getSignedUrlCallback={getSignedUrlCallback(data)}
                    afterButtonDiv={
                        <div>
                            <Checkbox label={'Override imported bundles'}
                                      checked={productsBundle.overrideExistingBundlesOnImport}
                                      onChange={overrideExistingBundlesOnImportChange}/>
                        </div>}
                />
            </div>
            {productsBundle.importState && productsBundle.importState.status === 'processing' && <div className="csv-processing">
                <span className="csv-processing-text">Processing CSV...</span>
                <Spinner size="small"/>
            </div>}
            {productsBundle.importState && productsBundle.importState.status === 'failure' && <div>
                There was an error. Please refresh.
            </div>}
        </div>
        <Modal open={productsBundle.overrideExisingBundlesOnImportModalOpen}
               onClose={toggleOverrideExistingBundlesModal(false)}
               title={'Override imported bundle'}
               primaryAction={{
                   content: 'Cancel',
                   onAction: toggleOverrideExistingBundlesModal(false),
               }}
               secondaryActions={[
                   {
                       content: 'Override',
                       onAction: overrideExistingBundlesOnImport,
                   },
               ]}>

            <Modal.Section>
                Warning! All of your bundles will be deleted, this action cannot be undone!
            </Modal.Section>
        </Modal>
    </div>;

export default ImportExportBundles;
