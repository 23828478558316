import React from 'react';
import '../../css/sticky-atc/StickyAtc.scss';
import {Heading, List, TextContainer, Button} from "@shopify/polaris";

const FreeShippingBarFaq = ({freeShippingBar}) => {
    return <div className="sticky-atc-faq">
        <TextContainer>
            <Heading>How to enable Zoorix?</Heading>
            <List type="number">
                <List.Item>
                    Go to <Button plain
                                  onClick={() => window.open(`https://${freeShippingBar.shop}/admin/themes/current/editor?context=apps`, '_blank')}>the
                    Shopify Editor</Button>
                </List.Item>
                <List.Item>
                    Make sure that Zoorix is enabled.
                </List.Item>
                <List.Item>
                    Hit save.
                </List.Item>
            </List>
        </TextContainer>

    </div>
};

export default FreeShippingBarFaq;
