import React from 'react';
import {
    Tabs
} from "@shopify/polaris";
import '../css/Integrations.scss'
import { integrationTabs } from "../actions/nav";


const Integrations = ({ nav, changeTab }) =>
    <div id="integrations-container">
        <div className="integrations-title">Integrations</div>
        <div className="integrations-subtitle">Discover new apps and connect your existing tools with Zoorix</div>
        <div className="integrations-tabs">
            <Tabs tabs={integrationTabs} selected={nav.integrationsTab} onSelect={changeTab}>
                {integrationTabs[nav.integrationsTab].id === 'all' && <div>
                    <div className="integrations-grid">
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/pagefly.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge page-builder-app">Page Builder</div>
                                    <div className="integration-grid-item-title">PageFly</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Easily drag & drop Zoorix deals within PageFly’s page builder
                            </div>
                            <div onClick={() => { window.open('https://pagefly.io?ref=MkukKDYa&target=app-listing') }} className="integration-grid-item-view">View Integration</div>

                        </div>
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/gempages-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge page-builder-app">Page Builder</div>
                                    <div className="integration-grid-item-title">GemPages</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Easily drag & drop Zoorix deals within PageFly’s page builder
                            </div>
                            <div onClick={() => { window.open('https://apps.shopify.com/gempages?utm_source=zoorix&utm_medium=referral&utm_campaign=refer-partner') }} className="integration-grid-item-view">View Integration</div>

                        </div>
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/lang-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge translation-app">Translation</div>
                                    <div className="integration-grid-item-title">LangShop</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Translate Zoorix widgets and offers using LangShop
                            </div>
                            <div onClick={() => { window.open('https://apps.shopify.com/langshop?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app') }} className="integration-grid-item-view">View Integration</div>
                        </div>
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/langify-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge translation-app">Translation</div>
                                    <div className="integration-grid-item-title">Langify</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Translate Zoorix widgets and offers using Langify
                            </div>
                            <div onClick={() => { window.open('https://apps.shopify.com/langify?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app') }} className="integration-grid-item-view">View Integration</div>

                        </div>
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/transy-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge translation-app">Translation</div>
                                    <div className="integration-grid-item-title">Transcy</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Translate Zoorix widgets and offers using Transcy
                            </div>

                            <div onClick={() => { window.open('https://apps.shopify.com/transcy-multiple-languages?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app') }} className="integration-grid-item-view">View Integration</div>
                        </div>
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/weglot-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge translation-app">Translation</div>
                                    <div className="integration-grid-item-title">WeGlot</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Translate Zoorix widgets and offers using WeGlot
                            </div>
                            <div onClick={() => { window.open('https://apps.shopify.com/weglot?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app') }} className="integration-grid-item-view">View Integration</div>

                        </div>
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/g-trans-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge translation-app">Translation</div>
                                    <div className="integration-grid-item-title">G | Translate</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Translate Zoorix widgets and offers using G | Translate
                            </div>
                            <div onClick={() => { window.open('https://apps.shopify.com/multilingual-shop-by-gtranslate?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app') }} className="integration-grid-item-view">View Integration</div>

                        </div>
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/t-lab-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge translation-app">Translation</div>
                                    <div className="integration-grid-item-title">Translation Lab</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Translate Zoorix widgets and offers using Translation Lab
                            </div>
                            <div onClick={() => { window.open('https://apps.shopify.com/content-translation?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app') }} className="integration-grid-item-view">View Integration</div>

                        </div>
                    </div>
                </div>}
                {integrationTabs[nav.integrationsTab].id === 'page-builders' && <div>
                    <div className="integrations-grid">
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/pagefly.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge page-builder-app">Page Builder</div>
                                    <div className="integration-grid-item-title">PageFly</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Easily drag & drop Zoorix deals within PageFly’s page builder
                            </div>
                            <div onClick={() => { window.open('https://pagefly.io?ref=MkukKDYa&target=app-listing') }} className="integration-grid-item-view">View Integration</div>

                        </div>
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/gempages-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge page-builder-app">Page Builder</div>
                                    <div className="integration-grid-item-title">GemPages</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Easily drag & drop Zoorix deals within PageFly’s page builder
                            </div>
                            <div onClick={() => { window.open('https://apps.shopify.com/gempages?utm_source=zoorix&utm_medium=referral&utm_campaign=refer-partner')}} className="integration-grid-item-view">View Integration</div>

                        </div>
                    </div>
                </div>}
                {integrationTabs[nav.integrationsTab].id === 'translations' && <div>
                    <div className="integrations-grid">
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/lang-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge translation-app">Translation</div>
                                    <div className="integration-grid-item-title">LangShop</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Translate Zoorix widgets and offers using LangShop
                            </div>
                            <div onClick={() => { window.open('https://apps.shopify.com/langshop?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app') }} className="integration-grid-item-view">View Integration</div>
                        </div>
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/langify-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge translation-app">Translation</div>
                                    <div className="integration-grid-item-title">Langify</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Translate Zoorix widgets and offers using Langify
                            </div>
                            <div onClick={() => { window.open('https://apps.shopify.com/langify?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app') }} className="integration-grid-item-view">View Integration</div>
                        </div>
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/transy-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge translation-app">Translation</div>
                                    <div className="integration-grid-item-title">Transcy</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Translate Zoorix widgets and offers using Transcy
                            </div>
                            <div onClick={() => { window.open('https://apps.shopify.com/transcy-multiple-languages?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app') }} className="integration-grid-item-view">View Integration</div>

                        </div>
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/weglot-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge translation-app">Translation</div>
                                    <div className="integration-grid-item-title">WeGlot</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Translate Zoorix widgets and offers using WeGlot
                            </div>
                            <div onClick={() => { window.open('https://apps.shopify.com/weglot?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app') }} className="integration-grid-item-view">View Integration</div>
                        </div>
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/g-trans-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge translation-app">Translation</div>
                                    <div className="integration-grid-item-title">G | Translate</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Translate Zoorix widgets and offers using G | Translate
                            </div>
                            <div onClick={() => { window.open('https://apps.shopify.com/multilingual-shop-by-gtranslate?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app') }} className="integration-grid-item-view">View Integration</div>
                        </div>
                        <div className="integration-grid-item">
                            <div className="integration-grid-item-top">
                                <div className="integration-grid-item-image">
                                    <img alt="" src="https://public.zoorix.com/images/t-lab-icon.svg" />


                                </div>
                                <div className="integration-grid-item-badge-title">
                                    <div className="integration-grid-item-badge translation-app">Translation</div>
                                    <div className="integration-grid-item-title">Translation Lab</div>
                                </div>
                            </div>
                            <div className="integration-grid-item-description">
                                Translate Zoorix widgets and offers using Translation Lab
                            </div>
                            <div onClick={() => { window.open('https://apps.shopify.com/content-translation?utm_campaign=app-listing&utm_source=app-providers&utm_medium=zoorix&utm_content=in-app') }} className="integration-grid-item-view">View Integration</div>
                        </div>
                    </div>
                </div>}
            </Tabs>
        </div>
    </div>;

export default Integrations;
