import { connect } from 'react-redux'
import Subscribed from "../components/Subscribed";

const mapStateToProps = state => {
    return {
    };
};

const mapDispatchToProps = (dispatch) => ({
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(Subscribed);
