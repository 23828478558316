import { connect } from 'react-redux'
import {
    appV2WizardBack,
    wizardFetchStage5Products
} from "../../../actions/v2/appV2Wizard";
import AppV2WizardConfigOffer from "../../../components/v2/wizard/AppV2WizardConfigOffer";

const mapStateToProps = state => ({
    appV2Wizard: state.appV2Wizard,
});

const mapDispatchToProps = (dispatch) => ({
    next: () => dispatch(wizardFetchStage5Products()),
    back: () => dispatch(appV2WizardBack()),
});

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(AppV2WizardConfigOffer);
