import React from 'react';
import { Frame, Toast, Card, Spinner } from "@shopify/polaris";
import '../../css/ProductsWarranty.css'
import VisibleWarranties from "../../containers/products/VisibleWarranties";
import VisibleWarrantyEdit from "../../containers/products/VisibleWarrantyEdit";
import VisibleWarrantyBulkEdit from "../../containers/products/VisibleWarrantyBulkEdit";

const ProductsWarranty = ({ toastIsActive, onDismissToast, isShowWarranties, isShowEdit, isShowBulkEdit, ready, load }) => {
    load();
    return <div>
        <Frame className="frame-wrapper">
            <div className={`warranty-card-wrapper ${ready && 'warranty-loaded'}`}>
                {ready ?
                    <Card>
                        <Card.Section sectioned>
                            {isShowWarranties && <VisibleWarranties/>}
                            {isShowEdit && <VisibleWarrantyEdit/>}
                            {isShowBulkEdit && <VisibleWarrantyBulkEdit/>}
                            {toastIsActive && <Toast content="Settings saved" onDismiss={onDismissToast}/>}
                        </Card.Section>
                    </Card>
                    :
                    <span className="spinner-wrapper"><Spinner/></span>}
            </div>
        </Frame>
    </div>
};

export default ProductsWarranty;
